import React, { memo } from 'react';
import './User.css';

function User({ userName, email }) {
  return (
    <div className="channels_settings_tab_panel_user_main_container">
      <img
        src={`//datact-avatars.s3.eu-west-1.amazonaws.com/users/${encodeURI(email)}`}
        onError={e => {
          if (!e.target.src?.endsWith('/user_placeholder.png'))
            e.target.src = '/user_placeholder.png';
        }}
        alt="user"
        className="channels_settings_tab_panel_user_icon"
      />
      <span>{userName}</span>
    </div>
  );
}

export default memo(User);
