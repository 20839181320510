import React, { useState, useEffect } from 'react';
import api from 'src/api';
import MiniGauge from './MiniGauge';
import heic2any from 'heic2any';

function PinContent({ media_type, media_url }) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    setImageUrl(null);
    if (media_url && media_url !== '/' && ['image', 'panorama', 'video'].includes(media_type)) {
      setLoading(true);
      api
        .call(`/getPresignedPinDownload`, { file_location: media_url })
        .then(res => {
          if (media_url.match(/.(heic|heif)$/i) && res?.url) {
            return fetch(res.url)
            .then(r => r.blob())
            .then(b => heic2any({ blob: b, quality: .5 }))
            .then(j => window.URL.createObjectURL(j))
            .then(u => setImageUrl(u));
          }

          setImageUrl(res.url);
        })
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [media_type, media_url]);

  if (media_type === 'image')
    return (
      <a
        href={imageUrl || '#'}
        target="_blank"
        rel="noreferrer"
        style={{ display: 'block', position: 'relative' }}
      >
        {imageUrl && (
          <img
            src={imageUrl}
            style={{ opacity: loading ? 0 : 1 }}
            className="popup__image"
            alt="Image"
            onLoad={() => {
              if (imageUrl) setLoading(false);
            }}
          />
        )}
        {loading && <div className="spinner image__loading" />}
      </a>
    );

  if (media_type === 'panorama')
    return loading ? (
      <div className="spinner image__loading" />
    ) : (
      <div className="panorama">
        <iframe
          src={`panorama/pannellum.htm#autoLoad=true&minHfov=5&panorama=${encodeURIComponent(
            imageUrl
          )}`}
          border="0"
          width="485"
          height="400"
          allow="fullscreen"
        ></iframe>
      </div>
    );

  if (media_type === 'video') {
    return (
      imageUrl && (
        <video controls width="485" preload="auto">
          <source src={imageUrl} />
          <a href={imageUrl}>Download</a>
        </video>
      )
    );
  }

  if (media_type === 'vegagerdin') {
    return <div className="livestream">
      {media_url.map((u, i) =>
        <img key={i} src={u} onLoad={e => setTimeout(() => { e.target.src = e.target.src; }, 60e3)} style={{width: '100%'}} />
      )}
    </div>
  }

  if (media_type === 'live_camera')
    return (
      <div className="livestream">
        <iframe src={media_url} border="0" width="540" height="400" allow="fullscreen"></iframe>
      </div>
    );

  if (media_type === 'file')
    return (
      <div
        className="comment-attachment-download"
        title="Download Attachment"
        onClick={async () => {
          const response = await api.call(`/getPresignedPinDownload`, { file_location: media_url });
          if (!response?.url) throw JSON.stringify(response, null, 2);
          window.open(response.url);
        }}
      >
        <img src="/icons/download.svg" />
        {media_url.split('/').pop()}
      </div>
    );

  if (media_type === 'camera_trap') {

    let observations = [
      { date: '2018-11-29T21:13:11.000Z', confidence: 62, classification: 'Other Animal' },
      { date: '2018-09-09T09:37:23.000Z', confidence: 86, classification: 'Other Animal' },
      { date: '2018-09-09T03:25:22.000Z', confidence: 62, classification: 'Other Animal' },
      { date: '2018-08-23T08:46:43.000Z', confidence: 47, classification: 'Other Animal' },
      { date: '2018-08-21T07:45:56.000Z', confidence: 11, classification: 'Other Animal' },
    ]

    try { observations = JSON.parse(media_url) } catch (e) { console.error(e); }

    return (
      <>
        <div className="camera_trap_container">
          <div>
            <p>
              Status: <b className="active">Active</b>
            </p>
            <p>
              Observations: <b>{observations.length}</b>
            </p>
          </div>
          <div className="conservation-status-selector">
            {[
              [0, 'CR', 'Critically Endangered'],
              [0, 'EN', 'Endangered'],
              [0, 'VU', 'Vulnerable'],
              [0, 'NT', 'Near Threatened'],
            ].map(([count, code, name]) => (
              <div key={code}>
                <span className="conservation-status">{code}</span>
                {name}: <b>{count}</b>
              </div>
            ))}
          </div>
        </div>
        <div className="camera_trap_snapshots">
          {observations.map(({ classification, confidence, date }, i) => {
            const color = confidence > 75 ? '#60D381' : confidence > 50 ? '#E8B466' : '#C4C4C4'; // green-orange-gray
            return (
              <div key={i}>
                <p>{date.replace(/[TZ]/g, ' ').slice(0, 19)}</p>
                <p className="confidence" title={`Confidence: ${confidence}%`}>
                  <MiniGauge
                    radius={15}
                    thickness={confidence > 75 ? 3 : 2}
                    progress={confidence / 100}
                    background={'transparent'}
                    foreground={color}
                    text={confidence.toFixed(0).padStart(2, '0')}
                  />
                  <span style={{ color }}>%</span>
                </p>
                <p>{classification}</p>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default PinContent;
