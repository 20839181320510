import React, { memo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import tooltipIcon from 'public/icons/tooltip.svg';
import './Tooltip.css';

function Tooltip({ tooltipId, tooltipContent, isError }) {
  if (!tooltipId || !tooltipContent) {
    return null;
  }

  return (
    <>
      <img
        src={tooltipIcon}
        data-tooltip-id={tooltipId}
        className={isError ? 'tool_tip_error' : undefined}
      />
      <ReactTooltip
        id={tooltipId}
        place="right"
        content={tooltipContent}
        className="common_tooltip"
      />
    </>
  );
}

export default memo(Tooltip);
