import React from 'react';

export default function MiniGauge({ radius, thickness, background, foreground, progress, text }) {
    const diameter = 2 * radius, perimeter = Math.PI * diameter, size = 2*(radius+thickness);
    return (
        <svg width={size} height={size}>
            <circle cx="50%" cy="50%" r={radius} strokeWidth={thickness} stroke={background} fill="transparent" />
            <circle cx="50%" cy="50%" r={radius} strokeWidth={thickness} stroke={foreground} fill="transparent"
                strokeDasharray={perimeter}
                strokeDashoffset={perimeter*(1-progress)} 
            />
            <text x="50%" y="50%" stroke={foreground}
                textAnchor="middle"
                alignmentBaseline="central"
            >{text}</text>
        </svg>
    );
}