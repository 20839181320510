import React, { useState } from 'react';
import './Water.less';
import InputPro from 'src/Common/InputPro';
import SelectPro from 'src/Common/SelectPro/SelectPro';
import BaseButton from 'src/Common/Buttons/BaseButton';
import api from 'src/api';

const Water = ({ asset, address, coordinates, handleBack, reload, edit }) => {
  const [waterType, setType] = useState('abstraction');
  const [waterSource, setWaterSource] = useState(null);
  const [amount, setAmount] = useState(null);

  const sources = [
    { value: 'surface', label: 'Surface Water' },
    { value: 'municipality', label: 'Municipality' },
    { value: 'fresh', label: 'Fresh Water' },
    { value: 'ground', label: 'Ground Water' },
    { value: 'other', label: 'Other' },
  ];

  const origin_date = new Date();

  const confirmed = !!coordinates;

  async function CreateWater() {
    const payload = {
      origin_date: origin_date,
      action: waterType,
      source: waterSource.value,
      amount: parseFloat(amount),
      active: true,
      confirmed: confirmed,
      location: coordinates || null,
    };

    try {
      const success = await api.call('/asset/water_usage/create', {
        asset_id: asset.asset_id,
        ...payload,
      });
      console.log(success);
      reload();
    } catch (error) {
      console.log('Error creating water', error);
    }
  }

  return (
    <div className="water_container">
      <div className="sub_asset_header">
        <img width="38px" src="/icons/water.svg" /> Water
      </div>
      <div className="radio_header">Choose water source type</div>
      <div className="water_element radio">
        <BaseButton
          additionalClassName="water_action_button"
          onClick={() => setType('abstraction')}
          buttonType={waterType == 'abstraction' ? 'STANDARD' : 'SECONDARY'}
        >
          Abstraction
        </BaseButton>
        <BaseButton
          additionalClassName="water_action_button"
          onClick={() => setType('discharge')}
          buttonType={waterType == 'discharge' ? 'STANDARD' : 'SECONDARY'}
        >
          Discharge
        </BaseButton>
      </div>
      <div className="water_element select_group">
        <SelectPro onChange={setWaterSource} options={sources} name="Water Source"></SelectPro>
        <InputPro
          bindValue={setAmount}
          label="Amount"
          fixedLabel
          className="water_amount"
          type="number"
          subFix={'hL'}
        ></InputPro>
      </div>

      <BaseButton
        additionalClassName="cancel_button"
        buttonType={'SECONDARY'}
        onClick={() => handleBack()}
      >
        Back
      </BaseButton>
      <BaseButton
        title="Create Water"
        additionalClassName="continue_button"
        disabled={!amount || !waterSource || !waterType}
        buttonType={'STANDARD'}
        onClick={() => CreateWater()}
      >
        Create
      </BaseButton>
    </div>
  );
};

export default Water;
