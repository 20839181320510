import React, { memo, useMemo } from 'react';
import { STATUS_TYPES } from 'src/shared/constants';
import './Status.css';

function Status({ status, className }) {
  const status_class_name = useMemo(() => {
    switch (status) {
      case STATUS_TYPES.CONFIRMED:
        return 'nature_reporting_details_green_status';
      case STATUS_TYPES.ACTIVE:
        return 'nature_reporting_details_green_status';
      case STATUS_TYPES.ARCHIVE:
        return 'nature_reporting_details_gray_status';
      case STATUS_TYPES.ESTIMATED:
        return 'nature_reporting_details_gray_status';
      default:
        return 'nature_reporting_details_red_status';
    }
  }, [status]);

  return (
    <p className={`nature_reporting_details_status ${status_class_name} ${className && className}`}>
      {status}
    </p>
  );
}

export default memo(Status);
