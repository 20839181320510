import React, { useEffect, useState } from 'react';
import api from 'src/api';
import BaseButton from 'src/Common/Buttons/BaseButton';
import './CreateChart.less';
import ChartsPro from 'src/Common/ChartsPro/ChartsPro';
import ReactSelect from 'react-select';
import InputPro from 'src/Common/InputPro';

const CreateChart = ({ assetDetails, cancel, area }) => {
  const [chartType, setChartType] = useState('line');
  const [spreadSheetList, setSpreadSheetList] = useState([]);
  const [selectedSpreadSheet, setSelectedSpreadSheet] = useState(null);

  const [clickedColumns, setClickedColumns] = useState([]);

  const [selectedColumn, setSelectedColumn] = useState(-1);
  const [selectedValue, setSelectedValue] = useState(null);

  const [valueDirection, setValueDirection] = useState('none');

  const [labels, setLabels] = useState([]);
  const [xAxis, setXAxis] = useState([]);

  const [vertical, setVertical] = useState(false);
  const [stacked, setStacked] = useState(false);

  const [yAxisName, setYAxisName] = useState('Y-Axis');

  const [step, setStep] = useState(1);

  const chartTypes = [
    { value: 'single', chart:'single', label: 'KPI', image: '/icons/edit.svg', vertical: false },
    { value: 'line', chart:'line', label: 'Line Chart', image: '/icons/dataframes/line_chart.svg', vertical: false },
    { value: 'bar', chart:'bar', label: 'Bar Chart', image: '/icons/dataframes/bar_chart.svg', vertical: false },
    { value: 'verticalbar', chart:'bar', label: 'Vertical Bar Chart', image: '/icons/dataframes/bar_chart.svg', vertical: true},
    { value: 'scatter', chart:'scatter', label: 'Scatter Plot', image: '/icons/dataframes/scatter_chart.svg', vertical: false },
    { value: 'pie', chart:'pie', label: 'Pie Chart', image: '/icons/dataframes/pie_chart.svg', vertical: false},
  ];

  useEffect(() => {
    const fetchSpreadSheet = async () => {
      const response = await api.call('/asset/dataframe/list', { area_id: area.identifier });
      const spreadSheetList = response.success ? response.success.map(item => ({
        label: item.name,
        value: item.name,
        data: item.data,
        id: item.dataframe_id
      })) : [];
      setSpreadSheetList(spreadSheetList);
    };
  
    fetchSpreadSheet();
  }, [area]);

  useEffect(() => {
      setClickedColumns([]);
      
      setSelectedValue([]);
      if(chartType == 'pie') {
        setSelectedColumn(1);
      }
      else {
        setSelectedColumn(-1);
      }
  }, [chartType, selectedSpreadSheet]);

  const handleColumnClick = index => {
    setSelectedColumn(index);
    if (index == 0) return;
    console.log('Clicked Rows:', clickedColumns, index);
    if (clickedColumns.includes(index)) {
      setClickedColumns(clickedColumns.filter(row => row !== index));
    } else {
      setClickedColumns([...clickedColumns, index]);
    }
  };

  const handleValueClick = (column, row) => {
    if (row == -1) return;
    setSelectedValue([parseFloat(column), parseFloat(row)]);
  };

  const handleValueDirection = direction => {
    if (direction == valueDirection) {
      setValueDirection('none');
      return;
    }
    setValueDirection(direction);
  };

  async function uploadChart() {
    if (!selectedSpreadSheet) return;
    if (chartType == 'pie') {
      await api
      .call('/asset/chart/create', {
        dataframe_id: selectedSpreadSheet.id,
        chart_type: 'pie',
        label_columns: [xAxis[0].toString()],
        value_columns: labels.map(item => JSON.stringify(item)),
        vertical: false,
        stacked: false
      }).then(res => console.log(res));
    }
    else if (chartType != 'single') //all charts except kpi and pie
    {
      await api
      .call('/asset/chart/create', {
        dataframe_id: selectedSpreadSheet.id,
        chart_type: chartType,
        label_columns: labels,
        value_columns: xAxis.map(item => JSON.stringify(item.map(number => number.toString()))),
        vertical: vertical,
        stacked: stacked,
        y_axis_name: yAxisName
      }).then(res => console.log(res));
    }
    else { //Kpi chart
      await api
      .call('/asset/chart/create', {
        dataframe_id: selectedSpreadSheet.id,
        chart_type: 'block',
        label_columns: [headers[selectedColumn]],
        value_columns: [values[selectedValue[1] + 1][selectedValue[0]], valueDirection],
        vertical: false,
        stacked: false,
      }).then(res => console.log(res));
    }
    console.log('Chart Uploaded');
    cancel();
  }

  function ChartData(labels, xAxis) {
    setLabels(labels);
    setXAxis(xAxis);
    console.log(labels, xAxis);
  }

  let values = selectedSpreadSheet?.data[0].values || [];
  let headers = values.length > 0 ? values[0] : [];

  return (
    <div>
      <div className="select_csv">
        <div className='csv_select_label'>
        <div className='area_label_header'>{area.label || "No Area"}</div>
        <div>Select Spreadsheet</div>
        <ReactSelect
          options={spreadSheetList}
          className='select_spreadsheet_chart'
          onChange={setSelectedSpreadSheet}
          placeholder="Select Spreadsheet"
          noOptionsMessage={() => 'No Spreadsheets found in this area'}
          formatOptionLabel={option => (
            <div className="chart-select-option">
              <img src={'/icons/copy.svg'} alt="country-select-image" style={{ verticalAlign: "middle" }}/>
              <span style={{marginLeft: "15px", verticalAlign: "middle" }}>{option.label}</span>
            </div>)}
        />
        </div>
        <div className="csv_select_label">
          <div>Select Chart</div>
          <ReactSelect options={chartTypes} defaultValue={chartTypes[1]} onChange={(e) => {setChartType(e.chart); setVertical(e.vertical)}} formatOptionLabel={option => (
          <div className="chart-select-option">
            <img src={option.image} alt="country-select-image" style={{ verticalAlign: "middle" }}/>
            <span style={{marginLeft: "15px", verticalAlign: "middle" }}>{option.label}</span>
          </div>)}>
          </ReactSelect>
          </div>



          {(chartType !== 'single' && chartType !== 'pie') && (
          <> 
          {console.log(yAxisName)}
            <InputPro className='y_axis_name' label='Y-Axis Name' bindValue={setYAxisName}/>
            <div className='check_attributes'>
                <input type="checkbox" id="stacked" name="stacked" checked={stacked} onChange={() => setStacked(!stacked)} />
                <label htmlFor="stacked">Stacked</label>
            </div>
          </>)}
      </div>

      {chartType != 'single' && (
        <ChartsPro
          chartType={chartType}
          spreadSheet={selectedSpreadSheet}
          disabledColumns={clickedColumns}
          ChartData={ChartData}
          selectedColumn={selectedColumn}
          vertical={vertical}
          stacked={stacked}
        />
      )}
      
      {chartType == 'single' && (
        <div className='single_value_container'>
        <div className="single_value_chart">
            <div className="single_value">{(values.length && selectedValue?.length) ? values[selectedValue[1] + 1][selectedValue[0]] : 'Value'}</div>
            <div className="single_value_title">{headers.length && selectedColumn > -1 ? headers[selectedColumn] : 'Header'}</div>
        </div>

        <div className='value_arrows'>
          <div onClick={() => handleValueDirection('up')} style={{color: valueDirection == 'up' ? 'green' : 'black'}}>↑</div>
          <div onClick={() => handleValueDirection('down')} style={{color: valueDirection == 'down' ? 'red' : 'black'}}>↓</div>
        </div>
        </div>
      )}

      {/* Adjusted Table with direct values usage */}
      {values.length > 1 && (
        <div className="chart_container">
          <div className="table_container">
            <table>
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      style={
                        (chartType === 'single' || chartType === 'pie') ? {
                          color: selectedColumn == index ? 'green' : 'black',
                        } : {
                        color: clickedColumns.includes(index) ? '#b6b6b6' : 'black',
                        backgroundColor: index === 0 ? '#ebebeb' : 'transparent',
                        fontWeight:
                          clickedColumns.includes(index) || index === 0 ? 'bold' : 'normal',
                      }}
                      onClick={() => handleColumnClick(index)}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                        {(index !== 0 && chartType !== 'single' && chartType !== 'pie') && (
                           <input 
                           type='checkbox' 
                           name="chartOption" // Assuming all radios are part of a group
                           checked={!clickedColumns.includes(index)} // Assuming `header` is a unique value for each option
                         />
                        )}{' '}
                        {header}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {values.slice(1).map((value, rowIndex) => (
                  <tr key={rowIndex}>
                    {value.map((val, colIndex) => (
                      <td
                        key={`${rowIndex}-${colIndex}`}
                        onClick={() => handleValueClick(colIndex, rowIndex)}
                        style={ 
                          (chartType === 'single') ? { 
                            color: selectedValue?.length && selectedValue[0] === colIndex && selectedValue[1] === rowIndex ? 'blue' : 'black'
                          } : (chartType === 'pie') ?
                          {
                            color: (selectedColumn !== (colIndex) && colIndex === 0) ? '#b6b6b6' : 'black',
                            backgroundColor: colIndex === 0 ? '#ebebeb' : 'transparent',
                            fontWeight: selectedColumn !== (colIndex) || colIndex === 0 ? 'bold' : 'normal'
                          } :
                          {
                          color: clickedColumns.includes(colIndex) ? '#b6b6b6' : 'black',
                          backgroundColor: colIndex === 0 ? '#ebebeb' : 'transparent',
                          fontWeight: clickedColumns.includes(colIndex) || colIndex === 0 ? 'bold' : 'normal'
                        }
                      }>
                        {val}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <BaseButton buttonType="STANDARD" additionalClassName="continue_button" onClick={uploadChart}>Upload Chart</BaseButton>
      <BaseButton buttonType="SECONDARY" additionalClassName="cancel_button" onClick={cancel}>Cancel</BaseButton>
    </div>
  );
};


export default CreateChart;
