import React, { useState, useEffect } from 'react';
import './SwitchTeam.less';

import api from 'src/api';
import Avatar from 'src/Common/Avatar';
import Policy from 'src/Policy';
import Analytics from 'src/Analytics';

function SwitchTeam() {
  let [teams, setTeams] = useState(null);
  let [consent, setConsent] = useState(true);
  let [externalChannels, setExternalChannels] = useState([]);

  const init = async () => {
    try {
      const result = await api.call('/team/list');
      if (!result?.success) throw new Error('getTeams failed');

      const email = api.claims?.['https://datact.svarmi.com/email'];
      const external = await api.call('/channel/external', { email: email });
      setExternalChannels(external?.success);
      setConsent(result.success.tc_consent);

      const userDetails = await api.call('/user/details', { email: email });
      console.log(userDetails);

      const teamDetails = userDetails.user.teams.map(team => {
        const activeUntilDate = team.active_until ? new Date(team.active_until) : new Date('2030'); // tmp fix until prod deploy
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const expired = activeUntilDate < yesterday;

        return {
          name: team.name,
          expired,
        };
      });

      const sortedTeams = teamDetails.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );

      setTeams(sortedTeams);
    } catch (error) {
      Analytics.reportClientError(error);
      console.trace(error);
      setTeams([]);
      setConsent(true);
    }
  };

  const acceptTCConsent = async () => {
    return (await api.call('/acceptTCConsent')).success;
  };

  const switchTeam = async team => {
    console.log(team);
    console.log(externalChannels);
    if (team.expired) return;

    try {
      setTeams(null);
      // Update user_metadata.selected_team to the new team, then force-refresh the token to have this updated info baked in
      await api.call('/switchTeam', { team: team.name });
      await api.forceRefreshToken();
    } catch (error) {
      console.info(error);
    }
  };

  // On first load only
  useEffect(() => {
    init();
  }, []);

  return (
    <div className="main__container">
      <div
        className="login-background"
        style={{ backgroundImage: "url('/login_background.jpg')" }}
      />
      <div className="company__selection__container">
        <div className="company__selection__info">
          <h1>Welcome</h1>
          <p>Select your team</p>
          {!teams ? (
            <span className="spinner" />
          ) : teams.length === 0 ? (
            <div className="noteam__container">
              <span style={{ fontSize: '60px' }}>☹</span>
              <br />
              You do not seem to be part of any team at this time.
            </div>
          ) : (
            <div className="company__container">
              {teams.map((team, index) => {
                return (
                  <div
                    className={'company__icon ' + (team.expired ? 'expired' : '')}
                    title={team.expired ? 'Expired' : team.name}
                    onClick={() => switchTeam(team)}
                    key={index}
                  >
                    {/* Assuming Avatar component can handle team objects or you might want to pass specific properties */}
                    <Avatar team={team.name} size={108} />
                    <span>{team.name}</span>
                  </div>
                );
              })}
            </div>
          )}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          {!externalChannels ? null : externalChannels.length === 0 ? null : (
            <>
              {!teams ? null : (
                <div>
                  <div className="company__selection__info">
                    <p>External Channels</p>
                  </div>
                  <div className="externals__container">
                    {externalChannels.map((channel, index) => {
                      if (channel.name === 'main') {
                        // Skip rendering channels with the name "main"
                        return null;
                      }

                      return (
                        <div
                          className="external_company__icon"
                          onClick={() => switchTeam({ name: channel.team_name })}
                          key={index}
                        >
                          <Avatar team={channel.team_name} size={108} />
                          <span>{'Channel:' + channel.name + '\nTeam:' + channel.team_name}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          )}

          <div className="actions">
            <div
              onClick={() =>
                api.auth0.logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              <img src="icons/log_out.svg" alt="logout" />
              <span>Log out</span>
            </div>
          </div>
        </div>
        <div className="icon__item">
          <img src="/svarmi-vector-01.svg" alt="logo" className="logo"></img>
        </div>
      </div>

      {!consent && (
        <div className="policy__main__container">
          <Policy />
          <div style={{ display: 'flex' }}>
            <button
              className="policy__button"
              onClick={async () => {
                await acceptTCConsent();
                setConsent(true);
              }}
            >
              Accept
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SwitchTeam;
