import React, { useEffect, useState } from 'react';
import utils from 'src/utils';
import api from 'src/api';
import { useStore } from 'src/store';
import exifr from 'exifr';
import Analytics from 'src/Analytics';
import Input from 'src/Common/Input';
import ErrorMessage from 'src/Common/notificationMessages/ErrorMessage';
import MenuPage from 'src/Common/MenuPage';
import PinColorPicker from 'src/Pins/PinColorPicker';
import CommentComposer from 'src/Pins/CommentComposer';
import LocationSelection from 'src/Assets/LocationSelection';

export default function SinglePinUpload(props) {
  const store = useStore();
  const isFullMode = store.ui.menuFull;
  const [loading, setLoading] = useState(false);
  const fileSizeLimit = 200 * Math.pow(2, 20); // 200mb
  const [error, setError] = useState('');
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState('yellow');
  const [photo, setPhoto] = useState(null);
  const [preview, setPreview] = useState(null);

  /* Pin location */
  const [address, setAddress] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [addressLock, setAddressLock] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);

  const [selectedTab, setSelectedTab] = useState('Gps');

  useEffect(() => {
    const l = store.ui.mapPreviewPin?.location;
    if (!l) return;
    getGeocodedPlaceName(l).then(setTitle);
    store.ui.map?.state.olmap?.jumpTo({ point_4326: l });
  }, []);

  useEffect(() => {
    const { location } = store.ui.mapPreviewPin || {};

    if (location && selectedTab === 'Manual') {
      const [longitude, latitude] = location.map(parseFloat);

      setLongitude(longitude);
      setLatitude(latitude);
      console.log(longitude, latitude);
      updateAddress([longitude, latitude]);
    }
  }, [store.ui.mapPreviewPin, store.ui.mapPreviewPin?.location, selectedTab]);

  async function getGeocodedPlaceName(position) {
    try {
      const url =
        `https://api.mapbox.com/geocoding/v5/mapbox.places/` +
        `${position[0]},${position[1]}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;
      const result = await fetch(url);
      const data = await result.json();
      return data.features[0].place_name;
    } catch (e) {
      return '';
    }
  }

  async function updateAddress(position) {
    if (store.ui.mapPreviewPin.style != 'manual') return;
    const name = await getGeocodedPlaceName(position);
    if (name) store.ui.mapPreviewPin.address = name;
  }

  useEffect(() => {
    store.ui.mapPreviewPin.style = 'asset';
    if (selectedTab === 'Manual') {
      store.ui.mapPreviewPin.style = 'manual';
    } else {
      store.ui.mapPreviewPin.style = 'asset';

      if (longitude && latitude) {
        store.ui.mapPreviewPin.location = [longitude, latitude];
      }

      if (selectedTab == 'Gps') {
        if (longitude != 0 && latitude != 0 && (longitude || latitude)) {
          console.log(longitude + ' ' + latitude);
          store.ui.map?.state.olmap?.jumpTo({ point_4326: [longitude, latitude] });
        }
        if (longitude) setLongitude(parseFloat(longitude));
        if (latitude) setLatitude(parseFloat(latitude));
      }
    }
  }, [selectedTab, longitude, latitude]);

  useEffect(() => {
    console.log(addressLock);
    if (!addressLock) {
      setAddress(store.ui.mapPreviewPin.address);
    }
  }, [store.ui.mapPreviewPin.address]);

  useEffect(() => {
    if (store.ui.mapPreviewPin.location) {
      setDefaultValue(store.ui.mapPreviewPin.location);
    }
  }, [store.ui.mapPreviewPin?.location?.toString?.()]);

  function zoomLocation(location) {
    console.log(location);
    store.ui.map?.state.olmap?.jumpTo({ point_4326: [location.center[0], location.center[1]] });
  }

  const locationInfo = {
    isFullMode: isFullMode,
    selectedTab: selectedTab,
    setSelectedTab: setSelectedTab,
    address: address,
    setAddress: setAddress, // include the setter function here
    longitude: store.ui.mapPreviewPin?.location?.[0] || longitude,
    setLongitude: setLongitude,
    latitude: store.ui.mapPreviewPin?.location?.[1] || latitude,
    setLatitude: setLatitude,
    setAddressExternal: zoomLocation,
    addressLock: addressLock,
    setAddressLock: setAddressLock,
    defaultValue: defaultValue,
    defaultIndex: 1,
    centerStyle: true,
  };

  /* End of location */

  async function uploadSinglePin() {
    let pinDetails, resultS3, resultAPI;
    try {
      setLoading(true);

      // transform the coordinats to lon lat
      pinDetails = {
        media_type: 'image',
        media_urls: [],
        title: title,
        text: comment,
        geog: store.ui.mapPreviewPin.location,
        status: status,
        email: store.session.user.email,
        team_name: store.session.team.name,
        channel_name: store.session.channel.name,
      };

      if (photo) {
        try {
          const { DateTimeOriginal, ExifImageWidth: w, ExifImageHeight: h } = await exifr.parse(photo, [
            'DateTimeOriginal',
            'ExifImageWidth',
            'ExifImageHeight',
          ]);

          // Also fix origin_date here
          pinDetails.origin_date = DateTimeOriginal.toISOString() || photo.lastModifiedDate.toISOString();

          // Panorama detection based on aspect ratio equal exactly 2
          if (w == 2 * h || h == 2 * w) pinDetails.media_type = 'panorama';
        } catch (e) { e; }

        // Get presign url to upload the pin image
        const presignResponse = await api.call(`/getPresignedPinUpload`, { filename: photo.name });
        const signedRequestBundle = presignResponse.success;

        if (!signedRequestBundle)
          throw new Error(
            `Unable to obtain an upload URL: ${presignResponse.error || presignResponse}`
          );

        // Then build a request from the signed bundle
        const formFields = new FormData();
        Object.entries(signedRequestBundle.fields).forEach(_ => formFields.append(..._));
        formFields.append('file', photo);

        // And send it
        const result = await fetch(signedRequestBundle.url, {
          method: 'POST',
          body: formFields,
        });

        store.ui.mapPreviewPin.location = null;

        if (!result.ok) throw new Error(`Problem while uploading: ${JSON.stringify(result)}`);
        pinDetails.media_urls = [signedRequestBundle.fields.key];
      }

      const resultAPI = await api.call('/pin/create', pinDetails);
      if (!resultAPI.success) throw 'Failed to create pin';
    } catch (error) {
      console.info(error);
      error.pinDetails = pinDetails;
      error.resultS3 = resultS3;
      error.resultAPI = resultAPI;
      Analytics.reportClientError(error);
      throw `Something went wrong!`;
    } finally {
      await props.refreshPins();
      setComment('');
      setTitle('');
      setLoading(false);
      props.onClose();
    }
  }

  async function validatePhoto(photo) {
    // Check at least one photo selected
    if (!photo) setError('Please select at least one photo to upload');

    // check if photo is too big
    if (photo.size > fileSizeLimit) {
      setError(
        `"${photo.name}" (${utils.formatBytes(
          photo.size
        )}) exceeds our file size limit (${utils.formatBytes(fileSizeLimit)})`
      );
    }
  }

  async function readPhoto(event) {
    try {
      setLoading(true);
      event.preventDefault();

      const selectedPhoto = event.target.files[0];

      validatePhoto(selectedPhoto);
      const tmpURL = window.URL.createObjectURL(selectedPhoto);
      setPreview(tmpURL);

      setPhoto(selectedPhoto);
    } catch (error) {
      console.info(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <MenuPage
      title={'Create Pin'}
      onClickBack={props.onClose}
      isExpandButtonShowed
      icon={'/icons/toolbar/pin.svg'}
      isIconShowed={true}
      isShowingChannel={false}
      isArrowButtonShowed={true}
    >
      <LocationSelection locationInfo={locationInfo} />
      <div className="single-pin-upload" style={{ width: 500, margin: '0 auto' }}>
        {error && (
          <ErrorMessage
            id="error-message"
            data-test="error-alert"
            variant="danger"
            error={error}
            setError={setError}
          ></ErrorMessage>
        )}

        {loading && <div className="spinner image__loading" />}
        {!loading && (
          <>
            <Input
              value={title}
              onChangeInput={e => setTitle(e.target.value)}
              typeInput="title"
              placeholder="Title"
              autoFocus={true}
            />

            <CommentComposer
              editing={''}
              onChange={e => setComment(e.commentText)}
              onSubmitComment={e => uploadSinglePin()}
            />

            <div className="dropzone" style={{ height: 200 }}>
              {preview && (
                <div className="overlay">
                  <div style={{ backgroundImage: `url("${preview}")` }}></div>
                </div>
              )}
              {!preview && (
                <div>
                  Drag and drop an image into this box, or click here to open the file browser and
                  select the image manually.
                </div>
              )}
              <input type="file" autoComplete="off" tabIndex="-1" onChange={readPhoto} />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                margin: '20px auto',
              }}
            >
              <p style={{ color: 'var(--gold)', marginRight: 20 }}>Select pin color: </p>
              <PinColorPicker currentStatus={status} updateStatus={setStatus} />
            </div>
            <button disabled={!title} className="base_button" onClick={uploadSinglePin}>
              Add a pin
            </button>
          </>
        )}
      </div>
    </MenuPage>
  );
};