// RedList
export const RED_LIST_NAMES = {
  cr: 'Critically Endangered',
  en: 'Endangered',
  vu: 'Vulnerable',
  nt: 'Near Threatened',
}

// Charts
export const AXIS_LABEL = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 15,
  fontFamily: 'Open Sans',
};

export const AXIS_LINE = {
  show: true,
  lineStyle: {
    color: '#000',
  },
};

// NatureTrendsChart
export const NATURE_TRENDS_CHART_OPTION = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    textStyle: { ...AXIS_LABEL, color: '#747474' },
    formatter: p => `${p[0].value.toFixed(3)}<br /> Year:${p[0].axisValue}`, // '{c0.toFixed(3)}<br /> Year: {b0}',
  },
  color: '#006400',
  grid: {
    containLabel: true,
    left: '10px',
    right: '10px',
    bottom: '10px',
    top: '10px',
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLabel: AXIS_LABEL,
    axisLine: AXIS_LINE,
  },
  yAxis: {
    name: '%',
    type: 'value',
    min: 'dataMin',
    max: 'dataMax',
    axisLine: AXIS_LINE,
    axisTick: {
      show: true,
      length: 10,
    },
    axisLabel: { ...AXIS_LABEL, margin: 15, formatter: v => `${v.toFixed(3)}` },
    nameTextStyle: { ...AXIS_LABEL, color: '#8E8E8E', fontSize: 10 },
  },
  series: {
    type: 'scatter',
    symbolSize: 5,
    data: [],
    z: 155,
    symbol: 'circle',
    symbolRotate: 45,
    symbolOffset: [0, 0],
    itemStyle: {
      borderColor: '#006400',
      borderWidth: 2,
      color: '#fff',
    },
  },
};

// Forest trend chart
export const FOREST_TREND_CHART_OPTION = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    textStyle: { ...AXIS_LABEL, color: '#747474' },
    formatter: '{c0}ha<br /> Year: {b0}',
  },
  color: '#006400',
  grid: {
    containLabel: true,
    left: '10px',
    right: '10px',
    bottom: '10px',
    top: '10px',
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLabel: AXIS_LABEL,
    axisLine: AXIS_LINE,
  },
  yAxis: {
    name: 'ha',
    type: 'value',
    min: 'dataMin',
    max: 'dataMax',
    axisLine: AXIS_LINE,
    axisTick: {
      show: true,
      length: 10,
    },
    axisLabel: { ...AXIS_LABEL, margin: 15, formatter: v => `${v.toFixed(2)}ha` },
    nameTextStyle: { ...AXIS_LABEL, color: '#8E8E8E', fontSize: 10 },
  },
  series: { type: 'line', smooth: true, data: [] },
};