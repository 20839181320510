import React, { useEffect } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import arrowIcon from 'public/icons/arrow.svg';
import { useStore } from 'src/store';
import Progress from './Progress';
import NameWithLabel from './NameWithLabel';
import './Table.css';
import utils from 'src/utils';


const TH_SQUEEZED_WIDTH_NATURE_REPORTING_ASSETS_LIST_TABLE = {
  header_display_name: '50%',
  //header_Location: '10%',
  header_opportunity_score: '10%',
  header_Progress: '40%',
};

const TH_EXPANDED_WIDTH_NATURE_REPORTING_ASSETS_LIST_TABLE = {
  header_display_name: '40%',
  //header_Location: '7%',
  header_opportunity_score: '10%',
  header_priorityScore: '10%',
  header_Progress: '15%',
};

function Table({ data, globalFilterValue, mapJumpTo, onClickDetails, editAsset, deleteAsset }) {
  const store = useStore();
  const isFullMode = store.ui.menuFull;

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Asset Name',
          accessor: row => row,
          sortType: (a, b) => b.original.display_name.localeCompare(a.original.display_name),
          Cell: data => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <NameWithLabel
                name={data.row.original.display_name}
                onSelectDetails={() => onClickDetails(data.value)}
                onNavigateMap={() => mapJumpTo({ point_4326: data.row.original.location })}
                asset={data.row.original}
              />
            </div>
          ),
        },
        {
          Header: 'Nature Importance',
          accessor: 'nature_importances',
          Cell: data => (
            <div>
              {data.value && data.value.length > 0 ? (
                <div>
                  {data.value[0].nature_importance !== null
                    ? data.value[0].nature_importance.toFixed(2)
                    : 'N/A'}
                </div>
              ) : (
                <div>N/A</div>
              )}
            </div>
          ),
        },

        //Fullscreen Mode
        isFullMode && {
          Header: 'Bii',
          accessor: 'bii',
          sortType: (a, b) => a.original.bii - b.original.bii,
          Cell: data => <div>{data.value ? parseFloat(data.value).toFixed(2) : 'N/A'}</div>,
        },

        isFullMode && {
          Header: 'Eii',
          accessor: 'eii',
          sortType: (a, b) => a.original.eii - b.original.eii,
          Cell: data => <div>{data.value ? parseFloat(data.value).toFixed(2) : 'N/A'}</div>,
        },

        isFullMode && {
          Header: 'Rwr',
          accessor: 'rwr',
          sortType: (a, b) => a.original.rwr - b.original.rwr,
          Cell: data => <div>{data.value ? parseFloat(data.value).toFixed(2) : 'N/A'}</div>,
        },

        isFullMode && {
          Header: 'Water Stress',
          accessor: 'water_stress',
          sortType: (a, b) => a.original.water_stress - b.original.water_stress,
          Cell: data => <div>{data.value ? parseFloat(data.value).toFixed(2) : 'N/A'}</div>,
        },

        isFullMode && {
          Header: 'CR Overlap',
          accessor: 'cr_overlap',
          sortType: (a, b) => a.original.cr_overlap - b.original.cr_overlap,
          Cell: data => <div>{data.value ? parseFloat(data.value).toFixed(2) : 'N/A'}</div>,
        },

        isFullMode && {
          Header: 'WDPA Overlap',
          accessor: 'wdpa_overlap',
          sortType: (a, b) => a.original.wdpa_overlap - b.original.wdpa_overlap,
          Cell: data => <div>{data.value ? parseFloat(data.value).toFixed(2) : 'N/A'}</div>,
        },

        isFullMode && {
          Header: 'KBA Overlap',
          accessor: 'kba_overlap',
          sortType: (a, b) => a.original.kba_overlap - b.original.kba_overlap,
          Cell: data => <div>{data.value ? parseFloat(data.value).toFixed(2) : 'N/A'}</div>,
        },
        //End Of Fullscreen Mode

        {
          Header: 'Pressure on Nature',
          accessor: 'opportunity_score',
          sortType: (a, b) => a.original.opportunity_score - b.original.opportunity_score,
          Cell: data => <div>{data.value !== null ? data.value.toFixed(2) : 'N/A'}</div>,
        },
        {
          Header: 'Progress',
          accessor: row => row,
          Cell: ({ value, row }) => {
            // Add 'row' parameter to the Cell component
            const data = row.original; // Access the 'data' variable from 'row.original'

            return (
              <Progress
                progress={value.progress * 100 || 0}
                onClickDetails={() => onClickDetails(value)}
                onNavigateMap={() => mapJumpTo({ point_4326: data.location })}
                asset={data}
                editAsset={() => editAsset(data.asset_id)}
                deleteAsset={deleteAsset}
              />
            );
          },
          disableGlobalFilter: true,
          sortType: (a, b) => a.original.progress - b.original.progress,
        },
      ].filter(Boolean),
    [isFullMode]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      globalFilter: rows =>
        rows.filter(r =>
          utils.slugify(r.original.display_name).match(utils.slugify(globalFilterValue))
        ),
    },
    useGlobalFilter,
    useSortBy
  );

  useEffect(() => {
    setGlobalFilter(globalFilterValue);
  }, [setGlobalFilter, globalFilterValue]);

  useEffect(() => {
    setHiddenColumns(isFullMode ? [] : ['priorityScore']);
  }, [isFullMode, setHiddenColumns]);

  return (
    <>
      <div
        className="nature_reporting_table_container"
        style={{ overflowY: globalFilterValue ? 'visible' : 'auto' }}
      >
        <table className="nature_reporting_table" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => {
              const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

              return (
                <tr key={key} {...restHeaderGroupProps}>
                  {headerGroup.headers.map(column => {
                    const { key, ...restHeaderProps } = column.getHeaderProps(
                      column.getSortByToggleProps()
                    );
                    return (
                      <th
                        key={key}
                        {...restHeaderProps}
                        style={{
                          width: isFullMode
                            ? TH_EXPANDED_WIDTH_NATURE_REPORTING_ASSETS_LIST_TABLE[key]
                            : TH_SQUEEZED_WIDTH_NATURE_REPORTING_ASSETS_LIST_TABLE[key],
                          ...(isFullMode && {
                            width: TH_EXPANDED_WIDTH_NATURE_REPORTING_ASSETS_LIST_TABLE[key],
                          }),
                        }}
                      >
                        <div className="channels_list_tab_panel_table_th_container">
                          {column.render('Header')}
                          <img
                            src={arrowIcon}
                            className={`channels_list_tab_panel_table_sort_icon ${
                              column.isSorted
                                ? column.isSortedDesc
                                  ? 'channels_list_tab_panel_table_sort_icon_max_min_sort'
                                  : 'channels_list_tab_panel_table_sort_icon_min_max_sort'
                                : 'channels_list_tab_panel_table_sort_icon_no_sort'
                            }`}
                          />
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);

              const { key, ...restRowProps } = row.getRowProps();

              return (
                <tr key={key} {...restRowProps} className="row">
                  {row.cells.map(cell => {
                    const { key, ...restCellProps } = cell.getCellProps();

                    return (
                      <td key={key} {...restCellProps}>
                        {cell.render('Cell') !== null ? cell.render('Cell') : 'N/A'}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="nature_reporting_table_border" />
    </>
  );
}

export default Table;
