import React, { Fragment, memo } from 'react';
import { RED_LIST_NAMES } from 'src/Assets/constants';
import './TooltipsWidget.css';

function TooltipsWidget({ tooltipData, isTeam }) {
  let cr = [];
  let en = [];
  let vu = [];
  let nt = [];

  if (!isTeam) {
    tooltipData.species.forEach(animal => {
      switch (animal.category) {
        case 'CR':
          cr.push(animal);
          break;
        case 'EN':
          en.push(animal);
          break;
        case 'VU':
          vu.push(animal);
          break;
        case 'NT':
          nt.push(animal);
          break;
      }
    });
  }

  const species = tooltipData.species_metrics;
  const totalSpecies = (!species && isTeam) ? "N/A" : isTeam
    ? species?.num_cr + species?.num_en + species?.num_nt + species?.num_vu
    : cr.length + en.length + vu.length + nt.length;

  if (isTeam && species) {
    cr = species.num_cr;
    en = species.num_en;
    vu = species.num_vu;
    nt = species.num_nt;
  }

  const openListInNewTab = () => {
    window.open('').document.write(
      tooltipData.species.map(s => `${s.category},${s.binomial}`).sort().join('<br>')
    )
  }

  //let groups = { cr, en, vu, nt };
  return (
    <div className="dashboard_panel_tooltips_widget_main_container" onClick={openListInNewTab}>
      <div className="dashboard_panel_tooltips_widget_container">
        <p className="dashboard_panel_tooltips_widget_total_amount">{totalSpecies}</p>
        <p className="dashboard_panel_tooltips_widget_total_amount_description">Red List Species</p>
      </div>
      <div className="dashboard_panel_tooltips_widget_tooltips_container">
        {['cr', 'en', 'vu', 'nt'].map(code => (
          <Fragment key={code}>
            {/*<Tooltip code={code} tooltipData={groups[code]} useLength={isTeam} />*/}
            <div data-tooltip-id={code} className="dashboard_panel_tooltips_widget_tooltip">
              <div className="dashboard_panel_tooltips_widget_tooltip_triangle" />
              <span className="dashboard_panel_tooltips_widget_tooltip_number">
                {(!species && isTeam) ? 
                <>
                  {code === 'cr' && "N/A"}
                  {code === 'en' && "N/A"}
                  {code === 'vu' && "N/A"}
                  {code === 'nt' && "N/A"}
                </> 
                :
                <>
                  {code === 'cr' && (isTeam ? species.num_cr : cr.length)}
                  {code === 'en' && (isTeam ? species.num_en : en.length)}
                  {code === 'vu' && (isTeam ? species.num_vu : vu.length)}
                  {code === 'nt' && (isTeam ? species.num_nt : nt.length)}
                </>
                }
              </span>
              <span className="dashboard_panel_tooltips_widget_tooltip_label">
                {code.toUpperCase()}
              </span>
              <span className="dashboard_panel_tooltips_widget_tooltip_description">
                {RED_LIST_NAMES[code]}
              </span>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default memo(TooltipsWidget);
