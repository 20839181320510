import React from 'react';
import './css/ErrorMessage.css';

function ErrorMessage(props) {
  return (
    <div className="error-box">
      <div className="error-icon" style={{ background: "url('/public/icons/error.png')" }}></div>
      <div className="error-message">
        <h6>{props.title || 'Upload error'}</h6>
        <p id="test-error-message">{props.error}</p>
      </div>
      <div className="error-close" onClick={() => props.setError('')}>
        &times;
      </div>
    </div>
  );
}

export default ErrorMessage;
