import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useStore } from 'src/store';
import api from 'src/api';
import Analytics from 'src/Analytics';
import BaseButton from 'src/Common/Buttons/BaseButton';
import Avatar from 'src/Common/Avatar';
import SelectPro from 'src/Common/SelectPro/SelectPro';
import './Menu.less';

export default function Menu(props) {
  const store = useStore();
  const menuOpen = store.ui.menuOpen;
  const sharing = store.session.team?.plan === 'temporary';

  const [options, setOptions] = useState(
    store.data.channels?.map(c => ({ label: c.name, value: c.channel_id })) || []
  );
  const [settingsActivated, setSettingsActivated] = useState(false);
  const setLoading = props.setLoading;
  const defaultValue =
    options.find(o => o.value === store.session.channel?.channel_id) || options[0];

  const methods = useForm({});

  useEffect(() => {
    const updatedOptions =
      store.data.channels?.map(c => ({ label: c.name, value: c.channel_id })) || [];
    const mainChannelIndex = updatedOptions.findIndex(element => element.label === 'main');
    if (mainChannelIndex !== -1) {
      const mainChannel = updatedOptions[mainChannelIndex];
      mainChannel.label = 'Main';

      updatedOptions.splice(mainChannelIndex, 1);
      updatedOptions.unshift(mainChannel);

      setOptions(updatedOptions);
    }
  }, [store.data.channels]);

  const handleclick = () => {
    setSettingsActivated(false);
    store.ui.menuOpen = !menuOpen;
  };

  const handleShowSettings = useCallback(() => {
    Analytics.trackClick('menu-settings');
    store.ui.menu.currentTab = 'Settings';
  }, [props]);

  const handleChangeChannel = useCallback(async selectedOption => {
    Analytics.trackClick('menu-change-channel');
    const selectedChannel = store.data.channels.find(c => c.channel_id === selectedOption.value);
    store.session.channel = { channel_id: selectedChannel.channel_id, name: selectedChannel.name };
    await props.refreshAllData();
  }, []);

  const handleSwitchTeam = useCallback(async () => {
    try {
      setLoading(true);
      Analytics.trackClick('menu-switch-team');
      await api.call('/switchTeam', { team: '' });
      await api.forceRefreshToken();
      handleclick();
    } catch (error) {
      setLoading(false);
      console.info(error);
    }
  }, [handleclick]);

  const askLogout = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 0px 10px #00000029',
              borderRadius: 2,
              padding: 40,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h1
              style={{
                fontSize: 24,
                letterSpacing: 0.4,
                marginBottom: 14,
                color: '#3B3B3B',
              }}
            >
              Log out
            </h1>
            <p>Are your sure you wish to log out?</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 38,
              }}
            >
              <p className="popup__logout__text" style={{ marginRight: 85 }} onClick={onClose}>
                NO
              </p>
              <p
                className="popup__logout__text"
                onClick={async () => {
                  api.auth0.logout({ logoutParams: { returnTo: window.location.origin } });
                  onClose();
                }}
              >
                YES
              </p>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className="menu_container">
      <BaseButton
        buttonType={'SECONDARY'}
        onClick={() => handleclick()}
        additionalClassName={'mobile__close_button'}
      >
        Close
      </BaseButton>
      {!sharing && (
        <div className="card_container">
          <Avatar user={store.session.user.email} size={100} />
          <div className="card_container_details">
            <p className="card_name">{store.session.user.display_name}</p>
            <p className="card_details">{store.session.user.email}</p>
            <p className="card_option">
              {store.session.isExternalUser ? "External User" :{ owner: 'Owner', admin: 'Admin', view_only: 'User' }[store.session.user.role]}
            </p>
          </div>
        </div>
      )}
      {!settingsActivated && (
        <>
          <div className="menu">
            {store.session.assets_enabled && !sharing && (
              <div
                className="menu__item"
                onClick={() => {
                  Analytics.trackClick('menu-assets');
                  props.showAssets();
                }}
              >
                <img src="/icons/menu/assets.svg" alt="assets" />
                <h2>Assets</h2>
              </div>
            )}

            {!sharing && (
              <div
                className="menu__item"
                onClick={() => {
                  store.ui.menu.currentTab = 'DataManager';
                  Analytics.trackClick('menu-data-manager');
                }}
              >
                <img src="/icons/menu/data_manager.svg" alt="mapping"></img>
                <h2>Data manager</h2>
              </div>
            )}

            {!sharing && (
              <div
                className="menu__item"
                onClick={() => {
                  store.ui.menu.currentTab = 'Processing';
                  Analytics.trackClick('menu-processing');
                }}
              >
                <img src="/icons/menu/processing_manager.svg" alt="processing"></img>
                <h2>Processing</h2>
              </div>
            )}

            {!sharing && (
              <div
                className="menu__item"
                onClick={() => {
                  store.ui.menu.currentTab = 'SatelliteImagery';
                  Analytics.trackClick('menu-satellite-imagery');
                }}
              >
                <img src="/icons/menu/satellite_imagery.svg" alt="satellite"></img>
                <h2>Satellite imagery</h2>
              </div>
            )}

            <div
              className="menu__item"
              onClick={() => {
                // props.setHelpPopup(true);
                store.ui.menu.currentTab = 'Help';
                Analytics.trackClick('menu-help');
              }}
            >
              <img src="/icons/menu/help.svg" alt="help"></img>
              <h2>Help</h2>
            </div>

            {!sharing && (
              <div className="menu__item" onClick={handleShowSettings}>
                <img src="/icons/menu/settings.svg" alt="settings"></img>
                <h2>Settings</h2>
              </div>
            )}

            {!sharing && (
              <div className="option_bar_channels_selector_container">
                <FormProvider {...methods}>
                  <form>
                    <SelectPro
                      name="Channel"
                      placeholder="Choose channel"
                      registerName="channels"
                      options={options}
                      defaultValue={defaultValue}
                      className="select_channel_selector"
                      onChange={handleChangeChannel}
                    />
                  </form>
                </FormProvider>
              </div>
            )}

            {!sharing && menuOpen && (
              <div className="card_container">
                <Avatar team={store.session.team.name} size={90} />
                <div className="card_container_details">
                  <p className="card_name card_text_transform">{store.session.team.name}</p>
                  <p className="card_details card_text_transform">{store.session.team.plan}</p>
                  <div className="card_option_container" onClick={handleSwitchTeam}>
                    <img src="/icons/menu/switch_team.svg" alt="swich_team" />
                    <p className="card_option" style={{ cursor: 'pointer' }}>
                      Switch team
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div
              className="menu__item"
              onClick={() => {
                askLogout();
                Analytics.trackClick('menu-logout');
              }}
            >
              <img src="/icons/menu/log_out.svg" alt="logout"></img>
              <h2 className="menu__item_log_out">Log out</h2>
            </div>
          </div>
        </>
      )}
    </div>
  );
}