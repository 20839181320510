import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import crossIcon from 'public/icons/cross.svg';
import arrowIcon from 'public/icons/arrow.svg';
import { useStore } from 'src/store';
import ImageButton from 'src/Common/Buttons/ImageButton';
import User from './User';
import DeleteUserModal from './DeleteUserModal';
import './Table.css';
import api from 'src/api';

function Table({ data, channelName, refresh, editPermission }) {
  const [isDeleteUserModalShowed, setIsDeleteUserModalShowed] = useState(false);

  const [deleteUserEmail, setDeleteUserEmail] = useState(''); //Used to display email on
  const [userToDelete, setUserToDelete] = useState(null);
  const store = useStore();
  const isFullMode = store.ui.menuFull;

  useEffect(() => {}, [store.data.allChannels]);

  function handleShowDeleteUserModal(userId) {
    setIsDeleteUserModalShowed(true);
    setUserToDelete(userId);
  }

  const handleHideDeleteUserModal = useCallback(() => {
    setIsDeleteUserModalShowed(false);
  }, []);

  async function handleDeleteUserModal() {
    await api.call('/channel/deleteUser', {
      channel_id: store.session.channel.channel_id,
      user_id: userToDelete,
    });
    setIsDeleteUserModalShowed(false);
    refresh();
  }

  const columns = React.useMemo(
    () => [
      {
        Cell: ({ row }) => <User userName={row.original.name} email={row.original.col2} />,
        Header: 'Name',
        accessor: 'name',
        sortType: 'alphanumeric',
      },

      {
        Header: 'E-mail',
        accessor: 'col2',
        sortType: 'alphanumeric',
        Cell: ({ value }) => value,
      },

      {
        Header: '',
        accessor: 'col3',
        Cell: ({ row }) => {
          const handleDelete = name => {
            setDeleteUserEmail(name);
            handleShowDeleteUserModal(row.original.col3);
          };

          return (
            editPermission && (
              <ImageButton
                src={crossIcon}
                alt="cross icon"
                className="channels_settings_tab_panel_cross_icon"
                onClick={() => handleDelete(row.original.name)}
              />
            )
          );
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },

    useSortBy
  );

  return (
    <>
      {isDeleteUserModalShowed && (
        <DeleteUserModal
          onClickCancel={handleHideDeleteUserModal}
          onClickRightButton={handleDeleteUserModal}
          userEmail={deleteUserEmail}
          channelName={channelName}
        />
      )}

      <div
        className={`channels_settings_tab_panel_table_container ${
          isFullMode && 'channels_settings_tab_panel_table_container_full_mode'
        }`}
      >
        <table className="channels_settings_tab_panel_table" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => {
              const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

              return (
                <tr key={key} {...restHeaderGroupProps}>
                  {headerGroup.headers.map(column => {
                    const { key, ...restHeaderProps } = column.getHeaderProps(
                      column.getSortByToggleProps()
                    );

                    return (
                      <th
                        key={key}
                        {...restHeaderProps}
                        style={{
                          width: ({
                            header_col1: '45%',
                            header_col2: '45%',
                            header_col3: '10%',
                          })[key],
                        }}
                      >
                        <div className="channels_settings_tab_panel_table_th_container">
                          {column.render('Header')}
                          <img
                            src={arrowIcon}
                            className={`channels_settings_tab_panel_table_sort_icon ${
                              column.isSorted
                                ? column.isSortedDesc
                                  ? 'channels_settings_tab_panel_table_sort_icon_max_min_sort'
                                  : 'channels_settings_tab_panel_table_sort_icon_min_max_sort'
                                : 'channels_settings_tab_panel_table_sort_icon_no_sort'
                            }`}
                          />
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);

              const { key, ...restRowProps } = row.getRowProps();

              return (
                <tr key={key} {...restRowProps}>
                  {row.cells.map(cell => {
                    const { key, ...restCellProps } = cell.getCellProps();
                    return (
                      <td key={key} {...restCellProps}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default memo(Table);
