import React, { useState, useRef } from 'react';
import './SpreadSheet.less';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import SelectPro from 'src/Components/Common/SelectPro/SelectPro';
import Papa from 'papaparse';
import api from 'src/api';
import LoadingScreen from 'src/Routes/LoadingScreen';
import InputPro from 'src/Components/Common/InputPro';

const SpreadSheet = ({ asset, handleBack, location }) => {
  const [selectedArea, setSelectedArea] = useState(null);
  const [spreadsheetName, setSpreadsheetName] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isloading, setIsLoading] = useState(false);

  const areas = asset.areas?.map(aoi => ({ value: aoi.area_id, label: aoi.nameOfArea }));

  const fileInputRef = useRef(null);
  const triggerFileSelectPopup = () => fileInputRef.current.click();

  // Combined table headers and rows into one state
  const [values, setValues] = useState([]);
  const [description, setDescription] = useState('');

  async function handleUpload() {
    setIsLoading(true);
    console.log('Selected Area:', selectedArea);
    const geog = location,
    name = spreadsheetName;

    const data = [{ values }];
    const res = await api.call('/asset/data_frame/create', {
      area_id: selectedArea.value,
      description,
      geog,
      name,
      data,
    });

    console.log('Uploading:', res);

    setSelectedFile(null);
    handleBack(true);
    setIsLoading(false);
  }

  const changeHandler = event => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const headers = results.meta.fields;
        const valuesArray = [];

        valuesArray.push(headers);
        console.log(results);
        results.data.forEach(d => {
          valuesArray.push(Object.values(d));
        });

        setValues(valuesArray);

        event.target.value = ''; // Reset the input value to allow uploading the same file again
      },
    });
  };

  if (isloading)
    return (
      <div style={{ minHeight: '150px', marginTop: '50px' }}>
        <LoadingScreen>Loading...</LoadingScreen>
      </div>
    );

  return (
    <div className="spreadsheet_container">
      <div className="input_container_spreadsheet">
        <InputPro
          className={'name_input_spreadsheat'}
          defaultValue={`Data-${new Date().getDate() + new Date().getTime()}`}
          label="Spreadsheet Name"
          bindValue={setSpreadsheetName}
          fixedLabel
        ></InputPro>
        <SelectPro
          name={'Select Area'}
          options={areas}
          noOptionsMessage={'No area found.\n Please create an area'}
          onChange={setSelectedArea}
        />
      </div>

      <textarea
        rows={15}
        cols={60}
        onInput={e => setDescription(e.target.value)}
        className="spreadsheet_description_textarea"
        placeholder="Description..."
      />

      {selectedArea && (
        <>
          <input
            type="file"
            ref={fileInputRef}
            accept=".csv"
            onChange={changeHandler}
            style={{ display: 'none' }}
          />
          {values.length <= 1 && (
            <BaseButton
              buttonType={values.length > 1 ? 'SECONDARY' : 'STANDARD'}
              onClick={triggerFileSelectPopup}
            >
              Upload CSV
            </BaseButton>
          )}
        </>
      )}

      <BaseButton buttonType='SECONDARY' additionalClassName="continue_button" onClick={() => handleBack(true)}> Back </BaseButton>

      {values.length > 1 && (
        <>
          <BaseButton
            additionalClassName="cancel_csv_button"
            buttonType={'SECONDARY'}
            onClick={triggerFileSelectPopup}
          >
            Re-Upload CSV
          </BaseButton>
          <BaseButton additionalClassName="upload_csv_button" onClick={() => handleUpload()}>
            Create Spreadsheet
          </BaseButton>
        </>
      )}

      {/* Table */}
      {values.length > 1 && (
        <div className="table_container">
          <table>
            <thead>
              <tr>
                {values[0].map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {values.slice(1).map((row, index) => (
                <tr key={index}>
                  {row.map((cell, i) => (
                    <td key={`${index}-${i}`}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SpreadSheet;
