import React from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import SliderRail from './SliderRail';
import Handle from './Handle';
import Track from './Track';
import Tick from './Tick';

export default function SliderItem({ minDate, maxDate, type, datapoints, values, updateValuefromSlider }) {
  const startOfRange = new Date(1989, 6, 10);
  const endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);
  const [min, max] = [minDate || startOfRange, maxDate || endOfToday];

  /*
  const [[min, max], setRange] = useState([
    minDate || startOfRange,
    maxDate || endOfToday
  ]);
  
  const [calendar, setCalendar] = useState(false);

  const deactivateCalendar = (min, max, type) => {
    setCalendar(false);
    if (min && max) {
      updateMinMax(min, max, type);
      setRange([min, max])
    }
  };
  */

  const dateTicks = [];

  if (type === 'pins') {
    datapoints.forEach(item => {
      //const { date } = item;
      dateTicks.push(new Date(item.origin_date || item.created_at).getTime());
    });
  } else {
    datapoints.forEach(item => {
      dateTicks.push(new Date(item.date).getTime());
    });
  }

  return (
    <>
      <div className="slider__item">
        <h2
          style={{
            fontSize: 12,
            width: '8em',
          }}
        >
          {type == 'raster' ? '2D' : type}
        </h2>

        <div className="slider__date__text">
          {`${min.getDate()}/${min.getMonth() + 1}/${min.getFullYear()}`}
        </div>
        <Slider
          mode={3}
          step={1000 * 60 * 30 /* half hour */}
          domain={[+min, +max]}
          rootStyle={{
            position: 'relative',
            width: '100%',
          }}
          onChange={v => updateValuefromSlider(v, type)}
          onUpdate={v => updateValuefromSlider(v, type)}
          values={values}
        >
          <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div>
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={[+min, +max]}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div>
                {tracks.map(({ id, source, target }) => (
                  <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks values={dateTicks}>
            {({ ticks }) => (
              <div>
                {ticks.map((tick, index) => (
                  <Tick
                    key={index}
                    tick={tick}
                    count={ticks.length}
                    format={ms =>  // "Mon Jan 01 2000" -> "Jan 08"
                      new Date(ms).toDateString().split(' ').slice(1,2).join(' ')
                }
                  />
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
        <div className="slider__date__text__second">
          {`${max.getDate()}/${max.getMonth() + 1}/${max.getFullYear()}`}
        </div>
      </div>
    </>
  );
};