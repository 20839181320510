import React, { memo, useEffect } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import arrowIcon from 'public/icons/arrow.svg';
import { useStore } from 'src/store';
import {
  TH_EXPANDED_WIDTH_CHANNELS_LIST_TAB_PANEL_TABLE,
  TH_SQUEEZED_WIDTH_CHANNELS_LIST_TAB_PANEL_TABLE,
} from 'src/shared/constants';
import ChannelHandlers from './ChannelHandlers';
import './Table.css';

function Table({ data, globalFilterValue, onClickEditChannel, setToolTipValue }) {
  const store = useStore();
  const isFullMode = store.ui.menuFull;
  const columns = React.useMemo(
    () => [
      {
        Header: 'Channel Name',
        accessor: 'col1',
        sortType: 'alphanumeric',
      },
      {
        Header: 'Creation Date',
        accessor: 'col2',
        sortType: (a, b) => new Date(b.values.col2) - new Date(a.values.col2),
      },
      {
        Header: 'Description',
        accessor: 'col3',
        sortType: 'alphanumeric',
      },
      {
        Header: '',
        accessor: 'channel',
        id: 'channel',
        disabled: true,
        sortType: (a, b) => b.original.joined < a.original.joined,
        Cell: ({ value, row }) => {
          return (
            <ChannelHandlers
              channelId={value}
              channelIndex={row.index}
              channelName={row.original.col1}
              onClickEditChannel={onClickEditChannel}
              joined={row.original.joined}
              refresh={row.original.refresh}
            />
          );
        },
      },
    ],
    [onClickEditChannel]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [
            {
              id: 'channel', // Change this to 'col2' for sorting by the 'Creation Date' column
              desc: true, // Set to true for descending order, false for ascending
            },
          ],
        },
      },
      useGlobalFilter,
      useSortBy
    );

  useEffect(() => {
    setGlobalFilter(globalFilterValue);
  }, [globalFilterValue, setGlobalFilter]);

  const filteredRows = rows.filter(row => row);

  return (
    <div className="channels_list_tab_panel_table_container">
      <table className="channels_list_tab_panel_table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map(column => {
                  const { key, ...restHeaderProps } = column.getHeaderProps(
                    column.getSortByToggleProps()
                  );

                  return (
                    <th
                      key={key}
                      {...restHeaderProps}
                      style={{
                        width: isFullMode
                          ? TH_EXPANDED_WIDTH_CHANNELS_LIST_TAB_PANEL_TABLE[key]
                          : TH_SQUEEZED_WIDTH_CHANNELS_LIST_TAB_PANEL_TABLE[key],
                      }}
                    >
                      <div className="channels_list_tab_panel_table_th_container">
                        {column.render('Header')}
                        {!column.disabled && (
                          <img
                            src={arrowIcon}
                            className={`channels_list_tab_panel_table_sort_icon ${
                              column.isSorted
                                ? column.isSortedDesc
                                  ? 'channels_list_tab_panel_table_sort_icon_max_min_sort'
                                  : 'channels_list_tab_panel_table_sort_icon_min_max_sort'
                                : 'channels_list_tab_panel_table_sort_icon_no_sort'
                            }`}
                          />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        <tbody {...getTableBodyProps()}>
          {filteredRows.map(row => {
            prepareRow(row);

            const { key, ...restRowProps } = row.getRowProps();

            return (
              <tr key={key} {...restRowProps}>
                {row.cells.map(cell => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <td
                      key={key}
                      {...restCellProps}
                      onMouseEnter={() => {
                        let content = cell.render('Cell'); //I feel terrible to hack like this! :(((
                        if (content.props.value && content.props.column.Header == 'Description') {
                          if (content.props.value.length > 7) {
                            setToolTipValue(content);
                          } else {
                            setToolTipValue(null);
                          }
                        }
                      }}
                      onMouseLeave={() => {
                        setToolTipValue(null);
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default memo(Table);
