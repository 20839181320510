import React, { memo, useMemo } from 'react';
import { BUTTON_TYPES } from 'src/shared/constants/base_button';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import UploadButton from 'src/Components/Common/Buttons/UploadButton';
import './TabBaseInfo.css';

function TabBaseInfo({ title, buttonType, uploadButtonParams, baseButtonParams, imageParams }) {
  const button = useMemo(() => {
    switch (buttonType) {
      case BUTTON_TYPES.UPLOAD:
        return uploadButtonParams?.buttonContent ? (
          <UploadButton {...uploadButtonParams} additionalClassName="help_tab_base_info_button" />
        ) : null;
      default:
        return baseButtonParams?.buttonName ? (
          <BaseButton additionalClassName="faq_button" onClick={baseButtonParams.onClick}>
            {baseButtonParams.buttonName}
          </BaseButton>
        ) : null;
    }
  }, [buttonType, uploadButtonParams, baseButtonParams]);

  return (
    <div className="help_tab_base_info_main_container">
      <img
        {...imageParams}
        className={`help_tab_base_info_image ${imageParams.className && imageParams.className}`}
      />
      <h3 className="help_tab_base_info_title">{title}</h3>
      {button}
    </div>
  );
}

export default memo(TabBaseInfo);
