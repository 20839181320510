import React, { useEffect, useState } from 'react';
import './WmsTabPanel.less';
import BaseModal from 'src/Components/Common/Modal/BaseModal';
import api from 'src/api';
import LoadingScreen from 'src/Routes/LoadingScreen';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import utils from 'src/utils';
import { useStore } from 'src/store';
import Analytics from 'src/Context/Analytics';

function WmsTabPanel() {
  const store = useStore();
  const [items, setItems] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteKey, setDeleteKey] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [keyView, setKeyView] = useState(null);
  const [createKey, setCreateKey] = useState(false);
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [isLoading, setIsloading] = useState(true);
  const [hoverState, setHoverState] = useState(-1);
  const [activityView, setActivityView] = useState(-1);
  const developmentPrefix = process.env.REACT_APP_EXPERIMENTAL_FEATURES_ENABLED ? '.dev' : '';
  const wmsURL = `https://wms${developmentPrefix}.datact.svarmi.com/${store.session.team.name}/${store.session.channel.name}`;

  const fetchData = async () => {
    try {
      setIsloading(true);
      const response = await api.call('/wms_key/list');
      const data = (response.success || []).sort((a, b) => {
        const dateA = new Date(a.expires).getTime();
        const dateB = new Date(b.expires).getTime();

        return dateB - dateA;
      });

      data.forEach(key => {
        key.activity = checkExpirationStatus(key.expires);
        key.daysLeft = daysLeft(key.expires);
      });

      setItems(data);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      console.error('Error fetching data:', error);
    }
  };

  function setDeleteState(key, index) {
    setModalIsOpen(true);
    setDeleteKey(key);
    setDeleteIndex(index);
  }

  useEffect(() => {
    Analytics.trackClick('settings-tab-wms');
    fetchData();

    const currentDate = new Date();
    const nextMonth = new Date(currentDate);
    nextMonth.setMonth(currentDate.getMonth() + 1);

    const formattedDate = nextMonth.toISOString().split('T')[0];
    setStartDate(formattedDate);
    setDate(formattedDate);
  }, []);

  // Handlers
  const handleDateChange = event => {
    const selectedDate = event.target.value;
    setStartDate(selectedDate);
    setDate(selectedDate);
  };

  const handleCopyClick = value => {
    navigator.clipboard.writeText(value);
  };

  const handleExitKeyView = () => {
    setKeyView(null);
    fetchData();
  };

  const handleActivityView = index => {
    if (activityView == index) {
      setActivityView(-1);
      return;
    }
    setActivityView(index);
  };
  //

  // API calls
  const deleteWmsKey = async () => {
    try {
      setIsloading(true);
      setModalIsOpen(false);
      const response = await api.call('/wms_key/delete', { key_id: deleteKey.wms_key_id });
      await fetchData();
    } catch (error) {
      setIsloading(false);
      console.error('Error fetching data:', error);
    }
  };

  const createWmwKey = async () => {
    if (isLoading) return;
    setIsloading(true);
    try {
      setIsloading(true);
      const expirationDate = new Date(date);
      const response = await api.call('/wms_key/create', { expires: expirationDate });
      console.log(response);
      if (response) {
        setKeyView(response.api_key);
        setCreateKey(false);
      } else {
        console.error('API call was not successful:', response.error);
      }
    } catch (error) {
      setIsloading(false);
      console.error('Error fetching data:', error);
    }
    fetchData();
  };
  //

  //Date calculations
  const checkExpirationStatus = date => {
    const today = new Date();
    const expirationDate = new Date(date);
    const timeDiff = expirationDate.getTime() - today.getTime();
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays <= 0) {
      return 'expired';
    } else if (diffDays <= 7) {
      return 'expiring';
    } else {
      return 'active';
    }
  };

  const getTodayDateString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const daysLeft = date => {
    const today = new Date();
    const expirationDate = new Date(date);
    const timeDiff = expirationDate.getTime() - today.getTime();
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  };
  //

  return (
    <div className="wms_container">
      {modalIsOpen && (
        <BaseModal
          rightButton="Delete"
          title={`Delete Key ${deleteIndex + 1}`}
          onClickCancel={() => setModalIsOpen(false)}
          onClickRightButton={() => deleteWmsKey()}
        >
          <h2>Are you sure you want to delete this key?</h2>
        </BaseModal>
      )}
      {createKey ? (
        <>
          <h2>Creating WMS Key</h2>
          <div className="create-window">
            <div className="create-menu">
              <div className="expiry-block">
                <div style={{ color: 'var(--keli-gray)' }}>Expiration Date</div>
                <input
                  style={{ padding: '10px', fontSize: '16px' }}
                  type="date"
                  onChange={handleDateChange}
                  min={getTodayDateString()}
                  value={startDate}
                />
              </div>
              <div className="create-key-buttons">
                <BaseButton
                  buttonType={'SECONDARY'}
                  onClick={() => {
                    setCreateKey(false);
                    fetchData();
                  }}
                >
                  Cancel
                </BaseButton>
                <BaseButton onClick={() => createWmwKey()}>Create</BaseButton>
              </div>
            </div>
          </div>
        </>
      ) : !isLoading ? (
        <>
          {keyView == null ? (
            <div>
              <h2>
                <div className="wms-url">
                  <div className="wms-label">WMS URL</div>
                  <div className="wms-url-string">{wmsURL}</div>
                  <BaseButton
                    onClick={() => {
                      handleCopyClick(wmsURL);
                    }}
                    buttonType={'SECONDARY'}
                    additionalClassName="copy-icon"
                    src="/icons/copy.svg"
                    style={{ marginTop: '3px' }}
                  >
                    Copy<img width="18px" src="/icons/copy.svg"></img>
                  </BaseButton>
                </div>
                <div style={{ paddingTop: '2px' }}> WMS Api Keys </div>
              </h2>
              <ul className="key-window">
                <ul>
                  <div className="header-list">
                    <span style={{ width: '13%' }}>Key</span>
                    <span style={{ width: '31%' }}>Creation Date</span>
                    <span style={{ width: '50%' }}>Expiration Date</span>
                  </div>
                  <li>
                    {items.length > 0 ? (
                      items.map((key, index) => (
                        <span className="wms-key" key={index}>
                          <div
                            className={`key-list${
                              index === items.length - 1 ? ' last-key-list' : ''
                            }`}
                            style={{ position: 'relative' }}
                            onMouseEnter={() => setHoverState(index)}
                            onMouseLeave={() => setHoverState(-1)}
                          >
                            <span style={{ width: '13%' }}>{index + 1}</span>
                            <span style={{ width: '35%' }}>{utils.formatDate(key.created_at)}</span>
                            <span style={{ width: '20%' }}>{key.expires.split('T')[0]}</span>
                            <span
                              style={{ width: '15%' }}
                              onClick={() => handleActivityView(index)}
                              className={'activity-banner ' + key.activity}
                            >
                              {index == hoverState
                                ? key.daysLeft + ' days'
                                : utils.capitalize(key.activity)}
                            </span>
                            <BaseButton
                              buttonType={'SECONDARY'}
                              additionalClassName={'delete-key'}
                              onClick={() => setDeleteState(key, index)}
                            >
                              Delete
                            </BaseButton>
                          </div>
                        </span>
                      ))
                    ) : isLoading ? (
                      ''
                    ) : (
                      <div style={{ padding: '82px 0px' }}>No Keys</div>
                    )}
                  </li>
                </ul>
              </ul>
              <div className="create-menu">
                <BaseButton style={{ marginRight: '0' }} onClick={() => setCreateKey(true)}>
                  Create new API Key <img width="24px" src="/icons/Add.svg"></img>
                </BaseButton>
              </div>
            </div>
          ) : (
            <>
              <h2>WMS API Key</h2>
              <div className="key-window">
                <div className="create-menu" style={{ gap: '15px', marginBottom: '15px' }}>
                  <div
                    className="key"
                    onClick={() => {
                      handleCopyClick(keyView);
                    }}
                  >
                    {keyView.replace(/./g, '*')}
                    <div className="copy-key" style={{ position: 'absolute', right: '20px' }}>
                      <img
                        className="copy-icon second"
                        title="Copy To Clipboard"
                        src="/icons/copy.svg"
                      />
                    </div>
                  </div>
                  <img className="img-warning" src="/icons/warning.png" />
                  <div className="key-warning" style={{ fontSize: '14px' }}>
                    Make sure to copy your personal WMS API key and store it somewhere safe, you
                    will not be able to see this key again. This key gives access to all the
                    Channels that you have access to within this Team. Please do not share this key
                    with anyone.
                  </div>
                </div>
              </div>
              <div style={{ position: 'absolute', right: '0', marginTop: '15px' }}>
                <BaseButton buttonType={'SECONDARY'} onClick={() => handleExitKeyView()}>
                  Done
                </BaseButton>
              </div>
            </>
          )}
        </>
      ) : (
        <div style={{ marginTop: '115px' }}>
          <LoadingScreen />
        </div>
      )}
    </div>
  );
}

export default WmsTabPanel;
