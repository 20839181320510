import React, { memo, useEffect, useState } from 'react';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Tab from 'src/Common/Tab';
import NatureReportingAssetsList from './NatureReportingAssetsList';
import TeamDashboardTabPanel from './TeamDashboardTabPanel';
import './NatureReportingAssets.css';
import { useStore } from 'src/store';

Tab.tabsRole = 'Tab';

function NatureReportingAssets({
  mapJumpTo,
  onClickDetails,
  assets,
  teamExtraData,
  refreshAssets,
  editId,
  setEditId,
  editInfo,
}) {
  const [selectedIndex, setSelectedIndex] = useState(editId > 0 ? 1 : 0);

  const store = useStore();

  useEffect(() => {
    store.ui.mapPreviewPin.style = null;
  }, [selectedIndex]);

  return (
    <Tabs
      selectedIndex={selectedIndex}
      onSelect={setSelectedIndex}
      className={`nature_reporting_nature_reporting_assets_main_container`}
    >
      <TabList className="nature_reporting_nature_reporting_assets_tab_list">
        <Tab tabValue="Team Dashboard" />
        <Tab tabValue="All Assets" />
      </TabList>
      <TabPanel>
        <TeamDashboardTabPanel
          assets={assets}
          teamExtraData={teamExtraData}
          onClickDetails={onClickDetails}
        />
      </TabPanel>
      <TabPanel selectedClassName="nature_reporting_nature_reporting_assets_tab_panel">
        <NatureReportingAssetsList
          mapJumpTo={mapJumpTo}
          onClickDetails={onClickDetails}
          assets={assets}
          refreshAssets={refreshAssets}
          editId={editId}
          setEditId={setEditId}
          editInfo={editInfo}
        />
      </TabPanel>
    </Tabs>
  );
}

export default memo(NatureReportingAssets);
