import React, { memo, useEffect, useState } from 'react';
import { useStore } from 'src/store';
import { NATURE_TRENDS_CHART_OPTION, FOREST_TREND_CHART_OPTION } from 'src/shared/constants';
import Widget from 'src/Components/NatureReporting/Widget';
import TooltipsWidget from 'src/Components/NatureReporting/TooltipsWidget';
import SankeyDiagram from 'src/Components/NatureReporting/SankeyDiagram';
import BubbleChart from 'src/Components/NatureReporting/BubbleChart';
import NatureTrendsChart from 'src/Components/NatureReporting/NatureTrendsChart';
import './DashboardPanel.css';
import './Dashboard.less';
import utils from 'src/utils';
import InfoHover from 'src/Components/InfoHover/InfoHover';
import CsvAsset from './CsvAsset';
import CreateChart from './CreateChart/CreateChart';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import ReactECharts from 'echarts-for-react';
import api from 'src/api';

function DashboardPanel({
  assets,
  assetDetails,
  areaDetails,
  onClickDetails,
  setDataframes,
  dataframes,
  spreadSheets,
  fetchSpreadSheets,
  area,
  loadingSpreadSheet,
}) {
  const store = useStore();
  const isFullMode = store.ui.menuFull;
  const [creatingChart, setCreatingChart] = useState(false);

  const ecoHealthLink = "https://docs.google.com/document/d/1x-AiVumhryWKQiEkXGTqkFRYIbOKYsahz2eEXeKT9KM/edit#heading=h.jn74v4zb5iyy"
  const forestLossLink = "https://docs.google.com/document/d/1x-AiVumhryWKQiEkXGTqkFRYIbOKYsahz2eEXeKT9KM/edit#heading=h.dcali7ubvujl"


  // Sum up consumption items in water_usage list to arrive at total

  const year1 = assetDetails?.land_classes[0]?.year_1 || 'N/A';
  const year2 = assetDetails?.land_classes[0]?.year_2 || 'N/A';
  const totalWaterAbstraction =
    assetDetails?.water_usage
      ?.map(u => (u.action === 'abstraction' ? u.amount : 0))
      ?.reduce((a, b) => a + b, 0) || 0;

  const totalWaterDischarge =
    assetDetails?.water_usage
      ?.map(u => (u.action === 'discharge' ? u.amount : 0))
      ?.reduce((a, b) => a + b, 0) || 0;

  const totalEnergyUsageCalculated = assetDetails.energy_usage.reduce((total, element) => {
    return total + element.amount;
  }, 0);

  const deleteChart = async chartId => {
    await api.call('/asset/chart/delete', { chart_id: chartId });
    fetchSpreadSheets();
  };

  console.log(spreadSheets);

  const generateChartComponent = areaData => {
    if (!areaData) return null;
    const charts = areaData.flatMap(areaItem => areaItem.charts || []);
    return charts.map((chart, index) => {
      const labels = chart.label_columns;
      const vertical = chart.vertical;
      const stacked = chart.stacked;
      const y_axis_name = chart.y_axis_name;
      let valueRows;

      if (chart.chart_type != 'block') {
        try {
          valueRows = chart.value_columns.map(valCol => JSON.parse(valCol));
          console.log(valueRows);
        } catch (error) {
          console.error('Error parsing value_columns' + error);
          return null;
        }
        if (chart.chart_type != 'pie' && (!Array.isArray(valueRows) || !Array.isArray(valueRows[0]))) {
          console.error('valueColumns is not correctly structured');
          return null;
        }
      } else {
        valueRows = chart.value_columns;
        console.log(valueRows);
      }

      console.log(dataframes[0]);
      console.log(chart);
      if (chart.chart_type == 'pie') {
        return GeneratePieChart();
      } else if (chart.chart_type != 'block') {
        return GenerateChart();
      } else {
        return GenerateSingleValue();
      }

      function GeneratePieChart() {
        const yAxisName = labels[0] || 'Default Y-Axis Name'; // May not be used directly in a pie chart but kept for consistency or legend/title

        const updatedOptions = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)',
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            data: valueRows, // Excludes the first label, which might be a category or header
          },
          series: [
            {
              name: yAxisName, // This could be used as a series name or adjusted as needed
              type: 'pie',
              radius: '55%', // Adjust as needed
              center: ['50%', '60%'], // Adjust as needed
              data: valueRows,
              itemStyle: { normal: { label: { show: false }, labelLine: { show: false } } },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
              labels: { show: false },
            },
          ],
        };

        return (
          <div key={`chart-${index}`} className="dashboard_panel_chart_main_container">
            <ReactECharts option={updatedOptions} />
            <img
              width={'36px'}
              src="/icons/delete_action.svg"
              className="delete_chart_button"
              onClick={() => deleteChart(chart.chart_id)}
            />
            <div className="info_chart_button">
              <InfoHover
                href="/icons/tooltip.svg"
                text={dataframes.find(df => df[0].dataframe_id === chart?.dataframe_id)?.description || 'No description available'}
              />
            </div>
          </div>
        );
      }

      function GenerateSingleValue() {
        return (
          <div className="single_value_chart">
            <img
              width={'36px'}
              src="/icons/delete_action.svg"
              className="delete_chart_button"
              onClick={() => deleteChart(chart.chart_id)}
            />
            <div className="info_chart_button">
              <InfoHover
                href="/icons/tooltip.svg"
                text={dataframes.find(df => df[0].dataframe_id === chart?.dataframe_id)?.description || 'No description available'}
              />
            </div>
            <div className="single_value">
              
              {valueRows[0] + " "} 
              <div style={{color : valueRows[1] == 'up' ? 'green' : 'red' }}>{valueRows[1] === 'up' ? "↑" : (valueRows[1] === 'down' ? "↓" : "")}</div>
            </div>
            <div className="single_value_title">{labels[0]}</div>
          </div>
        );
      }

      function GenerateChart() {
        const seriesData = valueRows[0].map((_, i) => valueRows.slice(1).map(row => row[i]));
        const seriesNames = valueRows[0];
        const xAxisName = labels[0] || 'Default X-Axis Name';
        const yAxisName = y_axis_name || 'Default Y-Axis Name';
        const series = seriesData.map((data, sIndex) => ({
          name: seriesNames[sIndex],
          type: chart.chart_type,
          data,
        }));
        const updatedOptions = {
          tooltip: { trigger: 'axis' },
          legend: {},
          xAxis: {
            type: 'category',
            boundaryGap: chart.chart_type !== 'line',
            data: labels.slice(1),
            axisLabel: { interval: labels.length > 5 ? 5 : 0, rotate: 10 },
            name: xAxisName,
          },
          grid: { right: '100px', left: '100px' },
          yAxis: { type: 'value', name: yAxisName },
          series,
        };

        if (vertical) {
          var temp = updatedOptions.xAxis;
          updatedOptions.xAxis = updatedOptions.yAxis;
          updatedOptions.yAxis = temp;
        }

        if (stacked) {
          updatedOptions.series = updatedOptions.series.map(s => ({ ...s, stack: 'stack' }));
        }

        return (
          <div key={`chart-${index}`} className="dashboard_panel_chart_main_container">
            <ReactECharts option={updatedOptions} />
            <img
              width={'36px'}
              src="/icons/delete_action.svg"
              className="delete_chart_button"
              onClick={() => deleteChart(chart.chart_id)}
            />
            <div className="info_chart_button">
              <InfoHover
                href="/icons/tooltip.svg"
                text={dataframes[0]?.find(df => df.dataframe_id === chart?.dataframe_id)?.description || 'No description available'}
              />
            </div>
          </div>
        );
      }
    });
  };


  useEffect(() => {
    if (creatingChart) {
      const dashboardElement = document.querySelector('.nature_reporting_nature_reporting_details_tab_panel');
      if (dashboardElement) {
        dashboardElement.scrollTo(0, 0); // Scrolls to the top of the nature-dashboard
      }
    }
  }, [creatingChart]);

  var chartMargin = assetDetails?.land_classes.length < 1 ? '-350px' : '0'; //skítafix to make fullscreen work
  if (isFullMode) chartMargin = '-170px';
  if (assetDetails?.land_classes.length) chartMargin = '25px';

  /*var areaCount = 0;
  areaOptions.map(area => (areaCount += area.options.length));*/

  var notAvailable = 'N/A';

  if (creatingChart) {
    return (
      <CreateChart
        assetDetails={assetDetails}
        cancel={() => (setCreatingChart(false), fetchSpreadSheets())}
        area={area}
      />
    );
  }


  return (
    <>


      <div>{spreadSheets?.map(area => generateChartComponent(area))}</div>

      <div
        style={{ position: 'relative' }}
        className={`nature-dashboard ${isFullMode ? 'full' : ''}`}
      >
        {(areaDetails?.land_classes?.length > 0 || assetDetails?.land_classes?.length > 0) && (
          <div className="sankey-diagram" style={{ gridArea: 'SD' }}>
            <h2>
              Land Cover Change ({year1} - {year2})
              <InfoHover href={ecoHealthLink} text={'Click for more info'} />
            </h2>
            <SankeyDiagram
              data={areaDetails?.land_classes?.[0] || assetDetails?.land_classes?.[0]}
              isFullMode={isFullMode}
            />
          </div>
        )}

        {assets && (
          <div className="bubble-chart" style={{ gridArea: 'BC' }}>
            <h2>
              Bubble Size: Business Importance
              <InfoHover text={'Business Importance'} />
            </h2>
            <BubbleChart
              assets={assets}
              selectedAsset={assetDetails.asset_id}
              isFullMode={isFullMode}
              onClickDetails={onClickDetails}
            />
          </div>
        )}

        {(
            areaDetails?.nature_trend?.length ||
            assetDetails?.nature_trend?.length ||
            assetDetails?.extraData?.nature_trend?.length
          ) && (
          <div className="trend-plot" style={{ gridArea: 'NT' }}>
            <h2>
              Ecosystem Health Index <InfoHover href={ecoHealthLink} text={'Click for more info'} />
            </h2>
            <NatureTrendsChart
              data={
                (areaDetails?.nature_trend?.length && areaDetails.nature_trend.map(v => [v.year, v.vigor])) ||
                (assetDetails?.nature_trend?.length && assetDetails.nature_trend.map(v => [v.year, v.vigor])) ||
                assetDetails?.extraData?.nature_trend ||
                []
              }
              trendLines
              baseOptions={NATURE_TRENDS_CHART_OPTION}
            />
          </div>
        )}

        {(
            areaDetails?.gfw_points?.length ||
            assetDetails?.gfw_points?.length ||
            assetDetails?.extraData?.forest_loss?.length
          ) && (
          <div className="trend-plot" style={{ gridArea: 'FT' }}>
            <h2>
              Total Forest loss per year (GFW)
              <InfoHover href={forestLossLink} text={'Click for more info'} />
            </h2>
            <NatureTrendsChart
              data={
                (areaDetails?.gfw_points?.length && areaDetails.gfw_points.map(p => [p.year, p.loss * 0.09])) || // 1 pixel is 30x30m which is 0.09ha
                (assetDetails?.gfw_points?.length && assetDetails.gfw_points.map(p => [p.year, p.loss * 0.09])) || // 1 pixel is 30x30m which is 0.09ha
                []
              }
              baseOptions={FOREST_TREND_CHART_OPTION}
            />
          </div>
        )}

        {(areaDetails?.species || assetDetails?.species) && (
          <div className="red-list" style={{ gridArea: 'RL' }}>
            <TooltipsWidget
              tooltipData={areaDetails?.species ? areaDetails : assetDetails}
              isTeam={false}
            />
          </div>
        )}

        {Object.entries({
          'Total Water Abstraction': utils.formatHumanReadable(totalWaterAbstraction, 'm³'),
          'Total Water Discharge': utils.formatHumanReadable(totalWaterDischarge, 'm³'),
          'Total Energy\n Usage': utils.formatMetricUnit(totalEnergyUsageCalculated, 'Wh'),

          'Total size of the\n area':
            (areaDetails?.size && utils.formatHumanReadable((areaDetails?.size || 0) / 1e6, 'km²')) ||
            (assetDetails?.areas?.[0]?.size && utils.formatHumanReadable((assetDetails?.areas?.[0]?.size || 0) / 1e6, 'km²')) ||
            notAvailable, // values in db seem to be in sqm rather than sqkm

          'Overlap with \n Key Biodiversity Areas ':
            assetDetails?.nature_importances?.[0]?.kba_overlap
            ? (assetDetails.nature_importances[0].kba_overlap*100).toFixed(1)+'%'
            : notAvailable,

          'Overlap with \n World Database on Protected Areas ':
            assetDetails?.nature_importances?.[0]?.wdpa_overlap
            ? (assetDetails.nature_importances[0].wdpa_overlap*100).toFixed(1)+'%'
            : notAvailable,

          'Overlap with \n Critically Endangered Species':
            assetDetails?.nature_importances?.[0]?.cr_overlap
            ? (assetDetails.nature_importances[0].cr_overlap*100).toFixed(1)+'%'
            : notAvailable,

          'Total Forest Loss (GFW)': utils.formatHumanReadable(
            areaDetails?.gfw_points?.map?.(a => a.loss).reduce((a, b) => a + b, 0) * 0.09 ||
            assetDetails?.gfw_points?.map?.(a => a.loss).reduce((a, b) => a + b, 0) * 0.09 ||
            0,
            'ha'
          ), // summed up graph, 1 pixel is 30x30m which is 0.09ha

          'Spotted Species': assetDetails?.extraData?.metrics?.spotted_species || 'N/A',
          'Water Stress': assetDetails?.nature_importances?.[0]?.water_stress?.toFixed?.(2) || 'N/A',
          'Ecosystem Integrity Index': assetDetails?.nature_importances?.[0]?.eii?.toFixed?.(2) || 'N/A',
          'Biodiversity Intactness Index': assetDetails?.nature_importances?.[0]?.bii?.toFixed?.(2) || 'N/A',
          'Rarity Weighted Species Richness Index': assetDetails?.nature_importances?.[0]?.rwr?.toFixed?.(2) || 'N/A',
          'Pressure on Nature': assetDetails?.opportunity_score?.toFixed?.(2) || 'N/A',
          'Nature Importance': assetDetails?.nature_importances?.[0]?.nature_importance?.toFixed?.(2) || 'N/A',
        }).map(([k, v], i) =>
          k.includes('Key Biodiversity Areas') ||
          k.includes('World Database on Protected Areas') ? (
            <Widget
              key={i}
              amount={v}
              description={k}
              style={{ gridArea: 'NN' }}
              overlapKey={k.includes('Key Biodiversity Areas') ? 'kba' : 'wdpa'}
            />
          ) : (
            <Widget key={i} amount={v} description={k} style={{ gridArea: 'NN' }} />
          )
        )}

        {loadingSpreadSheet && <div className="loading_spreadsheet">Loading...</div>}
      </div>

      <CsvAsset asset={assetDetails} />
      {store.session.experimental_enabled && (
        <div className="charts_container" style={{ marginTop: chartMargin }}>
          <BaseButton
            buttonType={'STANDARD'}
            disabled={spreadSheets.length < 1}
            title={spreadSheets.length < 0 ? 'No areas available for chart creation' : 'Create a new chart'}
            onClick={() => setCreatingChart(true)}
            additionalClassName="open_create_chart_button"
          >
            <img width={'22px'} src="/icons/Add.svg" /> Create Chart
          </BaseButton>

        </div>
      )}
    </>
  );
}

export default memo(DashboardPanel);
