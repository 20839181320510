// LocationPreview.js
import React from 'react';
import './LocationPreview.less';
import { useStore } from 'src/store';

const LocationPreview = ({ imagePath }) => {
  const store = useStore();

  return (
    <div
      className="location_preview_container"
      style={{ visibility: store.ui.mapPreviewPin.style === 'manual' ? 'visible' : 'hidden' }}
    >
      <img src={imagePath} alt="Centered Image" className="location_preview_image" />
    </div>
  );
};

export default LocationPreview;
