import React, { memo, useMemo, useRef, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import './BubbleChart.less';

function BubbleChart({ assets, selectedAsset, isFullMode, onClickDetails }) {
  const options = useMemo(
    () => ({
      grid: {
        show: false,
        top: 20,
        right: 0,
        bottom: 20,
        left: 0,
      },
      xAxis: {
        type: 'value',
        min: -10,
        max: +10,
        axisLabel: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLine: { lineStyle: { color: '#e2e2e2' } },
      },
      yAxis: {
        type: 'value',
        min: -10,
        max: +10,
        axisLabel: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLine: { lineStyle: { color: '#e2e2e2' } },
      },
      graphic: [
        {
          type: 'text',
          z: 100,
          left: 'left',
          top: 'middle',
          style: {
            fill: '#484848',
            width: 80,
            overflow: 'break',
            text: 'Lower Pressure on Nature',
            font: '12px Open Sans',
            textAlign: 'center',
            textVerticalAlign: 'top',
          },
        },
        {
          type: 'text',
          z: 100,
          left: 'right',
          top: 'middle',
          style: {
            fill: '#484848',
            width: 80,
            overflow: 'break',
            text: 'Higher Pressure on Nature',
            font: '12px Open Sans',
            textAlign: 'center',
            textVerticalAlign: 'top',
          },
        },
        {
          type: 'text',
          z: 100,
          left: 'center',
          top: 'top',
          style: {
            fill: '#484848',
            overflow: 'break',
            text: 'Higher Nature Importance',
            font: '12px Open Sans',
            textAlign: 'center',
            textVerticalAlign: 'top',
          },
        },
        {
          type: 'text',
          z: 100,
          left: 'center',
          top: 'bottom',
          style: {
            fill: '#484848',
            overflow: 'break',
            text: 'Lower Nature Importance',
            font: '12px Open Sans',
            textAlign: 'center',
            textVerticalAlign: 'bottom',
          },
        },
      ],
      series: [
        {
          name: 'Bubblechart',
          type: 'scatter',
          symbolSize: row => Math.max(row[2] * 0.7, 3),
          emphasis: {
            label: {
              show: true,
              formatter: param => param.data[3],
              position: 'top',
              font: '14px Open Sans',
            },
          },
          itemStyle: {
            color: param =>
              param.data[4] === selectedAsset ? 'rgba(253,92,120,1)' : 'rgba(124,118,85,.4)',
            opacity: 1,
          },
          data: assets
            .filter(
              a =>
                a.opportunity_score &&
                a.nature_importances?.[0]?.nature_importance &&
                a.business_importance &&
                a.display_name &&
                a.asset_id
            )
            .map(asset => [
              // Custom scaling function to have a neat graph
              (asset.opportunity_score - 2) * 8,
              (asset.nature_importances[0].nature_importance - 2.3) * 4,
              Math.min(Math.max((asset.business_importance || 0) / 200, 10), 50),

              // Asset props
              asset.display_name,
              asset.asset_id,
            ])
            .sort(
              (a, b) =>
                // Put bigger items on top of smaller ones, selectedAsset on top of all
                (selectedAsset === a[4] ? +Infinity : a[2]) -
                (selectedAsset === b[4] ? +Infinity : b[2])
            ),
        },
      ],
    }),
    [assets, selectedAsset]
  );

  // Always-force-resize hack :(
  const chart = useRef();
  useEffect(() => { chart?.current?.resize?.() }, [isFullMode])

  const onEvents = {
    click: e => {
      const asset_id = e.value?.[4];
      if (asset_id && asset_id != selectedAsset) onClickDetails({ asset_id });
    },
  };

  if (!assets) return <></>;
  return (
    <ReactECharts
      ref={chart}
      //key={isFullMode ? 'small-chart' : 'large-chart'}
      onEvents={onEvents}
      style={{ height: isFullMode ? 490 : 240, width: isFullMode ? undefined : 630 }}
      option={options}
    />
  );
}

export default memo(BubbleChart);
