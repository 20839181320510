import React from 'react';
import { useStore } from 'src/store';
import SaveChangesModal from './SaveChangesModal';

function Modal() {
  const store = useStore();
  const modalParams = store.ui.modalParams;
  /*const reset = () => {
    delete store.ui.modalParams.name;
    delete store.ui.modalParams.onClickRightButton;
  };*/
  switch (modalParams.name) {
    case 'SAVE_CHANGES':
      return (
        <SaveChangesModal
          {...modalParams}
          onClickCancel={() => {
            store.ui.modalParams = {};
          }}
          onClickRightButton={() => {
            modalParams.onClickRightButton?.();
            store.ui.modalParams = {};
          }}
        />
      );

    default:
      return null;
  }
}

export default Modal;
