import React from 'react';
import api from 'src/api';

export default class Analytics extends React.Component {
  static async addAsyncScript(url, id, dataCode) {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = url;
      script.onload = resolve;
      if (id) script.id = id;
      if (dataCode) script.dataset.code = dataCode;
      document.body.appendChild(script);
    });
  }

  componentDidMount() {
    // Pirsch
    if (process.env.REACT_APP_ANALYTICS_PIRSCH_ID && !window.pirsch) {
      Analytics.addAsyncScript(
        'https://api.pirsch.io/pirsch.js',
        'pirschjs',
        process.env.REACT_APP_ANALYTICS_PIRSCH_ID
      );
      Analytics.addAsyncScript(
        'https://api.pirsch.io/pirsch-events.js',
        'pirscheventsjs',
        process.env.REACT_APP_ANALYTICS_PIRSCH_ID
      );
    }

    // Microsoft Clarity
    if (process.env.REACT_APP_ANALYTICS_MSCLARITY_ID && !window.clarity) {
      window.clarity = function () {
        window.clarity.q = window.clarity.q || [];
        window.clarity.q.push(arguments);
      };
      Analytics.addAsyncScript(
        'https://www.clarity.ms/tag/' + process.env.REACT_APP_ANALYTICS_MSCLARITY_ID
      );
    }

    // Error reporting
    window.addEventListener('unhandledrejection', e => Analytics.reportClientError(e));
    window.addEventListener('error', e => Analytics.reportClientError(e));
  }

  static reportClientError(e) {
    if (navigator.onLine === false) return; // Don't bother logging errors if we know for sure we've lost network
    if (e.message === 'ResizeObserver loop limit exceeded') return; // See https://stackoverflow.com/q/49384120

    try {
      api.call('/clientError', {
        clientErrorReport: {
          time: new Date().toISOString(),
          object: e,
          object_json: JSON.stringify(e),
          type: e.type,
          stack: e.error?.stack || e.reason?.stack,
          content: {
            message: e.message,
            filename: e.filename,
            lineno: e.lineno,
            colno: e.colno,
          },
          url: window.location.href,

          pageMs: Math.floor(e.timeStamp),
          userAgent: window.navigator.userAgent,
          cpuCount: navigator.hardwareConcurrency,
          memoryGB: navigator.deviceMemory,

          connection: window.navigator.connection
            ? {
                type: window.navigator.connection.effectiveType,
                bandwidthMBS: window.navigator.connection.downlink,
                pingMs: window.navigator.connection.rtt,
              }
            : undefined,

          memory: window.performance.memory
            ? {
                jsHeapSizeLimit: window.performance.memory.jsHeapSizeLimit,
                totalJSHeapSize: window.performance.memory.totalJSHeapSize,
                usedJSHeapSize: window.performance.memory.usedJSHeapSize,
              }
            : undefined,
        },
      });
    } catch (e) {
      e;
    }
  }

  static updateInfo() {
    try {
      const { team, user } = window.store.session;
      window.clarity('identify', user.email);
      window.clarity('set', 'team', team.name);
      window.clarity('set', 'user', user.email);
      window.clarity('set', 'role', user.role);
      window.clarity('set', 'plan', team.plan);
    } catch (e) {
      console.trace(e);
    }
  }

  static trackClick(name) {
    try {
      window.pirsch?.(name);
    } catch (e) {
      console.trace(e);
    }
  }

  render() {
    return <></>;
  }
}
