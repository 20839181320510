import React, { memo } from 'react';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import InputWrapper from 'src/Components/Common/Inputs/InputWrapper';
import ConnectForm from 'src/Components/Common/Inputs/ConnectForm';

function Select({
  name,
  registerName,
  inputWrapperClassName,
  options,
  isDisabled,
  registerRules,
  isMulti,
  onChange,
  defaultValue,
  tooltipContent,
  setValue, //this is custom made by mr Hrafnkell To Retrieve values on change
  ...rest
}) {
  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          name={registerName}
          rules={registerRules}
          defaultValue={defaultValue}
          control={control}
          render={({ field, fieldState }) => (
            <InputWrapper
              name={name}
              inputWrapperClassName={inputWrapperClassName}
              isDisabled={isDisabled}
              error={fieldState?.error}
              tooltipContent={tooltipContent}
            >
              <ReactSelect
                {...rest}
                onChange={value => {
                  field.onChange(onChange ? onChange(value) : value);
                  if (setValue) setValue(value);
                }}
                isMulti={isMulti}
                options={options}
                defaultValue={defaultValue}
                isSearchable={false}
                isDisabled={isDisabled}
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    'height': '100%',
                    'padding': '0px',
                    'margin': '0px',
                    'boxShadow': 'none',
                    'background': '#eee',
                    'border': 'none',
                    '&:hover': {
                      border: '1px solid var(--gold)',
                    },
                  }),
                  valueContainer: baseStyles => ({
                    ...baseStyles,
                    margin: '0px',
                    padding: '12px 12px 12px 24px', // Could be usefull when we get more than one channel lol
                    height: isMulti ? 'auto' : '42px',
                  }),
                  input: baseStyles => ({
                    ...baseStyles,
                    margin: '0px',
                    padding: '0px',
                    height: '18px',
                  }),
                  indicatorSeparator: baseStyles => ({
                    ...baseStyles,
                    display: 'none',
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    'background': state.isSelected ? 'rgba(166, 158, 114, 0.3)' : '#fff',
                    '&:hover': {
                      background: 'rgba(166, 158, 114, 0.3)',
                    },
                    '&:active': {
                      background: '#7C7655',
                    },
                    'fontFamily': 'Open Sans',
                    'fontWeight': 400,
                    'fontSize': '14px',
                    'color': '#747474',
                    'padding': '12px 26px',
                  }),
                  placeholder: baseStyles => ({
                    ...baseStyles,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Open Sans',
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#b4b4b4',
                    height: '18px',
                  }),
                  singleValue: baseStyles => ({
                    ...baseStyles,
                    fontFamily: 'Open Sans',
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#747474',
                  }),
                  menu: provided => ({
                    ...provided,
                    borderRadius: 0,
                    border: 'none',
                    boxShadow: 'none',
                    zIndex: 20,
                  }),
                  menuList: provided => ({
                    ...provided,
                    padding: 0,
                    marginTop: '-7px',
                    border: 'none',
                    boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.3)',
                    maxHeight: '200px',
                  }),
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    'color': state.isFocused ? '#df3821' : '#747474',
                    'backgroundColor': 'transparent',
                    ':hover': {
                      backgroundColor: 'transparent',
                      color: '#df3821',
                    },
                  }),
                  multiValueLabel: provided => ({
                    ...provided,
                    fontFamily: 'Open Sans',
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#747474',
                  }),
                  dropdownIndicator: provided => ({
                    ...provided,
                    'color': '#747474',
                    ':hover': {
                      cursor: 'pointer',
                      color: '#747474',
                    },
                  }),
                  clearIndicator: provided => ({
                    ...provided,
                    'color': '#747474',
                    ':hover': {
                      cursor: 'pointer',
                      color: '#747474',
                    },
                  }),
                }}
              />
            </InputWrapper>
          )}
        />
      )}
    </ConnectForm>
  );
}

Select.defaultValues = {
  options: [],
  isDisabled: false,
  registerRules: {},
};

export default memo(Select);
