import React, { memo, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import { DEFAULT_USERS_FORM_VALUES } from 'src/shared/constants';
import { useStore } from 'src/store';
import Table from './Table';
import './UsersTabPanel.css';
import api from 'src/api';
import utils from 'src/utils';
import BaseModal from 'src/Components/Common/Modal/BaseModal';
import Input from 'src/Components/Common/Inputs/Input';
import Analytics from 'src/Context/Analytics';

function UsersTabPanel({ teamInfo, refreshTeam }) {
  const store = useStore();
  const methods = useForm({ defaultValues: DEFAULT_USERS_FORM_VALUES });
  const {
    formState: { isDirty },
  } = methods;

  const data = React.useMemo(() => {
    return teamInfo.users
      // Quick hack to hide svarmi staff from user list (unless it's a svarmi team)
      .filter(user =>
        !user.email.endsWith('svarmi.com') ||
        ['svarmi', 'demo', 'processing_tests'].includes(store.session.team.name)
      )
      .map(user => ({
        col1: user.display_name,
        col2: user.email,
        col3: utils.getTopRole(user.roles),
      }));
  }, [teamInfo.users]);

  const isFullMode = store.ui.menuFull;
  const canInvite = store.session.user.role === 'admin' || store.session.user.role === 'owner';
  const [invitationEmail, setInvitationEmail] = useState('');
  const [error, setError] = useState(null);
  const [confirm, setConfirm] = useState(false);

  async function performInvite() {
    const response = await api.call('/user/invite', { invite_email: invitationEmail });
    console.log(response);
    if (!response.success) {
      setError(`Something went wrong. Failed to invite "${invitationEmail}"`);
    } else {
      methods.reset();
      setInvitationEmail('');
    }
    await refreshTeam();
  }

  const handleClickSendInvitation = useCallback(() => {
    Analytics.trackClick('invite-user');
    console.log(invitationEmail);
    if (teamInfo.users.some(u => u.email === invitationEmail.trim().toLowerCase()))
      setConfirm(true);
    else
      performInvite();
  }, [invitationEmail, methods]);

  useEffect(() => {
    store.ui.unsavedChanges = isDirty;
  }, [isDirty]);

  useEffect(() => {Analytics.trackClick('settings-tab-users');}, [])

  return (
    <div>
      {store.session.isExternalUser ? (
        <div style={{ marginTop: '100px' }}>Not Visible To External Users</div>
      ) : (
        <>
          {confirm && (
            <BaseModal
              title={'This user is already part of the team'}
              rightButton={'Re-send invite'}
              onClickCancel={() => setConfirm(false)}
              onClickRightButton={() => {
                setConfirm(false);
                performInvite();
              }}
            ></BaseModal>
          )}
          {error && (
            <BaseModal hideButtons={true} title={error}>
              <div style={{ marginTop: '100px' }}>
                <BaseButton onClick={() => setError(null)} buttonType="SECONDARY">
                  Close
                </BaseButton>
              </div>
            </BaseModal>
          )}
          <FormProvider {...methods}>
            <form
              onSubmit={e => {
                e.preventDefault();
                handleClickSendInvitation();
              }}
              className="channels_users_tab_panel_form"
            >
              <Table data={data} refreshTeam={refreshTeam} />
              <div
                className={`channels_users_tab_panel_invitation_main_container ${
                  isFullMode ? 'channels_users_tab_panel_invitation_main_container_full_mode' : ''
                }`}
              >
                {canInvite ? (
                  <div className="channels_users_tab_panel_invitation_container">
                    <Input
                      name="Enter e-mail for invitation"
                      registerName="email"
                      placeholder="Enter Email name"
                      inputWrapperClassName="settings_users_tab_panel_input"
                      returnValue={setInvitationEmail}
                      /*
                      name="Enter e-mail for invitation"
                      value={invitationEmail}
                      className="input_invite"
                      placeholder="Enter Email"
                      onChange={e => setInvitationEmail(e.target.value)}*/
                    />
                    <BaseButton
                      type="button"
                      additionalClassName="channels_users_tab_panel_invitation_button"
                      onClick={handleClickSendInvitation}
                    >
                      Send Invitation
                    </BaseButton>
                  </div>
                ) : (
                  <div> </div>
                )}
              </div>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
}

export default memo(UsersTabPanel);
