import React, { useCallback, useState } from 'react';
import { CSVLink } from 'react-csv';
import './CsvAsset.less';

function CsvAsset({ asset }) {
  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Display Name', key: 'display_name' },
    { label: 'Location', key: 'location' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Address', key: 'address' },
    { label: 'Industry', key: 'industry' },
    { label: 'Footprint', key: 'footprint' },
    { label: 'Business Importance', key: 'business_importance' },
    { label: 'GFW Points', key: 'gfw_points' },
    { label: 'Land Classes', key: 'land_classes' },
    { label: 'Nature Importances', key: 'nature_importances' },
    { label: 'Nature Trend', key: 'nature_trend' },
    { label: 'Opportunity Score', key: 'opportunity_score' },
    { label: 'Priority', key: 'priority' },
    { label: 'Progress', key: 'progress' },
    { label: 'Sector', key: 'sector' },
    { label: 'Species', key: 'species' },
    { label: 'Energy Usage', key: 'energy_usage' },
    { label: 'Energy Usage Metrics', key: 'energy_usage_metrics' },
    { label: 'Water Usage', key: 'water_usage' },
    { label: 'Water Usage Metrics', key: 'water_usage_metrics' },
    { label: 'KBA Metrics', key: 'kba_metrics' },
    { label: 'WDPA Metrics', key: 'wdpa_metrics' },
    { label: 'Areas', key: 'areas' },
  ];

  const [csvData, setCsvData] = useState([]);

  const generateCsvData = useCallback(() => {
    if (!asset) return [];

    let data = asset
      ? [
          {
            name: asset.name,
            address: asset.address,
            business_importance: asset.business_importance || 'N/A',
            created_at: asset.created_at,
            display_name: asset.display_name,
            footprint: asset.footprint,
            gfw_points: asset.gfw_points,
            land_classes: asset.land_classes,
            location: asset.location,
            nature_importances: asset.nature_importances,
            nature_trend: asset.nature_trend,
            opportunity_score: asset.opportunity_score,
            priority: asset.priority,
            progress: asset.progress,
            sector: asset.sector,
            industry: asset.industry,
            energy_usage_metrics: asset.energy_usage_metrics?.total_amount || '0',
          },
        ]
      : [];

    function transformAndStoreData(assetArray, data, key) {
      assetArray.forEach((element, index) => {
        let detail = Object.keys(element).reduce((acc, currentKey) => {
          // Skip the key if it contains '_id'
          if (!currentKey.includes('id')) {
            acc[currentKey] = currentKey + ': ' + (element[currentKey] || 'N/A');
          }
          return acc;
        }, {});

        if (data[index] === undefined) data[index] = {};
        if (Object.keys(detail).length > 0) {
          data[index][key] = Object.values(detail).join(', ');
        }
      });
    }

    transformAndStoreData(asset.water_usage, data, 'water_usage');
    transformAndStoreData(asset.energy_usage, data, 'energy_usage');
    transformAndStoreData(asset.areas, data, 'areas');
    transformAndStoreData(asset.species, data, 'species');
    transformAndStoreData(asset.gfw_points, data, 'gfw_points');
    transformAndStoreData(asset.land_classes, data, 'land_classes');
    transformAndStoreData(asset.nature_trend, data, 'nature_trend');
    transformAndStoreData(asset.nature_importances, data, 'nature_importances');

    return data;
  }, [asset]);

  const handleDownloadClick = () => {
    const data = generateCsvData();
    setCsvData(data);
  };

  return (
    <CSVLink
      data={csvData}
      headers={headers}
      className="csv_download_button"
      asyncOnClick={true}
      filename={`${asset.display_name}.csv`}
      onClick={handleDownloadClick}
    >
      <img src="/icons/download.svg" />
      Download Asset .CSV
    </CSVLink>
  );
}

export default CsvAsset;
