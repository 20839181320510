import React, { useEffect, useState } from 'react';
import BaseButton from 'src/Common/Buttons/BaseButton';
import './DataTabPanel.less';
import Water from './Water/Water';
import LocationSelection from 'src/Assets/LocationSelection';
import { useStore } from 'src/store';
import DataList from './DataList/DataList';
import Energy from './Energy/Energy';
import AssetInfo from './AssetInfo/AssetInfo';
import SpreadSheet from './SpreadSheet/SpreadSheet';
import api from 'src/api';

const DataTabPanel = ({ asset, reload }) => {
  const DataTabWindow = {
    List: 'list',
    Data: 'data',
    Water: 'water',
    Energy: 'energy',
    SpreadSheet: 'spreadsheet',
  };

  const [window, setWindow] = useState(DataTabWindow.List);
  const [step, setStep] = useState(-1);

  const [editId, setEditId] = useState(null);
  const [editType, setEditType] = useState(null);

  const store = useStore();

  const isFullMode = false;
  const [address, setAddress] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [addressLock, setAddressLock] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Address');
  const [coordinates, setCoordinates] = useState(null);

  const [spreadSheets, setSpreadSheets] = useState([]);
  const [loadSpreadSheets, setLoadSpreadSheets] = useState(false);

  function handleContinue(newWindow) {
    setWindow(newWindow);
    setStep(step + 1);
  }

  useEffect(() => {
    if (spreadSheets.length === 0) fetchSpreadSheets();
  }, []);

  async function fetchSpreadSheets() {
    setLoadSpreadSheets(true);
    let tmpSpreadSheets = [];

    await Promise.all(
      asset.areas.map(async area => {
        let res = await api.call('/asset/dataframe/list', { area_id: area.area_id });
        console.log(res);
        tmpSpreadSheets.push(res.success); // This will ensure that all API calls are completed before proceeding.
      })
    );

    console.log(tmpSpreadSheets);
    setSpreadSheets(tmpSpreadSheets);
    setLoadSpreadSheets(false);
  }

  function handleBack(mainMenu = false) {
    store.ui.mapPreviewPin.style = null;
    store.ui.mapPreviewPin.location = null;

    if (mainMenu) {
      setWindow(DataTabWindow.List);
      setStep(0);
      reload();
      return;
    }

    switch (step) {
      case 0:
        setWindow(DataTabWindow.List);
        setStep(-1);
        break;
      case 1:
        setStep(0);
        setWindow(DataTabWindow.Data);
        break;
      case 2:
        setWindow(DataTabWindow.Data);
        setStep(1);
        break;
      default:
        break;
    }
  }

  //Location Region Start
  useEffect(() => {
    const { location } = store.ui.mapPreviewPin || {};

    if (location && selectedTab === 'Manual') {
      const [longitude, latitude] = location.map(parseFloat);

      setLongitude(longitude);
      setLatitude(latitude);
      setCoordinates([longitude, latitude]);
      console.log('Location:', location);
    }

    if (location && selectedTab === 'Address') {
      store.ui.map.state.olmap.jumpTo({ point_4326: location });
      setCoordinates(location);
      setAddress(location.address);
    }
  }, [store.ui.mapPreviewPin?.location, selectedTab]);

  useEffect(() => {
    store.ui.mapPreviewPin.style = 'asset';
    if (selectedTab === 'Manual') {
      store.ui.mapPreviewPin.style = 'manual';
    } else {
      store.ui.mapPreviewPin.style = 'asset';

      if (longitude && latitude) {
        store.ui.mapPreviewPin.location = [longitude, latitude];
      }

      if (selectedTab == 'Gps') {
        if (longitude != 0 && latitude != 0 && (longitude || latitude)) {
          console.log(longitude + ' ' + latitude);
          store.ui.map?.state.olmap?.jumpTo({ point_4326: [longitude, latitude] });
          setCoordinates([parseFloat(longitude), parseFloat(latitude)]);
        }
        if (longitude) setLongitude(parseFloat(longitude));
        if (latitude) setLatitude(parseFloat(latitude));
      }
    }
  }, [selectedTab, longitude, latitude]);

  //Location Region End

  function SelectScreen() {
    return (
      <>
        <div className="data_column_header">Please Select Data Type</div>
        <div className="data_container">
          <div className="data_column">
            <div className="data_type" onClick={() => handleContinue('water')}>
              <img width={24} src="/icons/water.svg " />
              Water
            </div>
          </div>
          <div className="data_column">
            <div className="data_type" onClick={() => handleContinue('energy')}>
              <img width={24} src="/icons/energy.svg " />
              Electricity
            </div>
          </div>

          <div className="data_column">
            <div className="data_type" onClick={() => handleContinue('spreadsheet')}>
              <img width={24} src="/icons/copy.svg " />
              Spreadsheet
            </div>
          </div>
        </div>
      </>
    );
  }

  function Body() {
    switch (window) {
      case DataTabWindow.Data:
        return SelectScreen();
      case DataTabWindow.Water:
        return (
          <Water
            asset={asset}
            address={addressLock}
            coordinates={coordinates}
            reload={reload}
            handleBack={handleBack}
          />
        );
      case DataTabWindow.Energy:
        return (
          <Energy asset={asset} coordinates={coordinates} reload={reload} handleBack={handleBack} />
        );
      case DataTabWindow.SpreadSheet:
        return <SpreadSheet asset={asset} handleBack={handleBack} location={coordinates} />;
      case DataTabWindow.List:
        return null;
      default:
        return <div>Something Went Wrong</div>;
    }
  }

  function EditBody() {
    switch (window) {
      case DataTabWindow.Data:
        return null;
      case DataTabWindow.Water:
        return (
          <Water
            edit
            asset={asset}
            address={addressLock}
            coordinates={coordinates}
            reload={reload}
            handleBack={handleBack}
          />
        );
      case DataTabWindow.Energy:
        return (
          <Energy
            edit
            asset={asset}
            coordinates={coordinates}
            reload={reload}
            handleBack={handleBack}
          />
        );
      case DataTabWindow.List:
        return null;
      default:
        return <div>Something Went Wrong</div>;
    }
  }

  const locationInfo = {
    isFullMode: isFullMode,
    selectedTab: selectedTab,
    setSelectedTab: setSelectedTab,
    address: address,
    setAddress: setAddress,
    longitude: longitude,
    setLongitude: setLongitude,
    latitude: latitude,
    setLatitude: setLatitude,
    addressExternal: address,
    addressLock: addressLock,
    setAddressLock: setAddressLock,
  };

  return (
    <div className="create_data_container">
      {window !== DataTabWindow.List && (
        <>
          {/*window === DataTabWindow.SpreadSheet && <DataTabSteps step={step}/>*/}
          {step <= 0 && (
            <BaseButton
              additionalClassName="continue_button"
              buttonType={'SECONDARY'}
              onClick={() => handleBack()}
            >
              Back
            </BaseButton>
          )}
        </>
      )}

      {window === DataTabWindow.List && (
        <>
          <AssetInfo asset={asset}></AssetInfo>
          <DataList
            setEditId={setEditId}
            setEditType={setEditType}
            asset={asset}
            reload={reload}
            spreadSheets={spreadSheets}
            loadSpreadSheets={loadSpreadSheets}
          />
          <BaseButton
            additionalClassName="continue_button"
            buttonType={'STANDARD'}
            onClick={() => handleContinue(DataTabWindow.Data)}
          >
            <img style={{ marginRight: '16px' }} width={'28px'} src="/icons/Add.svg" />
            Add Data
          </BaseButton>
        </>
      )}


      {(window === DataTabWindow.SpreadSheet) && (
        <LocationSelection locationInfo={locationInfo} />
      )}

      {editId >= 0 ? Body() : EditBody()}

      {(window === DataTabWindow.Water || window === DataTabWindow.Energy) && (
        <LocationSelection locationInfo={locationInfo} />
      )}
    </div>
  );
};

export default DataTabPanel;
