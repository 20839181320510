import React, { memo, useState, useEffect } from 'react';
import './TextToolTip.less';

function TextTooltip({ tooltipContent }) {
  const [tooltipPosition, setTooltipPosition] = useState(null);

  const handleMouseMove = e => {
    setTooltipPosition({ x: e.clientX + 20, y: e.clientY - 10 });
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  if (!tooltipContent) {
    return null;
  }

  return tooltipPosition ? (
    <div
      className="text-tooltip-container"
      style={{
        top: tooltipPosition.y,
        left: tooltipPosition.x,
        position: 'fixed',
        zIndex: 99999999999,
        maxWidth: '400px',
      }}
    >
      <div className="text-diamond" />
      <div className="text-tooltip">{tooltipContent}</div>
    </div>
  ) : null;
}

export default memo(TextTooltip);
