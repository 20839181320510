const DEFAULT_VIEWS_NUMBER = 5;

export const DEFAULT_VIEW_DATA = {
  name: '',
  isDefault: false,
  bounds: null,
};

export const DEFAULT_VIEWS = Array(DEFAULT_VIEWS_NUMBER)
  .fill(DEFAULT_VIEW_DATA)
  .map((item, index) => ({ ...item, id: index }));
