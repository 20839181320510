let api = {};

//api.token = async () => (await api.auth0.getIdTokenClaims()).__raw
api.token = async () =>
  window.location.pathname.startsWith('/shared/')
    ? api.currentToken // In sharing mode just reuse the token we've obtained manually
    : api.receiveNewToken(await api.auth0.getAccessTokenSilently()); // In classic mode constantly check for the most recent valid token and let Auth0 refresh it

api.forceRefreshToken = async () =>
  api.receiveNewToken(await api.auth0.getAccessTokenSilently({ cacheMode: 'off' }));

api.receiveNewToken = token => {
  if (token === api.currentToken) return token;
  console.log('New token received');
  api.currentToken = token;

  // A JWT is essentially three base64-encoded json blobs joined by dots, but it's slightly trickier:
  // - JWT uses the base64url RFC (special chars: _-, unpadded), while Javascript uses the base64 RFC (special chars: +/, = padding)
  // - Base64 maps to ascii, so unicode chars from the original binary data get split across two ascii chars
  // - We use decodeURIComponent to reinterpret this split up binary data into one long UTF-8 string, which requires first URI-encoding those chars
  // See https://stackoverflow.com/a/38552302
  const payload = api.currentToken.split('.')[1]
  const b64decoded = atob(payload.replace(/-/g, '+').replace(/_/g, '/'))
  const unicodeUri = b64decoded.split('').map(c => '%' + c.charCodeAt(0).toString(16).padStart(2, '0')).join('');
  const claims = JSON.parse(decodeURIComponent(unicodeUri));

  api.claims = claims;
  api.email = api.claims?.['https://datact.svarmi.com/email'];
  api.selectedTeam = api.claims?.['https://datact.svarmi.com/group'];

  api.datactUser = JSON.parse(api.claims?.['https://datact.svarmi.com/datactUser'] || 'null');
  console.log({ datactUser: api.datactUser });

  window.store.session = {
    ...window.store.session,
    token: token,
    user: {
      display_name: api.datactUser?.name,
      email: api.datactUser?.email,
      role: api.datactUser?.role,
    },
    team: {
      name: api.datactUser?.team,
      plan: api.datactUser?.plan,
    },
    channel: {
      channel_id: null,
      name: 'main',
    },
    isExternalUser: api.datactUser?.isExternalUser,
  };

  return token;
};

// Obtain access token manually via a special SharingLink Auth0 API call (username/password grant), rather than the classic loginWithRedirect()
api.obtainTokenForSharingLink = async hash => {
  const res = await fetch(`https://${process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN}/oauth/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/api/v2/`,
      client_id: process.env.REACT_APP_AUTH0_SHARINGLINK_CLIENT_ID,
      client_secret: process.env.REACT_APP_AUTH0_SHARINGLINK_CLIENT_SECRET,
      grant_type: 'password',

      username: `sharing_${hash}@no-email.invalid`,
      password: 'Aaaa0000_',
    }),
  });

  return (await res.json()).access_token;
};

api.call = async (path, data) => {
  try {
    const response = await fetch(process.env.REACT_APP_DATACT_API_URL + path, {
      method: 'POST',
      body: JSON.stringify({
        team: {
          team_id: window.store.session.team.team_id,
          name: window.store.session.team.name,
          plan: window.store.session.team.plan,
        },

        user: {
          user_id: window.store.session.user.user_id,
          display_name: window.store.session.user.display_name,
          email: window.store.session.user.email,
          role: window.store.session.user.role,
        },

        channel: {
          channel_id: window.store.session.channel?.channel_id,
          name: window.store.session.channel?.name || 'main',
        },

        ...data,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await api.token()}`,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
};

export default api;
