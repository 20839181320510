import React, { memo, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import addAreaDetails from 'public/icons/cutouts/add_area_details.png';
import Search from 'src/Components/Common/Inputs/Search';
import TabBaseInfo from 'src/Components/Common/TabBaseInfo';
import TextButton from 'src/Components/Common/Buttons/TextButton';
import Table from './Table';
import './ChannelsListTabPanel.css';
import { useStore } from 'src/store';
import TextToolTip from 'src/Components/Common/TextToolTip/TextToolTip';
import Analytics from 'src/Context/Analytics';

function ChannelsListTabPanel({ onClickAddChannel, onClickEditChannel, joinedChannels, refresh }) {
  const methods = useForm();
  const store = useStore();
  const channels = store.data.allChannels;
  const data = React.useMemo(() => {
    return channels.map(channel => ({
      col1: channel.name,
      col2: channel.created_at.split('T')[0],
      col3: channel.description,
      col4: channel.col4,
      channel: channel.channel_id,
      joined: joinedChannels.some(joinedChannel => joinedChannel.channel_id === channel.channel_id),
      refresh: refresh,
    }));
  }, [channels]);

  const { watch } = methods;

  const searchValue = watch('channelsSearch');

  const [toolTipValue, setToolTipValue] = useState('');

  const imageParams = useMemo(
    () => ({
      src: addAreaDetails,
      alt: 'channels',
      className: 'channels_list_tab_panel_empty_page_image',
    }),
    []
  );

  const baseButtonParams = useMemo(
    () => ({
      buttonName: 'Add New Channel',
    }),
    []
  );

  if (!data.length) {
    return (
      <TabBaseInfo
        title={'You have no \n channels'}
        imageParams={imageParams}
        baseButtonParams={baseButtonParams}
      />
    );
  }

  return (
    <div>
      {store.session.isExternalUser ? (
        <div className="channels_external_user_no_access">Not Visible To External Users</div>
      ) : (
        <FormProvider {...methods}>
          <TextToolTip tooltipContent={toolTipValue} />
          <form className="channels_list_tab_panel_form">
            <div className="channels_list_tab_panel_search_wrapper">
              <Search placeholder="Search ..." registerName="channelsSearch" />
            </div>
            {data && (
              <Table
                data={data}
                globalFilterValue={searchValue}
                onClickEditChannel={onClickEditChannel}
                setToolTipValue={setToolTipValue}
              />
            )}
            {!store.session.isExternalUser && (
              <div className="channels_list_tab_panel_buttons_main_container" onClick={() => Analytics.trackClick('add-new-channel')}>
                <TextButton className="channels_list_text_button" onClick={onClickAddChannel}>
                  ＋ Add New Channel
                </TextButton>
              </div>
            )}
          </form>
        </FormProvider>
      )}
    </div>
  );
}

export default memo(ChannelsListTabPanel);
