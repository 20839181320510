import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { buffer } from 'ol/extent';
import { easeOut } from 'ol/easing';
import { getVectorContext } from 'ol/render';

class AnimatedCluster extends VectorLayer {
  constructor(opt_options) {
    const options = opt_options || {};
    super(opt_options);

    this.oldcluster = new VectorSource();
    this.clusters = [];
    this.animation = { start: false };
    this.set(
      'animationDuration',
      typeof options.animationDuration == 'number' ? options.animationDuration : 700
    );
    this.set('animationMethod', options.animationMethod || easeOut);

    // Save cluster before change
    this.getSource().on('change', this.saveCluster.bind(this));

    // Animate the cluster
    this.on(['precompose', 'prerender'], this.animate.bind(this));
    this.on(['postcompose', 'postrender'], this.postanimate.bind(this));
  }

  saveCluster() {
    if (this.oldcluster) {
      this.oldcluster.clear();
      if (!this.get('animationDuration')) return;

      const features = this.getSource().getFeatures();

      if (features.length && features[0].get('features')) {
        this.oldcluster.addFeatures(this.clusters);
        this.clusters = features.slice(0);
        this.sourceChanged = true;
      }
    }
  }

  stopAnimation() {
    this.animation.start = false;
    this.animation.cA = [];
    this.animation.cB = [];
  }

  // Remove clipping after the layer is drawn
  postanimate(e) {
    if (this.clip_) {
      e.context.restore();
      this.clip_ = false;
    }
  }

  animate(e) {
    const duration = this.get('animationDuration');
    if (!duration) return;

    const resolution = e.frameState.viewState.resolution;
    let time = e.frameState.time;

    // Start a new animation, if change resolution and source has changed
    if (this.animation.resolution != resolution && this.sourceChanged) {
      let extent = buffer(e.frameState.extent, 100 * resolution);

      // Zooming in versus zooming out
      if (this.animation.resolution < resolution) {
        this.animation.cA = this.oldcluster.getFeaturesInExtent(extent);
        this.animation.cB = this.getSource().getFeaturesInExtent(extent);
        this.animation.reverse = false;
      } else {
        this.animation.cA = this.getSource().getFeaturesInExtent(extent);
        this.animation.cB = this.oldcluster.getFeaturesInExtent(extent);
        this.animation.reverse = true;
      }

      // Add one animation for each children feature to spread out from
      // its parent cluster (will get reversed if zooming out)
      this.animation.clusters = [];
      this.animation.cA.forEach(c0 => {
        const f = c0.get('features');
        if (f && f.length) {
          const c = this.animation.cB.find(c => c.get('features')?.includes(f[0]));
          if (c) this.animation.clusters.push({ f: c0, pt: c.getGeometry().getCoordinates() });
        }
      });

      // Save state
      this.animation.resolution = resolution;
      this.sourceChanged = false;

      // No cluster or too much to animate
      if (!this.animation.clusters.length || this.animation.clusters.length > 1000) {
        this.stopAnimation();
        return;
      }

      // Start animation from now
      time = this.animation.start = new Date().getTime();
    }

    // Run animation
    if (this.animation.start) {
      let vectorContext = e.vectorContext || getVectorContext(e);
      let d = (time - this.animation.start) / duration;

      // Animation ends
      if (d > 1.0) {
        this.stopAnimation();
        d = 1;
      }

      d = this.get('animationMethod')(d);

      // Animate
      let style = this.getStyle();
      let stylefn = typeof style == 'function' ? style : style.length ? () => style : () => [style];

      // Layer opacity
      e.context.save();
      e.context.globalAlpha = this.getOpacity();
      this.animation.clusters.forEach(c => {
        let pt = c.f.getGeometry().getCoordinates();
        let dx = pt[0] - c.pt[0];
        let dy = pt[1] - c.pt[1];
        if (this.animation.reverse) {
          pt[0] = c.pt[0] + d * dx;
          pt[1] = c.pt[1] + d * dy;
        } else {
          pt[0] = pt[0] - d * dx;
          pt[1] = pt[1] - d * dy;
        }

        // Draw feature if one feature else draw a point
        const f =
          c.f.get('features').length === 1 && !dx && !dy
            ? c.f.get('features')[0]
            : new Feature(new Point(pt));
        let st = stylefn(c.f, resolution, true);
        if (!st.length) st = [st];

        st.forEach(s => {
          // Multi-line text
          if (s.getText() && /\n/.test(s.getText().getText())) {
            let offsetX = s.getText().getOffsetX();
            let offsetY = s.getText().getOffsetY();
            let rot = s.getText().getRotation() || 0;
            let fontSize = Number((s.getText().getFont() || '10px').match(/\d+/)) * 1.2;
            let str = s.getText().getText().split('\n');
            let dl,
              nb = str.length - 1;
            let s2 = s.clone();

            // Draw each lines
            str.forEach((t, i) => {
              if (i == 1) {
                // Allready drawn
                s2.setImage();
                s2.setFill();
                s2.setStroke();
              }

              switch (s.getText().getTextBaseline()) {
                case 'alphabetic':
                case 'ideographic':
                case 'bottom': {
                  dl = nb;
                  break;
                }
                case 'hanging':
                case 'top': {
                  dl = 0;
                  break;
                }
                default: {
                  dl = nb / 2;
                  break;
                }
              }

              s2.getText().setOffsetX(offsetX - Math.sin(rot) * fontSize * (i - dl));
              s2.getText().setOffsetY(offsetY + Math.cos(rot) * fontSize * (i - dl));
              s2.getText().setText(t);

              vectorContext.drawFeature(f, s2);
            });
          } else {
            vectorContext.drawFeature(f, s);
          }
        });
      });

      e.context.restore();

      // tell ol to continue postcompose animation
      e.frameState.animate = true;

      // Prevent layer drawing (clip with null rect)
      e.context.save();
      e.context.beginPath();
      e.context.rect(0, 0, 0, 0);
      e.context.clip();
      this.clip_ = true;
    }

    return;
  }
}

export default AnimatedCluster;
