import React, { Component } from 'react';
import api from 'src/api';
import utils from 'src/utils';
import { /*useStore,*/ ref } from 'src/store';

import OlMap from 'ol/Map';
import OlView from 'ol/View';
import { transform, transformExtent, fromLonLat } from 'ol/proj';
import { defaults as defaultInteractions, DragRotateAndZoom } from 'ol/interaction';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Cluster } from 'ol/source';
import Feature from 'ol/Feature';
import { Point, Circle, MultiPolygon, MultiPoint } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';

import {
  getIntersection,
  getArea,
  getCenter,
  boundingExtent,
  buffer as bufferExtent,
  containsCoordinate,
  intersects,
} from 'ol/extent';

import Analytics from 'src/Analytics';
import MeasureTool from 'src/Widgets/MeasureTool';
import ColorBar from 'src/Widgets/ColorBar';
import ContextMenu from 'src/Widgets/ContextMenu';
import LayerDetails from 'src/Widgets/LayerDetails';
import SearchBox from 'src/Widgets/SearchBox';
import PinDetails from 'src/Pins/PinDetails';
import AssetToolTip from 'src/Map/AssetToolTip';
import IconStyles from 'src/Map/IconStyles';
import AnimatedCluster from 'src/Map/AnimatedCluster';

import {
  getBasemaps,
  getWMSLayers,
  getRGBLayer,
  getFloatLayer,
  curtainPreRender,
  curtainPostRender,
} from 'src/Map/RasterLayers';

import 'ol/ol.css';
import './GeoMap.less';

export default class GeoMap extends Component {
  state = {
    center: [0, 0],
    zoom: 1,
    olmap: null,
    layers: [],
    pins: [],
    curtainSliderValue: 50,
    layerIconLayer: null,
    pinIconLayer: null,
    locationDot: null,
    currentLocation: null,
    loadingTiles: 0,
    totalTiles: 0,
    info: null,
    pixelValue: null,
    mostVisibleLayers: null,
    contextMenu: null,
    clickedLayer: null,
    clickedAsset: null,
    clickedPin: null,
    hoveredLayer: null,
    hoveredPin: null,
    aoiLayers: null,
    overlapLayer: null,
    aoiPreviewLayers: null,
    assetLayer: null,
    monitoringAreaLayers: null,
    waterUsageLayers: null,
    previewLocationLayer: null,
    energyUsageLayers: null,
    hoveredAsset: null,
    dataManagerHover: null,
    assetAreaLayer: null,
    dataframes: null,
    dataframesLayer: null,
  };

  async componentDidMount() {
    await this.init();
  }

  async init() {
  
    const basemaps = getBasemaps();
    const wmsLayers = getWMSLayers();

    const rasterLayers = [];
    for (const [index, item] of this.props.layers.entries()) {
      let layer;
      if(['thermal', 'diff_thermal', 'dem', 'diff_dem', 'dsm', 'diff_dsm'].includes(item.raster.raster_type)) {
        layer = getFloatLayer(item);
      } else {
        layer = getRGBLayer(item);
      }

      item.viewObject = layer;
      rasterLayers.push(layer);
    }

    if (this.state.olmap) this.state.olmap.setTarget(null);

    const allLayers = [...Object.values(basemaps).flat(), ...rasterLayers, ...wmsLayers].filter(v => v);
    const olmap = new OlMap({
      target: 'map',
      layers: allLayers,
      view: new OlView({
        center: this.state.center || [0, 0],
        zoom: this.state.zoom || 1,
        // Prevent panning beyond the dateline and getting duplicate markers and other bugs
        extent: [-20037508, -20048966, 20037508, 20048966],
        multiworld: false,
      }),
      crossOrigin: 'Anonymous',
      maxTilesLoading: 16,
      interactions: defaultInteractions({
        doubleClickZoom: false,
      }).extend([new DragRotateAndZoom()]),
    });

    // Handle loading bar for tiles
    allLayers.forEach(layer => {
      layer.on('prerender', curtainPreRender);
      layer.on('postrender', curtainPostRender);
      const source = layer.getSource();
      source.on('tileloadstart', () => this.setState(s => ({ loadingTiles: s.loadingTiles + 1, totalTiles: s.totalTiles + 1 })));
      source.on('tileloadend', () => this.setState(s => ({ loadingTiles: s.loadingTiles - 1 }), checkComplete));
      source.on('tileloaderror', () => this.setState(s => ({ loadingTiles: s.loadingTiles - 1 }), checkComplete));
    });

    // If no tiles remaining, wait 500ms and reset the loading bar
    const checkComplete = () => {
      if (this.state.loadingTiles === 0)
        setTimeout(() => this.setState(s => (s.loadingTiles === 0 ? { totalTiles: 0 } : {})), 500);
    };

    // Add short partition between zoom buttons
    const partition = document.createElement('div');
    partition.className = 'zoom-buttons-partition';
    const olZoomIn = document.getElementsByClassName('ol-zoom-in')[0];
    olZoomIn.after(partition);

    // Context menu handling

    olmap.on('pointerdown', () => this.setState({ contextMenu: null }));
    olmap.on('contextmenu', e => {
      // Ignore any right-click event that happened outside of the actual map layer
      if (e.originalEvent.target.tagName.toLowerCase() !== 'canvas') return;
      e.preventDefault();
      this.setState({
        contextMenu: {
          pixel: e.pixel,
          coordinate: e.coordinate,
        },
      });
    });

    olmap.on('moveend', e => {
      // Hide or show appropriate DescriptionWindow and ColorBar based on which layers are in view
      const viewport = e.frameState.extent;
      const viewportArea = getArea(viewport);
      const [minDate, maxDate] = this.props.rangeFilters['raster'].selected;

      const mostVisibleLayers = this.props.layers
        .filter(l => minDate <= new Date(l.date).getTime() && new Date(l.date).getTime() <= maxDate)
        .filter(l => getArea(getIntersection(viewport, l.extent_3857)) / viewportArea > 0.0001);

      // Update position
      try {
        const center = olmap.getView().getCenter();
        const zoom = olmap.getView().getZoom();
        this.props.updateLocation(center, zoom);
        this.setState({ center, zoom, mostVisibleLayers });
      } catch (error) {
        console.info(error);
      }

      // If in location edit/preview mode, update location
      if (window.store.ui.mapPreviewPin.style == 'manual' && this.state.olmap) {
        let center = this.state.olmap.getView().getCenter();
        let newLocation = transform(center, 'EPSG:3857', 'EPSG:4326');
        console.log(newLocation);
        window.store.ui.mapPreviewPin.location = newLocation;
      }
    });

    const getHitFeatures = evt => {
      // Ignore clicks on features if MeasureTool is out
      if (
        olmap
          .getInteractions()
          .getArray()
          .some(i => ['Draw', 'Modify', 'Snap'].includes(i.constructor.name))
      )
        return { layers: [], assets: [], clusters: [] };

      const layers = this.props.layers.filter(
        l =>
          l.viewObject.rendered &&
          containsCoordinate(l.extent_3857, evt.coordinate) &&
          new MultiPolygon(l.geog_3857).intersectsCoordinate(evt.coordinate)
      );

      const assets = [];
      const clusters = []; // Note: A pin is simply a cluster with one item
      const previewPin = [];

      olmap.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (layer === basemaps.hybrid[1]) return; // Ignore basemap's VectorLayer Features

        if (layer?.values_?.modelObject?.imAFootprint) {
          assets.push(layer.values_.modelObject);
        }
        if (layer?.values_?.modelObject?.asset_id) {
          assets.push(layer.values_.modelObject);
        }
        if (feature?.values_.modelObject?.asset_id) {
          assets.push(feature?.values_.modelObject);
        }
        if (feature?.values_?.features?.length) {
          clusters.push(feature.values_.features.map(f => f.values_.modelObject));
        }
      });

      return { layers, assets, clusters, previewPin };
    };

    olmap.on('click', evt => {
      // This is used to place preview pin
      if (this.state.movePreviewPin) {
        this.setState({ movePreviewPin: false });
        return;
      }

      let clickedAsset, clickedLayer, clickedPin, showPinClusterHint;
      const clicked = getHitFeatures(evt);
      // To limit UX confusion, clicking only affects one type of feature at a time, hence the strict priority order below:

      console.log(clicked);

      // Cluster
      if (clicked.clusters.length && clicked.clusters[0].length > 1) {
        clicked.clusters.map(arrayOfItems => {
          if (!arrayOfItems) return;

          //if clicked on  asset or pin cluster
          if (arrayOfItems[0]?.asset_id || arrayOfItems[0]?.pin_id) {
            const extent = boundingExtent(
              arrayOfItems.map(item => item.location_3857 || item.geog_3857)
            );

            olmap.jumpTo({ extent_3857: extent });
            if (getArea(extent) <= 0.01) {
              const index =
                clicked.clusters[0].map(p => p.pin_id).indexOf(this.state.clickedPin?.pin_id) + 1;
              clickedPin = clicked.clusters[0][index];
              showPinClusterHint = true;
            }
          }

          //if clicked on a layer
          if (arrayOfItems[0]?.extent_3857) {
            const extent = boundingExtent(
              arrayOfItems
                .map(p =>
                  p.extent_3857
                    ? [p.extent_3857.slice(0, 2), p.extent_3857.slice(2)]
                    : [p.geog_3857]
                )
                .flat()
            );
            olmap.jumpTo({ extent_3857: extent });
          }
        });
      }

      // Single Item
      else if (clicked.clusters.length && clicked.clusters[0].length === 1) {
        // if asset
        /*if (clicked.clusters[0][0].asset_id) {
          clickedAsset = clicked.clusters[0][0];
          this.props.showAssets(clickedAsset);
          return;
        }*/

        if (clicked.clusters[0][0].extent_3857) {
          // For layers, jump to i
          olmap.jumpTo({ extent_3857: clicked.clusters[0][0].extent_3857 });
        }
        // For pins just open the popup
        else {
          clickedPin = clicked.clusters[0][0];
        }
      }

      // Assets
      else if (clicked.assets.length) {
        // check for if the clicked on asset instead of aoi or mon_area
        // make sure that is not water usage or energy usage
        if (
          !clicked.assets[0].geog &&
          clicked.assets[0].asset_id &&
          !clicked.assets[0].water_usage_id &&
          !clicked.assets[0].energy_usage_id
        ) {
          clickedAsset = clicked.assets[0];
          this.props.showAssets(clickedAsset);
        }
      }
      // Layers
      else if (clicked.layers.length) {
        // Make sure to grab the topmost layer (furthest down the list) among all the ones that intersect at this
        //clickedLayer = clicked.layers?.slice(-1)?.[0]
      }

      this.setState({ clickedAsset, clickedLayer, clickedPin, showPinClusterHint });
    });

    olmap.on('pointermove', evt => {


      if (evt.dragging) return;
      this.setState({ hoveredAsset: null });
      const hovered = getHitFeatures(evt);
      const pointer = hovered?.assets?.length || hovered?.clusters?.length || hovered?.previewPin?.length;
      olmap.getTargetElement().style.cursor = pointer ? 'pointer' : '';

      // Hovered asset
      if (hovered.assets[0]) {
        this.setState({ hoveredAsset: hovered.assets[0] });
      } else {
        this.setState({ hoveredAsset: null });
      }

      // Hovered pin
      if (
        hovered.clusters.length &&
        hovered.clusters[0].length === 1 &&
        hovered.clusters[0][0]?.pin_id !== this.state.clickedPin?.pin_id
      ) {
        this.setState({ hoveredPin: hovered.clusters[0][0] });
      } else {
        this.setState({ hoveredPin: null });
      }

      // Hovered layer
      // Make sure to grab the topmost layer (furthest down the list) among all the ones that intersect at this
      this.setState({ hoveredLayer: hovered.layers?.slice(-1)?.[0] });
    });

    olmap.on('pointercancel', evt => {
      console.log('Leave');
      if (evt.dragging) return;
      this.setState({ hoveredAsset: null });
    });


    /*
     * All encompassing function for the jump animation (zoom out, travel, zoom back in),
     * callable from many places and with many different parameters
     */
    olmap.jumpTo = args => {
      console.log({ jumpTo: args });
      const view = olmap?.getView();
      if (!view) return;

      let {
        shape_3857, shape_4326, // SimpleGeometry (such as MultiPolygon), such that getExtent() can be called
        geog_3857, geog_4326, // Geog coords array as typical from our database
        extent_3857, extent_4326, // [minx,miny,maxx,maxy] extent
        point_3857, point_4326, radius_m = 1e3, // Single point coordinate and a radius around it (1km default)
        padding_m = 0, padding_px = 100, // Spacing from the edges
        duration = 1000,
        callback = _ => _,
      } = args;

      // Convert 4326s to their 3857s counterparts if available
      shape_3857 ??= shape_4326 && shape_4326.clone().transform('EPSG:4326', 'EPSG:3857');
      extent_3857 ??= extent_4326 && transformExtent(extent_4326, 'EPSG:4326', 'EPSG:3857');
      point_3857 ??= point_4326 && fromLonLat(point_4326);

      // Convert geogs to shapes, convert single points to shapes (with a padding circle around it so it doesn't zoom to infinity)
      shape_3857 ??= geog_3857 && new MultiPolygon(utils.wrapGeog(geog_3857));
      shape_3857 ??=
        geog_4326 &&
        new MultiPolygon(utils.wrapGeog(geog_4326)).transform('EPSG:4326', 'EPSG:3857');
      shape_3857 ??= point_3857 && new Circle(point_3857, radius_m);

      // Convert shape to extent, and if we still haven't somehow obtained an extent at this point, we're screwed, abort.
      extent_3857 ??= shape_3857 && shape_3857.getExtent();
      if (!extent_3857) return;
 
      // Account for padding_px in the overall framing
      const padding = [padding_px, padding_px, padding_px, padding_px];
      const mapSize = olmap.getSize()     
      const topleft = olmap.getCoordinateFromPixel([ padding[3], padding[0] ])
      const bottomright = olmap.getCoordinateFromPixel([ mapSize[0] - padding[1], mapSize[1] - padding[2] ])
      const focusSize = [ mapSize[0] - padding[1] - padding[3], mapSize[1] - padding[0] - padding[2] ];
      const focusExtent = topleft && bottomright && boundingExtent([ topleft, bottomright ]) || view.calculateExtent()
  
      // Initial frame (current view)
      const startExtent = focusExtent;
      const startZoom = view.getZoom();

      // Final frame (zoomed in on target with correct padding)
      const finalExtent = bufferExtent(extent_3857, padding_m);
      const finalZoom = view.getZoomForResolution(view.getResolutionForExtent(finalExtent, focusSize));

      // Check against the world extent
      const worldExtent = [-41891566, -20132330, 20132330, 41891566];
      if (!intersects(worldExtent, finalExtent)) {
        console.error("The extent is outside the world extent");
        return;
      }

      // Apex frame (the middle of the jump where the camera is at is highest and both start and finish are visible)
      const apexExtent = boundingExtent(utils.chunks([].concat(startExtent, finalExtent), 2))      
      const apexZoom = view.getZoomForResolution(view.getResolutionForExtent(apexExtent, focusSize))
      
      // The bigger the jump, the longer it should take the camera (constant speed, not constant duration)
      const zoomOutDuration = (startZoom - apexZoom) * duration * 0.2;
      const zoomInDuration = (finalZoom - apexZoom) * duration * 0.2;

      // Chain the animations for the zoom out, travel, then zoom in with padding.
      view.cancelAnimations();
      view.fit(apexExtent, {padding, duration: zoomOutDuration, callback: b => b &&
        view.fit(finalExtent, {padding, duration: zoomInDuration, callback: c => c &&
          callback()
        })
      })
    };
  

    this.initializeMonitoringAreas(olmap);
    this.initializeLayerClusters(olmap);
    this.initializePins(olmap);
    this.initializeAOI(olmap);
    this.initializePreviewAOI(olmap);
    this.initializeAssets(olmap);
    this.initializeDataframes(olmap);
    this.updateAssetFootprint(olmap);
    this.initializeWaterUsage(olmap);
    this.initializeEnergyUsage(olmap);
    this.initializePreviewLocation(olmap);

    // add olmap to mapstore [note: super bad way to do it - just need to rewrite this component as function...]
    window.store.ui.map = ref(this);

    this.setState({
      olmap: olmap,
      layers: allLayers,
      basemaps,
    });
  }

  componentWillUnmount() {
    //this.unsubscribe(); //is causing crash TODO (investigate)
  }

  async componentDidUpdate(prevProps, prevState) {
    // Prevent pointless rerenders (triggered by pointermove > getHitFeatures > setState(hoveredPin), in this case)
    if (
      !Object.entries(this.props).some(([k, v]) => v !== prevProps[k]) &&
      !Object.entries(this.state).some(([k, v]) => v !== prevState[k])
    )
      return;

    // clear context menu when zoom changes on map
    if (this.state.zoom !== prevState.zoom) {
      this.clearContextMenu();
    }
    // Reload everything if layers have changed
    if (this.props.layers != prevProps.layers) return await this.init();
    if (this.props.assets != prevProps.assets) return await this.init();

    // Enable whichever basemap should be visible and hide the others
    if (this.props.basemap !== prevProps.basemap) {
      Object.entries(this.state.basemaps || []).forEach(([name, layers]) =>
        layers.forEach(l => l.setVisible(name === this.props.basemap))
      );
    }

    if (this.state.clickedPin !== prevState.clickedPin) {
      if (this.state.clickedPin) {
        this.pin;
      }
    }

    // Curtain and/or rangeFilters have changed, we need to move each layer to its correct curtain side and visibility
    if (this.state.olmap && this.props.layers && (
      JSON.stringify(this.props.rangeFilters?.raster) !== JSON.stringify(prevProps.rangeFilters?.raster) ||
      this.props.curtain !== prevProps.curtain
    )) {
      try {
        // For each layer, based on its captureDate and the current position of the handles, there are three possibilities:
        this.props.layers.forEach(layer => {
          const captureDate = new Date(layer.date).getTime();
          const handles = this.props.rangeFilters.raster.selected;

          // Falls within the right selection range
          if(
            handles[2] && handles[3] &&
            handles[2] <= captureDate && captureDate <= handles[3]
          ) {
            layer.viewObject.set('curtainSide', 'right');
            layer.viewObject.setVisible(true);
          }

          // Falls within the left selection range (or also just single selection range)
          else if(
            handles[0] && handles[1] &&
            handles[0] <= captureDate && captureDate <= handles[1]
          ) {
            layer.viewObject.set('curtainSide', 'left');
            layer.viewObject.setVisible(true);
          }

          // Falls outside and should be hidden
          else {
            layer.viewObject.setVisible(false);
          }
        });
      } catch (error) {
        console.info(error);
        Analytics.reportClientError(error);
      }
    }

    const checkCurrentlocation = this.props.currentLocation.every(
      (value, index) => value === prevProps.currentLocation[index]
    );

    if (
      this.state.olmap &&
      this.props.currentLocationRadius &&
      this.props.currentLocation &&
      this.props.currentLocation.length === prevProps.currentLocation.length &&
      !checkCurrentlocation
    ) {
      // Geometries
      const point = new Point(
        transform(
          [this.props.currentLocation[0], this.props.currentLocation[1]],
          'EPSG:4326',
          'EPSG:3857'
        )
      );

      const circle = new Circle(
        transform(
          [this.props.currentLocation[0], this.props.currentLocation[1]],
          'EPSG:4326',
          'EPSG:3857'
        ),
        this.props.currentLocationRadius
      );

      // Features
      const pointFeature = new Feature(point);
      const circleFeature = new Feature(circle);

      // Source and vector layer
      const vectorSource = new VectorSource({
        projection: 'EPSG:4326',
        features: [circleFeature, pointFeature],
      });

      const vector = new VectorLayer({
        source: vectorSource,
        zIndex: 200,
        style: new Style({
          fill: new Fill({
            color: 'rgba(253, 92, 120, 0.4)',
          }),
          stroke: new Stroke({
            color: '#fd5c78',
            width: 2,
          }),
          image: new CircleStyle({
            radius: 8,
            fill: new Fill({
              color: '#fd5c78',
            }),
          }),
        }),
      });

      if (this.state.currentLocation) {
        this.state.olmap.removeLayer(this.state.currentLocation);
      }

      this.state.olmap.addLayer(vector);

      this.state.olmap.jumpTo({ extent_3857: circle.extent_ });

      this.setState({
        currentLocation: vector,
      });
    }

    if (this.state.olmap && this.state.currentLocation && !checkCurrentlocation) {
      this.state.olmap.removeLayer(this.state.currentLocation);
    }

    if (
      (this.state.olmap && this.props.pins !== prevProps.pins) ||
      (this.props.selectedPins.status !== prevProps.selectedPins.status) ||
      (this.state.olmap && this.state.dataManagerHover !== prevState.dataManagerHover)
    ) {
        this.initializePins(this.state.olmap);
    }

    if (
      (this.state.olmap && this.state.dataManagerHover !== prevState.dataManagerHover)
    ) {
        this.initializeLayerClusters(this.state.olmap);
    }


    if (this.state.olmap && this.state.clickedPin !== prevState.clickedPin) {
      this.props.updateHotlink?.();
    }

    if (this.state.olmap && (this.props.aois !== prevProps.aois || this.props.hoveredOverlay !== prevProps.hoveredOverlay)) {
      this.initializeAOI(this.state.olmap);
    }

    if (this.state.olmap && this.props.aoiPreview !== prevProps.aoiPreview) {
      this.initializePreviewAOI(this.state.olmap);
    }

    if (this.state.olmap && this.props.assetOverlap !== prevProps.assetOverlap) {
      this.initializeGenericOverlay(this.state.olmap);
    }

    if ((this.state.olmap && this.props.monitoringAreas !== prevProps.monitoringAreas) ||
      (this.props.hoveredOverlay !== prevProps.hoveredOverlay)) {
      this.initializeMonitoringAreas(this.state.olmap);
    }

    if (this.state.olmap && this.props.waterUsage !== prevProps.waterUsage) {
      this.initializeWaterUsage(this.state.olmap);
    }

    if (this.state.olmap && this.props.energyUsage !== prevProps.energyUsage) {
      this.initializeEnergyUsage(this.state.olmap);
    }

    if (
      (this.state.olmap && this.props.selectedAsset !== prevProps.selectedAsset) ||
      (this.state.olmap && this.state.hoveredAsset !== prevState.hoveredAsset) ||
      (this.state.olmap && this.state.dataManagerHover !== prevState.dataManagerHover)
    ) {
      this.initializeAssets(this.state.olmap);
    }

    if (this.state.olmap && this.props.dataframes !== prevProps.dataframes) {
      this.initializeDataframes(this.state.olmap);
    }

    if ((this.state.olmap && this.props.selectedAsset !== prevProps.selectedAsset) ||
        (this.props.hoveredOverlay !== prevProps.hoveredOverlay)) {
      this.updateAssetFootprint(this.state.olmap);
    }

    if (this.state.olmap && this.props.previewLocation !== prevProps.previewLocation || this.state.movePreviewPin !== prevState.movePreviewPin) {
      this.initializePreviewLocation(this.state.olmap);
    }
  }

  initializePreviewLocation(olmap) {
    if (!this.props.previewLocation?.location || this.props.previewLocation.length === 0) {
      if (this.state.previewLocationLayer) {
        olmap?.removeLayer(this.state.previewLocationLayer);
      }
      return;
    }

    // Assuming Feature, Point, Style, Icon, and waterAssetIcon are properly defined
    const feature = new Feature({
      geometry: new Point(fromLonLat(this.props.previewLocation.location)),
      modelObject: this.props.previewLocation,
    });

    feature.setStyle(IconStyles.previewLocation);

    const vectorLayer = new VectorLayer({
      zIndex: 202,
      source: new VectorSource({
        features: [feature],
      }),
    });

    if (this.state.previewLocationLayer) {
      olmap?.removeLayer(this.state.previewLocationLayer);
    }

    olmap?.addLayer(vectorLayer);
    this.setState({ previewLocationLayer: vectorLayer });
  }

  initializeAssets(olmap) {
    if (!this.props.assets || this.props.assets.length === 0) {
      if (this.state.assetLayers) {
        olmap?.removeLayer(this.state.assetLayers);
      }
      return;
    }

    const assetStyleFunction = feature => {
      const assetId = feature.get('modelObject')?.asset_id;
      const assetName = feature.get('modelObject')?.name;
      const featureAssetId = feature.get('modelObject')?.asset_id;
      const isAssetIdInDataBlock =
        window.store?.ui?.hoveredDataBlock.id == featureAssetId &&
        window.store?.ui?.hoveredDataBlock.type == 'Asset';

      if (assetId && assetId !== featureAssetId) {
        return IconStyles.assetLocationMuted;
      } else if (isAssetIdInDataBlock) {
        return IconStyles.assetLocationHovered;
      } else {
        return IconStyles.assetLocation;
      }
    };

    const assetFeatures = this.props.assets
      .filter(a => a.location_3857)
      .map(a => {
        const feature = new Feature({
          geometry: new Point(a.location_3857),
          modelObject: a,
        });
        return feature;
      });

    const assetSource = new VectorSource({
      features: assetFeatures,
    });

    const assetLayer = new VectorLayer({
      source: assetSource,
      style: assetStyleFunction,
      zIndex: 102,
    });

    olmap?.removeLayer(this.state.assetLayers);
    olmap?.addLayer(assetLayer);

    this.setState({ assetLayers: assetLayer });
  }

  initializeDataframes(olmap) {
    if (!this.props.dataframes || this.props.dataframes.length === 0) {
      if (this.state.dataframesLayer) {
        olmap?.removeLayer(this.state.dataframesLayer);
      }
      return;
    }

    const assetStyleFunction = feature => {
      return IconStyles.dataframeLocation;
    };

    const dataframeFeatures = this.props.dataframes && this.props.dataframes[0]
      ? this.props.dataframes[0].map(a => {
          const feature = new Feature({
            geometry: new Point(transform(a.geog, 'EPSG:4326', 'EPSG:3857')),
            modelObject: a,
          });
          return feature;
        })
      : [];

    const dataframeSource = new VectorSource({
      features: dataframeFeatures,
    });

    const dataframesLayer = new VectorLayer({
      source: dataframeSource,
      style: assetStyleFunction,
      zIndex: 102,
    });

    olmap?.removeLayer(this.state.dataframesLayer);
    olmap?.addLayer(dataframesLayer);

    this.setState({ dataframesLayer: dataframesLayer });
  }

  updateAssetFootprint(olmap) {
    if (this.state.assetAreaLayer) {
      this.state.assetAreaLayer.forEach(layer => {
        olmap?.removeLayer(layer);
      });
    }

    const activeStyle = new Style({
      fill: new Fill({ color: 'rgba(102, 143, 183, 0.3)' }),
      stroke: new Stroke({
        color: 'rgba(102, 143, 183, 1)',
        width: 4,
      }),
    });

    const inActiveStyle = new Style({
      fill: new Fill({ color: 'transparent' }),
      stroke: new Stroke({
        color: 'rgba(102, 143, 183, 1)',
        width: 4,
        lineDash: [8, 8],
      }),
    });

    const disabledStyle = new Style({
      fill: new Fill({ color: 'rgba(118, 198, 250, .2)' }),
      stroke: new Stroke({
        color: 'rgba(250,0,0,0.4)',
        width: 4,
        lineDash: [16, 16],
      }),
    });

    const newAssetAreaLayers = [];

    this.props.assetFootprints?.forEach((item, index) => {
      if (item.active || window.store.ui?.hoveredOverlay?.includes(item.area_id)) {
        const multiPolygon = new MultiPolygon(item.geog);

        const assetFootprintAreaFeature = new Feature(multiPolygon);
        assetFootprintAreaFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

        const newLayer = new VectorLayer({
          zindex: 500,
          properties: { modelObject: item },
          style: [
            !item.active ? disabledStyle : window.store.ui?.hoveredOverlay?.includes(item.area_id) ? activeStyle : inActiveStyle,
          ],
          source: new VectorSource({
            features: [assetFootprintAreaFeature],
          }),
        });
        olmap?.addLayer(newLayer);

        newAssetAreaLayers.push(newLayer);
      }

      if (this.state.assetAreaLayer) {
        olmap?.removeLayer(this.state.assetAreaLayer[index]);
      }
    });

    this.setState({ assetAreaLayer: newAssetAreaLayers });
  }

  initializeWaterUsage(olmap) {
    if (!this.props.waterUsage || this.props.waterUsage.length === 0) {
      if (this.state.waterUsageLayers) {
        olmap?.removeLayer(this.state.waterUsageLayers);
      }

      return;
    }

    const waterUsageLayer = new VectorLayer({
      zIndex: 100,
      source: new VectorSource({
        features: this.props.waterUsage
          .filter(a => a.location)
          .map(a => {
            const feature = new Feature({
              geometry: new Point(fromLonLat(a.location)),
              modelObject: a,
            });

            feature.setStyle(IconStyles.waterLocation);
            return feature;
          }),
      }),
    });

    if (this.state.waterUsageLayers) {
      olmap?.removeLayer(this.state.waterUsageLayers);
    }

    olmap?.addLayer(waterUsageLayer);

    this.setState({ waterUsageLayers: waterUsageLayer });
  }

  initializeEnergyUsage(olmap) {
    if (!this.props.energyUsage || this.props.energyUsage.length === 0) {
      if (this.state.energyUsageLayers) {
        olmap?.removeLayer(this.state.energyUsageLayers);
      }

      return;
    }
    const energyUsageLayer = new VectorLayer({
      zIndex: 100,
      source: new VectorSource({
        features: this.props.energyUsage
          .filter(a => a.location)
          .map(a => {
            const feature = new Feature({
              geometry: new Point(fromLonLat(a.location)),
              modelObject: a,
            });

            feature.setStyle(IconStyles.energyLocation);
            return feature;
          }),
      }),
    });

    if (this.state.energyUsageLayers) {
      olmap?.removeLayer(this.state.energyUsageLayers);
    }

    olmap?.addLayer(energyUsageLayer);

    this.setState({ energyUsageLayers: energyUsageLayer });
  }

  initializeMonitoringAreas(olmap) {
    if (this.state?.monitoringAreaLayers) {
      this.state.monitoringAreaLayers?.map(layer => {
        olmap?.removeLayer(layer);
      });
    }

    const activeStyle = new Style({
      fill: new Fill({ color: 'rgba(250, 198, 118, .4)' }),
      stroke: new Stroke({
        color: 'rgba(250, 198, 118, 1)',
        width: 4,
      }),
    });

    const inActiveStyle = new Style({
      fill: new Fill({ color: 'transparent' }),
      stroke: new Stroke({
        color: 'rgba(250, 198, 118, 1)',
        width: 4,
        lineDash: [8, 8],
      }),
    });

    const disabledStyle = new Style({
      fill: new Fill({ color: 'rgba(250, 198, 118, .4)' }),
      stroke: new Stroke({
        color: 'rgba(250,0,0,0.4)',
        width: 4,
        lineDash: [16, 16],
      }),
    });

    const monitoringAreaLayers = [];

    this.props.monitoringAreas?.map((area, index) => {
      if (area.active || window.store.ui?.hoveredOverlay?.includes(area.area_id)) {
        const multiPolygon = new MultiPolygon(area.geog);

        const monitoringAreaFeature = new Feature(multiPolygon);
        monitoringAreaFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

        const monitoringAreaLayer = new VectorLayer({
          zindex: 500,
          properties: { modelObject: area },
          style: [
            !area.active ? disabledStyle : window.store.ui?.hoveredOverlay?.includes(area.area_id) ? activeStyle : inActiveStyle,
          ],
          source: new VectorSource({
            features: [monitoringAreaFeature],
          }),
        });

        olmap?.addLayer(monitoringAreaLayer);

        monitoringAreaLayers.push(monitoringAreaLayer);
      }

      if (this.state.monitoringAreaLayers) {
        olmap?.removeLayer(this.state.monitoringAreaLayers[index]);
      }
    });

    this.setState({ monitoringAreaLayers: monitoringAreaLayers });
  }

  initializeAOI(olmap) {
    //aoi layer

    //if no aoi dont draw
    if (!this.props.aois || this.props.aois.length <= 0) {
      this.state.aoiLayers?.map(layer => {
        olmap?.removeLayer(layer);
      });
      return;
    }

    const activeStyle = new Style({
      fill: new Fill({ color: 'rgba(205,151,224,0.3)' }),
      stroke: new Stroke({
        color: '#CD97E0',
        lineDash: [1, 1],
        width: 4,
      }),
    });

    const inActiveStyle = new Style({
      fill: new Fill({ color: 'transparent' }),
      stroke: new Stroke({
        color: '#CD97E0',
        lineDash: [10, 10],
        width: 4,
      }),
    });

    const disabledStyle = new Style({
      fill: new Fill({ color: 'rgba(205,151,224,0.2)' }),
      stroke: new Stroke({
        color: 'rgba(250,0,0,0.4)',
        width: 4,
        lineDash: [16, 16],
      }),
    });

    const allAOILayers = [];

    this.props.aois.map((aoi, index) => {
      if (aoi.active || window.store.ui?.hoveredOverlay?.includes(aoi.area_id)) {
        const multiPolygon = new MultiPolygon(aoi.geog);
        const aoiFeature = new Feature(multiPolygon);
        aoiFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

        const aoiLayer = new VectorLayer({
          zindex: 4,
          properties: { modelObject: aoi },
          style: [
            !aoi.active ? disabledStyle : window.store.ui?.hoveredOverlay?.includes(aoi.area_id) ? activeStyle : inActiveStyle,
            new Style({
              geometry: () => {
                // get all coordinates from the polygon and create a MultiPoint
                const coordinates = aoiFeature.getGeometry().flatCoordinates;

                // turn 1D array into 2D arra for MultiPoint
                const tmpCoords = [];
                for (let i = 0; i < coordinates.length; i += 2) {
                  tmpCoords.push(coordinates.slice(i, i + 2));
                }
                return new MultiPoint(tmpCoords);
              },
            }),
          ],
          source: new VectorSource({
            features: [aoiFeature],
          }),
        });

        olmap?.addLayer(aoiLayer);

        allAOILayers.push(aoiLayer);
      }

      if (this.state.aoiLayers) {
        olmap?.removeLayer(this.state.aoiLayers[index]);
      }
    });

    this.setState({
      aoiLayers: allAOILayers,
    });
  }

  initializePreviewAOI(olmap) {
    if (!this.props.aoiPreview || this.props.aoiPreview.length === 0) {
      if (this.state.aoiPreviewLayers) {
        this.state.aoiPreviewLayers.map(layer => {
          olmap?.removeLayer(layer);
        });
      }
      return;
    }

    const activeStyle = new Style({
      fill: new Fill({ color: 'rgba(255, 255, 255, 1)' }),
      stroke: new Stroke({
        color: 'rgba(255, 255, 255, 1)',
        lineDash: [10, 10],
        width: 12,
      }),
    });

    const inActiveStyle = new Style({
      fill: new Fill({ color: 'rgba(5, 2, 6, 0.4)' }),
      stroke: new Stroke({
        color: 'rgba(177, 177, 177, 1)',
        lineDash: [10, 10],
        width: 4,
      }),
    });

    const allAOILayers = [];

    this.props.aoiPreview.map((aoi, index) => {
      const multiPolygon = new MultiPolygon(aoi.geog);
      const aoiFeature = new Feature(multiPolygon);

      aoiFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

      const aoiLayer = new VectorLayer({
        zindex: 4,
        properties: { modelObject: aoi },
        style: [
          aoi.active ? activeStyle : inActiveStyle,
          new Style({
            geometry: () => {
              // get all coordinates from the polygon and create a MultiPoint
              const coordinates = aoiFeature.getGeometry().flatCoordinates;

              // turn 1D array into 2D arra for MultiPoint
              const tmpCoords = [];
              for (let i = 0; i < coordinates.length; i += 2) {
                tmpCoords.push(coordinates.slice(i, i + 2));
              }
              return new MultiPoint(tmpCoords);
            },
          }),
        ],
        source: new VectorSource({
          features: [aoiFeature],
        }),
      });

      if (this.state.aoiPreviewLayers) {
        olmap?.removeLayer(this.state.aoiPreviewLayers[index]);
      }
      olmap?.addLayer(aoiLayer);

      allAOILayers.push(aoiLayer);
    });

    this.setState({
      aoiPreviewLayers: allAOILayers,
    });
  }

  initializeGenericOverlay(olmap) {
    if (!this.props.assetOverlap || this.props.assetOverlap.length === 0) {
      if (this.state.overlapLayer) {
        this.state.overlapLayer.forEach(layer => {
          olmap?.removeLayer(layer);
        });
        this.setState({ overlapLayer: null });
      }
      return;
    }
    window.store.ui.assetOverlap.loading = true;
    const layersToRemove = olmap
      ?.getLayers()
      .getArray()
      .filter(layer => layer.get('name'));
    layersToRemove.forEach(layer => {
      if (layer.get('name').startsWith('GenericLayer_')) {
        olmap?.removeLayer(layer);
      }
    });

    const kbaStyle = new Style({
      fill: new Fill({ color: 'rgba(255, 203, 0, 0.3)' }),
      stroke: new Stroke({
        color: 'rgba(255, 203, 0, 0.9)',
        lineDash: [2, 2],
        width: 2,
      }),
    });

    const wdpaStyle = new Style({
      fill: new Fill({ color: 'rgba(56, 168, 0, 0.3)' }),
      stroke: new Stroke({
        color: 'rgba(56, 168, 0, 0.9)',
        lineDash: [2, 2],
        width: 2,
      }),
    });

    let activeStyle = null;
    if (window.store.ui.assetOverlap.currentOverlap === 'wdpa') {
      activeStyle = wdpaStyle;
    } else if (window.store.ui.assetOverlap.currentOverlap === 'kba') {
      activeStyle = kbaStyle;
    }

    const alloverlap = [];
    this.props.assetOverlap.map((aoi, index) => {
      const multiPolygon = new MultiPolygon(aoi.overlap_geog);
      const aoiFeature = new Feature(multiPolygon);
      aoiFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

      const genericLayer = new VectorLayer({
        zindex: 3,
        name: `GenericLayer_${index}`,

        properties: { modelObject: aoi },
        style: [
          activeStyle,
          new Style({
            geometry: () => {
              const coordinates = aoiFeature.getGeometry().flatCoordinates;

              // turn 1D array into 2D array for MultiPoint
              const tmpCoords = [];
              for (let i = 0; i < coordinates.length; i += 2) {
                tmpCoords.push(coordinates.slice(i, i + 2));
              }
              return new MultiPoint(tmpCoords);
            },
          }),
        ],
        source: new VectorSource({
          features: [aoiFeature],
        }),
      });

      olmap?.removeLayer(genericLayer);
      olmap?.addLayer(genericLayer);

      alloverlap.push(genericLayer);
    });

    this.setState({
      overlapLayer: alloverlap,
    });
    window.store.ui.assetOverlap.loading = false;
  }

  async initializePins(olmap) {
    const pins = this.props.pins;
    if (!pins) return;

    const pinStyleFunction = feature => {
      const pinId = feature.get('modelObject')?.pin_id;

      const media_urls = feature.get('modelObject')?.media_urls;
      const media_type = feature.get('modelObject')?.media_type;
      const status = feature.get('modelObject')?.status;

      const isPinIdInDataBlock =
        window.store?.ui?.hoveredDataBlock?.id == pinId &&
        window.store?.ui?.hoveredDataBlock?.type == 'Pin';
      feature.set('isPinIdInDataBlock', isPinIdInDataBlock);
      if (isPinIdInDataBlock) {
        return IconStyles.pin(media_urls.length && media_type, status, null, true);
      } else {
        return IconStyles.pin(media_urls.length && media_type, status);
      }
    };

    let pinFeatures = pins
    .map(pin => {
      if (!pin.title) return; // invalid pin
  
      // Add derived properties
      pin.geog_3857 = fromLonLat(pin.geog);
  
      const iconFeature = new Feature(new Point(pin.geog_3857));
      iconFeature.set('modelObject', pin);
  
      // Apply the assetStyleFunction to determine the style
      const style = pinStyleFunction(iconFeature);
      iconFeature.set('style', style);
  
      return iconFeature;
    })
    .filter(pin => pin);

    /* not sure what would be the most optimal distance here*/
    const pinClusterSource = new Cluster({
      distance: 30,
      source: new VectorSource({
        features: pinFeatures,
        crossOrigin: 'anonymous',
      }),
    });

    const pinClusterStyleFunction = cluster => {
      // Pass through style if single feature

      const features = cluster.get('features');
      if (features.length === 1) return features[0].get('style');

      // Build cluster style if multiple
      const allStatus = features.map(f => f.get('modelObject').status);
      const maxStatus = ['purple', 'cyan', 'green', 'yellow'].find(s => allStatus.includes(s));
      return IconStyles.pinCluster(
        features.length,
        maxStatus,
        features.some(f => f.get('isPinIdInDataBlock'))
      );
    };

    const pinIconLayer = new AnimatedCluster({
      style: pinClusterStyleFunction,
      source: pinClusterSource,
      animationDuration: 500,
      zIndex: 101,
      animateFeature: null,
    });

    olmap?.removeLayer(this.state.pinIconLayer);
    olmap?.addLayer(pinIconLayer);

    this.setState({
      pins: pins,
      pinIconLayer: pinIconLayer,
    });
  }

  initializeLayerClusters(olmap) {
    const layers = this.props.layers;
    const icons = [];

    layers.forEach(layer => {
      layer.coords = getCenter(new MultiPolygon(layer.geog_3857).getExtent()); //.flatCoordinates;
      const hovered =
        window.store.ui?.hoveredDataBlock?.id == layer.dataset.dataset_id &&
        window.store.ui.hoveredDataBlock.type == 'Dataset';

      const iconFeature = new Feature(new Point(layer.coords));
      iconFeature.set('style', IconStyles.layer(hovered));
      iconFeature.set('modelObject', layer);
      iconFeature.set('isLayerIdInDataBlock', hovered);
      icons.push(iconFeature);
    });

    const layerClusterSource = new Cluster({
      distance: 100,
      source: new VectorSource({
        features: icons,
        crossOrigin: 'anonymous',
      }),
    });

    const layerClusterStyleFunction = cluster => {
      // Pass through if single feature, build cluster style if multiple
      const features = cluster.get('features');
      return features.length === 1
        ? features[0].get('style')
        : IconStyles.layerCluster(features.length, features.some(f => f.get('isLayerIdInDataBlock') == true));
    };

    const layerIconLayer = new AnimatedCluster({
      style: layerClusterStyleFunction,
      source: layerClusterSource,
      animationDuration: 500,
      zIndex: 100,
      maxZoom: 11,
      animateFeature: null,
    });

    olmap?.removeLayer(this.state.layerIconLayer);
    olmap?.addLayer(layerIconLayer);
    //olmap?.addLayer(unclusteredLayer);

    this.setState({
      layerIconLayer: layerIconLayer,
      //layerIconLayer: unclusteredLayer,
    });
  }

  clearContextMenu = () => {
    this.setState({ contextMenu: null });
  };

  changeCurtain = e => {
    const { olmap } = this.state;
    olmap.render();

    this.setState({
      curtainSliderValue: e.target.value,
    });
  };

  updateMap() {
    const { olmap, center, zoom } = this.state;
    olmap.getView().setCenter(center);
    olmap.getView().setZoom(zoom);
  }

  updatePin = async pin => {
    try {
      const pinDetails = {
        pin_id: pin.pin_id,
        status: pin.status,
        title: pin.title,
        text: pin.description,
        location: pin.location,
      };

      // update the pin and get back the updated pin and set as the clickedPin
      const result = await api.call('/pin/update', pinDetails);
      if (!result.success) throw 'Something went wrong';

      await this.props.refreshPins();
      this.setState({ clickedPin: { ...pin, ...pinDetails } });
    } catch (error) {
      console.info(error);
      Analytics.reportClientError(error);
    }
  };

  deletePin = async id => {
    try {
      const result = await api.call('/pin/delete', { pin_id: id });
      if (!result.success) throw 'Something went wrong';

      this.props.refreshPins();
      // set clickedPin to null to remove the pindetails
      this.setState({ clickedPin: null });
    } catch (error) {
      console.info(error);
      Analytics.reportClientError(error);
    }
  };

  render() {

    return (
      <div id="map" style={{ width: '100%', height: '100%' }}>

        {this.state.hoveredAsset && this.state.olmap && (
          <AssetToolTip asset={this.state.hoveredAsset} olmap={this.state.olmap} />
        )}

        {store.ui.mapPreviewPin?.style === 'manual' && (<img
          className="location_preview"
          src="icons/location_pin.svg"
        />)}

        {this.props.measure && (
          <MeasureTool
            map={this.state.olmap}
            measure={this.props.measure}
            onShapeConfirmed={this.props.disableMeasurement}
          ></MeasureTool>
        )}

        {this.state.mostVisibleLayers
          ?.filter(l =>
            ['thermal', 'diff_thermal', 'dem', 'diff_dem', 'dsm', 'diff_dsm'].includes(
              l.raster.raster_type
            )
          )
          ?.slice(0, 1)
          .map(layer => (
            <ColorBar key={layer} layer={layer} map={this.state.olmap}></ColorBar>
          ))}

        <LayerDetails layer={this.state.hoveredLayer || this.state.clickedLayer} />

        <div>
          {
            // Note: We don't toggle display of PinDetails like the other Details above
            // because its content will be DOM-manipulated out of where they get rendered
            // and into a new map Overlay by OpenLayers. Removing the component then causes
            // a "DOMException: Failed to execute 'removeChild' on 'Node': The node to be
            // removed is not a child of this node" error.
            //
            // There might be a better way to solve this with createRoot and createPortal
            // but since this it works fine for the time being I'm leaving it as is:
            // PinDetails always present, just with empty contents if no pin selected.
            this.state.olmap && (
              <PinDetails
                map={this.state.olmap}
                pin={this.state.clickedPin || this.state.hoveredPin}
                open={!!this.state.clickedPin}
                updatePin={this.updatePin}
                deletePin={this.deletePin}
              />
            )
          }
          {this.state.showPinClusterHint && (
            <div
              className="hint__message"
              style={{
                backgroundImage: 'url(/icons/map.svg)',
                paddingLeft: 50,
              }}
            >
              Some pins seem to share the same coordinates and cannot be separated.
              <br />
              Click multiple times on the cluster to cycle through them.
            </div>
          )}
        </div>

        <div
          id="progress"
          style={
            this.state.totalTiles
              ? {
                  opacity: 1,
                  width: `${(((this.state.totalTiles - this.state.loadingTiles) / this.state.totalTiles) * 100).toFixed(1)}%`,
                }
              : { opacity: 0, width: `100%` }
          }
        />

        <div
          className="ol-control locate__me__button"
          onClick={e => {
            this.props.getCurrentLocation(e);
            Analytics.trackClick('locate-me');
          }}
        >
          <img
            src="icons/toolbar_user_location.svg"
            className="locate__me__icon"
            alt="location"
            width="24"
            height="24"
            title="Locate me"
          />
        </div>

        <SearchBox olmap={this.state.olmap} />

        {this.props.curtain && (
          <input
            id="curtain"
            type="range"
            onChange={this.changeCurtain}
            value={this.state.curtainSliderValue}
            step="0.01"
          />
        )}

        {this.props.curtain && this.props.curtainMessage && (
          <div
            className="hint__message"
            style={{
              backgroundImage: 'url(/icons/map.svg)',
              paddingLeft: 50,
            }}
          >
            Drag the vertical separator left or right to compare layers
          </div>
        )}

        {this.state.contextMenu && (
          <ContextMenu
            menu={this.state.contextMenu}
            addSinglePin={coords => {
              this.setState({ contextMenu: null }); // close contextmenu
              this.props.showAddSinglePin(coords);
            }}
          />
        )}
      </div>
    );
  }
};