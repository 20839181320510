import React, { memo, useMemo, useRef, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import './SankeyDiagram.css';
import { CHART_FONT } from 'src/shared/constants/nature_reporting';

const dictionary = [
  //Modis
  { id: 0, slug: 'EvergreenNeedleleafForests', title: 'Evergreen Needleleaf Forests', color: '#006400' },
  { id: 1, slug: 'EvergreenBroadleafForests', title: 'Evergreen Broadleaf Forests', color: '#006400' },
  { id: 2, slug: 'DeciduousBroadleafForests', title: 'Deciduous Broadleaf Forests', color: '#006400' },
  { id: 3, slug: 'DeciduousNeedleleafForests', title: 'Deciduous Needleleaf Forests', color: '#006400' },
  { id: 4, slug: 'MixedForests', title: 'Mixed Forests', color: '#006400' },
  { id: 5, slug: 'ClosedShrublands', title: 'Closed Shrublands', color: '#6BAB44' },
  { id: 6, slug: 'OpenShrublands', title: 'Open Shrublands', color: '#6BAB44' },
  { id: 7, slug: 'WoodySavannas', title: 'Woody Savannas', color: '#6BAB44' },
  { id: 8, slug: 'Savannas', title: 'Savannas', color: '#C4D313' },
  { id: 9, slug: 'Grasslands', title: 'Grasslands', color: '#C4D313' },
  { id: 10, slug: 'PermanentWetlands', title: 'Wetlands', color: '#6BAB44' },
  { id: 11, slug: 'Croplands', title: 'Croplands', color: '#C4D313' },
  { id: 12, slug: 'UrbanandBuilt-upLands', title: 'Urban areas', color: '#F15A29' },
  { id: 13, slug: 'Cropland/NaturalVegetationMosaics', title: 'Mixed Crops', color: '#C4D313' },
  { id: 14, slug: 'Barren', title: 'Barren', color: '#E8BFA1' },
  { id: 15, slug: 'SnowandIce', title: 'Snow and Ice', color: '#E8BFA1' },
  { id: 16, slug: 'WaterBodies', title: 'Water Bodies', color: '#0064C8' },

  //Wofs
  { id: 17, slug: 'water', title: 'Water', color: '#419bdf' },
  { id: 18, slug: 'trees', title: 'Trees', color: '#397d49' },
  { id: 19, slug: 'grass', title: 'Grass', color: '#88b053' },
  { id: 20, slug: 'flooded_vegetation', title: 'Flooded Vegetation', color: '#7a87c6' },
  { id: 21, slug: 'crops', title: 'Crops', color: '#e49635' },
  { id: 22, slug: 'shrub_and_scrub', title: 'Shrub and Scrub', color: '#dfc35a' },
  { id: 23, slug: 'built', title: 'Built', color: '#c4281b' },
  { id: 24, slug: 'bare', title: 'Bare', color: '#a59b8f' },
  { id: 25, slug: 'snow_and_ice', title: 'Snow and Ice', color: '#b39fe1' },
];



function matrixToSankey(landClasses, matrix) {
  const total = matrix.flat().reduce((r, v) => r + v, 0);
  const sourceNodes = landClasses.map(item => ({
    ...item,
    id: `${item.title} (2000)`,
    //value: matrix[item.id].reduce((r,v)=>r+v, 0),
    label: `${item.title}`,
    tooltip: `${item.title} (2000): ${(
      (matrix[item.id].reduce((r, v) => r + v, 0) / total) *
      100
    ).toFixed(1)}%`,
    itemStyle: { color: item.color },
  }));

  const targetNodes = landClasses.map(item => ({
    ...item,
    id: `${item.title} (2020)`,
    label: ``,
    //value: matrix.reduce((r,v)=>r+v[item.id], 0),
    tooltip: `${item.title} (2020): ${(
      (matrix.reduce((r, v) => r + v[item.id], 0) / total) *
      100
    ).toFixed(1)}%`,
    itemStyle: { color: item.color },
  }));

  const links = matrix
    .map((row, from) =>
      row.map((value, to) => ({
        source: sourceNodes[from].id,
        target: targetNodes[to].id,
        value,
      }))
    )
    .flat()
    .filter(l => l.value); // remove all empty links to declutter the graph

  // remove empty nodes as well
  const nodes = []
    .concat(sourceNodes, targetNodes)
    .filter(n => links.some(l => [l.source, l.target].includes(n.id)));

  return { links, nodes, total };
}

const shortClasses = [
  { id: 0, slug: 'natural_habitat', title: 'Natural Habitat', color: '#60D381' },
  { id: 1, slug: 'crops', title: 'Crops', color: '#E8B466' },
  { id: 2, slug: 'built', title: 'Urban', color: '#FF6F6F' },
  { id: 3, slug: 'water', title: 'Water', color: '#74C0F7' },
];

function SankeyDiagram({ data, isFullMode }) {
  const fullClasses = data.class_map.map((item, i) => {
    const classEntry = dictionary.find(c => c.slug === item);

    return {
      id: i,
      slug: classEntry.slug,
      title: classEntry.title,
      color: classEntry.color,
    };
  });

  const matrixSize = 4;
  const matrix = Array.from({ length: matrixSize }, () => Array(matrixSize).fill(0));

  const groupMap = ['natural_habitat', 'crops', 'built', 'water'];

  groupMap.forEach((group, groupIndex) => {
    data.group_map.forEach((g, dataIndex) => {
      if (g === group) {
        matrix[groupIndex] = matrix[groupIndex].map((value, matrixIndex) => {
          return value + data.links[dataIndex][matrixIndex];
        });
      }
    });
  });

  const sankey = isFullMode
    ? matrixToSankey(fullClasses, data.links)
    : matrixToSankey(shortClasses, matrix);

  const options = useMemo(
    () => ({
      series: [
        {
          type: 'sankey',
          data: sankey.nodes,
          links: sankey.links,

          label: {
            formatter: ({ data }) => data.label,
            ...CHART_FONT,
            width: 170,
            overflow: 'truncate',
          },

          draggable: false,
          left: '20px',
          top: '20px',
          bottom: '20px',
          right: '20px',

          emphasis: {
            focus: 'adjacency',
          },

          lineStyle: {
            color: 'gradient',
            curveness: 0.5,
          },
        },
      ],

      /*
    grid: {
      left: '0%',
      right: '0%',
      top: '0%',
      bottom: '0%',
    },
    */

      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        textStyle: { ...CHART_FONT },
        formatter: ({ data }) => data.tooltip,
      },
    }),
    [sankey]
  );

  // Always-force-resize hack :(
  const chart = useRef();
  useEffect(() => { chart?.current?.resize?.() }, [isFullMode])


  if (!data) return <></>;
  return (
    <ReactECharts
      ref={chart}
      //key={isFullMode ? 'small-chart' : 'large-chart'}
      style={{ height: isFullMode ? 490 : 240, width: isFullMode ? undefined : 630 }}
      option={options}
    />
  );
}

export default memo(SankeyDiagram);
