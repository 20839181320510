import React from 'react';
import './DetailedView.less';
import OptionBarItemContainer from 'src/Components/Common/OptionBarItemContainer';
import utils from 'src/utils';
import BaseModal from 'src/Components/Common/Modal/BaseModal';
import api from 'src/api';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';

function DetailedView(
  setSelectedDataset,
  selectedDataset,
  store,
  setDelete,
  shareModal,
  setShareModal,
  shareLink,
  setShareLink
) {
  const userIsBasic = store.session.user.role === 'view_only';
  const LoadingMsg = 'Loading...';
  async function generateShareLink() {
    console.log(selectedDataset);
    setShareLink('Loading...');
    api
      .call('/createSharedLink', { datasets: [selectedDataset.info.name] })
      .then(response => {
        response.success ? setShareLink(response.hash) : setShareLink(null);
      })
      .catch(e => {});
  }

  async function download(path, filename) {
    try {
      const response = await api.call(`/getPresignedDownload/${path}?rename=${filename}`);
      if (!response?.url) throw JSON.stringify(response, null, 2);
      window.open(response.url);
    } catch (error) {
      console.trace(error);
    }
  }

  async function downloadDataset2d() {
    const name = selectedDataset.info.display_name;
    const raster = selectedDataset.info.rasters[0];
    if (raster) {
      download(
        raster.file_location.split('svarmi-datact-datasets/')[1],
        `${name}_${raster.name}_captured-${raster.origin_date
          .slice(2, 19)
          .replace(/[:-]/g, '')
          .replace('T', '-')}_processed-${raster.created_at
          .slice(2, 19)
          .replace(/[:-]/g, '')
          .replace('T', '-')}${raster.file_location.match(/\..*$/)?.[0] || '.las'}`
      );
    }
  }

  async function downloadDataset3d() {
    const name = selectedDataset.info.display_name;
    const pointcloud = selectedDataset.info.pointclouds[0];
    if (pointcloud) {
      download(
        pointcloud.file_location.split('svarmi-datact-datasets/')[1],
        `${name}_${pointcloud.name}_captured-${pointcloud.origin_date
          .slice(2, 19)
          .replace(/[:-]/g, '')
          .replace('T', '-')}_processed-${pointcloud.created_at
          .slice(2, 19)
          .replace(/[:-]/g, '')
          .replace('T', '-')}${pointcloud.file_location.match(/\..*$/)?.[0] || '.las'}`
      );
    }
  }

  let twoD = selectedDataset.info.optimized_rasters?.length > 0;
  let threeD = selectedDataset.info.optimized_pointclouds?.length > 0;

  console.log(selectedDataset);
  return (
    <OptionBarItemContainer
      title={'Dataset'}
      isExpandButtonShowed
      onClickBack={() => setSelectedDataset(null)}
      isShowingChannel={true}
      isArrowButtonShowed={true}
    >
      {shareModal && (
        <BaseModal
          title={'Share dataset?'}
          rightButton={shareLink === null || shareLink == LoadingMsg ? 'Share' : 'Copy link'}
          rightButtonStyle="STANDARD"
          leftButton={shareLink === null || shareLink == LoadingMsg ? 'Cancel' : 'Done'}
          onClickCancel={() => setShareModal(false)}
          onClickRightButton={() => {
            if (shareLink === null || shareLink == LoadingMsg) {
              generateShareLink();
            } else {
              const link = `${window.location.origin}/shared/${shareLink}`;
              navigator.clipboard
                .writeText(link)
                .then(() => {
                  console.log('Link copied to clipboard');
                })
                .catch(err => {
                  console.error('Failed to copy link: ', err);
                });
            }
          }}
        >
          <div className="data_manager_shareLink">
            {shareLink &&
              (shareLink === LoadingMsg
                ? LoadingMsg
                : `${window.location.origin}/shared/${shareLink}`)}
          </div>
          <div className="data_manager_expiry">
            {shareLink &&
              shareLink != LoadingMsg &&
              'Expires on ' +
                new Date(Date.now() + 1000 * 60 * 60 * 24 * 31).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
          </div>
        </BaseModal>
      )}
      <div className="data_manager_detailed_view">
        <img
          width={'42px'}
          src="/icons/address_icon.svg"
          onClick={() =>
            store?.ui?.map?.state?.olmap?.jumpTo({ geog_4326: selectedDataset.coordinate })
          }
        />
        <div className="data_manager_dataset_title" title={selectedDataset.title}>
          {selectedDataset.title}
        </div>
        <div className="data_manager_dataset_description">{selectedDataset.info.description}</div>
        <img
          width={'24px'}
          hidden={userIsBasic}
          src="/icons/share_link.svg"
          onClick={() => {
            setShareModal(true);
            setShareLink(null);
          }}
        />
      </div>

      <div className="data_manager_grid">
        <div className="data_manager_detail_item">
          N/A <div>{'N/A'}</div>
        </div>
        <div className="data_manager_detail_item">
          Capture Date <div>{utils.formatDate(selectedDataset.info.created_at)}</div>
        </div>
        <div className="data_manager_detail_item">
          Accuracy <div>{selectedDataset.info.Accuracy || 'N/A'}</div>
        </div>
        <div className="data_manager_detail_item">
          Resolution <div>{selectedDataset.info.ground_resolution || 'N/A'}</div>
        </div>
      </div>

      <div className="data_manager_type_blocks">
        <div className={'type_block ' + (!twoD ? 'hollow' : '')}>2D</div>
        <div title="(WIP) Go To 3D" className={'type_block ' + (!threeD ? 'hollow' : '')}>
          3D
        </div>
      </div>

      {
        <div className="data_manager_buttons">
          <BaseButton
            disabled={store.session.user.role === 'view_only'}
            title={
              store.session.user.role === 'view_only'
                ? 'Only admins and owners can delete datasets'
                : 'Delete Dataset'
            }
            additionalClassName="dataset_trash"
            buttonType="DELETE"
            onClick={() => setDelete(true)}
          >
            <img src="/icons/delete_action.svg"></img>
          </BaseButton>
          <BaseButton
            disabled={!twoD}
            title="Download 2D data (.tif)"
            additionalClassName="cancel_button"
            buttonType="STANDARD"
            onClick={() => downloadDataset2d()}
          >
            <img src="/icons/download.svg"></img>2D
          </BaseButton>
          <BaseButton
            disabled={!threeD}
            title="Download 3D Data (.las)"
            additionalClassName="continue_button"
            buttonType="STANDARD"
            onClick={() => downloadDataset3d()}
          >
            <img src="/icons/download.svg"></img>3D
          </BaseButton>
        </div>
      }
    </OptionBarItemContainer>
  );
}

export default DetailedView;
