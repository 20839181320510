import React, { memo } from 'react';
import ConnectForm from 'src/Components/Common/Inputs/ConnectForm';
import InputWrapper from 'src/Components/Common/Inputs/InputWrapper';
import './TextArea.css';

function TextArea({
  className,
  registerName,
  registerRules = {},
  tooltipId,
  tooltipContent,
  inputWrapperClassName,
  name,
  setValue,
  returnValue,
  ...rest
}) {
  function handleOnChange(event) {
    const value = event.target.value;
    if (returnValue) returnValue(value);
  }

  return (
    <ConnectForm>
      {({ register, formState: { errors } }) => {
        return (
          <InputWrapper
            name={name}
            error={errors[registerName]}
            tooltipId={tooltipId}
            tooltipContent={tooltipContent}
            inputWrapperClassName={inputWrapperClassName}
          >
            <textarea
              className={`text_area ${className && className}`}
              {...rest}
              {...register(registerName, registerRules)}
              onChange={event => handleOnChange(event)}
            >
              {setValue}
            </textarea>
          </InputWrapper>
        );
      }}
    </ConnectForm>
  );
}

export default memo(TextArea);
