import React, { memo, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DEFAULT_TEAM_FORM_VALUES } from 'src/shared/constants';
import { useStore } from 'src/store';
import Input from 'src/Components/Common/Inputs/Input';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import Select from 'src/Components/Common/Inputs/Select';
import UploadAvatar from '../UploadAvatar';
import './TeamTabPanel.less';
import api from 'src/api';
import Analytics from 'src/Context/Analytics';

function TeamTabPanel({ teamInfo, refreshTeam }) {
  const store = useStore();
  const methods = useForm({ defaultValues: DEFAULT_TEAM_FORM_VALUES });
  const [coordinateSystem, setCoordinateSystem] = useState(null);
  const [heightSystem, setHeightSystem] = useState(null);

  const {
    formState: { isDirty },
    reset,
  } = methods;

  const COORDS = [
    {
      value: 'EPSG::4326',
      label: 'EPSG::4326',
    },
    {
      value: 'EPSG::3057',
      label: 'EPSG::3057',
    },
    {
      value: 'EPSG::9951',
      label: 'EPSG::9951',
    },
  ];

  const onSubmit = data => {
    reset(data);
  };

  const currentCoordinateSystem = COORDS.findIndex(e => e.value === teamInfo.coordinate_system);
  const currentHeightSystem = COORDS.findIndex(e => e.value === teamInfo.height_system);

  useEffect(() => {
    store.ui.unsavedChanged = isDirty;
  }, [isDirty]);

  useEffect(() => {Analytics.trackClick('settings-tab-team');}, [])

  function ApplyCoordinateSystem() {
    api.call('/team/update', { coordinate_system: coordinateSystem }).then(refreshTeam);
    setCoordinateSystem(null);
  }

  function ApplyHeightSystem() {
    api.call('/team/update', { height_system: heightSystem }).then(refreshTeam);
    setHeightSystem(null);
  }

  return (
    <FormProvider {...methods}>
      <form
        className="settings_team_tab_panel_main_container"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Input
          name="Team Name"
          registerName="firstName"
          placeholder="Enter team name"
          inputWrapperClassName="settings_profile_tab_panel_input"
          setValue={store.session.team.name}
          disabled
        />
        <UploadAvatar
          isTeam
          avatar={`//datact-avatars.s3.eu-west-1.amazonaws.com/teams/${store.session.team.name}`}
        />
        <div className="coord-group">
          <Select
            name="Coordinate System"
            placeholder="Choose a coordinate system"
            registerName="coordinateSystem"
            defaultValue={COORDS[currentCoordinateSystem]}
            field={'w'}
            options={COORDS}
            onChange={e => setCoordinateSystem(e.value)}
            tooltipContent={"This is the EPSG code that any new data will be processed in, this does not change data that's already been processed or uploaded"}
          />

          <BaseButton onClick={() => ApplyCoordinateSystem()} disabled={!coordinateSystem}>
            Save Coordinate System
          </BaseButton>
        </div>
        {/*
        <Select
          name="Height System"
          placeholder="Choose height system"
          registerName="heightSystem"
          defaultValue={COORDS[currentHeightSystem]}
          field={'w'}
          options={COORDS}
          onChange={e => setHeightSystem(e.value)}
          tooltipContent={"This is the EPSG code that any new data will be processed in, this does not change data that's already been processed or uploaded"}
        />

        <BaseButton
          onClick={() => ApplyHeightSystem()}
          disabled={!heightSystem}
        >
          Save Height System
        </BaseButton>
        */}
      </form>
    </FormProvider>
  );
}

export default memo(TeamTabPanel);
