import React from 'react';
import { useStore } from 'src/store';
import Analytics from 'src/Analytics';
import './LayersPanel.less';

export default function LayersPanel({ setBasemap }) {

  const store = useStore();
  const [open, setOpen] = React.useState(false);

  const wmsLayers = store.ui.map?.state?.olmap?.getLayers?.()?.getArray?.()?.filter?.(l => l.values_.isWMS) || [];
  const datasetLayers = store.ui.map?.state?.mostVisibleLayers?.map?.(l => l.viewObject)?.filter?.(l => l.ol_uid && l.values_.modelObject) || [];
  //console.log({wmsLayers, datasetLayers});

  return <div className={"layers_panel" + (open ? " open" : "")} onClick={e => Analytics.trackClick('layers-panel')}>

    {open && (<>
      <h2><img src="/icons/basemaps/basemap.svg" alt="" /> Layers</h2>
      <img src="/icons/cross.svg" className="close_button" onClick={() => setOpen(false)} />
    </>)}

    <div className="basemap_selector">
      <div onClick={() => setBasemap("satellite")}>
        <img src="/icons/basemaps/satellite.png" title="Satellite" alt="" />
        Satellite
      </div>
      <div onClick={() => setBasemap("hybrid")}>
        <img src="/icons/basemaps/labels.png" title="Labels" alt="" />
        Labels
      </div>
      <div onClick={() => setBasemap("light")}>
        <img src="/icons/basemaps/terrain.png" title="Terrain" alt="" />
        Terrain
      </div>
      <div className="open_button" onClick={() => setOpen(true)}>
        <img src="/icons/basemaps/basemap.svg" title="More" alt="" />
        More
      </div>
    </div>

    {open && (<>
      <div className="layers-list">

        {!!wmsLayers?.length && (<>
          <h2>External WMS</h2>
          {wmsLayers.map(layer => 
            <div key={layer.ol_uid}>
              <label title={layer.values_.title}>
                <input
                  type="checkbox"
                  defaultChecked={layer.getVisible()}
                  onChange={e => layer.setVisible(e.target.checked)
                    /* skitafix to force rerender: */ + setOpen(Date.now())
                  }
                />
                {layer.values_.title}
                {!!layer.values_.info &&
                  <a href={layer.values_.info} target="_blank" className="info-button" rel="noreferrer">
                    <img src="/icons/wms_info.svg" title="More info" alt="info" />
                  </a>
                }
              </label>
              <input
                type="range"
                title="Opacity"
                defaultValue={Math.round(layer.getOpacity() * 100)}
                onChange={e => layer.setOpacity(e.target.value / 100)}
              />
              {!!layer.values_.legend && layer.getVisible() &&
                <img className="legend" src={layer.values_.legend} title="Legend" alt="Legend" />
              }
            </div>
          )}
        </>)}

        {/*!!datasetLayers?.length && (<>
          <h2>Datasets</h2>
          {datasetLayers.map(layer =>
            <div key={layer.ol_uid}>
              <label title={layer.values_.modelObject.path}>
                <input
                  type="checkbox"
                  defaultChecked={layer.getVisible()}
                  onChange={e => layer.setVisible(e.target.checked)}
                />
                {layer.values_.modelObject.dataset.display_name}
              </label>
              <input
                type="range"
                defaultValue={Math.round(layer.getOpacity() * 100)}
                onChange={e => layer.setOpacity(e.target.value / 100)}
              />
            </div>
          )}
        </>)*/}

      </div>
    </>)}
  </div>
};