import React from 'react';
import './ThreeDConnection.css';
import api from 'src/api';

function ThreeDConnection(props) {
  // Based on raster names (old hack, todo remove eventually)
  const rastername_pointclouds = props.visibleLayers
    .map(layer => ([
      layer.dataset.dataset_id,
      encodeURIComponent(layer.name.replace('.tif', '')),
    ]));

  // Optimized pointclouds (proper way to do it)
  const optimized_pointclouds = props.visibleLayers
    .map(layer =>
      layer.dataset.optimized_pointclouds
        .filter(pc => pc.viewable)
        .sort((a, b) => a.created_at < b.created_at ? -1 : 1)
        .map(pc => ([
          layer.dataset.dataset_id,
          encodeURIComponent(pc.file_location.match(/([^/]*)$/)?.[1]),
        ]))
    )
    .flat()

  // Also a hack for some old pointclouds, todo fix eventually
  const optimized_pointclouds_nolas = optimized_pointclouds.map(v => [v[0], v[1].replace('.las', '')]);

  // Uniquify the keys
  const pointclouds = Array.from(
    new Set([].concat(optimized_pointclouds, optimized_pointclouds_nolas, rastername_pointclouds))
  );

  const combined = {
    jwt: api.currentToken,
    pointclouds: pointclouds,
  };

  return (
    <div className="connection__container">
      <iframe src={`/viewer/index.html#${encodeURIComponent(JSON.stringify(combined))}`} />
    </div>
  );
}

export default ThreeDConnection;
