import React from 'react';

export default function Track({ source, target, getTrackProps, disabled = false }) {
  /* return nothing to make blank line between 2 handles*/

  if (source.id === '$$-1') {
    return null;
  }
  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translate(0%, -50%)',
        height: 2,
        zIndex: 13,
        backgroundColor: disabled ? '#999' : '#6AAFE1',
        cursor: 'pointer',
        left: `${source.percent + 0.5}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}
