import React, { useState } from 'react';
import './Energy.less';
import InputPro from 'src/Common/InputPro';
import SelectPro from 'src/Common/SelectPro/SelectPro';
import BaseButton from 'src/Common/Buttons/BaseButton';
import api from 'src/api';

const Energy = ({ asset, coordinates, handleBack, reload, edit }) => {
  const [energyType, setType] = useState('abstraction');
  const [energySource, setEnergySource] = useState(null);
  const [amount, setAmount] = useState(null);

  const sources = [
    { value: 'wind_solar_geothermal', label: 'Wind/Solar/Geothermal' },
    { value: 'biomass_oil', label: 'Biomass Oil' },
    { value: 'natural_gas_nuclear', label: 'Natural Gas / Nuclear' },
    { value: 'coal_and_lignite', label: 'Coal / Lignite' },
    { value: 'hydropower_firewood', label: 'Hydropower / Firewood' },
  ];

  const origin_date = new Date();

  async function CreateEnergy() {
    const payload = {
      origin_date: origin_date,
      source: energySource?.value,
      amount: parseFloat(amount),
      active: true,
      confirmed: false,
      location: coordinates,
    };

    try {
      const success = await api.call('/asset/energy_usage/create', {
        asset_id: asset.asset_id,
        ...payload,
      });
      console.log(success);
      reload();
    } catch (error) {
      console.log('Error creating energy', error);
    }
  }

  return (
    <div className="energy_container">
      <div className="sub_asset_header">
        <img width="38px" src="/icons/energy.svg" /> Energy
      </div>
      <div className="energy_element select_group">
        <SelectPro onChange={setEnergySource} options={sources} name="Energy Source"></SelectPro>
        <InputPro
          bindValue={setAmount}
          label="Amount"
          fixedLabel
          className="water_amount"
          type="number"
          subFix={'kWh'}
        ></InputPro>
      </div>

      <BaseButton
        additionalClassName="cancel_button"
        buttonType={'SECONDARY'}
        onClick={() => handleBack()}
      >
        Back
      </BaseButton>
      <BaseButton
        title="Create Energy"
        additionalClassName="continue_button"
        disabled={!amount || !energySource || !energyType}
        buttonType={'STANDARD'}
        onClick={() => CreateEnergy()}
      >
        Create
      </BaseButton>
    </div>
  );
};

export default Energy;
