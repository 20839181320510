import React, { useState, useEffect } from 'react';
import { Exifr } from 'exifr';
import { VectorImage as VectorImageLayer } from 'ol/layer';
import { Style, Icon as IconStyle } from 'ol/style';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import { Vector as VectorSource } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import Analytics from 'src/Context/Analytics';
import './UploadPreview.css';
import utils from 'src/utils';

function UploadPreview({ map, photos, cancel, error }) {
  const [progress, setProgress] = useState(0);
  const [description, setDescription] = useState(null);

  useEffect(() => {
    // some fuckery going on with state updates from async function
    // this bit ensures we remember the correct references of objects
    // for later cleanup
    const cleanupRegistry = {};

    // onComponentMount
    buildPreview(cleanupRegistry).catch(e => {
      console.trace(e);
      Analytics.reportClientError(new Error(e));
      error(e.toString());
      cancel();
    });

    // onComponentWillUnmount
    return () => cleanup(cleanupRegistry);
  }, []);

  const buildPreview = async cleanupRegistry => {
    // Somewhat optimized parser to get what we want and ignore the rest
    const ExifrOptions = {
      ifd0: false,
      ifd1: true,
      thumbnail: true,
      exif: ['DateTimeOriginal'],
      //xmp: true, // needed for drone-dji data, otherwise set to false
      translateValues: false,
      reviveValues: false,
      sanitize: false,
      mergeOutput: false,
      silentErrors: false,
    };

    // Processing pictures one at a time (for/await) is slower than in parallel,
    // but more memory efficient and thus doesn't crash the tab when loading
    // huge datasets
    const images = cleanupRegistry.images = new Array(photos.length);
    for (let i = 0; i < images.length; i++) {
      const exifr = new Exifr(ExifrOptions);
      await exifr.read(photos[i]);
      const parsed = await exifr.parse();

      // We use thumbnails as the real images are way too heavy to render
      const src = URL.createObjectURL(new Blob([await exifr.extractThumbnail()]));
      const [width, height] = await new Promise((resolve, reject) => {
        const img = new Image();
        img.onerror = reject;
        img.onload = () => resolve([img.naturalWidth, img.naturalHeight]);
        img.src = src;
      });

      images[i] = {
        date: parsed.exif.DateTimeOriginal,
        coords: [parsed.gps.longitude, parsed.gps.latitude],
        width,
        height,
        src,
      };

      setProgress(() => i); // force sync update to state
    }

    // Compute flight legs between each picture (meaningless if not sorted by capture time first)
    images.sort((a, b) => a.date - b.date);
    const legs = images
      .slice(0, -1)
      .map((v, i) => utils.sphericalBearingDistance(images[i].coords, images[i + 1].coords));

    // Individual spacings or even averages are thrown off by large gaps between flights and/or
    // 180 turns at the end of each run. Getting the median is a more robust way to get the "typical"
    // or "most common" ground spacing between consecutive pictures which is what we're interested in.
    //const medianSpacingMeters = legs.map(l => l.distance).sort()[Math.floor(legs.length/2)]

    // At 70% overlap (a decent default guess), every image will be shifted 15% from the next, while
    // having travelled however many meters. This defines a ground resolution and thus a pixel scaling factor.
    const shiftFactor = (1 - 0.7) / 2;

    for (let i = 0; i < images.length; i++) {
      // If we have two legs to choose from (to estimate flight heading and speed), pick the shortest.
      // This ensures that two close-by pictures will be aligned and sized close to each other, while
      // large gaps between clusters or end-runs are ignored. If we only have one leg available
      // (first and last pictures), just pick that.
      const referenceLeg =
        legs[i] && legs[i - 1]
          ? legs[i].distance < legs[i - 1].distance
            ? legs[i]
            : legs[i - 1]
          : legs[i] || legs[i - 1];

      images[i].rotation = referenceLeg.bearing;
      images[i].baseScale = referenceLeg.distance / shiftFactor / images[i].height;
    }

    // Kill extra images at random across the array to stay under the 400 render limit
    // This preserves the overall look and coverage area of the preview the best we can
    const indices = Object.keys(images);
    while (indices.length > 400) indices.splice(Math.random() * indices.length, 1);

    // Build OpenLayer features with corresponding parameters
    const features = indices.map(i => {
      const feature = new Feature(new Point(fromLonLat(images[i].coords)));
      feature.setStyle(
        new Style({
          image: new IconStyle({
            src: images[i].src,
            rotation: (images[i].rotation * Math.PI) / 180,
            rotateWithView: true,
            opacity: 0.4,
          }),
        })
      );

      feature.getStyle().getImage().baseScale = images[i].baseScale;
      return feature;
    });

    // Set handler to maintain scale on map
    const icons = features.map(f => f.getStyle().getImage());
    const scalingFunction = cleanupRegistry.scalingFunction = mapViewResolution =>
      icons.forEach(i => i.setScale(i.baseScale / mapViewResolution));
    map.on('postrender', e => scalingFunction(e.map.getView().getResolution()));
    scalingFunction(); // do a first resize pass

    // Add the photo layer to the map
    const source = new VectorSource();
    source.addFeatures(features);
    const layer = cleanupRegistry.layer = new VectorImageLayer({
      title: 'Upload Preview',
      source: source,
      zIndex: 1000000000,
    });
    map.addLayer(layer);

    // Parse first picture in full, to get more detailed information
    const exifr = new Exifr(true);
    await exifr.read(photos[0]);
    const details = await exifr.parse();

    setDescription(() => ({
      count: photos.length,
      skipped: features.length < photos.length,
      minDate: new Date(images[0].date.replace(':', '-').replace(':', '-')),
      maxDate: new Date(images[images.length - 1].date.replace(':', '-').replace(':', '-')),
      resolution: `${details.ExifImageWidth}x${details.ExifImageHeight}`,
      device: `${details.Make} / ${details.Model} - ${details.SerialNumber}`,
    }));

    // Pan the map towards the dataset and enable tilt
    const extent = source.getExtent();
    map.jumpTo({
      extent_3857: extent,
      callback: () => document.querySelector('#map').classList.add('perspective'),
    });
  };

  const cleanup = cleanupRegistry => {
    if (!cleanupRegistry.layer) return;
    document.querySelector('#map').classList.remove('perspective');
    map.removeEventListener('postrender', cleanupRegistry.scalingFunction);
    map.removeLayer(cleanupRegistry.layer);
    //cleanupRegistry.layer.getSource().getFeatures().forEach(f => URL.revokeObjectURL(f.style_.image_.iconImage_.src_))
    cleanupRegistry.images?.forEach(i => URL.revokeObjectURL(i.src));
    cleanupRegistry.layer.dispose();
    cleanupRegistry.layer = null;
  };

  if (!description)
    return (
      <div className="upload-preview">
        <p className="upload-preview-loading-text">Building preview...</p>
        <p>
          {progress}/{photos.length}
        </p>
      </div>
    );

  return (
    <div className="upload-preview">
      <h2>Previewing {description.count} pictures.</h2>
      {description.skipped && (
        <i>
          Warning: Too many pictures to render,
          <br /> some pictures have been skipped
        </i>
      )}

      <h3>Taken between:</h3>
      <p>
        {description.minDate.toISOString().slice(0, 19).replace('T', ' ')}
        <br />
        {description.maxDate.toISOString().slice(0, 19).replace('T', ' ')}
        <br />
      </p>

      <h3>From device:</h3>
      <p>{description.device}</p>

      <h3>At resolution:</h3>
      <p>{description.resolution}</p>

      <button onClick={() => cancel()}>Close preview</button>
    </div>
  );
}

export default UploadPreview;
