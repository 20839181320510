import React, { memo, useState, useEffect } from 'react';
import api from 'src/api';
import utils from 'src/utils';
import Analytics from 'src/Analytics';
import { FormProvider, useForm } from 'react-hook-form';
import BaseButton from 'src/Common/Buttons/BaseButton';
import Input from 'src/Common/Inputs/Input';
import RadioButton from 'src/Common/Inputs/RadioButton';
import MenuPage from 'src/Common/MenuPage';
import MeasureTool from 'src/Widgets/MeasureTool';
import { MultiPolygon } from 'ol/geom';
import { transformExtent } from 'ol/proj';
import { useStore } from 'src/store';
import { getCenter } from 'ol/extent';
import './SatelliteImagery.less';


const SatelliteImageryForm = memo(function SatelliteImageryForm({ shape, onComplete }) {
  const methods = useForm({ shouldUnregister: false });
  const { getValues, setValue, watch } = methods;

  const [error, setError] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [availablePasses, setAvailablePasses] = useState(null);
  const selectedPass = watch('selectedPass');

  useEffect(() => {
    const fetchAddress = async () => {
      if (!shape) return;
      const place = await utils.getGeocodedPlacename(...getCenter(shape.getExtent()));
      if (!getValues('requestName')) setValue('requestName', place);
      refreshAvailablePasses();
    };

    fetchAddress();
  }, [shape, setValue]);

  async function refreshAvailablePasses() {
    try {
      setAvailablePasses('loading');
      const result = await api.call('/satellite/list', { area: shape.getCoordinates() });
      console.log(result);
      if (!result?.passes) throw 'Error while fetching satellite passes';
      setAvailablePasses(result.passes);
    } catch (error) {
      setError(error);
      Analytics.reportClientError(error);
    }
  }

  const onSubmit = async data => {
    try {
      const { requestName, /*dataType,*/ selectedPass } = data;

      // Trigger satellite API
      setRefreshing(true);
      const result = await api.call('/satellite/fetch', {
        area: shape.getCoordinates(),
        //type: dataType,
        pass: selectedPass,
        name: requestName,
      });
      if (result?.info?.msg !== 'Succesfully saved satellite data')
        throw 'Failed to launch satellite data processing';

      // Loading bar illusion + page refresh at the end
      const delay = 10;
      setTimeout(() => onComplete(shape), (delay + 2) * 1000);
      requestAnimationFrame(() =>
        Array.from(document.querySelectorAll('.satellite-imagery .progressbar-fill')).forEach(e => {
          e.style.transitionDuration = delay + 's';
          e.style.transitionTimingFunction = 'linear';
          e.style.width = '100%';
        })
      );
    } catch (error) {
      setError(error);
      Analytics.reportClientError(error);
    }
  };

  if (error) {
    return <div style={{ color: 'var(--pastel-red)' }}>{error.toString()}</div>;
  }

  if (refreshing) {
    return (
      <div className="satellite-imagery">
        <p>Satellite data is being fetched...</p>
        <div className="progressbar" style={{ marginBottom: 0 }}>
          <div className="progressbar-fill" />
          <div className="progressbar-fill" />
        </div>
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form className="satellite-imagery" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="">
          <div className="">
            <Input
              name="Layer name"
              placeholder="Enter a name for this satellite layer"
              registerName="requestName"
              registerRules={{ required: 'Enter a name for this layer' }}
            />
            {/*
            <div className="">
              <h2>Type</h2>
              <div className="request_data_form_radio_button_input_container">
                <RadioButton
                  label="RGB"
                  registerName="dataType"
                  className="request_data_form_radio_button"
                  value="rgb"
                  defaultChecked={true}
                />
                <RadioButton
                  label="NDVI"
                  registerName="dataType"
                  className="request_data_form_radio_button"
                  value="ndvi"
                />
                <RadioButton
                  label="Green Index"
                  registerName="dataType"
                  className="request_data_form_radio_button"
                  value="green_index"
                />
                <RadioButton
                  label="World Cover"
                  registerName="dataType"
                  className="request_data_form_radio_button"
                  value="world_cover"
                />
              </div>
            </div>
            */}
            <div className="">
              <h2>Satellite passes</h2>
              {availablePasses === null && 'No area selected'}
              {availablePasses === 'loading' && (
                <div>
                  <span className="spinner" />
                  Loading...
                </div>
              )}
              {Array.isArray(availablePasses) &&
                (!availablePasses.length
                  ? 'No passes available for this area in the last 15 days'
                  : availablePasses.map((p, i) => (
                      <RadioButton
                        registerName="selectedPass"
                        value={p.datetime}
                        key={i}
                        label={`${p.datetime}: ${p.cloud_coverage}% cloudy`}
                      />
                    )))}
            </div>
            <div className="request_data_form_continue_button_container">
              <BaseButton
                disabled={!selectedPass}
                type="submit"
                onClick={e => Analytics.trackClick('satellite-imagery-confirm')}
              >
                Confirm
              </BaseButton>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
});

function SatelliteImagery({ refreshDatasets, refreshLayers, onClickClose }) {
  const store = useStore();
  const map = store.ui.map;
  const [shape, setShape] = useState(null);

  const menuOpen = store.ui.menuOpen;
  const [showMeasureTool, setShowMeasureTool] = useState(true);

  const onShapeConfirmed = shape => {
    setShape(new MultiPolygon(shape.clone().transform('EPSG:3857', 'EPSG:4326').getCoordinates()));
    setShowMeasureTool(false);
  };

  useEffect(() => {
    if (!menuOpen) onClickClose();
  }, [menuOpen]);

  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        onClickClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <MenuPage
      title="Satellite Imagery"
      onClickBack={onClickClose}
      isExpandButtonShowed
      icon="/icons/menu/satellite_imagery.svg"
      isIconShowed={true}
      isShowingChannel={true}
      isArrowButtonShowed={true}
    >
      {showMeasureTool && (
        <MeasureTool map={map.state.olmap} measure="area" onShapeConfirmed={onShapeConfirmed} />
      )}
      <SatelliteImageryForm
        shape={shape}
        onComplete={async shape => {
          await refreshDatasets();
          await refreshLayers();

          onClickClose();
          store.ui.menuOpen = false;

          let transformedExtent = transformExtent(shape.extent_, 'EPSG:4326', 'EPSG:3857');

          if (transformedExtent)
            setTimeout(() => store.ui.map.state.olmap.jumpTo({ extent_3857: transformedExtent }), 500);
        }}
      />
    </MenuPage>
  );
}

export default SatelliteImagery;
