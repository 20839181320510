import React, { useEffect, useState } from 'react';
import Overlay from 'ol/Overlay';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './PinDetails.less';
import Avatar from 'src/Components/Avatar';
import api from 'src/api';
import PinContent from './PinContent';
import CommentComposer from './CommentComposer';
import Linkify from 'linkify-react';
import linkifyRegisterKeywords from 'linkify-plugin-keyword';
import { useStore } from 'src/store';
import exifr from 'exifr';
import BaseModal from 'src/Components/Common/Modal/BaseModal';
import PinColorPicker from './PinColorPicker';
import Analytics from 'src/Context/Analytics';

const PinDetails = ({ map, pin, open, updatePin, deletePin }) => {
  const [overlay, setOverlay] = useState(null);
  const [actionsLoading, setActionsLoading] = useState(false);
  const [pinTitle, setTitle] = useState('');
  const [pinDescription, setDescription] = useState('');
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const store = useStore();

  useEffect(() => {
    Analytics.trackClick('pin-details');
    const refPopup = document.getElementById('popup');
    const newOverlay = new Overlay({ element: refPopup });
    setOverlay(newOverlay);

    linkifyRegisterKeywords(store.data.users.map(u => '@' + u.email));
  }, []);

  const refreshComments = () => {
    setCommentsLoading(true);
    setEditingCommentId(null);
    api.call('/pin/comment/list', { pin_id: pin.pin_id }).then(comments => {
      console.log(comments);
      const sortedComments = (comments?.success || []).sort((a, b) => a.comment_id - b.comment_id);
      setComments(sortedComments);
      setCommentsLoading(false);
    });
  };

  const updateStatus = status => {
    setActionsLoading(true);
    pin.status = status;
    updatePin(pin).then(() => setActionsLoading(false));
  };

  const uploadSingleFile = async file => {
    console.log(file);
    let media_type = 'file'; // default

    if (file.name.match(/\.jpe?g$/i)) {
      media_type = 'image';

      // Panorama detection based on exif and/or aspect ratio equal exactly 2
      try {
        const exif = await exifr.parse(file, [
          'ImageWidth',
          'ImageHeight',
          'ExifImageWidth',
          'ExifImageHeight',
          'FullPanoHeightPixels',
          'FullPanoWidthPixels',
          'IsMergedPanorama',
          'UsePanoramaViewer',
          'XPKeywords',
          'ProjectionType',
        ]);
        console.log(exif);

        const w = exif.ImageWidth || exif.ExifImageWidth || 1;
        const h = exif.ImageHeight || exif.ExifImageHeight || 1;
        const aspectIs2to1 = w == 2 * h || h == 2 * w;
        if (
          aspectIs2to1 ||
          exif.FullPanoHeightPixels ||
          exif.FullPanoWidthPixels ||
          exif.IsMergedPanorama ||
          exif.UsePanoramaViewer ||
          exif.XPKeywords === 'pano' ||
          exif.ProjectionType === 'equirectangular' ||
          exif.ProjectionType === 'cylindrical'
        ) {
          media_type = 'panorama';
        }
      } catch (e) {
        e;
      }
    }

    // Attempt to display any other image format
    if (file.name.match(/\.(png|gif|webp|bmp)$/i)) {
      media_type = 'image';
    }

    if (file.name.match(/\.(mp4|webm|mov|avi|mkv|wmv|m4v|mpe?g)$/i)) {
      media_type = 'video';
    }

    const presignResponse = await api.call(`/getPresignedPinUpload`, { filename: file.name });
    const signedRequestBundle = presignResponse.success;

    if (!signedRequestBundle)
      throw new Error(
        `Unable to obtain an upload URL: ${presignResponse.error || presignResponse}`
      );

    // Then build a request from the signed bundle
    const formFields = new FormData();
    Object.entries(signedRequestBundle.fields).forEach(_ => formFields.append(..._));
    formFields.append('file', file);

    // And send it
    const result = await fetch(signedRequestBundle.url, {
      method: 'POST',
      body: formFields,
    });

    if (!result.ok) throw new Error(`Problem while uploading: ${JSON.stringify(result)}`);
    return { media_type, media_url: signedRequestBundle.fields.key };
  };

  useEffect(() => {
    if (pin && pin.pin_id) {
      setIsEditing(false);
      overlay.setMap(map);
      overlay.setPosition(pin.geog_3857);
      setComments([]);
      if (open) {
        overlay.panIntoView({ margin: 60 });
        refreshComments();
      }

      if (!pin.media_type) pin.media_type = 'image';
      setEditingCommentId(null);
    }
  }, [pin, overlay, map, open]);

  function prepareUpdatePin(title, description) {
    if (!isEditing) {
      setTitle(title);
      setDescription(description);
      setIsEditing(true);
      return;
    }
    store.ui.mapPreviewPin.location = null;
    pin.title = pinTitle;
    pin.description = pinDescription;
    pin.location = store.ui.mapPreviewPin.location;
    setActionsLoading(true);
    updatePin(pin).then(() => setActionsLoading(false));
  }

  function mentionTag(content) {
    const email = content.slice(1);
    return (
      <div className="mention-tag">
        <Avatar user={email} size={20} />@
        {store.data.users.find(u => u.email === email)?.display_name?.trim?.() || email}
      </div>
    );
  }

  function displayNameOrEmail(email) {
    const display_name = store.data.users.find(u => u.email === email)?.display_name?.trim?.();
    if(display_name)
      return <>{display_name} <i>({email})</i></>;
    else
      return <>{email}</>;
  }

  if (!pin) return <div id="popup"></div>;

  const { title, text, email, origin_date, status, created_at, media_type, media_urls } = pin;

  const highlightedCommentId = store.ui.map.state.highlightedCommentId;

  const hasEditPermission =
    store.session.user.email === email ||
    store.session.user.role === 'owner' ||
    store.session.user.role === 'admin';

  if (!open) {
    return (
      <div id="popup" className="ol-popup pin_hover_popup">
        <div
          className="popup__header"
          style={{
            borderLeft: `8px solid ${
              {
                purple: '#A567BB',
                cyan: '#8FC4E1',
                green: '#426E47',
                yellow: '#F5AF47',
              }[status] || 'transparent'
            }`,
          }}
        >
          <div className="popup__header__text">
            <time dateTime={created_at}>{new Date(created_at).toLocaleString('en-uk')}</time>
            <p>{title}</p>
          </div>
          <Avatar user={email} />
        </div>
        {(media_type === null || media_type === 'image') && media_urls[0] && (
          <div className="thumbnail">
            <PinContent media_type="image" media_url={media_urls[0]} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div id="popup" className="ol-popup pin_full_popup">
      <div className="popup__header">
        <Avatar user={email} />
        <div className="popup__header__text">
          <p>{displayNameOrEmail(email)}</p>
          <time dateTime={created_at}>{new Date(created_at).toLocaleString('en-uk')}</time>
        </div>
      </div>

      <div className="popup__content">
        {isEditing ? (
          <div>
            {store.ui.mapPreviewPin?.location && (
              <div style={{ width: '180px' }} className="location-banner">
                Long: {store.ui.mapPreviewPin.location[0].toFixed(2)}
                Lat: {store.ui.mapPreviewPin.location[1].toFixed(2)}
              </div>
            )}
            <input
              className="input-header"
              type="text"
              value={pinTitle}
              onChange={e => setTitle(e.target.value)}
              placeholder="Enter title"
            />
            <input
              className="input-text"
              type="text"
              value={pinDescription}
              onChange={e => setDescription(e.target.value)}
              placeholder="Enter text"
            />
          </div>
        ) : (
          <>
            <PinColorPicker
              disabled={actionsLoading}
              currentStatus={status}
              updateStatus={updateStatus}
            />
            <div>
              {title && <h2>{title}</h2>}
              {text && (
                <div style={{ marginTop: '15px' }}>
                  <Linkify
                    options={{
                      target: '__blank',
                      rel: 'noopener noreferrer',
                      render: { keyword: ({ content }) => mentionTag(content) },
                    }}
                  >
                    {text}
                  </Linkify>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {media_urls?.[0] &&
        (media_type === 'vegagerdin' ? (
          <PinContent media_type={media_type} media_url={media_urls} />
        ) : (
          <PinContent media_type={media_type} media_url={media_urls[0]} />
        ))}

      {commentsLoading ? (
        <>
          <div className="spinner comments__loading" />
          <p style={{ textAlign: 'center' }}>{commentsLoading.length ? commentsLoading : ''}</p>
        </>
      ) : (
        <>
          <div className="comments__container">
            {comments.map(comment => {
              return (
                <div
                  key={comment.comment_id}
                  className={'comment' + (comment.comment_id === highlightedCommentId ? ' highlighted' : '')}
                  onLoad={
                    comment.comment_id === highlightedCommentId
                      ? e => setTimeout(() => e.target.scrollIntoViewIfNeeded(), 1000)
                      : null
                  }
                >
                  <div className="comment__header">
                    <Avatar user={comment.email} />
                    <p>{displayNameOrEmail(comment.email)}:</p>
                    <time dateTime={comment.created_at}>
                      {new Date(comment.updated_at).toLocaleString('en-uk')}
                      {comment.updated_at !== comment.created_at ? '(edited)' : ''}
                    </time>
                    <div className="actions">
                      {comment.comment_id !== editingCommentId ? (
                        <>
                          {comment.email === store.session.user.email && comment.text !== '[comment deleted]' &&
                           <div key="edit" onClick={() => {
                              Analytics.trackClick('pin-comment-edit');
                              setEditingCommentId(comment.comment_id)
                            }}>
                            <img src="/icons/edit.svg" /> Edit
                          </div>}
                        </>
                      ) : (
                        <>
                          <div
                            key="delete"
                            className="delete"
                            onClick={async () => {
                              Analytics.trackClick('pin-comment-delete');
                              setCommentsLoading(true);
                              try {
                                /*await api.call('/pin/comment/delete', {
                                  comment_id: comment.comment_id,
                                });*/
                                // Just show an empty [comment deleted] placeholder instead of actual delete
                                await api.call('/pin/comment/edit', {
                                  comment_id: comment.comment_id,
                                  text: '[comment deleted]',
                                  media_type: null,
                                  media_urls: [],
                                });
                              } finally {
                                refreshComments();
                              }
                            }}
                          >
                            <img src="/icons/delete_icon.svg" /> Delete comment
                          </div>
                          <div key="cancel" onClick={() => setEditingCommentId(null)}>
                            <img src="/icons/cross.svg" /> Cancel edit
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="comment__content">
                    {comment.comment_id === editingCommentId ? (
                      <CommentComposer
                        editing={comment.text}
                        onSubmitComment={async ({ commentText }) => {
                          console.log({ commentText });
                          setCommentsLoading(true);
                          try {
                            await api.call('/pin/comment/edit', {
                              comment_id: comment.comment_id,
                              text: commentText,
                            });
                          } finally {
                            refreshComments();
                          }
                        }}
                      />
                    ) : (
                      <Linkify
                        options={{
                          target: '__blank',
                          rel: 'noopener noreferrer',
                          render: { keyword: ({ content }) => mentionTag(content) },
                        }}
                      >
                        {comment.text}
                      </Linkify>
                    )}
                    {comment.media_urls?.length ?
                      comment.media_urls.map((media_url, i) => (
                        <PinContent
                          key={i}
                          media_type={comment.media_type || 'image'}
                          media_url={media_url}
                        />
                      )) : (<></>)
                    }
                  </div>
                </div>
              );
            })}
          </div>

          <CommentComposer
            onSubmitComment={async ({ commentText, attachments }) => {
              Analytics.trackClick('pin-comment-post');
              console.log({ commentText, attachments });
              setCommentsLoading(true);

              try {
                if (!attachments?.length) {
                  // Single text comment
                  await api.call('/pin/comment/post', {
                    pin_id: pin.pin_id,
                    text: commentText,
                  });
                } else {
                  // Parallel attachment uploads
                  let completed = 0;
                  const uploads = await Promise.all(attachments.map(async a => {
                    const res = await uploadSingleFile(a);
                    setCommentsLoading(`Uploading attachments... ${(100*completed++/attachments.length).toFixed(0)}%`);
                    return res;
                  }))

                  // Single post with media_urls array
                  await api.call('/pin/comment/post', {
                    pin_id: pin.pin_id,
                    text: commentText,
                    media_type: uploads[0].media_type,
                    media_urls: uploads.map(u => u.media_url),
                  });
                }
              } finally {
                refreshComments();
              }
            }}
          />
        </>
      )}

      <div className={'popup__actions' + (actionsLoading ? ' disabled' : '')}>
        {hasEditPermission && (
          <p style={{ marginRight: 'auto' }} onClick={() => {
            Analytics.trackClick('pin-details-edit');
            prepareUpdatePin(title, text)
          }}>
            {isEditing ? 'Save' : 'Edit'}
          </p>
        )}

        <p onClick={() => {Analytics.trackClick('pin-details-delete'); setIsDeleting(true);}}>Delete</p>

        {isDeleting && hasEditPermission && (
          <BaseModal
            title={'Deleting Pin'}
            onClickCancel={() => setIsDeleting(false)}
            onClickRightButton={() => {
              setIsDeleting(false);
              deletePin(pin.pin_id);
              map.removeOverlay(overlay);
            }}
            rightButton="Delete"
          >
            <h2 className="setting_save_changes_modal_title">
              Are you sure you want to delete this pin?
            </h2>
          </BaseModal>
        )}
      </div>
    </div>
  );
};

export default PinDetails;
