import React, { memo, useEffect, useState } from 'react';
import { useStore } from 'src/store';
import { FormProvider, useForm } from 'react-hook-form';
import api from 'src/api';
import Analytics from 'src/Analytics';
import Input from 'src/Common/Inputs/Input';
import BaseButton from 'src/Common/Buttons/BaseButton';
import UploadAvatar from './UploadAvatar';
import './ProfileTabPanel.less';

export default memo(function ProfileTabPanel({ refreshTeam }) {
  const store = useStore();
  const methods = useForm({ defaultValues: {
    phone: null,
  }});
  const {
    formState: { isDirty },
  } = methods;

  const onSubmit = async () => {
    try {
      setInitialName(displayName);
      const success = await api.call('/user/update/', { display_name: displayName });
      console.log(success);
      store.session.user.display_name = displayName;
      await refreshTeam();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    store.ui.unsavedChanges = isDirty;
  }, [isDirty]);

  useEffect(() => {
    Analytics.trackClick('settings-tab-profile');
    setInitialName(store.session.user.display_name);
    setDisplayName(store.session.user.display_name);
  }, []);

  const [displayName, setDisplayName] = useState('');
  const [initalName, setInitialName] = useState('');

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="settings_profile_tab_panel_main_container">
          <Input
            name="Name"
            registerName="firstName"
            placeholder="Enter first name"
            inputWrapperClassName="settings_profile_tab_panel_input"
            setValue={displayName}
            returnValue={setDisplayName}
          />
          <UploadAvatar
            avatar={`//datact-avatars.s3.eu-west-1.amazonaws.com/users/${encodeURI(
              store.session.user.email
            )}`}
          />
          <BaseButton
            additionalClassName="settings_profile_tab_panel_save_button"
            disabled={displayName == '' || displayName == initalName}
          >
            Save Name
          </BaseButton>
        </div>
      </form>
    </FormProvider>
  );
});