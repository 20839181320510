import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Analytics from 'src/Context/Analytics';
import { useStore } from 'src/store';
import './Toolbar.less';

function Toolbar(props) {
  const store = useStore();
  const menuOpen = store.ui.menuOpen;
  const sharing = store.session.team?.plan === 'temporary';

  const enable3DButton = store.ui.map &&
  store.ui.map.state.mostVisibleLayers?.some(l => l.dataset.optimized_pointclouds.length);


  const handleclick = () => {
    props.disableMeasurement();
    store.ui.menuOpen = !menuOpen;
    props.closeNatureReporting();
    store.ui.assetOverlap.currentOverlap = null;
  };

  if (props.activate3D) {
    return (
      <div className="toolbar__2d">
        <img
          src="/icons/toolbar/2D.svg"
          onClick={e => {
            e.target.style.pointerEvents = 'none';
            document.querySelector('.connection__container').style.opacity = 0;
            setTimeout(() => {
              props.set3DView();
              document.querySelector('#map').classList.remove('perspective');
            }, 2100);
            Analytics.trackClick('toolbar-3d-button');
          }}
          className="optionbar__item__2d"
          alt="2D"
          title="switch to 2D"
        ></img>
      </div>
    );
  }

  let distanceLeft = '-3px';
  if (menuOpen) distanceLeft = '493px';

  return (
    <div className={'toolbar__wrapper__container'} style={{ left: distanceLeft }}>
      <div className="toolbar__container">
        <div
          className={menuOpen ? 'close__button' : 'hamburger__button'}
          title={menuOpen ? 'Close menu' : 'Open menu'}
          onClick={() => {
            handleclick();
            Analytics.trackClick('menu');
          }}
        >
          <div />
          <div />
          <div />
        </div>

        <img
          src={'/icons/menu/data_manager.svg'}
          className="toolbar__item"
          onClick={() => {
            if (store.ui.menu.currentTab === 'DataManager' && store.ui.menuOpen) {
              store.ui.menu.currentTab = null;
              return;
            }
            store.ui.menu.currentTab = 'DataManager';
            store.ui.menuOpen = true;
            Analytics.trackClick('toolbar-data-manager');
          }}
          alt="datamanager"
          title="Data Manager"
        />
        <img
          src="/icons/toolbar/curtain.svg"
          className="toolbar__item"
          onClick={() => {
            props.toggleCurtain();
            Analytics.trackClick('toolbar-activate-curtain');
          }}
          alt="switch between maps"
          title="Activate curtain"
        />
        <img
          src="/icons/toolbar/pin.svg"
          onClick={() => {
            if (!sharing) {
              store.ui.menu.currentTab = 'PinUpload';
              store.ui.menuOpen = true;
              Analytics.trackClick('toolbar-upload-pins');
            }
          }}
          className="toolbar__item"
          alt="pin"
          title="Upload pins"
          style={sharing ? { opacity: 0.5, pointerEvents: 'none' } : {}}
        />
        <img
          src="/icons/toolbar/printer.svg"
          className="toolbar__item"
          alt="Print map"
          title="Print map"
          onClick={() => {
            props.onDownloadMap();
            Analytics.trackClick('toolbar-save-map');
          }}
        />
        <div className="toolbar_divider" />
        <img
          src="/icons/toolbar/measure_area.svg"
          onClick={() => {
            props.disableMeasurement();
            requestAnimationFrame(() => props.measureArea());
            Analytics.trackClick('toolbar-measure-area');
          }}
          className="toolbar__item"
          alt="measure area"
          title="Measure area"
        />
        <img
          src="/icons/toolbar/measure_distance.svg"
          onClick={() => {
            props.disableMeasurement();
            requestAnimationFrame(() => props.measureLength());
            Analytics.trackClick('toolbar-measure-distance');
          }}
          className="toolbar__item"
          alt="measure distance"
          title="Measure distance"
        />
        <div className="toolbar_divider" />
        <img
          src="/icons/toolbar/3D.svg"
          onClick={() => {
            props.set3DView();
            requestAnimationFrame(() => {
              document.querySelector('.connection__container').style.opacity = 1;
              document.querySelector('#map').classList.add('perspective');
            });
            Analytics.trackClick('toolbar-3d-button');
          }}
          className={enable3DButton ? 'toolbar__item threeD' : 'toolbar__item threeD disabled'}
          alt="3D"
          title="Switch to 3D"
        />
      </div>
    </div>
  );
}

export default Toolbar;
