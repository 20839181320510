import React, { useState } from 'react';
import { useStore } from 'src/store';
import { toLonLat } from 'ol/proj';
import Analytics from 'src/Analytics';
import './ContextMenu.less';

export default function ContextMenu({ menu, addSinglePin }) {
  const [copied, setCopied] = useState(false);
  const [lon, lat] = toLonLat(menu.coordinate);
  const coords = `${lat.toFixed(6)}, ${lon.toFixed(6)}`;
  const store = useStore();
  const sharing = store.session.team?.plan === 'temporary';

  return (
    <div className="context-menu" style={{ left: menu.pixel[0], top: menu.pixel[1] }}>
      <div
        className="coordinate__container"
        title="Click to copy coordinates to clipboard"
        onClick={() => {
          Analytics.trackClick('context-menu-copy-coordinates');
          navigator.clipboard.writeText(coords);
          setCopied(true);
          setTimeout(() => setCopied(false), 1e3);
        }}
      >
        <div>{coords}</div>
        {
          copied ? 
          <span className="copy">Copied!</span> :
          <img className="copy" alt="Copy" src="/icons/copy.svg" />
        }
      </div>

      {!sharing && (
        <div
          onClick={() => {
            Analytics.trackClick('context-menu-add-pin-here');
            addSinglePin([lon, lat]);
            store.ui.menu.currentTab = 'SinglePinUpload';
            store.ui.mapPreviewPin.location = [lon, lat];
            store.ui.mapPreviewPin.style = 'pin';
          }}
        >
          Add a pin here
        </div>
      )}
    </div>
  );
};