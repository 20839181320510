import React, { memo } from 'react';
import ReactProgressBar from '@ramonak/react-progress-bar';
import './ProgressBar.css';

function ProgressBar({
  progress = 0,
  width = 'auto',
  height = '4px',
  isLabelVisible = false,
  borderRadius = '50px',
  borderColor = '#d9d9d9',
  fillColor,
  ...rest
}) {
  /*
  const bgColor = useMemo(() => {
    if (progress >= 0 && progress < 20) {
      return '#E48693';
    }

    if (progress >= 20 && progress < 40) {
      return '#D09869';
    }

    if (progress >= 40 && progress < 60) {
      return '#BFA549';
    }

    if (progress >= 60 && progress < 80) {
      return '#A4955D';
    }

    return '#7C7655';
  }, [progress]);
  */

  return (
    <div className="progress_bar_main_container" style={{ width, height }}>
      <div className="progress_bar_wrapper" style={{ borderRadius, borderColor }} />
      <ReactProgressBar
        completed={progress}
        bgColor={fillColor}
        height="100%"
        isLabelVisible={isLabelVisible}
        width="100%"
        borderRadius={borderRadius}
        baseBgColor="transparent"
        {...rest}
      />
    </div>
  );
}

export default memo(ProgressBar);
