import React, { memo } from 'react';
import BaseModal from 'src/Components/Common/Modal/BaseModal';
import './DeleteUserModal.css';

function DeleteUserModal({ onClickCancel, onClickRightButton, userEmail }) {
  return (
    <BaseModal
      rightButton="Delete"
      onClickCancel={onClickCancel}
      onClickRightButton={onClickRightButton}
    >
      <h2 className="channels_settings_tab_panel_delete_user_modal_title">
        {`Are you sure you want to remove\n ${userEmail} from your team?`}
      </h2>
    </BaseModal>
  );
}

export default memo(DeleteUserModal);
