import React, { useState, useEffect } from 'react';
import Analytics from 'src/Analytics';
import './SearchBox.less';

const TRANSITION_DELAY = 200;

export default function SearchBox(props) {
  const [isOpen, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [searchTimeout, setSearchTimeout] = useState(null); // Throttle searches to min 500ms when typing
  const [results, setResults] = useState([]);

  // Escape and enter keys
  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        setOpen(false);
      }
      if (event.key === 'Enter') {
        document.querySelector('.search-box-dropdown :nth-child(1)')?.click();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const performSearch = async query => {
    if (query === '') return [];

    /*
		const url =
			`https://api.maptiler.com/geocoding/` +
			`${query}.json` + 
			`?key=${process.env.REACT_APP_MAPTILER_TOKEN}`
		*/

    const url =
      `https://api.mapbox.com/geocoding/v5/mapbox.places/` +
      `${query}.json` +
      `?types=place,postcode,address&autocomplete=true&fuzzyMatch=true` +
      `&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;

    const res = await fetch(url);
    const json = await res?.json();
    return json?.features || [];
  };

  return (
    <div className={`ol-control search__drawer ${isOpen && 'search__drawer_active'}`}>
      <div
        className="search_icon_container"
        onClick={() => {
          setOpen(!isOpen);
          setQuery('');
          setResults([]);
          requestAnimationFrame(() => {
            setTimeout(() => {
              document.querySelector('#search_location_input')?.focus?.();
            }, TRANSITION_DELAY);
          });
          Analytics.trackClick('search-location-open');
        }}
      >
        <img
          src={'/icons/search_icon.svg'}
          className="search__icon"
          alt="search"
          title="Search for a location"
        />
      </div>

      {isOpen && (
        <div className="search-box">
          <input
            type="text"
            id="search_location_input"
            autoComplete="off"
            value={query}
            onChange={e => {
              setQuery(e.target.value);
              clearTimeout(searchTimeout);
              setSearchTimeout(
                setTimeout(async () => setResults(await performSearch(e.target.value)), 500)
              );
            }}
          />
          <div className="search-box-dropdown">
            {results.slice(0, 2).map((item, key) => (
              <div
                key={key}
                onClick={() => {
                  props.olmap?.jumpTo({
                    point_4326: item.center,
                    radius: 1e3,
                  });
                  setOpen(false);
                }}
              >
                {item.place_name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}