import React, { memo, useMemo } from 'react';
import addAreaDetails from 'public/icons/cutouts/add_area_details.png';
import TabBaseInfo from 'src/Components/Common/TabBaseInfo';
import './EmptyDashboardPanel.css';

function EmptyDashboardPanel({ onClick }) {
  const imageParams = useMemo(
    () => ({
      src: addAreaDetails,
      alt: 'add asset details',
      className: 'nature_reporting_nature_reporting_details_empty_dashboard_tab_panel_img',
    }),
    []
  );

  const baseButtonParams = useMemo(
    () => ({
      buttonName: 'Add Asset Details',
      onClick: onClick,
    }),
    [onClick]
  );

  return (
    <TabBaseInfo
      imageParams={imageParams}
      baseButtonParams={baseButtonParams}
      title={'Please, add asset details\n to see dashboard.'}
    />
  );
}

export default memo(EmptyDashboardPanel);
