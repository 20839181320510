import React, { useState, useEffect } from 'react';
import exifr from 'exifr';
import heic2any from 'heic2any';
import { boundingExtent } from 'ol/extent';
import utils from 'src/utils';
import api from 'src/api';
import { useStore } from 'src/store';
import { useDropzone } from 'react-dropzone';
import Analytics from 'src/Analytics';
import Input from 'src/Common/Input';
import ErrorMessage from 'src/Common/notificationMessages/ErrorMessage';
import MenuPage from 'src/Common/MenuPage';
import PinColorPicker from 'src/Pins/PinColorPicker';
import './BatchPinUpload.less';


export default function BatchPinUpload(props) {
  const store = useStore();

  const fileSizeLimit = 200 * Math.pow(2, 20); // 200mb

  const [error, setError] = useState('');
  const [stage, setStage] = useState('form'); // form -> validating -> upload -> done

  const [photos, setPhotos] = useState([]);
  const [noGPSPhotos, setNoGPSPhotos] = useState([]);

  const [parsedPhotos, setParsedPhotos] = useState([]);

  const [progressbarDone, setProgressBarDone] = useState(0);
  const [progressbarTotal, setProgressBarTotal] = useState();
  const [progressbarStarted, setProgressBarStarted] = useState();

  const [loading, setLoading] = useState(false);

  // this gets called everytime photos gets changed
  useEffect(() => {
    async function handlePhotoURLS() {
      try {
        setLoading(true);
        // create an array of thumbnails to use as previews for valid pHotos
        const newURLSwithExif = await createPreviews(photos);

        // create a preview with original photo when no exif data is available
        const newURLwithNoExif = await createPreviewsForNoExifPhotos(noGPSPhotos);

        // get location for each photo with exif data
        const extractedLocations = await getAllGPS(photos);

        // add newest information to an array of parsedphotos
        const parsedPhotos = photos.map((item, index) => {
          item['preview'] = newURLSwithExif[index];
          item['location'] = extractedLocations[index];
          return item;
        });

        const parsedNoGPSPhotos = noGPSPhotos.map((item, index) => {
          item['preview'] = newURLwithNoExif[index];

          return item;
        });

        let allParsedPhotos = [...parsedPhotos, ...parsedNoGPSPhotos];

        // add comment and title to the image
        allParsedPhotos = allParsedPhotos.map(async item => {
          // set title name as file name
          item['title'] = item.name;
          //item['comment'] = item.name === item.path ? '' : `From file: ${item.path}`;
          item['status'] = 'yellow';

          if (item.location) {
            // set title to the place name gotten from the maptiler
            item['title'] = await utils.getGeocodedPlacename(
              item.location.longitude,
              item.location.latitude
            );
          }

          return item;
        });

        setParsedPhotos(await Promise.all(allParsedPhotos));
      } catch (error) {
        console.info(error);
      } finally {
        setLoading(false);
      }
    }
    handlePhotoURLS();
  }, [photos, noGPSPhotos]);

  async function createPreviews(photos) {
    // turn photo into temproray local source
    const newPhotoURLS = photos.map(async photo => {
      if (photo.name.match(/.(heic|heif)$/i)) {
        const thumbnail = window.URL.createObjectURL(await heic2any({ blob: photo, quality: 0.1 }));
        return thumbnail;
      }
      const thumbnail = exifr.thumbnailUrl(photo);
      return thumbnail;
    });

    return await Promise.all(newPhotoURLS);
  }

  async function createPreviewsForNoExifPhotos(photos) {
    // turn photo into temproray local source
    const newPhotoURLS = photos.map(async photo => {
      return window.URL.createObjectURL(photo);
    });

    return await Promise.all(newPhotoURLS);
  }

  async function getAllGPS(photos) {
    const locations = photos.map(async photo => {
      const location = exifr.gps(photo);
      return location;
    });

    return await Promise.all(locations);
  }

  async function handleUploadPhotos() {
    if (stage !== 'form') return;

    try {
      setStage('upload');

      // upload the photos
      const result = await uploadAllPhotos();
      if (result) {
        setStage('done');
        const extent = boundingExtent(result.map(r => r.geog));
        store.ui.map?.state?.olmap?.jumpTo?.({ extent_4326: extent });
      }
    } catch (error) {
      setError(error.toString());
      setStage('form');
      setParsedPhotos(parsedPhotos.filter(p => !p.success)); // don't retry successful pictures
    } finally {
      await props.refreshPins();
    }
  }
  async function validatePhotos(photoList) {
    // Check at least one photo selected
    if (!photoList.length) errorMessages.push('Please select at least one photo to upload');

    // divide photos to validated and nonvalidated photos
    const validatedPhotos = [];
    const nonValidatedPhotos = [];

    const errorMessages = {
      alreadyUploaded: [],
      noExif: [],
      notJPG: [],
      sizeLimit: [],
    };

    // Check for duplicates (same name+size+lastModified) and keep only the first instance
    photoList = photoList.filter(p1 => photoList.some(p2 =>
      p1.name === p2.name &&
      p1.size === p2.size &&
      p1.lastModified === p2.lastModified
    ));

    for (let photo of photoList) {
      // Limit individual size
      if (photo.size > fileSizeLimit) {
        nonValidatedPhotos.push(photo);
        errorMessages['sizeLimit'].push(photo.name);
        continue;
      }

      // Check .jpg
      if (!photo.name.match(/.(jpg|jpeg|heic|heif)$/i)) {
        nonValidatedPhotos.push(photo);
        errorMessages['notJPG'].push(photo.name);
        continue;
      }

      // Check EXIF geotag
      const exifData = await exifr.parse(photo);
      console.log(exifData);
      if (!exifData) {
        // in some cases there is a exif data and no location data
        nonValidatedPhotos.push(photo);
        errorMessages['noExif'].push(photo.name);
        continue;
      }

      // Check if pin has already been uploaded
      const date = (exifData.DateTimeOriginal||new Date()).toISOString().slice(0, 19) + 'Z';
      if(props.existingPins.some(pin => {
        if (pin.origin_date === date) {
          const { distance } = utils.sphericalBearingDistance(pin.geog, [
            exifData.longitude,
            exifData.latitude,
          ]);
          if (distance < 0.5) {
            nonValidatedPhotos.push(photo)
            errorMessages['alreadyUploaded'].push(photo.name);
            return true
          }
        }
      })) continue;

      validatedPhotos.push(photo);
    }

    // create a custom error message to make it compact and smaller
    let tmpErrorMsg = '';
    for (let key in errorMessages) {
      // dont add to tmp variable if there is no error message
      if (!errorMessages[key]) continue;
      if (errorMessages[key].length === 0) continue;

      if (key === 'alreadyUploaded') {
        tmpErrorMsg = `The following images had already been uploaded and have been excluded:\n${errorMessages[key].join(', ')}. ${tmpErrorMsg}`;
      }
      if (key === 'noExif') {
        tmpErrorMsg = `No EXIF geotag could be found for the following images:\n${errorMessages[key].join(', ')}. ${tmpErrorMsg}`;
      }
      if (key === 'notJPG') {
        tmpErrorMsg = `The following images are not in the correct format:\n${errorMessages[key].join(', ')}. ${tmpErrorMsg}`;
      }
      if (key === 'sizeLimit') {
        tmpErrorMsg = `The following images exceed our file size limit:\n${errorMessages[key].join(', ')}. ${tmpErrorMsg}`;
      }
    }

    setError(tmpErrorMsg);

    return { validatedPhotos, nonValidatedPhotos };
  }

  async function uploadAllPhotos() {
    let total = parsedPhotos.length;

    setProgressBarTotal(total);
    setProgressBarStarted(total);
    setProgressBarDone(0);

    const uploadPromises = [];
    for (let i = 0; i < parsedPhotos.length; i++) {
      const photo = parsedPhotos[i];
      uploadPromises.push(uploadSinglePin(photo));
    }

    const pinDetails = await Promise.all(uploadPromises);
    console.log(pinDetails)
    if(pinDetails && !pinDetails.some(p => !p.geog)) {
      const resultAPI = await api.call('/pin/batch', {pins: pinDetails});
      console.log(resultAPI)
      if (!resultAPI.success) throw new Error(`Failed to add pins to database: ${JSON.stringify(resultAPI)}`);
    }

    return pinDetails;
  }

  async function uploadSinglePin(photo) {
    let pinDetails, resultS3, resultAPI;
    try {
      const exif = await exifr.parse(photo);

      const { latitude, longitude, DateTimeOriginal, ExifImageHeight, ExifImageWidth } = exif;

      if (!latitude || !longitude || !DateTimeOriginal)
        throw new Error(
          `Invalid exif tags for ${photo.name}: ${JSON.stringify({ latitude, longitude, DateTimeOriginal })}`
        );

      const media_type =
        ExifImageWidth === 2 * ExifImageHeight || ExifImageHeight === 2 * ExifImageWidth
          ? 'panorama'
          : 'image';

      // Create the pin data structure in the DynamoDB
      pinDetails = {
        media_type,
        media_urls: [],
        title: photo.title,
        text: photo.comment,
        geog: [longitude, latitude],
        status: photo.status,
        email: store.session.user.email,
        team_name: store.session.team.name,
        channel_name: store.session.channel.name,
        origin_date: DateTimeOriginal.toISOString() || photo.lastModifiedDate.toISOString(),
      };

      // Get presign url to upload the pin image
      const presignResponse = await api.call(`/getPresignedPinUpload`, { filename: photo.name });
      const signedRequestBundle = presignResponse.success;

      if (!signedRequestBundle)
        throw new Error(
          `Unable to obtain an upload URL for ${photo.name}: ${JSON.stringify(presignResponse.error || presignResponse)}`
        );

      // Then build a request from the signed bundle
      const formFields = new FormData();
      Object.entries(signedRequestBundle.fields).forEach(_ => formFields.append(..._));
      formFields.append('file', photo);

      // And send it
      const result = await fetch(signedRequestBundle.url, {
        method: 'POST',
        body: formFields,
      });

      if (!result.ok) throw new Error(`Problem while uploading ${photo.name}: ${JSON.stringify(result)}`);
      pinDetails.media_urls = [signedRequestBundle.fields.key];

      setProgressBarDone(currProgressbarDone => currProgressbarDone + 1);
      photo.success = true;

      return pinDetails;
    } catch (error) {
      error.pinDetails = pinDetails;
      error.resultS3 = resultS3;
      error.resultAPI = resultAPI;
      Analytics.reportClientError(error);
      throw new Error(
        `Something went wrong while attempting to upload ${photo.name}\n\n`+
        `The following images had already succeeded and have been removed from the list:\n${
          parsedPhotos.filter(p => p.success).map(p => p.name).join('\n') || 'None'
        }\n\n`+
        `Error details:\n${
          error.message || 'None'
        }`
      );
    }
  }

  const reactDropzone = useDropzone({ multiple: true, onDrop: readAllPhotos });
  async function readAllPhotos(photoList) {
    try {
      const { validatedPhotos } = await validatePhotos(photoList);

      // set the photos to state
      // skip no gps photos for now
      setNoGPSPhotos([]);
      setPhotos(validatedPhotos);
    } catch (error) {
      console.trace(error);
    }
  }

  return (
    <MenuPage
      title={'Create Pins'}
      onClickBack={props.onClose}
      isExpandButtonShowed
      icon={'/icons/toolbar/pin.svg'}
      isIconShowed={true}
      isShowingChannel={false}
      isArrowButtonShowed={true}
    >
      <div style={{ maxWidth: 500, margin: '0 auto' }}>
        {error && (
          <ErrorMessage
            id="error-message"
            data-test="error-alert"
            variant="danger"
            error={error}
            setError={setError}
          ></ErrorMessage>
        )}

        {(stage === 'form' || stage === 'validating') && (
          <>
            {parsedPhotos.length ? (
              <div
                style={{
                  fontSize: '20px',
                  color: 'var(--gold)',
                  padding: '10px',
                }}
              >
                {parsedPhotos.length} images:
              </div>
            ) : (
              <div
                className="dropzone"
                style={{ cursor: 'pointer' }}
                {...reactDropzone.getRootProps()}
              >
                <div>
                  Drag and drop images (.jpg) into this box, or click here to open the file browser
                  and select them manually.
                </div>
                <div> Maximum {utils.formatBytes(fileSizeLimit)} per image.</div>
                <div style={{ marginTop: '50px' }}>
                  <img src="/icons/upload.svg" className="icon" />
                </div>
                <input {...reactDropzone.getInputProps()} />
              </div>
            )}

            <div className="image__wrapper">
              {loading && <div className="spinner image__loading" />}
              {!loading &&
                parsedPhotos.map((photo, index) => (
                  <div key={index} className="image__item">
                    <img src={photo.preview} title={photo.name} />
                    <Input
                      value={photo.title}
                      onChangeInput={e => {
                        const values = [...parsedPhotos];
                        values[index].title = e.target.value;
                        setParsedPhotos(values);
                      }}
                      typeInput="title"
                      placeholder="Title"
                    />
                    <Input
                      value={photo.comment}
                      onChangeInput={e => {
                        const values = [...parsedPhotos];
                        values[index].comment = e.target.value;
                        setParsedPhotos(values);
                      }}
                      typeInput="comment"
                      placeholder="Comment"
                    />
                    <p title={photo.path}>File: {photo.path}</p>
                    <PinColorPicker
                      currentStatus={photo.status}
                      updateStatus={status => {
                        const values = [...parsedPhotos];
                        values[index].status = status;
                        setParsedPhotos(values);
                      }}
                    />
                  </div>
                ))}
            </div>
            <button
              className="base_button"
              style={{ marginTop: 20 }}
              onClick={() => handleUploadPhotos()}
              disabled={!photos?.length}
            >
              Upload
            </button>
          </>
        )}

        {stage === 'upload' && (
          <>
            <p></p>
            <div className="progressbar">
              <div
                className="progressbar-fill"
                style={{
                  width: ((100 * progressbarStarted) / progressbarTotal).toFixed(2) + '%',
                }}
              />
              <div
                className="progressbar-fill"
                style={{
                  width: ((100 * progressbarDone) / progressbarTotal).toFixed(2) + '%',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>Uploading images</div>
              <div>
                {progressbarDone}/{progressbarTotal}
              </div>
            </div>
          </>
        )}

        {stage === 'done' && (
          <>
            <h3>Thank you, images have been uploaded successfully as pins!</h3>
            <div></div>
          </>
        )}
      </div>
    </MenuPage>
  );
};