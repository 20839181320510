import React, { memo, useEffect } from 'react';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import { useStore } from 'src/store';
import MenuPage from 'src/Common/MenuPage';
import Tab from 'src/Common/Tab';
import FAQTab from 'src/Help/FAQTab';
import ContactSupportTab from './ContactSupportTab';
import './Help.less';

Tab.tabsRole = 'Tab';

function Help({ onClickClose }) {
  const store = useStore();
  const menuOpen = store.ui.menuOpen;

  useEffect(() => {
    if (!menuOpen) {
      //this might seem stupid but it's getting the value before the change
      onClickClose();
    }
  }, [menuOpen]);

  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        onClickClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <MenuPage
      title="Help"
      icon="/icons/menu/help.svg"
      isIconShowed={true}
      onClickBack={onClickClose}
      isArrowButtonShowed={true}
    >
      <Tabs className="help_main_container">
        <TabList className="help_tab_list">
          <Tab tabValue="Contact Support" />
          <Tab tabValue="FAQ" />
        </TabList>
        <TabPanel>
          <ContactSupportTab onClickClose={onClickClose} />
        </TabPanel>
        <TabPanel>
          <FAQTab />
        </TabPanel>
      </Tabs>
    </MenuPage>
  );
}

export default memo(Help);
