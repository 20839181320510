import React, { memo, useMemo } from 'react';
import './Status.css';

function Status({ status, className }) {
  const status_class_name = useMemo(() => {
    switch (status) {
      case "confirmed":
        return 'nature_reporting_details_green_status';
      case "active":
        return 'nature_reporting_details_green_status';
      case "archive":
        return 'nature_reporting_details_gray_status';
      case "estimated":
        return 'nature_reporting_details_gray_status';
      default:
        return 'nature_reporting_details_red_status';
    }
  }, [status]);

  return (
    <p className={`nature_reporting_details_status ${status_class_name} ${className && className}`}>
      {status}
    </p>
  );
}

export default memo(Status);
