import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTable, useGlobalFilter } from 'react-table';
import {
  TH_EXPANDED_WIDTH_NATURE_REPORTING_DETAILS_FILES_TAB_PANEL_TABLE,
  TH_SQUEEZED_WIDTH_NATURE_REPORTING_DETAILS_FILES_TAB_PANEL_TABLE,
} from 'src/shared/constants/nature_reporting';
import UploadDate from './UploadDate';
import { useStore } from 'src/store';
import api from 'src/api';
import './Table.css';
import BaseModal from 'src/Components/Common/Modal/BaseModal';
import utils from 'src/utils';

function Table({ data, globalFilterValue, refresh }) {
  const store = useStore();
  const isFullMode = store.ui.menuFull;

  const [deleteFileName, setDeleteFileName] = useState(null);
  const [deleteFileId, setDeleteFileId] = useState(null);

  function OpenDeleteModal(fileName, id) {
    console.log(id);
    setDeleteFileName(fileName);
    setDeleteFileId(id);
  }

  async function handleClickDownload(row) {
    const presignedDownload = await api.call(`/getPresignedAssetFilesDownload`, {
      file_location: row.original.file_location,
    });
    window.open(presignedDownload.url);
  }

  async function handleClickDelete() {
    const success = await api.call('/asset/report/delete', { report_id: deleteFileId });
    console.log(success);
    setDeleteFileName(null);
    refresh();
  }

  const handleResetDeleteFileName = useCallback(() => {
    setDeleteFileName(null);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'File Name',
        accessor: 'name',
        width: 100,
        Cell: ({ value, row }) => (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => handleClickDownload(row)}
            title={`${value}    (Click to Download)`}
          >
            {value}
          </div>
        ),
      },
      {
        Header: 'File Size',
        accessor: 'file_size',
        disableGlobalFilter: true,
        width: 200,
      },
      {
        width: 200,
        Header: 'Upload Date ',
        accessor: 'created_at',
        Cell: ({ value, row }) => {
          return (
            <UploadDate
              date={value}
              onClickDownload={() => handleClickDownload(row)}
              onClickDelete={() => OpenDeleteModal(row.original.name, row.original.report_id)}
            />
          );
        },
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } =
    useTable(
      {
        columns,
        data,
      },
      useGlobalFilter
    );

  useEffect(() => {
    setGlobalFilter(globalFilterValue);
  }, [globalFilterValue, setGlobalFilter]);

  return (
    <>
      {deleteFileName && (
        <BaseModal
          onClickRightButton={() => handleClickDelete()}
          onClickCancel={() => handleResetDeleteFileName()}
          rightButton={'Delete!'}
          title={'Deleting File'}
        >
          <h2 className="nature_reporting_assets_list_delete_asset_modal_title">
            Are you sure you want to permanently delete this file: {deleteFileName}?
          </h2>
        </BaseModal>
      )}
      <div className="nature_reporting_details_files_tab_panel_table_container">
        <table className="nature_reporting_details_files_tab_panel_table" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => {
              const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

              return (
                <tr key={key} {...restHeaderGroupProps}>
                  {headerGroup.headers.map(column => {
                    const { key, ...restHeaderProps } = column.getHeaderProps();

                    return (
                      <th
                        key={key}
                        {...restHeaderProps}
                        style={{
                          width: isFullMode
                            ? TH_EXPANDED_WIDTH_NATURE_REPORTING_DETAILS_FILES_TAB_PANEL_TABLE[key]
                            : TH_SQUEEZED_WIDTH_NATURE_REPORTING_DETAILS_FILES_TAB_PANEL_TABLE[key],
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);

              const { key, ...restRowProps } = row.getRowProps();

              return (
                <tr key={key} {...restRowProps}>
                  {row.cells.map(cell => {
                    const { key, ...restCellProps } = cell.getCellProps();
                    return (
                      <td key={key} {...restCellProps}>
                        {key.includes("file_size") ? utils.formatBytes(cell.value) : cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="nature_reporting_details_files_tab_panel_table_border" />
    </>
  );
}

export default memo(Table);
