import React, { memo, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Search from 'src/Common/Inputs/Search';
import TextButton from 'src/Common/Buttons/TextButton';
import Table from './Table';
import CreateNatureReport from './CreateNatureReport';
import './NatureReportingAssetsList.css';
import api from 'src/api';
import { useStore } from 'src/store';
import LoadingScreen from 'src/LoadingScreen';
import BaseModal from 'src/Common/Modal/BaseModal';
import Analytics from 'src/Analytics';

function NatureReportingAssetsList({
  mapJumpTo,
  onClickDetails,
  assets,
  refreshAssets,
  editId,
  setEditId,
  editInfo,
}) {
  const methods = useForm({ shouldUnregister: true });
  const store = useStore();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isUploadError] = useState(false);
  const [assetName, setAssetName] = useState('');
  const [registration, setRegistration] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const [industrySelection, setIndustrySelection] = useState([]); //This is sector until db update
  const [sectorSelection, setSectorSelection] = useState([]); //    This is subsector until db update

  const [isCreatingAsset, setIsCreatingAsset] = useState(false);

  const data = React.useMemo(() => assets, [assets]);

  const isDataExist = useMemo(() => !!data?.length, [data]);

  const [deletingAsset, setDeletingAsset] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {Analytics.trackClick('assets-tab-asset-list');}, [])

  useEffect(() => {
    setIsFormVisible(!isDataExist);
  }, [isDataExist]);

  const showForm = () => {
    setIsFormVisible(true);
  };

  const hideForm = () => {
    setEditId(-1);
    store.ui.mapPreviewPin.style = 'null';
    store.ui.mapPreviewPin.location = null;
    setIsFormVisible(false);
  };

  const { watch } = methods;

  const searchValue = watch('natureReportingSearch');

  data.forEach(item => {
    item.bii = item?.nature_importances?.[0]?.bii,
    item.eii = item?.nature_importances?.[0]?.eii,
    item.water_stress = item?.nature_importances?.[0]?.water_stress,
    item.cr_overlap = item?.nature_importances?.[0]?.cr_overlap,
    item.wdpa_overlap = item?.nature_importances?.[0]?.wdpa_overlap,
    item.kba_overlap = item?.nature_importances?.[0]?.kba_overlap
  });


  const onSubmit = async data => {

    if(editId > -1) return;
    if(isCreatingAsset) return;
    setIsCreatingAsset(true);

    try {
      console.log(data);
      const newAssetData = {
        name: assetName,
        display_name: assetName,
        address: address.place_name,
        location: coordinates,
        sector: sectorSelection.label,
        industry: industrySelection.label,
        registration: registration,
        description: description,
      };
      const success = await api.call('/asset/create', { newAssetData });
      if (success.success) {
        setIsCreatingAsset(false);
        await refreshAssets();
        onClickDetails(success.asset);
        store.ui.mapPreviewPin.style = 'null';
        store.ui.mapPreviewPin.location = null;
        setIsFormVisible(false);
      }
    } catch (error) {
      setIsCreatingAsset(false);
      console.error('Failed to submit asset data:', error);
      setIsFormVisible(false);
    }
  };

  async function deleteAsset() {
    console.log(deletingAsset);
    setDeleteLoading(true);
    const res = await api.call('/asset/delete', { asset_id: deletingAsset.asset_id });
    console.log(res);
    refreshAssets();
    setDeleteLoading(false);
    setDeletingAsset(null);
  }

  useEffect(() => {
    //When address is set, set coordinates and jump to location
    console.log(address);
    if (!address?.center || !address) return;
    let point = address.center;
    console.log(point);
    setCoordinates(point);
    store.ui.map?.state.olmap?.jumpTo({ point_4326: point });
    store.ui.mapPreviewPin.location = [point[0], point[1]];
    store.ui.mapPreviewPin.style = 'asset';
  }, [address]);

  return deleteLoading || isCreatingAsset ? (
    <LoadingScreen />
  ) : (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="nature_reporting_list_form"
        autoComplete="off"
      >
        {(isFormVisible || editId > 0) &&
          (editId > 0 ? ( 
            editInfo === null ? (
              <LoadingScreen />
            ) : (
              // EDIT MODE
              <CreateNatureReport
                assetName={assetName || editInfo.display_name}
                setAssetName={setAssetName}
                setCoordinates={setCoordinates}
                onClickCancel={hideForm}
                setAddressExternal={setAddress}
                setIndustry={setIndustrySelection}
                setSector={setSectorSelection}
                setRegistration={setRegistration}
                setDescription={setDescription}
                sector={sectorSelection}
                registration={registration}
                description={description}
                industry={industrySelection}
                refreshAssets={refreshAssets}
                editId={editId}
                editInfo={editInfo}
                onClickDetails={onClickDetails}
              />
            )
          ) : (
            // CREATE MODE
            <CreateNatureReport
              assetName={assetName}
              setAssetName={setAssetName}
              setCoordinates={setCoordinates}
              onClickCancel={hideForm}
              onClickDetails={onClickDetails}
              setAddressExternal={setAddress}
              setIndustry={setIndustrySelection}
              setSector={setSectorSelection}
              setRegistration={setRegistration}
              setDescription={setDescription}
              refreshAssets={refreshAssets}
              sector={sectorSelection}
              registration={registration}
              description={description}
              industry={industrySelection}
            />
          ))}
        {!isFormVisible && editId < 1 && (
          <>
            {deletingAsset && (
              <BaseModal
                rightButton="Delete"
                onClickCancel={() => setDeletingAsset(null)}
                onClickRightButton={deleteAsset}
              >
                <h2 className="setting_save_changes_modal_title">
                  {`Are you sure you want to delete ${deletingAsset?.display_name}?`}
                </h2>
              </BaseModal>
            )}
            {isDataExist && (
              <>
                <div className="nature_reporting_list_search_wrapper">
                  <Search placeholder="Search ..." registerName="natureReportingSearch" />
                </div>
                <Table
                  data={data}
                  globalFilterValue={searchValue}
                  mapJumpTo={mapJumpTo}
                  onClickDetails={onClickDetails}
                  editAsset={setEditId}
                  deleteAsset={setDeletingAsset}
                />
              </>
            )}
            <TextButton
              onClick={showForm}
              className="nature_reporting_list_add_nature_reporting_list"
            >
              ＋ Add Asset
            </TextButton>
            <div className="nature_reporting_list_buttons_main_container">
              <div className="nature_reporting_list_buttons_container">
                {/* Consider extracting these elements if they become more complex or need to be reused */}
              </div>
              {isUploadError && (
                <span className="nature_reporting_list_upload_button_error">
                  Error. Your file didn’t upload. Try again.
                </span>
              )}
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
}

export default memo(NatureReportingAssetsList);
