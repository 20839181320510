import React, { memo, useCallback, useState } from 'react';
import edit from 'public/icons/edit.svg';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import ImageButton from 'src/Components/Common/Buttons/ImageButton';
import BaseModal from 'src/Components/Common/Modal/BaseModal';
import './ChannelHandlers.css';
import { useStore } from 'src/store';
import api from 'src/api';

function ChannelHandlers({ channelId, onClickEditChannel, channelName, joined, refresh }) {
  const store = useStore();
  const currentChannel = store.session.channel;
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handlePressEdit = useCallback(() => {
    if (isLoading) return;
    onClickEditChannel(currentChannel);
    store.session.channel = store.data.channels.find(c => c.channel_id === channelId);
  }, [currentChannel, channelId, onClickEditChannel]);

  const toggleModal = () => {
    if (isLoading) return;
    setShowModal(prevShowModal => !prevShowModal);
  };

  async function leaveChannel() {
    setShowModal(false);
    setLoading(true);
    const res = await api.call('/user/details', { email: store.session.user.email });
    let reload = store.session.channel.channel_id == channelId;
    await api.call('/channel/deleteUser', { channel_id: channelId, user_id: res.user.user_id });
    if (reload) window.location.reload();
    await refresh();
    setLoading(false);
  }

  async function joinChannel() {
    setLoading(true);
    setShowModal(false);
    await api.call('/user/invite', {
      invite_email: store.session.user.email,
      invite_channel_id: channelId,
    });
    await refresh();
    setLoading(false);
  }

  function processClick() {
    joined ? leaveChannel() : joinChannel();
  }

  return (
    <div className="channel_handlers_table_main_container">
      {showModal ? (
        <BaseModal
          rightButton={joined ? 'Leave' : 'Join'}
          onClickCancel={() => toggleModal()}
          title={!joined ? 'Joining Channel' : 'Leaving Channel'}
          onClickRightButton={() => processClick()}
        >
          <h2 className="channels_settings_tab_panel_archive_modal_title">
            {`Are you sure you want to ${joined ? 'Leave' : 'Join'}\n ${channelName}?`}
          </h2>
        </BaseModal>
      ) : null}

      {joined && !isLoading && channelName.toLowerCase() != 'main' && (
        <ImageButton
          src={edit}
          alt="edit icon"
          onClick={handlePressEdit}
          className="channel_handlers_table_edit_icon"
        />
      )}

      <BaseButton
        buttonType={isLoading ? 'SECONDARY' : joined ? 'DELETE' : 'STANDARD'}
        type="button"
        additionalClassName="channel_handlers_table_base_button"
        onClick={() => toggleModal()}
        style={isLoading ? { border: 'none' } : null}
      >
        {isLoading ? (
          <div className="loading-container">
            <div className="dot-container">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
            </div>
          </div>
        ) : (joined ? 'Leave' : 'Join')}
      </BaseButton>
    </div>
  );
}

export default memo(ChannelHandlers);
