import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Table from './Table';
import './AreaTabPanel.css';
import { useStore } from 'src/store';
import BaseButton from 'src/Common/Buttons/BaseButton';
import MeasureTool from 'src/Widgets/MeasureTool';
import api from 'src/api';
import utils from 'src/utils';
import { getArea } from 'ol/extent';
import BaseModal from 'src/Common/Modal/BaseModal';
import LoadingScreen from 'src/LoadingScreen';

function AreaTabPanel({
  assetDetails,
  area,
  areaName,
  mapJumpTo,
  refresh,
  showArea,
  setShowArea,
  refreshAssets,
  resetAreaWindow,
}) {
  const [shape, setShape] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({ shouldUnregister: true });
  const store = useStore();

  let data = [];
  console.log(area);
  if (assetDetails && area) {
    data = area.map(a => ({
      area_id: a.nameOfArea,
      created_at: utils.formatDate(a.created_at),
      area: (a.size / 1_000_000).toFixed(2) + 'Km²',
      active: a.active,
      id: a.area_id,
      geog: a.geog,
    }));
  }

  const { watch } = methods;

  const searchValue = watch('monitoringAreasSearch');

  const ConfirmFootprint = async () => {
    const polygon = shape.clone().transform('EPSG:3857', 'EPSG:4326').getCoordinates();
    console.log(shape.getCoordinates()[0][0]);
    setIsLoading(true);

    if (getArea(shape.getExtent()) < 1) {
      setShape(null);
      setError('The Area is too small (< 1m²), Please draw a larger area');
      setIsLoading(false);
      return;
    } else if (shape.getCoordinates()[0][0].length <= 4) {
      var allCoords = shape.getCoordinates()[0][0];
      var foundDuplicates = 0;

      for (var i = 0; i < allCoords.length; i++) {
        for (var j = i + 1; j < allCoords.length; j++) {
          if (allCoords[i][0] == allCoords[j][0] && allCoords[i][1] == allCoords[j][1]) {
            foundDuplicates++;
            break; // duplicate pair is only counted once.
          }
        }
      }

      if (foundDuplicates > 1) {
        console.log(foundDuplicates);
        setError('Area is a line, Please draw a valid area');
        setShape(null);
        setIsLoading(false);
        return;
      }
    }

    const res = await api.call('/asset/area/create', {
      asset_id: assetDetails.asset_id,
      version: 1,
      active: true,
      geog: polygon,
      area_type: areaName == 'Monitoring Area' ? 'monitoring_area' : 'asset_footprint',
    });
    console.log(res);
    const res2 = await api.call('/area/regenerate_metrics', {
      area_id: res.monitoring_area.area_id,
      geog: polygon,
    });
    console.log(res2);
    setShowArea(false);
    setShape(null);
    refreshAssets({ asset_id: assetDetails.asset_id });
  };

  useEffect(() => {
    if (shape) {
      ConfirmFootprint();
    }
  }, [shape]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <FormProvider {...methods}>
      <div className="area-title">{areaName}</div>
      <div style={{ left: '224px' }} className="border-preview">
        <div
          style={{ borderColor: areaName === 'Monitoring Area' ? '#FAC676' : '#668FB7' }}
          className="dotted-preview"
        ></div>
      </div>
      {showArea ? (
        <BaseButton
          buttonType="SECONDARY"
          additionalClassName="new-area cancel"
          onClick={() => setShowArea(false)}
        >
          Cancel
        </BaseButton>
      ) : (
        <BaseButton
          additionalClassName="new-area"
          onClick={() => {
            resetAreaWindow();
            setShowArea(true);
          }}
        >
          Add {areaName} <img width="24px" src="icons/Add.svg" />
        </BaseButton>
      )}
      {error && (
        <BaseModal title={error} hideButtons>
          <BaseButton onClick={() => setError(null)}>Close</BaseButton>
        </BaseModal>
      )}
      <form className="monitoring_areas_tab_panel_list_form">
        <Table
          data={data}
          refreshAssets={refreshAssets}
          assetDetails={assetDetails}
          globalFilterValue={searchValue}
          mapJumpTo={mapJumpTo}
        />
      </form>
      {showArea && !shape && (
        <MeasureTool
          map={store.ui.map.state.olmap}
          mustContainPoint={areaName === 'Asset Footprint' ? assetDetails.location : []}
          measure="area"
          onShapeConfirmed={setShape}
        />
      )}
    </FormProvider>
  );
}

export default AreaTabPanel;
