import React, { memo } from 'react';
import InputWrapper from 'src/Common/Inputs/InputWrapper';
import ConnectForm from 'src/Common/Inputs/ConnectForm';
import SimpleInput from 'src/Common/Inputs/SimpleInput';
import './Input.css';

function Input({
  name,
  registerName,
  registerRules,
  inputRightElement,
  tooltipId,
  tooltipContent,
  inputWrapperClassName,
  setValue,
  returnValue,
  ...rest
}) {
  return (
    <ConnectForm>
      {({ formState: { errors } }) => {
        return (
          <InputWrapper
            name={name}
            error={errors[registerName]}
            tooltipId={tooltipId}
            tooltipContent={tooltipContent}
            inputWrapperClassName={inputWrapperClassName}
          >
            <div className="input_main_container">
              <SimpleInput
                registerName={registerName}
                registerRules={registerRules}
                className="input"
                showError={false}
                setValue={setValue}
                returnValue={returnValue}
                {...rest}
              />
              {inputRightElement && (
                <div className="input_main_container_input_right_element_container">
                  {inputRightElement}
                </div>
              )}
            </div>
          </InputWrapper>
        );
      }}
    </ConnectForm>
  );
}

export default memo(Input);
