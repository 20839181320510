import React, { memo } from 'react';
import BaseModal from 'src/Common/Modal/BaseModal';
import './SaveChangesModal.css';

function SaveChangesModal({ onClickCancel, onClickRightButton }) {
  return (
    <BaseModal
      rightButton="Close"
      onClickCancel={onClickCancel}
      onClickRightButton={onClickRightButton}
    >
      <h2 className="setting_save_changes_modal_title">
        {`Are you sure you want to close without\n saving changes?`}
      </h2>
    </BaseModal>
  );
}

export default memo(SaveChangesModal);
