import React from 'react';
import './Handle.css';

export default function Handle({
  disabled = false,
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}) {
  const tmpDate = new Date(value);

  return (
    <>
      <div
        className="handle__date__text"
        style={{
          left: `${percent}%`,
          position: 'absolute',
          cursor: 'pointer',
          top: -20,
          zIndex: 15,
          fontSize: 10,
          color: '#6AAFE1',
          marginLeft: `${id.split('-')[1] % 2 !== 0 ? 30 : -60}px`,
          overflow: 'hidden',
        }}
      >
        {`${tmpDate.getDate()}/${tmpDate.getMonth() + 1}/${tmpDate.getFullYear()}`}
      </div>
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: `matrix(${id.split('-')[1] % 2 !== 0 ? -1 : 1}, 0, 0, -1, 0, 0)`,
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          width: 18,
          height: 21,
          cursor: 'pointer',
          backgroundImage: 'url(/icons/slider_selector_active.svg)',
          top: -10,
          zIndex: 16,
        }}
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        {...getHandleProps(id)}
      />
    </>
  );
}
