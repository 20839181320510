import React, { useState, useEffect } from 'react';
import utils from 'src/utils';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Overlay from 'ol/Overlay';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Draw, Modify, Snap } from 'ol/interaction';
import { getArea, getLength } from 'ol/sphere';
import { getCenter } from 'ol/extent';
import { MultiPoint } from 'ol/geom';
import { transform } from 'ol/proj';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { read as readShapefile } from 'shapefile';
import Analytics from 'src/Analytics';
import './MeasureTool.less';

export default function MeasureTool(props) {
  const { map } = props;

  // On component unmount, trigger cleanup
  const [onUnmount, setOnUnmount] = useState({});
  useEffect(() => onUnmount?.handler, [onUnmount?.handler, props.measure]);

  // On first render, start user flow
  const [stage, setStage] = useState(null); // null -> "drawing" -> "adjusting"
  const [uploadHandler, setUploadHandler] = useState({});
  if (stage === null) {
    setStage('drawing');

    // Shape style elements
    const fill = new Fill({
      color: '#0000004D',
    });
    const stroke = new Stroke({
      color: '#FD5C78',
      lineDash: [5, 5],
      width: 2,
    });
    const point = new CircleStyle({
      fill: new Fill({ color: '#FD5C78' }),
      radius: 5,
    });
    const cursor = new CircleStyle({
      fill: new Fill({ color: '#0000004D' }),
      stroke: new Stroke({ color: '#FD5C78' }),
      radius: 5,
    });

    // Correctly select vertices for rendering
    const polygonVertices = feature =>
      new MultiPoint(feature.getGeometry().getCoordinates()[0].slice(0, -1));
    const linestringVertices = feature =>
      new MultiPoint(feature.getGeometry().getCoordinates().slice(0, -1));
    const fullpathVertices = feature => new MultiPoint(feature.getGeometry().getCoordinates());

    // Dynamic style function needed to draw in-progress polygons correctly (with cursor and uncompleted edge)
    const drawType = props.measure === 'area' ? 'MultiPolygon' : 'LineString';
    const drawStyle = feature =>
      ({
        Point: new Style({ image: cursor }),
        MultiPolygon: new Style({ fill }),
        LineString: [
          new Style({ fill, stroke }),
          new Style({ image: point, geometry: linestringVertices }),
        ],
      })[feature.getGeometry().getType()];

    // Two-pass style needed to draw completed polygon/paths correctly (one for fill and stroke, one for vertices)
    const completedStyle = [
      new Style({ fill, stroke }),
      new Style({
        image: point,
        geometry: props.measure === 'area' ? polygonVertices : fullpathVertices,
      }),
    ];

    // Setup shape edition ("source" is what's being edited by the various "interactions")
    const source = new VectorSource();
    const drawInteraction = new Draw({
      source,
      style: drawStyle,
      type: drawType,
    });
    const vectorLayer = new VectorLayer({
      source,
      style: completedStyle,
      zIndex: 1,
    });
    const modifyInteraction = new Modify({
      source,
      style: new Style({ image: cursor }),
    });
    const snapInteraction = new Snap({ source });

    // Setup measurement tooltip
    const tooltipElement = document.createElement('div');
    tooltipElement.className = 'tooltip pointy';
    tooltipElement.innerText = '';
    const tooltip = new Overlay({
      element: tooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center',
    });
    tooltip.element.style.pointerEvents = 'none';
    document.querySelector('.ol-viewport').style.cursor = 'copy'; // or crosshair

    // Allow user to start placing points
    map.addInteraction(drawInteraction);

    // Once the first point of the path is placed
    drawInteraction.on('drawstart', event => {
      map.addOverlay(tooltip);
      event.feature
        .getGeometry()
        .on('change', props.measure === 'area' ? updateArea : updateLength);
    });

    // add also event when modified
    modifyInteraction.on('modifystart', event => {
      map.addOverlay(tooltip);
      event.features
        .getArray()[0]
        .getGeometry()
        .on('change', props.measure === 'area' ? updateArea : updateLength);
    });

    // As the temporary shape changes under the cursor
    const updateArea = event => {
      const shape = event.target;
      const center = getCenter(shape.getExtent());
      const area = getArea(shape);
      tooltip.setPosition(center);
      tooltipElement.innerHTML = utils.formatArea(area);
    };

    const updateLength = event => {
      const shape = event.target;
      const end = shape.getLastCoordinate();
      const length = getLength(shape);
      tooltip.setPosition(end);
      tooltipElement.innerHTML = utils.formatLength(length);
    };

    // Once the path has looped around and completed a shape
    const drawCompleted = () => {
      map.addLayer(vectorLayer); // display the completed shape (drawInteraction temporary polygon has disappeared now that the shape is complete)
      map.removeInteraction(drawInteraction); // prevent drawing any additional polygons
      map.addInteraction(modifyInteraction); // allow existing shape to be tweaked further
      map.addInteraction(snapInteraction); // snap cursor to it for convenience
      tooltipElement.classList.add('confirm');
      tooltipElement.addEventListener('click', onConfirmShape);
      tooltip.setOffset([0, -7]);
      tooltip.element.style.pointerEvents = 'auto';
      document.querySelector('.ol-viewport').style.cursor = 'cell'; // or move, grab, null
      setStage('adjusting');
    };
    drawInteraction.on('drawend', drawCompleted);

    // Or handle shape uploading instead
    const uploadHandler = async files => {
      try {
        // Extract file extension .shp or .kml allowed
        const fileExtension = files[0].name.split('.').pop().toLowerCase();
        let multipolygons = [];

        if (fileExtension === 'json' || fileExtension === 'geojson') {
          // Parse the GeoJSON data
          const geojson = JSON.parse(await files[0].text());
          multipolygons = geojson.features.map(feature => {
            if (feature.geometry.type === 'MultiPolygon') {
              // Convert 3D coordinates to 2D for MultiPolygon geometries
              return feature.geometry.coordinates.map(polygon => 
                polygon.map(ring => 
                  ring.map(coord => coord.slice(0, 2))
                )
              );
            } else if (feature.geometry.type === 'Polygon') {
              // Convert 3D coordinates to 2D for Polygon geometries
              return [feature.geometry.coordinates.map(ring => 
                ring.map(coord => coord.slice(0, 2))
              )];
            } else {
              return [];
            }
          });
        } else if (fileExtension === 'shp') {
          // Convert shapefile to geojson and proceed as above
          const geojson = await readShapefile(files[0].stream());
          multipolygons = geojson.features.map(feature =>
            feature.geometry.type === 'MultiPolygon'
              ? feature.geometry.coordinates
              : feature.geometry.type === 'Polygon'
              ? [feature.geometry.coordinates]
              : []
          );
        } else if (fileExtension === 'kml') {
          // Read the kml file as a text and parse It with DOMParser Web API
          const kmlText = await files[0].text();
          const xmlDoc = new DOMParser().parseFromString(kmlText, 'text/xml');

          // Extract coordinates of all linear rings
          multipolygons = [...xmlDoc.getElementsByTagName('Polygon')].map(polygon => [
            [...polygon.getElementsByTagName('coordinates')].map(coordinates =>
              coordinates.textContent.split(/\r?\n/).map(
                (
                  line // KML coordinates format is newline-separated list of comma-separated pairs of float values
                ) =>
                  line
                    .split(',')
                    .map(number => parseFloat(number))
                    .slice(0, 2) // Sometimes KML polygons have 3 XYZ coordinates, so make sure to only grab the first two
              )
            ),
          ]);
        }

        // Merge all multipolygons into one big multipolygon
        const multipolygonCoords = multipolygons.flat();
        if (!multipolygonCoords.length) throw 'Unable to parse a polygon';

        // Set the editable shape to this
        const multipolygon = new MultiPolygon(multipolygonCoords);
        multipolygon.transform('EPSG:4326', 'EPSG:3857');
        source.clear();
        source.addFeature(new Feature(multipolygon));

        // Rebind area/confirm tooltip (bound separately)
        map.addOverlay(tooltip);
        multipolygon.on('change', updateArea);
        updateArea({ target: multipolygon });
        drawCompleted();

        // Pan the view to the uploaded shape
        map.jumpTo({ shape_3857: multipolygon });
      } catch (e) {
        window.alert(
          'Unable to parse the polygon file. Please make sure the file is correctly formatted, in EPSG:4326 coordinates, and contains at least one Polygon or MultiPolygon.'
        );
        console.trace(e);
        Analytics.reportClientError(e);
      }
    };

    // Once the user has confirmed the shape
    const onConfirmShape = () => {
      const geometry = source.getFeatures()[0].getGeometry();

      if (props.mustContainPoint?.length >= 2 && checkIntersection(geometry) === false)
        return alert(`Your shape must encompass the Asset. Please draw a shape that contains the asset.`);

      props.onShapeConfirmed?.(geometry);
      stopDrawing();
    };

    const checkIntersection = geometry => {
      const transformedPoint = transform(props.mustContainPoint, 'EPSG:4326', 'EPSG:3857');
      return geometry.intersectsCoordinate(transformedPoint);
    };

    // Don't forget to remove all the things we just bound to the map
    const stopDrawing = () => {
      map.removeInteraction(drawInteraction);
      map.removeInteraction(snapInteraction);
      map.removeInteraction(modifyInteraction);
      map.removeLayer(vectorLayer);
      map.removeOverlay(tooltip);
      document.querySelector('.ol-viewport').style.cursor = null;

      setOnUnmount({});
      setStage(null);
    };

    // And to do so on component unmount too
    setOnUnmount({ handler: stopDrawing });

    // Expose handler to parent scope
    setUploadHandler({ uploadHandler });
  }

  return stage === null ? (
    <></>
  ) : (
    <div>
      <div
        className="hint__message"
        style={{
          backgroundImage: 'url(/icons/map.svg)',
          paddingLeft: 50,
        }}
      >
        {stage === 'drawing'
          ? props.customDrawingMessage
            ? props.customDrawingMessage
            : props.measure === 'area'
            ? 'Draw a complete path around the area that you want to select. Double-click to complete.'
            : props.measure === 'length'
            ? 'Draw a path by adding points to measure its length. Double-click to complete.'
            : ''
          : stage === 'adjusting'
          ? props.customAdjustingMessage
            ? props.customAdjustingMessage
            : props.measure === 'area'
            ? 'You can adjust the selected area by pulling on corners and edges. Click confirm once you are done.'
            : props.measure === 'length'
            ? 'You can adjust your path by pulling on corners and edges. Click confirm once you are done.'
            : ''
          : ''}
      </div>
      {
        props.measure === 'area' &&
        stage === 'drawing' && (
          <div
            className="hint__message"
            style={{
              backgroundImage: 'url(/icons/upload.svg)',
              paddingLeft: 50,
              top: 'auto',
              bottom: 50,
            }}
          >
            <br />
            Or upload a file defining the polygon here (.geojson).
            <br />
            <br />
            <input
              type="file"
              autoComplete="off"
              tabIndex="-1"
              accept=".geojson"
              onChange={e => uploadHandler.uploadHandler(Array.from(e.target.files))}
            />
          </div>
        )}
    </div>
  );
};