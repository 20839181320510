import React, { memo, useEffect } from 'react';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import ContactSupportTab from './ContactSupportTab';
import OptionBarItemContainer from 'src/Components/Common/OptionBarItemContainer';
import Tab from 'src/Components/Common/Tab';
import FAQTab from './FAQTab';
import './Help.css';
import { useStore } from 'src/store';

Tab.tabsRole = 'Tab';

function Help({ onClickClose }) {
  const store = useStore();
  const menuOpen = store.ui.menuOpen;

  useEffect(() => {
    if (!menuOpen) {
      //this might seem stupid but it's getting the value before the change
      onClickClose();
    }
  }, [menuOpen]);

  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        onClickClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <OptionBarItemContainer
      title="Help"
      icon="/icons/menu/question.svg"
      isIconShowed={true}
      onClickBack={onClickClose}
      isArrowButtonShowed={true}
    >
      <Tabs className="help_main_container">
        <TabList className="help_tab_list">
          <Tab tabValue="Contact Support" />
          <Tab tabValue="FAQ" />
        </TabList>
        <TabPanel>
          <ContactSupportTab onClickClose={onClickClose} />
        </TabPanel>
        <TabPanel>
          <FAQTab />
        </TabPanel>
      </Tabs>
    </OptionBarItemContainer>
  );
}

export default memo(Help);
