import React, { memo } from 'react';
import Modal from 'react-modal';
import './BaseModal.css';
import BaseButton from 'src/Common/Buttons/BaseButton';

function BaseModal({
  children,
  rightButton,
  leftButton,
  onClickCancel,
  onClickRightButton,
  title,
  overrideClassName,
  hideButtons,
  hideRightButton,
  rightButtonStyle,
}) {
  return (
    <Modal
      style={{overlay: { zIndex: 1000 }}}
      className={overrideClassName || 'base_modal'}
      isOpen
      ariaHideApp={false}
    >
      <div className="base_modal_title">{title}</div>
      <div className="base_modal_children_container">{children}</div>
      {!hideButtons && (
        <div className="base_modal_buttons_container">
          <BaseButton buttonType={'SECONDARY'} onClick={onClickCancel}>
            {leftButton || 'Cancel'}
          </BaseButton>
          {!hideRightButton &&<BaseButton buttonType={rightButtonStyle || 'DELETE'} onClick={onClickRightButton}>
            {rightButton}
          </BaseButton>}
        </div>
      )}
    </Modal>
  );
}

export default memo(BaseModal);
