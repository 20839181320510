import React, { memo } from 'react';
import './ImageButton.css';

function ImageButton({ className, onClick, containerClassName, ...rest }) {
  return (
    <div
      className={`image_button_container ${containerClassName && containerClassName}`}
      onClick={onClick}
    >
      <div
        style={{ backgroundImage: `url(${rest.src})`, backgroundSize: 'contain' }}
        className={`image_button ${className && className}`}
        {...rest}
      />
    </div>
  );
}

export default memo(ImageButton);
