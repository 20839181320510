import React, { memo } from 'react';
import { Tab as ReactTab } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './Tab.css';

function Tab({ tabValue, className, icon, ...otherProps }) {
  const tabClassName = `tab ${className || ''}`;

  return (
    <ReactTab {...otherProps} className={tabClassName} selectedClassName="selected_tab">
      <div style={{ display: 'flex' }}>
        {icon && <img src={icon} alt="Cross Icon" />}
        {tabValue}
      </div>
      <div className="selected_tab_border" />
    </ReactTab>
  );
}

export default memo(Tab);
