import React, { memo } from 'react';
import downloadIcon from 'public/icons/download.svg';
import deleteIcon from 'public/icons/delete_icon.svg';
import ImageButton from 'src/Components/Common/Buttons/ImageButton';
import './UploadDate.css';

function UploadDate({ date, onClickDelete, onClickDownload }) {
  return (
    <div className="nature_reporting_progress_main_container">
      <span className="files_tab_panel_upload_date_text">{date}</span>
      <ImageButton
        src={downloadIcon}
        alt="download icon"
        className="files_tab_panel_upload_date_image_button"
        onClick={onClickDownload}
      />
      <ImageButton
        src={deleteIcon}
        alt="deleteIcon icon"
        className="files_tab_panel_upload_date_image_button"
        onClick={onClickDelete}
      />
    </div>
  );
}

export default memo(UploadDate);
