import React, { memo, useEffect, useState } from 'react';
import { useStore } from 'src/store';
import ProgressBar from 'src/Common/ProgressBar';
import { NATURE_TRENDS_CHART_OPTION, FOREST_TREND_CHART_OPTION } from 'src/Assets/constants';
import Widget from 'src/Assets/Widget';
import TooltipsWidget from 'src/Assets/TooltipsWidget';
import SankeyDiagram from 'src/Assets/SankeyDiagram';
import BubbleChart from 'src/Assets/BubbleChart';
import NatureTrendsChart from 'src/Assets/NatureTrendsChart';
import './TeamDashboardTabPanel.css';
import LoadingScreen from 'src/LoadingScreen';
import utils from 'src/utils';
import InfoHover from 'src/Common/InfoHover';
import Analytics from 'src/Analytics';

function TeamDashboardTabPanel({ assets, teamExtraData, onClickDetails }) {
  const store = useStore();
  const isFullMode = store.ui.menuFull;
  const totalProgress =
    assets.length > 0 ? assets.map(a => a.progress).reduce((a, b) => a + b) / assets.length : 0;
  const [metrics, setTeamMetrics] = useState(null);

  useEffect(() => {Analytics.trackClick('assets-tab-team-dashboard');}, [])

  const ecoHealthLink = "https://docs.google.com/document/d/1x-AiVumhryWKQiEkXGTqkFRYIbOKYsahz2eEXeKT9KM/edit#heading=h.jn74v4zb5iyy"
  const forestLossLink = "https://docs.google.com/document/d/1x-AiVumhryWKQiEkXGTqkFRYIbOKYsahz2eEXeKT9KM/edit#heading=h.dcali7ubvujl"

  const handleTeamDashboard = async () => {
    try {
      setTeamMetrics(store.data.teamMetrics);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleTeamDashboard();
  }, [store.data.teamMetrics]);

  if (!store.data.teamMetrics && store.data.teamMetrics != -1) {
    return (
      <div style={{ marginTop: '25%' }}>
        <LoadingScreen />
      </div>
    );
  }

  return (
    <>
      <div className={`nature-dashboard-progress-bar`}>
        <h2>Total Progress: {Math.round(totalProgress * 100)}%</h2>
        <ProgressBar progress={totalProgress * 100} height="10px" bgColor={'white'} />
      </div>

      <div className={`nature-dashboard-team ${isFullMode ? 'full' : ''}`}>
        {store.data?.teamMetrics?.land_classes && (
          <div className="sankey-diagram" style={{ gridArea: 'SD' }}>
            <h2>Land Cover Change (2000-2020)</h2>
            <SankeyDiagram data={store.data?.teamMetrics?.land_classes[0]} isFullMode={isFullMode} />
          </div>
        )}

        {assets && (
          <div className="bubble-chart" style={{ gridArea: 'BC' }}>
            <h2>Bubble Size: Business Importance <InfoHover text={"Business Importance"} /></h2>
            <BubbleChart assets={assets} isFullMode={isFullMode} onClickDetails={onClickDetails} />
          </div>
        )}

        {teamExtraData?.nature_trend && (
          <div className="trend-plot" style={{ gridArea: 'NT' }}>
            <h2>Ecosystem Health Index <InfoHover href={ecoHealthLink} text={"Click for more info"}/></h2>
            <NatureTrendsChart
              data={teamExtraData.nature_trend}
              baseOptions={NATURE_TRENDS_CHART_OPTION}
              trendLines
            />
          </div>
        )}

        {teamExtraData?.forest_loss && (
          <div className="trend-plot" style={{ gridArea: 'FT' }}>
            <h2>Total Forest loss per year (GFW) <InfoHover href={forestLossLink} text={"Click for more info"}/></h2>
            <NatureTrendsChart
              data={teamExtraData.forest_loss?.map(p => [p[0], p[1] * 0.09])} // 1 pixel is 30x30m which is 0.09ha
              baseOptions={FOREST_TREND_CHART_OPTION}
            />
          </div>
        )}

        <div className="red-list" style={{ gridArea: 'RL' }}>
          {metrics && <TooltipsWidget tooltipData={metrics} isTeam />}
        </div>

        {Object.entries({
          'Total Assets': assets ? assets.length.toString() : '0',
          'Assets overlapping a KBA': metrics != -1 ? metrics?.kba_metrics.num_overlapping_assets.toString() : "N/A",
          'Assets overlapping a WDPA':  metrics != -1 ? metrics?.wdpa_metrics.num_overlapping_assets.toString() : "N/A",
          'Total size of area influence': utils.formatHumanReadable(metrics?.total_aoi_area/1e6, 'km²'),
          'Overlap with \n Key Biodiversity Areas ': metrics != -1 ? (metrics?.kba_metrics.percent_overlap * 100 || 0).toFixed(1) + " %" : "N/A",
          'Overlap with \n World Database on Protected Areas ': metrics != -1 ? (metrics?.wdpa_metrics.percent_overlap * 100 || 0).toFixed(1) + " %" : "N/A",
          'Total Water Abstraction': utils.formatHumanReadable(metrics?.water_usage_metrics?.total_abstraction_amount || 0, 'm³'),
          'Total Water Discharge':  utils.formatHumanReadable(metrics?.water_usage_metrics?.total_discharge_amount || 0, 'm³'),
          'Total Energy Usage': utils.formatMetricUnit(metrics?.energy_usage_metrics?.total_amount || 0, 'Wh'),
          'Total Forest Loss (GFW)': utils.formatHumanReadable(teamExtraData?.forest_loss?.map(a=>a[1]).reduce((a,b)=>a+b,0)*0.09 || 0, 'ha'), // summed up graph, 1 pixel is 30x30m which is 0.09ha
          //'Spotted Species': metrics.spotted_species || 0,
        }).map(([k, v], i) => (
          <Widget key={i} amount={v} description={k} style={{ gridArea: 'NN' }} />
        ))}
      </div>
    </>
  );
}

export default memo(TeamDashboardTabPanel);
