import React, { useState, useEffect } from 'react';
import './SatelliteImagery.less';

import OptionBarItemContainer from 'src/Components/Common/OptionBarItemContainer';

import MeasureTool from 'src/Components/MeasureTool';
import SatelliteImageryForm from './SatelliteImageryForm';
import { MultiPolygon } from 'ol/geom';

import { useStore } from 'src/store';
import { transformExtent } from 'ol/proj';

function SatelliteImagery({ refreshDatasets, refreshLayers, onClickClose }) {
  const store = useStore();
  const map = store.ui.map;
  const [shape, setShape] = useState(null);

  const menuOpen = store.ui.menuOpen;
  const [showMeasureTool, setShowMeasureTool] = useState(true);

  const onShapeConfirmed = shape => {
    setShape(new MultiPolygon(shape.clone().transform('EPSG:3857', 'EPSG:4326').getCoordinates()));
    setShowMeasureTool(false);
  };

  useEffect(() => {
    if (!menuOpen) onClickClose();
  }, [menuOpen]);

  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        onClickClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <OptionBarItemContainer
      title="Satellite Imagery"
      onClickBack={onClickClose}
      isExpandButtonShowed
      icon="/icons/menu/create_data.svg"
      isIconShowed={true}
      isShowingChannel={true}
      isArrowButtonShowed={true}
    >
      {showMeasureTool && (
        <MeasureTool map={map.state.olmap} measure="area" onShapeConfirmed={onShapeConfirmed} />
      )}
      <SatelliteImageryForm
        shape={shape}
        onComplete={async shape => {
          await refreshDatasets();
          await refreshLayers();

          onClickClose();
          store.ui.menuOpen = false;

          let transformedExtent = transformExtent(shape.extent_, 'EPSG:4326', 'EPSG:3857');

          if (transformedExtent)
            setTimeout(() => store.ui.map.state.olmap.jumpTo({ extent_3857: transformedExtent }), 500);
        }}
      />
    </OptionBarItemContainer>
  );
}

export default SatelliteImagery;
