import React, { memo, useCallback, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import crossIcon from 'public/icons/cross.svg';
import arrowIcon from 'public/icons/arrow.svg';
import editIcon from 'public/icons/edit.svg';
import { TH_EXPANDED_WIDTH_USERS_SETTINGS_TAB_PANEL_TABLE } from 'src/shared/constants';
import ImageButton from 'src/Components/Common/Buttons/ImageButton';
import User from './User';
import DeleteUserModal from './DeleteUserModal';
import './Table.css';
import { useStore } from 'src/store';
import api from 'src/api';
import BaseModal from 'src/Components/Common/Modal/BaseModal';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';

function Table({ data, refreshTeam }) {
  const [isDeleteUserModalShowed, setIsDeleteUserModalShowed] = useState(false);
  const [isEditUserModalShowed, setIsEditUserModalShowed] = useState(false);
  const [userEmailDelete, setUserEmailDelete] = useState('');
  const [userEmailEdit, setUserEmailEdit] = useState('');
  const store = useStore();
  const currentChannel = store.session.channel;
  const isFullMode = store.ui.menuFull;

  const handleShowDeleteUserModal = useCallback(name => {
    setUserEmailDelete(name);
    setIsDeleteUserModalShowed(true);
  }, []);

  const handleHideDeleteUserModal = useCallback(() => {
    setIsDeleteUserModalShowed(false);
  }, []);

  async function handleDeleteUser() {
    console.log('channel ' + currentChannel.channel_id);
    const success = await api.call('/user/remove', { user_email: userEmailDelete });
    console.log(success);
    setIsDeleteUserModalShowed(false);
    refreshTeam();
  }

  async function handleEditUser(email) {
    setIsEditUserModalShowed(true);
    setUserEmailEdit(email);
  }

  async function changePermission(newPermission) {
    const res = await api.call('/user/updateRole', {
      user_email: userEmailEdit,
      role: newPermission,
    });
    console.log(res);
    refreshTeam();
    setIsEditUserModalShowed(false);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'col1',
        sortType: 'alphanumeric',
        Cell: ({ row }) => <User userName={row.original.col1} email={row.original.col2} />,
      },
      {
        Header: 'E-mail',
        accessor: 'col2',
        sortType: 'alphanumeric',
      },
      {
        Header: 'Role',
        accessor: 'col3',
      },
      {
        Header: '',
        accessor: 'col4',
        Cell: ({ row }) => {
          const handleDelete = () => {
            handleShowDeleteUserModal(row.original.col2);
          };

          return (
            <>
              {['owner', 'admin'].includes(store.session.user.role) ? (
                <div className="channels_action_buttons">
                  <ImageButton
                    src={editIcon}
                    alt="edit icon"
                    className="channels_users_tab_panel_cross_icon"
                    onClick={() => handleEditUser(row.original.col2)}
                  />
                  <ImageButton
                    src={crossIcon}
                    alt="cross icon"
                    className="channels_users_tab_panel_cross_icon"
                    onClick={handleDelete}
                  />
                </div>
              ) : null}
            </>
          );
        },
      },
    ],
    [handleShowDeleteUserModal]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },

    useSortBy
  );

  return (
    <>
      {isDeleteUserModalShowed && (
        <DeleteUserModal
          onClickCancel={handleHideDeleteUserModal}
          onClickRightButton={handleDeleteUser}
          userEmail={userEmailDelete}
          channelName="Organic.Team Channel"
          title="Removing User"
        />
      )}

      {isEditUserModalShowed && (
        <BaseModal
          title="Change Permission"
          overrideClassName="permission_modal"
          onClickCancel={() => setIsEditUserModalShowed(false)}
          onClickRightButton={handleDeleteUser}
          //userEmail={userEmailDelete}
          //channelName="Organic.Team Channel"
          hideButtons
        >
          <h2 style={{ display: 'flex' }}>
            <BaseButton
              buttonType={'SECONDARY'}
              additionalClassName={'users_permission_button'}
              onClick={() => changePermission('user')}
            >
              View Only
            </BaseButton>
            <BaseButton
              buttonType={'SECONDARY'}
              additionalClassName={'users_permission_button'}
              onClick={() => changePermission('admin')}
            >
              Admin
            </BaseButton>
            <BaseButton
              buttonType={'SECONDARY'}
              additionalClassName={'users_permission_button'}
              onClick={() => changePermission('owner')}
            >
              Owner
            </BaseButton>
            <BaseButton
              buttonType={'DELETE'}
              additionalClassName={'users_permission_button'}
              onClick={() => setIsEditUserModalShowed(false)}
            >
              Cancel
            </BaseButton>
          </h2>
        </BaseModal>
      )}

      <div
        className={`channels_users_tab_panel_table_container ${
          store.ui.menuFull && 'channels_users_tab_panel_table_container_full_mode'
        }`}
      >
        <table className="channels_users_tab_panel_table" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => {
              const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

              return (
                <tr key={key} {...restHeaderGroupProps}>
                  {headerGroup.headers.map(column => {
                    const { key, ...restHeaderProps } = column.getHeaderProps(
                      column.getSortByToggleProps()
                    );

                    return (
                      <th
                        key={key}
                        {...restHeaderProps}
                        style={{
                          width: isFullMode
                            ? TH_EXPANDED_WIDTH_USERS_SETTINGS_TAB_PANEL_TABLE[key]
                            : TH_EXPANDED_WIDTH_USERS_SETTINGS_TAB_PANEL_TABLE[key],
                        }}
                      >
                        <div className="channels_users_tab_panel_table_th_container">
                          {column.render('Header')}
                          <img
                            src={arrowIcon}
                            className={`channels_users_tab_panel_table_sort_icon ${
                              column.isSorted
                                ? column.isSortedDesc
                                  ? 'channels_users_tab_panel_table_sort_icon_max_min_sort'
                                  : 'channels_users_tab_panel_table_sort_icon_min_max_sort'
                                : 'channels_users_tab_panel_table_sort_icon_no_sort'
                            }`}
                          />
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);

              const { key, ...restRowProps } = row.getRowProps();

              return (
                <tr key={key} {...restRowProps}>
                  {row.cells.map(cell => {
                    const { key, ...restCellProps } = cell.getCellProps();

                    return (
                      <td key={key} {...restCellProps}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default memo(Table);
