import React, { memo, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Search from 'src/Common/Inputs/Search';
import UploadButton from 'src/Common/Buttons/UploadButton';
import TabBaseInfo from 'src/Common/TabBaseInfo';
import Table from './Table';
import './FilesTabPanel.css';
import api from 'src/api';

function FilesTabPanel({ assetDetails, onClickDetails, setTab }) {
  const methods = useForm({ shouldUnregister: true });

  const [isUploadError] = useState(false);
  const [isLoading, setIsLoading] = useState('');

  // Depending on whether we have the "Data" tab enabled in front or not. COuld be solved better but whatever for now
  const indexOfFilesTab = process.env.REACT_APP_EXPERIMENTAL_FEATURES_ENABLED ? 3 : 2;

  const data = React.useMemo(() => assetDetails?.reports || [], [assetDetails]);
  console.log(data);
  const handleUploadFile = useCallback(async files => {
    console.log(files);
    setTab(indexOfFilesTab);
    let i = 0;

    const oversizeFile = [...files].find(f => f.size > 150e6);
    if (oversizeFile) {
      alert(`${oversizeFile.name} exceeds the 150MB size limit`);
      return;
    }

    for (const file of files) {
      i++;
      setIsLoading(i + '/' + files.length + ' uploading...');
      const response = await api.call('/getPresignedAssetFilesUpload', { filename: file.name });
      console.log({ response });
      const signedRequestBundle = response.success;
      if (!signedRequestBundle) throw new Error(`Unable to obtain an upload URL: ${response}`);

      const formFields = new FormData();
      Object.entries(signedRequestBundle.fields).forEach(_ => formFields.append(..._));
      formFields.append('file', file);

      const result = await fetch(signedRequestBundle.url, {
        method: 'POST',
        body: formFields,
      });

      console.log(result);

      const dbResponse = await api.call('/asset/report/create', {
        asset_id: assetDetails.asset_id,
        origin_date: new Date().toISOString(),
        file_location: `s3://${signedRequestBundle.fields.bucket}/${signedRequestBundle.fields.key}`,
        name: file.name,
        report_type: 'asset_file',
        file_size: file.size,
      });

      console.log(dbResponse);
    }
    onClickDetails({ asset_id: assetDetails.asset_id });
    setIsLoading('');
    setTab(indexOfFilesTab);
  }, []);

  const { watch } = methods;

  const searchValue = watch('natureReportingDetailsFilesSearch');

  const onSubmit = data => {
    // TODO: remove console.log
    console.log(data);
  };

  const imageParams = useMemo(
    () => ({
      src: '/icons/cutouts/add_area_details.png',
      alt: 'no files',
      className: 'nature_reporting_details_files_tab_panel_empty_page_image',
    }),
    []
  );

  const uploadButtonParams = useMemo(
    () => ({
      id: 'nature_reporting_details_files_tab_panel_upload_file',
      buttonContent: 'Upload New File',
      additionalClassName:
        'nature_reporting_details_files_tab_panel_upload_button_additional_class_name',
      //accept: '.xlx,.ppt,.pptx,.doc,.docx,.csv,.pdf,.xlsx,.txt,.jpeg,.jpg', // just accept anything for now
      multiple: 'multiple',
      onChange: handleUploadFile,
    }),
    [handleUploadFile]
  );

  if (isLoading !== '') return <div className="files_status">{isLoading}</div>;

  if (!data.length) {
    return (
      <>
        <TabBaseInfo
          title={'You have no files'}
          buttonType={"upload"}
          uploadButtonParams={uploadButtonParams}
          imageParams={imageParams}
        />
        <span
          className="nature_reporting_details_files_tab_panel_upload_description"
          style={{ margin: '10px auto' }}
        >
          Maximum file size is 150 MB
        </span>
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="nature_reporting_details_files_tab_panel_form"
      >
        {data.length ? (
          <>
            <div className="nature_reporting_details_files_tab_panel_search_wrapper">
              <Search placeholder="Search ..." registerName="natureReportingSearch" />
            </div>
            <Table
              data={data}
              globalFilterValue={searchValue}
              refresh={() => onClickDetails({ asset_id: assetDetails.asset_id })}
            />
          </>
        ) : null}
        <div className="nature_reporting_details_files_tab_panel_buttons_main_container">
          <div className="nature_reporting_details_files_tab_panel_buttons_container">
            <div className="nature_reporting_details_files_tab_panel_upload_button_container">
              <UploadButton {...uploadButtonParams} />
            </div>
            <span className="nature_reporting_details_files_tab_panel_upload_description">
              Maximum file size is 150 MB
            </span>
          </div>
          {isUploadError ? (
            <span className="nature_reporting_details_files_tab_panel_upload_button_error">
              Error. Your file didn’t upload. Try again.
            </span>
          ) : null}
        </div>
      </form>
    </FormProvider>
  );
}

export default memo(FilesTabPanel);
