import React, { memo, useEffect, useState } from 'react';
import Table from './Table';
import './AoITabPanel.css';
import { useStore } from 'src/store';
import api from 'src/api';
import BaseButton from 'src/Common/Buttons/BaseButton';
import MeasureTool from 'src/Widgets/MeasureTool';

function AoITabPanel({
  assetDetails,
  mapJumpTo,
  refreshAssets,
  showGenerateAoi,
  setShowGenerateAoi,
  resetAreaWindow,
}) {
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const [successState, setSuccessState] = useState(false);
  const [loadingText, setLoadingText] = useState('Loading');

  const [shape, setShape] = useState(null);
  const [showArea, setShowArea] = useState(false);


  const data = assetDetails.aois && assetDetails.aois.length > 0 ? assetDetails.aois.map(aoi => ({
    col1: aoi.nameOfArea,
    col2: aoi.created_at.split('T')[0],
    col3: (aoi.size / 1_000_000).toFixed(2) + 'Km²',
    col4: aoi.active,
    id: aoi.area_id,
    geog: aoi.geog,
  })) : [];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingText(loadingText => {
        if (loadingText === 'Loading') {
          return 'Loading.';
        } else if (loadingText === 'Loading.') {
          return 'Loading..';
        } else if (loadingText === 'Loading..') {
          return 'Loading...';
        } else {
          return 'Loading';
        }
      });
    }, 300);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  async function GenerateAOI(assetDetails) {
    if (loading) return;
    setLoadingText('Loading');
    try {
      if (successState) {
        setLoading(true);
        const res = await api.call('/asset/aoi_gateway/', {
          asset_id: assetDetails.asset_id,
          water_usage: assetDetails.water_usage,
          point: assetDetails.location,
          get_data: true,
        });
        setSuccessState(false);
        setLoading(false);
        console.log(res);
        refreshAssets(assetDetails);
        store.ui.aoiPreview = null;
        store.ui.map.state.olmap.jumpTo({ geog_4326: res.success.geog });
        return;
      }
      console.log(assetDetails);
      setLoading(true);
      const res = await api.call('/asset/aoi_gateway/', {
        asset_id: assetDetails.asset_id,
        water_usage: assetDetails.water_usage,
        point: assetDetails.location,
        get_data: false,
      });
      console.log(res);
      const tempArray = [];
      tempArray.push(res.success);
      store.ui.aoiPreview = tempArray;
      setSuccessState(true);
      setLoading(false);
      store.ui.map.state.olmap.jumpTo({ geog_4326: res.success.geog });
    } catch (error) {
      setLoading(false);
      setSuccessState(false);
      console.error(error);
    }
  }

  const ConfirmFootprint = async () => {
    const polygon = shape.clone().transform('EPSG:3857', 'EPSG:4326').getCoordinates();
    console.log(polygon);
    const res = await api.call('/asset/area/create', {
      asset_id: assetDetails.asset_id,
      version: 1,
      active: true,
      geog: polygon,
      area_type: 'aoi',
    });
    console.log(res);
    setShowArea(false);
    setShape(null);
    refreshAssets({ asset_id: assetDetails.asset_id });
  };

  useEffect(() => {
    if (shape) {
      ConfirmFootprint();
    }
  }, [shape]);

  return (
    <div
      className="aoi_tab_panel_list_form"
      style={{ marginTop: assetDetails?.aois?.length > 0 ? '15px' : '0' }}
    >
      <div className="area-title">Area of Influence</div>
      <div style={{ left: '236px' }} className="border-preview">
        <div style={{ borderColor: '#CD97E0' }} className="dotted-preview" />
      </div>
      <BaseButton
        additionalClassName={`new-area ${(showGenerateAoi || showArea) ? 'cancel' : ''}`}
        onClick={() => {
          if (!showGenerateAoi) {
            resetAreaWindow();
            setShowGenerateAoi(true);
            setShowArea(false);
          } else {
            setSuccessState(false);
            setShowGenerateAoi(false);
            setShowArea(false);
            store.ui.aoiPreview = null;
          }
        }}
        buttonType={showGenerateAoi ? 'SECONDARY' : undefined}
      >
        {showGenerateAoi ? 'Cancel' : 'Add Area Of Influence'}
        <img
          style={{ visibility: showGenerateAoi ? 'hidden' : 'visible' }}
          width="24px"
          src="/icons/Add.svg"
        />
      </BaseButton>

      <Table
        data={data}
        assetDetails={assetDetails}
        mapJumpTo={mapJumpTo}
        refreshAssets={refreshAssets}
      />

      {showArea && !shape && (
        <MeasureTool
          map={store.ui.map.state.olmap}
          measure="area"
          onShapeConfirmed={setShape}
        />
      )}

      {showGenerateAoi && (
        <div className="aoi_buttons">
          {store.session.experimental_enabled && (
            <BaseButton
              buttonType={successState ? 'STANDARD' : 'STANDARD'}
              onClick={() => {
                setShowArea(true);
                setShowGenerateAoi(false);
              }}
              additionalClassName={'genOI_button'}
            >
              {/*!loading ? (!successState ? 'Manual Aoi' : 'Confirm') : loadingText*/}
              Manual Aoi
            </BaseButton>
          )}

          <BaseButton
            buttonType={successState ? 'STANDARD' : 'STANDARD'}
            onClick={() => GenerateAOI(assetDetails)}
            additionalClassName={'genOI_button'}
          >
            {!loading ? (!successState ? 'Generate Aoi' : 'Confirm') : loadingText}
          </BaseButton>
        </div>
      )}
    </div>
  );
}

export default memo(AoITabPanel);
