import React, { memo, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import deleteIcon from 'public/icons/delete_icon.svg';
import arrowRight from 'public/icons/arrow_right.svg';
import ImageButton from 'src/Components/Common/Buttons/ImageButton';
import Input from 'src/Components/Common/Inputs/Input';
import TextField from 'src/Components/NatureReporting/NatureReportingDetails/TextField';
import TextArea from 'src/Components/Common/Inputs/TextArea';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import { EMAIL_VALIDATION_RULE } from 'src/shared/constants';
import { useStore } from 'src/store';
import ArchiveModal from './ArchiveModal';
import Table from './Table';
import './ChannelsSettingsTabPanel.css';
import api from 'src/api';
import LoadingScreen from 'src/Routes/LoadingScreen';
import BaseModal from 'src/Components/Common/Modal/BaseModal';

function ChannelsSettingsTabPanel({ onClickBack, refreshTeam, refreshChannels }) {
  const [isArchiveModalShowed, setIsArchiveModalShowed] = useState(false);
  const [name, setName] = useState('');
  const [editName, setEditName] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [creationDate, setDate] = useState('');

  const [error, setError] = useState(false);

  const methods = useForm();
  const store = useStore();
  const selectedChannelId = store.session.channel.channel_id;
  const channels = store.data.channels;
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [channel, setChannel] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    channels.forEach(element => {
      if (element.channel_id === selectedChannelId) {
        setChannel(element);
        setName(element.name);
        setDate(element.created_at.split('T')[0]);
      }
    });
  });

  useEffect(() => {
    fetchData();
  }, [selectedChannelId]);

  const fetchData = async () => {
    await refreshTeam();
    try {
      setUsers(null);
      const response = await api.call('/channel/details', { channel_id: selectedChannelId });
      console.log(response.success);
      setUsers(response.success.users);
    } catch (error) {
      console.error(error);
    }
  };

  const data = React.useMemo(() => {
    return users?.filter?.(user =>
      !user.email.endsWith('svarmi.com') ||
      ['svarmi', 'demo', 'processing_tests'].includes(store.session.team.name)
    ).map(user => ({
      name: user.display_name,
      col2: user.email,
      col3: user.user_id,
    }));
  }, [users]);

  const isFullMode = store.ui.menuFull;

  const canInvite = store.session.user.role === 'admin' || store.session.user.role === 'owner';

  const handleSubmitForm = useCallback(
    onSubmit => {
      methods.handleSubmit(onSubmit)();
    },
    [methods]
  );

  const onSubmitInviteFriendForm = useCallback(async data => {
    console.log(data.invitationEmail);
    if (!canInvite) return;
    let success = await api.call('/user/invite', {
      invite_email: data.invitationEmail,
      invite_channel_id: store.session.channel.channel_id,
    });
    console.log(success);
    methods.reset();
    fetchData();
  }, []);

  const handleClickSendInvitation = useCallback(() => {
    handleSubmitForm(onSubmitInviteFriendForm);
  }, [handleSubmitForm, onSubmitInviteFriendForm]);

  async function handleClickSave() {
    if (isEditing) {
      const payload = {
        channel_id: selectedChannelId,
      };

      if (editName !== '') {
        payload.name = editName;
      }

      if (editDescription !== '') {
        payload.description = editDescription;
      }

      setLoading(true);
      const success = await api.call('/channel/update', payload);
      console.log(success);
      await refreshChannels();
      await fetchData();
      setLoading(false);
    }

    setIsEditing(prevIsEditing => !prevIsEditing);
  }

  const handleShowArchiveModal = useCallback(() => {
    setIsArchiveModalShowed(true);
  }, []);

  const handleHideArchiveModal = useCallback(() => {
    setIsArchiveModalShowed(false);
  }, []);

  async function onArchiveChannel() {
    setLoading(true);
    const success = await api.call('/channel/update', {
      channel_id: selectedChannelId,
      active: false,
    });
    setIsArchiveModalShowed(false);
    console.log(success);
    onClickBack();
    await refreshChannels();
    setLoading(false);
  }

  return (
    <>
      {isArchiveModalShowed && (
        <ArchiveModal
          onClickCancel={handleHideArchiveModal}
          onClickRightButton={onArchiveChannel}
          channelName={name}
        />
      )}
      {error && (
        <BaseModal hideButtons title={'You can only invite users that have a Datact account'}>
          <BaseButton
            buttonType={'DELETE'}
            style={{ marginTop: '65px' }}
            onClick={() => setError(false)}
          >
            Close
          </BaseButton>
        </BaseModal>
      )}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <FormProvider {...methods}>
          <form className="channels_settings_tab_panel_form">
            <div className="channels_settings_tab_panel_title_container">
              <ImageButton
                src={arrowRight}
                alt="cross icon"
                className="channels_settings_tab_panel_arrow_icon"
                onClick={onClickBack}
              />
              <h4 className="channels_settings_tab_panel_title">Channels Settings</h4>
            </div>
            <h2 className="channels_settings_tab_panel_main_title">{name}</h2>

            {isEditing && (
              <div className="channels_edit_container">
                <>
                  <TextField
                    textFieldKey="Creation Date"
                    textFieldValue={creationDate}
                    className="channels_settings_tab_panel_text_field channels_settings_tab_panel_mt20"
                  />
                  <Input
                    name="Channel Name"
                    placeholder="Enter your channel name"
                    registerName="channelName"
                    returnValue={setEditName}
                    registerRules={{ required: 'Please fill out this field.' }}
                    setValue={name}
                  />
                  {/* <TextField
                textFieldKey="Channel Owner"
                textFieldValue="Charles Brown"
                className="channels_settings_tab_panel_text_field"
              /> */}
                  <TextArea
                    registerName="channelDescription"
                    name="Description"
                    returnValue={setEditDescription}
                    placeholder="Enter channel description"
                    className="channels_settings_tab_panel_textarea"
                    setValue={channel?.description}
                  />
                </>
              </div>
            )}
            {data ? (
              <Table
                data={data}
                channelName={name}
                refresh={() => fetchData()}
                editPermission={canInvite}
              />
            ) : (
              <div style={{ gridColumn: 'span 2', textAlign: 'center', margin: '30px' }}>
                <span className="spinner" />
              </div>
            )}
            <div
              className={`channels_settings_tab_panel_invitation_main_container ${
                isFullMode && 'channels_settings_tab_panel_invitation_main_container_full_mode'
              }`}
            >
              <div className="channels_settings_tab_panel_invitation_container">
                <Input
                  name="Enter e-mail of new channel member"
                  placeholder="Enter e-mail of new member"
                  registerName="invitationEmail"
                  registerRules={{
                    pattern: {
                      value: EMAIL_VALIDATION_RULE,
                    },
                  }}
                />
                <BaseButton
                  type="button"
                  additionalClassName="channels_settings_tab_panel_invitation_button"
                  onClick={handleClickSendInvitation}
                  disabled={!canInvite}
                >
                  Add New Member
                </BaseButton>
              </div>
              {!canInvite && (
                <span className="channels_settings_tab_panel_invitation_warning">
                  You are not allowed to invite external users. Please contact the administrator
                </span>
              )}
            </div>
            {canInvite && (
              <div className="channels_settings_tab_panel_buttons_main_container">
                <BaseButton
                  type="button"
                  additionalClassName="channels_settings_tab_panel_archive_channel_button"
                  onClick={handleShowArchiveModal}
                >
                  <img
                    src={deleteIcon}
                    alt="archive icon"
                    className="channels_settings_tab_panel_archive_icon"
                  />
                  Archive Channel
                </BaseButton>
                <BaseButton
                  type="button"
                  onClick={handleClickSave}
                  additionalClassName="channels_settings_tab_panel_archive_channel_button channels_settings_tab_panel_save_button"
                >
                  {isEditing ? 'Save' : 'Edit'}
                </BaseButton>
              </div>
            )}
          </form>
        </FormProvider>
      )}
    </>
  );
}

export default memo(ChannelsSettingsTabPanel);
