import React, { memo, useState, useCallback } from 'react';
import './FileInput.css';
import utils from 'src/utils';

import { Controller } from 'react-hook-form';

import { useDropzone } from 'react-dropzone';

function FileInput({ name, registerName, registerRules, control, setValue }) {
  const fileCountLimit = 10000;
  const extraSizeLimit = 1 * Math.pow(2, 30); // 1GB
  const photoSizeLimit = 5 * Math.pow(2, 30); // 5GB
  const totalSizeLimit = 1 * Math.pow(2, 40); // 1TB

  const [photos, setPhotos] = useState([]);
  const [extras, setExtras] = useState([]);
  const [dropzoneOverlays, setDropzoneOverlays] = useState();
  const onDrop = useCallback(
    files => {
      const extraFormats = [
        '.csv',
        '.txt',
        '.nav',
        '.obs',
        '.bin',
        '.mrk',
        '.ubx',
        '.dat',
        '.\\d\\d[opn]',
        '.t\\d\\d',
      ];
      const extraFormatsRegexp = new RegExp(`(\\${extraFormats.join('|\\')})$`, 'i');
      const photoFormats = ['.jpg', '.jpeg'];
      const photoFormatsRegexp = new RegExp(`(\\${photoFormats.join('|\\')})$`, 'i');

      let allPhotos = [...photos, ...extras, ...files];
      // Check for duplicates (same name+size+lastModified) and keep only the first instance
      allPhotos = allPhotos.filter(
        p1 =>
          allPhotos.filter(
            p2 => p1.name === p2.name && p1.size === p2.size && p1.lastModified === p2.lastModified
          )[0] === p1
      );

      // Pick out flight data and GCP data files, to be treated separately
      const allExtras = allPhotos.filter(p => extraFormatsRegexp.test(p.name));

      // Remove everything that's not a JPEG, sort by filename
      allPhotos = allPhotos
        .filter(p => photoFormatsRegexp.test(p.name))
        .sort((a, b) => (a.name > b.name ? 1 : -1));

      // Sample some pictures throughout the list for the overlay
      const samples = [0, 1, 2, 3, 4]
        .slice(0, allPhotos.length)
        .map(i => allPhotos[Math.floor((i / 5) * allPhotos.length)]);
      dropzoneOverlays?.forEach(overlay => window.URL.revokeObjectURL(overlay));
      setDropzoneOverlays(samples.map(f => window.URL.createObjectURL(f)));

      // Done
      setExtras(allExtras);
      setPhotos(allPhotos);
      setValue('images', allPhotos);
      setValue('extras', allExtras);
    },
    [setValue, dropzoneOverlays]
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop,
  });

  return (
    <div className="input_main_container">
      <Controller
        name={name}
        control={control}
        registerName={registerName}
        rules={registerRules}
        // this is very weird and I am not sure why this happens
        // but I need to have value here othervise it throws an error
        // eslint-disable-next-line unused-imports/no-unused-vars
        render={({ field: { value, ...field }, formState: { errors } }) => {
          return (
            <div
              {...getRootProps()}
              className="dropzone"
              style={{ borderColor: errors?.images ? '#E62626' : '#c4c4c4' }}
            >
              <img src="/icons/upload_cloud.svg" className="icon" />
              <div className="dropzone_text_message_container">
                <h3>Drag and drop to upload</h3>
                <p>or</p>
                <h4>Browse files</h4>
                <p className="dropzone_text_message_container_add_opacity">
                  Maximum {fileCountLimit} images, {utils.formatBytes(photoSizeLimit)} per image,{' '}
                  {utils.formatBytes(totalSizeLimit)} total.{' '}
                </p>
                <p className="dropzone_text_message_container_add_opacity">
                  It is possible to add supporting files (.csv, .nav, .T04, ...) up to{' '}
                  {utils.formatBytes(extraSizeLimit)} per file.
                </p>
              </div>
              {!!(photos?.length || extras?.length) && (
                <div className="overlay">
                  {dropzoneOverlays?.map((overlay, i) => (
                    <div key={i} style={{ backgroundImage: `url("${overlay}")` }} />
                  ))}
                  <div className="overlay-counter">
                    {photos.length} images{(extras.length && `, ${extras.length} files`) || ''}
                  </div>
                </div>
              )}{' '}
              {errors && <div className="dropzone_error_message">{errors.images?.message.toString()}</div>}
              <input multiple {...field} {...getInputProps()} id="images" />
            </div>
          );
        }}
      />
    </div>
  );
}

export default memo(FileInput);
