import React from 'react';
import './UploadProgressBar.css';

function UploadProgressBar({ progressbarStarted, progressbarTotal, progressbarDone }) {
  return (
    <div className="progressbar_container" style={{ height: 350 }}>
      <h2>Uploading Data</h2>
      <div className="progressbar">
        <div
          className="progressbar-fill"
          style={{
            width: ((100 * progressbarStarted) / progressbarTotal).toFixed(2) + '%',
          }}
        />
        <div
          className="progressbar-fill"
          style={{
            width: ((100 * progressbarDone) / progressbarTotal).toFixed(2) + '%',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>Uploading images</div>
        <div>
          {progressbarDone}/{progressbarTotal}
        </div>
      </div>
    </div>
  );
}

export default UploadProgressBar;
