import React, { useState } from 'react';
import api from 'src/api';
import utils from 'src/utils';
import UTIF from 'utif';
import MeasureTool from 'src/Widgets/MeasureTool';

import Feature from 'ol/Feature';
import { Polygon, MultiPolygon } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Style } from 'ol/style';

function Inspect(props) {
  const { map } = props;

  const [stage, setStage] = useState(null); // null -> "ready" -> "drawing" -> "rendering" -> "ready"

  // On first render, start user flow
  if (stage === null) {
    setStage('ready');
  }

  // Once shape has been drawn
  async function onShapeConfirmed(shape, percentileCutoff) {
    const extent = shape.getExtent();

    // Add shape cutout overlay
    const insideOutPolygon = new Polygon([props.layer.raster.geom || props.layer.raster.geog]);
    insideOutPolygon.transform('EPSG:4326', 'EPSG:3857');
    insideOutPolygon.appendLinearRing(shape.getPolygon(0).getLinearRing());

    if (window.cutoutLayer) map.removeLayer(window.cutoutLayer);
    window.cutoutLayer = new VectorLayer({
      zIndex: 1,
      style: new Style({ fill: new Fill({ color: '#00000088' }) }),
      source: new VectorSource({
        features: [new Feature(insideOutPolygon)],
      }),
    });
    map.addLayer(window.cutoutLayer);

    setStage('rendering');

    // Fetch the selected area from mapserver
    const src =
      props.layer.viewObject.getSource().urls[0] +
      `&LAYERS=${props.layer.path}` +
      `&SERVICE=WMS` +
      `&VERSION=1.3.0` +
      `&REQUEST=GetMap` +
      `&FORMAT=image/tiff` +
      `&TANSPARENT=true` +
      `&CRS=EPSG:3857` +
      `&STYLES=` +
      `&WIDTH=128` +
      `&HEIGHT=128` +
      `&BBOX=${extent.join(',')}`;

    const res = await fetch(src, {
      method: 'GET',
      mode: 'cors',
      headers: { authorization: await api.token() },
    });

    // Decode the received tiff into float32 temperature values at each pixel
    const tiff_bytes = await res.arrayBuffer();
    const subfiles = UTIF.decode(tiff_bytes);
    UTIF.decodeImage(tiff_bytes, subfiles[0]);
    const buffer = new Float32Array(subfiles[0].data.buffer);
    const width = subfiles[0].width;
    const height = subfiles[0].height;

    // Loop through returned pixels
    let min,
      max,
      pixels = [];
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        // Ignore points outside the shape
        const lng = extent[0] + (x / width) * (extent[2] - extent[0]);
        const lat = extent[3] + (y / height) * (extent[1] - extent[3]);
        const inside = shape.intersectsCoordinate([lng, lat]);
        if (!inside) continue;

        // Ignore empty values
        const value = buffer[x + y * width];
        if (props.type === 'thermal' && (value === -10000 || value === 0)) continue;

        // Update min and max
        if (value < min || min === undefined) min = value;
        if (value > max || max === undefined) max = value;
        if (percentileCutoff) pixels.push(value);
      }
    }

    // For autoscale, take the 5th and 95th percentile as cut-offs, to improve contrast
    // Also uniquify sorted samples to remove long runs of "0"s in differential models
    if (percentileCutoff) {
      pixels = Array.from(new Set(pixels)).sort((a, b) => a - b);
      min = pixels[Math.floor(pixels.length * 0.05)];
      max = pixels[Math.ceil(pixels.length * 0.95)];
    }

    // Reset the ColorBar and thermal/elevation layer to use the new min and max
    await updateColorScale(extent, min, max);
  }

  async function updateColorScale(extent, min, max) {
    setStage('rendering');

    // Global used here cause quick & dirty and I'm lazy
    if (props.type === 'thermal') {
      window.thermalMin = min;
      window.thermalMax = max;
    }
    if (props.type === 'elevation') {
      window.elevationMin = min;
      window.elevationMax = max;
    }

    // Reset thermal layer tiles
    props.layer.viewObject.getSource().clear();

    // Pan map towards selected shape (also triggers rerender and thus layer update and colorbar update)
    map.jumpTo({ extent_3857: extent });

    // Wait until all tiles have finished loading
    while (!window.store.ui.map.state.totalTiles) await new Promise(r => setTimeout(r, 1000));
    while (window.store.ui.map.state.totalTiles) await new Promise(r => setTimeout(r, 1000));

    // Done
    setStage('ready');
  }

  if (stage == 'ready') {
    return (
      <div id="enhance_actions">
        <button onClick={() => setStage('drawing')}>Inspect</button>
        <button
          onClick={() =>
            onShapeConfirmed(
              new MultiPolygon(
                utils.wrapGeog(
                  window.cutoutLayer
                    ? window.cutoutLayer
                        .getSource()
                        .getFeatures()[0]
                        .getGeometry()
                        .getCoordinates()[1]
                    : props.layer.geog_3857
                )
              ),
              true
            )
          }
        >
          Autoscale
        </button>
        <button
          onClick={() => {
            map.removeLayer(window.cutoutLayer);
            window.cutoutLayer = undefined;
            if (props.type === 'thermal') updateColorScale(props.layer.extent_3857, -10, 50);
            else updateColorScale(props.layer.extent_3857, -10, 10);
          }}
        >
          Reset
        </button>
      </div>
    );
  }

  if (stage == 'drawing') {
    return <MeasureTool map={map} measure="area" onShapeConfirmed={onShapeConfirmed} />;
  }

  if (stage == 'rendering') {
    return (
      <div
        className="hint__message"
        style={{
          backgroundImage: 'url(/icons/map.svg)',
          paddingLeft: 50,
        }}
      >
        {props.type === 'thermal' ? 'Thermal' : 'Elevation'} data is being re-rendered, please
        wait...
      </div>
    );
  }
}

export default Inspect;
