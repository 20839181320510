import React, { useState, useEffect } from 'react';
import utils from 'src/utils';

const AssetToolTip = ({ asset, olmap }) => {
  const [tooltipPosition, setTooltipPosition] = useState(null);

  const handleMouseMove = e => {
    if (!olmap) {
      setTooltipPosition([5000, 5000]);
      return;
    }

    try {
      const eventPixel = olmap?.getEventPixel(e);
      if (!Array.isArray(eventPixel) || eventPixel.length < 2) {
        setTooltipPosition([5000, 5000]);
        return;
      }

      const [x, y] = eventPixel;
      setTooltipPosition({ x: x + 20, y: y - 20});
    } catch (e) {
      setTooltipPosition([5000, 5000]);
      return;
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  let isAsset = !!asset.display_name;
  let isWater = !!asset.water_usage_id;
  let isMonitoringArea = asset.area_type == 'monitoring_area';
  let isKBA = !!asset.kba_id;
  let isWDPA = !!asset.wdpa_id;
  let isAoi = asset.area_type == 'aoi';
  let isAssetFootprint = asset.area_type == 'asset_footprint';
  let isDataframe = asset.dataframe_id;

  let labelColor = '#2668A7';
  if (!isAsset && !isWater) labelColor = '#94770E';
  if (isAoi || isMonitoringArea) labelColor = '#0c910a';

  let translatedAction = '';
  if (!isAsset && asset.source) {
    translatedAction = utils.translateAssetActionType(asset.source);
  } else if (isAssetFootprint) {
    labelColor = 'rgb(102, 143, 183)';
    translatedAction = asset.nameOfArea;
  } else if (isAoi) {
    labelColor = 'rgb(205,151,224)';
    translatedAction = asset.nameOfArea;
  } else if (isMonitoringArea) {
    labelColor = '#FAC676';
    translatedAction = asset.nameOfArea;
  } else if (isKBA) {
    labelColor = '#FAC676';
    translatedAction = 'KBA';
  } else if (isWDPA) {
    labelColor = 'rgb(50, 143, 50)';
    translatedAction = 'WDPA';
  } else if (isDataframe) {
    labelColor = 'rgb(4, 4, 4)';
    translatedAction = 'Spreadsheet: ' + asset.name;
  }

  return (
    <div
      className={`container ${tooltipPosition ? 'visible' : ''}`}
      style={{
        left: tooltipPosition?.x,
        top: tooltipPosition?.y,
        borderLeft: '3px solid ' + labelColor,
        position: 'absolute',
      }}
    >
      <div className="tooltip-content">
        <div className="tooltip-title">{translatedAction || asset.display_name}</div>
        <div className="tooltip-description"></div>
      </div>
    </div>
  );
};

export default AssetToolTip;
