import React, { memo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import Link from 'src/Components/Common/Link';
import TextArea from 'src/Components/Common/Inputs/TextArea';
import './ContactSupportTab.css';
import Analytics from 'src/Context/Analytics';
import api from 'src/api';

function ContactSupportTab({ onClickClose }) {
  const methods = useForm();

  const [loading, setLoading] = useState(false);
  const [helpContent, setHelpContent] = useState('');
  const [showHelp, setShowHelp] = useState(false);

  const {
    setError,
    formState: { errors },
  } = methods;

  const onSubmit = async data => {
    const { helpQuestion } = data;

    if (!helpQuestion) return;

    try {
      Analytics.trackClick('help-contact-support-send');
      setLoading(true);
      const response = await api.call('/helpMessage', { comment: helpQuestion });

      if (!response?.info) {
        throw "Couldn't send the help message";
      }
      setShowHelp(true);
      methods.reset(); // This resets the form fields
      setHelpContent('');
    } catch (error) {
      setError('errorMessage', { message: error.toString() });
      onClickClose();
    } finally {
      setLoading(false);
    }
  };

  // TODO do proper loading component
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="help_contact_support_tab_main_container">
          {errors?.errorMessage && (
            <div className="help_contact_error_text">{errors?.errorMessage}</div>
          )}
          <p className="help_contact_support_tab_text">
            For user instructions click{' '}
            <Link
              linkText="here"
              href="https://docs.svarmi.com/"
              target="_blank"
              rel="noreferrer"
            />
            . If you have any questions or suggestions, please write your message in the box below
            or send us an email at{' '}
            <Link linkText="support@svarmi.com" href="mailto:support@svarmi.com" target="_blank" />
          </p>
          <TextArea
            registerName="helpQuestion"
            className="help_contact_support_tab_text_area"
            placeholder="Write your question here..."
            registerRules={{ required: 'Please, write your question.' }}
            returnValue={setHelpContent}
          />
          {showHelp && (
            <p className="help_contact_support_success">
              Message Sent! We will get back to you as quickly as we can
            </p>
          )}
          <BaseButton
            disabled={helpContent == ''}
            additionalClassName="help_contact_support_tab_submit_button"
          >
            Send
          </BaseButton>
        </div>
      </form>
    </FormProvider>
  );
}

export default memo(ContactSupportTab);
