import React, { memo, useState } from 'react';
import './User.css';

function User({ userName, email }) {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="channels_settings_tab_panel_user_main_container">
      <img
        src={
          isLoading
            ? '/user_placeholder.png'
            : `//datact-avatars.s3.eu-west-1.amazonaws.com/users/${encodeURI(email)}`
        }
        onLoad={handleImageLoad}
        onError={e => {
          e.target.onerror = null;
          e.target.src = '/user_placeholder.png';
        }}
        alt="user"
        className={
          isLoading
            ? `channels_settings_tab_panel_user_icon`
            : `channels_settings_tab_panel_user_icon fade-in`
        }
      />
      <span>{userName}</span>
    </div>
  );
}

export default memo(User);
