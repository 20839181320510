import React from 'react';
import Select from 'react-select';
import './SelectPro.less';

const SelectPro = ({
  name,
  placeholder,
  registerName,
  options,
  onChange,
  className,
  value,
  defaultValue,
  noOptionsMessage,
}) => {
  return (
    <div className="select_pro_container">
      <div className="select_header_text_field">{name}</div>
      <Select
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? 'grey' : 'var(--gold)',
            boxShadow: state.isFocused ? null : null,
          }),
        }}
        maxMenuHeight={160}
        name={name}
        placeholder={placeholder}
        registerName={registerName}
        options={options}
        onChange={onChange}
        className={className}
        defaultValue={defaultValue}
        value={value}
        noOptionsMessage={() => noOptionsMessage || 'No options found'}
      />
    </div>
  );
};

export default SelectPro;
