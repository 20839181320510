import React, { memo, useCallback, useState, useEffect } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { DEFAULT_TAB_PANEL, CHANNEL_SETTINGS_TAB_PANEL } from 'src/shared/constants';
import ChannelsListTabPanel from './ChannelsListTabPanel';
import ChannelDataTabPanel from './ChannelDataTabPanel';
import AddPeopleTabPanel from './AddPeopleTabPanel';
import SuccessfulInviteTabPanel from './SuccessfulInviteTabPanel';
import ChannelsSettingsTabPanel from './ChannelsSettingsTabPanel';
import './ChannelsTabPanel.css';
import Analytics from 'src/Context/Analytics';

function ChannelsTabPanel({ channels, refreshTeam, refreshChannels }) {
  const [selectedIndex, setSelectedIndex] = useState(DEFAULT_TAB_PANEL);

  useEffect(() => {Analytics.trackClick('settings-tab-channels');}, [])

  const handleSetSelectedIndex = useCallback(index => {
    setSelectedIndex(index);
  }, []);

  const handleMoveForward = useCallback(() => {
    setSelectedIndex(prev => prev + 1);
  }, []);

  const handleGoToFirstTab = useCallback(() => {
    setSelectedIndex(DEFAULT_TAB_PANEL);
  }, []);

  const handleMoveBack = useCallback(() => {
    setSelectedIndex(prev => prev - 1);
  }, []);

  const handleGoToChannelsSettingsTabPanel = useCallback(() => {
    setSelectedIndex(CHANNEL_SETTINGS_TAB_PANEL);
  }, []);

  return (
    <Tabs
      selectedIndex={selectedIndex}
      onSelect={handleSetSelectedIndex}
      className="channels_tab_panel_main_container"
    >
      <TabList className="channels_tab_panel_tab_list">
        <Tab />
        <Tab />
        <Tab />
        <Tab />
        <Tab />
      </TabList>
      <TabPanel selectedClassName="channels_tab_panel_tab_panel">
        <ChannelsListTabPanel
          joinedChannels={channels}
          onClickAddChannel={handleMoveForward}
          onClickEditChannel={handleGoToChannelsSettingsTabPanel}
          refresh={refreshChannels}
        />
      </TabPanel>
      <TabPanel>
        <ChannelDataTabPanel
          onClickContinue={handleMoveForward}
          onClickBack={handleMoveBack}
          refresh={refreshChannels}
        />
      </TabPanel>
      <TabPanel>
        <AddPeopleTabPanel onClickContinue={handleMoveForward} onClickBack={handleMoveBack} />
      </TabPanel>
      <TabPanel>
        <SuccessfulInviteTabPanel onClick={handleGoToFirstTab} />
      </TabPanel>
      <TabPanel selectedClassName="channels_tab_panel_tab_panel">
        <ChannelsSettingsTabPanel
          onClickBack={handleGoToFirstTab}
          refreshTeam={refreshTeam}
          refreshChannels={refreshChannels}
        />
      </TabPanel>
    </Tabs>
  );
}

export default memo(ChannelsTabPanel);
