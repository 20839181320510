import slugify from 'slugify';

export default {
  formatBytes(bytes) {
    if (!bytes) return '0 bytes';
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const log = Math.floor(Math.log(bytes) / Math.log(1024));
    const n = Math.min(Math.max(0, log), units.length - 1);
    const frac = bytes / Math.pow(1024, n);
    return `${frac.toLocaleString('en-US', { maximumFractionDigits: 2 })} ${units[n]}`;
  },

  formatMetricUnit(value, unit) {
    if (!value) return 'N/A';
    const units = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    const log = Math.floor(Math.log(value) / Math.log(1000));
    const n = Math.min(Math.max(0, log), units.length - 1);
    const frac = value / Math.pow(1000, n);
    return `${frac.toLocaleString('en-US', { maximumFractionDigits: 2 })} ${units[n]}${unit}`;
  },

  formatHumanReadable(value, unit) {
    if (!value) return 'N/A';
    const units = ['', 'k', 'M', 'B', 'T'];
    const log = Math.floor(Math.log(value) / Math.log(1000));
    const n = Math.min(Math.max(0, log), units.length - 1);
    const frac = value / Math.pow(1000, n);
    return `${frac.toLocaleString('en-US', { maximumFractionDigits: 1 })}${units[n]} ${unit}`;
  },

  formatArea(area) {
    return area < 1e5
      ? `${area.toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })} m<sup>2</sup>`
      : `${(area / 1e6).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })} km<sup>2</sup>`;
  },

  formatLength(length) {
    return length < 1e3
      ? `${length.toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })} m`
      : `${(length / 1e3).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })} km`;
  },

  formatDate(date) {
    return date ? date.replace('T', ' ').replace('Z', '') : '';
  },

  sphericalBearingDistance(from, to) {
    const earthRadius_meters = 6371008.8;

    const lon1 = (from[0] * Math.PI) / 180;
    const lon2 = (to[0] * Math.PI) / 180;
    const lat1 = (from[1] * Math.PI) / 180;
    const lat2 = (to[1] * Math.PI) / 180;

    const dy = Math.sin(lon2 - lon1) * Math.cos(lat2);
    const dx =
      Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

    const bearing = ((Math.atan2(dy, dx) / Math.PI) * 180 + 360) % 360;
    const distance = Math.sqrt(dx * dx + dy * dy) * earthRadius_meters;

    return { bearing, distance };
  },

  getZXYPath(zoom, point_4326) {
    const [lon, lat] = point_4326;
    const Z = Math.round(zoom) || 10;
    const N = Math.pow(2, Z);
    const X = (N * (lon + 180)) / 360;
    const Y = N * (0.5 - Math.asinh(Math.tan((lat / 180) * Math.PI)) / (2 * Math.PI));
    const path = `${Z}/${Math.round(X) % N}/${Math.round(Y) % N}`;
    return path;
  },

  slugify(unicodeString) {
    // Convert e.g. "Buðir, Kjósarhreppur, Iceland ≈ 😄" into "budir_kjosarhreppur_iceland"
    return slugify(unicodeString, { replacement: '_', lower: true, strict: true });
  },

  async getGeocodedPlacename(lng, lat) {
    try {
      // Try/caught cause it's just a helpful suggestion and shouldn't crash the UI if empty
      const response = await fetch(
        `https://api.maptiler.com/geocoding/${lng},${lat}.json?key=${process.env.REACT_APP_MAPTILER_TOKEN}`
      );
      const data = await response.json();
      return data.features[0].place_name;
    } catch (e) {
      return '';
    }
  },

  wrapGeog(geog) {
    // Check if three array levels for new multipolygon format, or wrap it up like so if single level old polygon format
    return geog && (geog[0]?.[0]?.length ? geog : [[geog]]);
  },

  capitalize(str) {
    return str?.length ? str[0].toUpperCase() + str.slice(1) : str;
  },

  chunks(array, size) {
    const a = array.slice(), chunked = [];
    while(a.length) chunked.push(a.splice(0, size));
    return chunked;
  },

  generateBarChartData(values, score) {
    return values.reduce(
      (result, item, index) => {
        const isCurrentValue = index + 1 === score;

        const categoryItem = {
          value: item,
          itemStyle: {
            color: isCurrentValue ? '#7c7655' : '#fff',
            borderWidth: Number(!isCurrentValue),
            borderColor: '#7c7655',
          },
        };

        result.category.push(categoryItem);
        result.values.push(index + 1);

        return result;
      },
      {
        category: [],
        values: [],
      }
    );
  },

  translateAssetActionType(type) {
    let translatedType = type;

    switch (type) {
      case 'biomass_oil':
        translatedType = 'Biomass Oil';
        break;
      case 'wind_solar_geothermal':
        translatedType = 'Wind/Solar/Geothermal';
        break;
      case 'natural_gas_nuclear':
        translatedType = 'Natural Gas/Nuclear';
        break;
      case 'coal_and_lignite':
        translatedType = 'Coal/Lignate';
        break;
      case 'hydropower_firewood':
        translatedType = 'Hydropower/Firewood';
        break;
      default:
        translatedType = type;
        break;
    }

    translatedType = this.capitalize(translatedType);
    return translatedType;
  },

  getTopRole(roles) {
    if (roles.includes('owner')) {
      return 'Owner';
    } else if (roles.includes('admin')) {
      return 'Admin';
    } else if (roles.includes('view_only')) {
      return 'User';
    } else {
      return 'null'; // Return null if none of the roles exist
    }
  },

  FindHighestAndLowest(numbers) {
    let tempLow = numbers[0]; // Initialize low with the first element
    let tempHigh = numbers[0];

    for (const number of numbers) {
      if (number < tempLow) {
        tempLow = number;
      } else if (number > tempHigh) {
        tempHigh = number;
      }
    }

    return [tempLow, tempHigh];
  },

  parseLocation(location) {
    if (location == null || location.length < 0) return 'No Location';
    return `[${location[1].toFixed(2)}, ${location[0].toFixed(2)}]`;
  },

  exportGeoJSON(data, filename) {

    const template = {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "geometry": {
            "type": "MultiPolygon",
            "coordinates": data 
          },
          "properties": null
        },
      ]
    };
  
    const jsonString = JSON.stringify(template);

    const blob = new Blob([jsonString], { type: 'application/json' });

    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename + '.geojson' || 'map.geojson';

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  },
};
