import React, { memo, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import utils from 'src/utils';
import './NatureTrendsChart.css';

function NatureTrendsChart({ data, baseOptions, trendLines }) {
  const [low, setLow] = useState(null);
  const [high, setHigh] = useState(null);

  const xAxisData = data.map(d => d[0]);
  const seriesData = data.map(d => d[1]);

  const lenght = 23;

  function calculateTrendlineWithOffset(data, offset) {
    const n = data.length;
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumX2 = 0;

    for (let i = 0; i < n; i++) {
      sumX += i;
      sumY += data[i];
      sumXY += i * data[i];
      sumX2 += i * i;
    }

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX ** 2);
    const intercept = (sumY - slope * sumX) / n;

    const trendlineData = [];
    for (let i = 0; i < n; i++) {
      const x = i;
      const y = slope * x + intercept + offset;
      trendlineData.push(y);
    }

    return trendlineData;
  }

  const trendline = calculateTrendlineWithOffset(seriesData, 0);

  const extraLines = [
    {
      type: 'line',
      showSymbol: false,
      data: Array(lenght).fill(0.8), //HIGH
      silent: true,
      emphasis: {
        disabled: true,
      },
      tooltip: {
        show: false,
        point: false,
      },
      point: {
        show: false,
      },
      color: 'rgba(204, 255, 204, 1)',
    },
    {
      type: 'line',
      showSymbol: false,
      data: Array(lenght).fill(0.4), //MEDIUM
      silent: true,
      emphasis: {
        disabled: true,
      },
      tooltip: {
        show: false,
        point: false,
      },
      point: {
        show: false,
      },
      color: 'rgba(255, 255, 204, 1.0)',
    },
    {
      type: 'line',
      showSymbol: false,
      data: Array(lenght).fill(0.2), //LOW
      silent: true,
      emphasis: {
        disabled: true,
      },
      tooltip: {
        show: false,
        point: false,
      },
      point: {
        show: false,
      },
      color: 'rgba(255, 204, 204, 0.7)',
    },
    {
      type: 'line',
      showSymbol: false,
      data: trendline, //TRENDLINE
      silent: true,
      emphasis: {
        disabled: true,
      },
      tooltip: {
        show: false,
        point: false,
      },
      point: {
        show: false,
      },
      lineStyle: {
        color: 'rgba(0, 100, 0, 0.7)',
        width: 2,
      },
    },
  ];

  let [newLow, newHigh] = [0, 1];
  if (trendLines && !low && !high && seriesData) {
    [newLow, newHigh] = utils.FindHighestAndLowest(seriesData);
    if (newLow) {
      setLow(newLow - 0.05);
      setHigh(newHigh + 0.05);
    } else {
      setLow(0);
      setHigh(1);
    }
  }

  const option = {
    ...baseOptions,
    xAxis: { ...baseOptions.xAxis, data: xAxisData },
    ...(trendLines
      ? {
          yAxis: {
            ...baseOptions.yAxis,
            min: low,
            max: high,
          },
        }
      : {}), // Include yAxis only if trendLines is true
    series: [
      {
        ...baseOptions.series,
        data: seriesData,
      },

      ...(trendLines ? extraLines : []),
    ],
  };

  return <ReactECharts option={option} style={{ height: 130 }} />;
}

export default memo(NatureTrendsChart);
