import React, { useRef, useEffect, useState } from 'react';
import api from 'src/api';
import { useStore } from 'src/store';
import { DataManagerStore } from 'src/DataManager/DataManagerStore';
import './DataBlock.less';

// Custom hook for intersection observer
function useIntersectionObserver(ref, options) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsIntersecting(entry.isIntersecting),
      options
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersecting;
}

function DataBlock({ handleSelectionChange, type, viewModeIsGrid, setSelectedAsset, selectMode, setSelectedDataset, resource, filter, setClickedPin, setNewViewBasedOnId }) {
    const blockRef = useRef(null);

    const store = useStore();
    const dataManagerStore = DataManagerStore();

    const viewType = viewModeIsGrid ? 'grid' : 'list';

    const [uuid, setUuid] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const fullscreen = store.ui.menuFull;

    const isVisible = useIntersectionObserver(blockRef, { threshold: 0.1 });  // Use the hook

    // Reset contents if being asked to display a new resource
    useEffect(() => {
        if(uuid != resource.uuid) {
            setImageUrl(null);
            setUuid(resource.uuid)
        }
    }, [resource.uuid]);

    // Load up imageUrl the first time we appear on screen
    useEffect(() => {
        if (isVisible && !imageUrl) {
            if(resource.pin?.thumbnail_location) {
                setLoading(true);
                api.call(`/getPresignedThumbnailDownload`, { file_location: resource.pin.thumbnail_location })
                    .then(res => setImageUrl(res.url))
                    .catch(error => console.error(error))
                    .finally(() => setLoading(false));
            } else if(resource.pin?.media_urls?.[0] && [null,"image","panorama"].includes(resource.pin?.media_type)) {
                setLoading(true);
                api.call(`/getPresignedPinDownload`, { file_location: resource.pin.media_urls[0] })
                    .then(res => setImageUrl(res.url))
                    .catch(error => console.error(error))
                    .finally(() => setLoading(false));
            } else {
                setLoading(false);
                if(resource.location) {
                    setImageUrl(
                        `https://api.maptiler.com/maps/outdoor/static/`+
                        `${resource.location},8/190x120.png`+
                        `?markers=${resource.location}`+
                        `|icon:https://v1.icons.run/16/material-symbols/stat-0-outline.png|anchor:center`+
                        `&attribution=false`+
                        `&key=${process.env.REACT_APP_MAPTILER_TOKEN}`
                    )
                }
            }
        }
    }, [uuid, isVisible]);

    function cubeFactory() {
        let twoD = resource.info?.optimized_rasters?.length > 0;
        let threeD = resource.info?.optimized_pointclouds?.length > 0;

        if (type === 'Dataset') {
            return (
                threeD ?
                    <div
                        className={'type_block ' + (!threeD ? 'hollow' : 'full')}
                        onClick={(e) => {
                            e.stopPropagation();
                            console.log('3D', resource.info);
                            setNewViewBasedOnId(resource.info.dataset_id, resource.info.pointclouds[0].pointcloud_id, 'Pointcloud', () =>
                                setTimeout(() => {
                                    console.log('Entering 3D');
                                    document.querySelector('.toolbar__container [title="Switch to 3D"]').click();
                                    store.ui.menu.currentTab = null;
                                }, 1000)
                            );
                        }}
                    >
                        3D
                    </div>
                :
                    <div className={'type_block ' + (!twoD ? 'hollow' : '')}>2D</div>
            );
        }
        else if (type === 'Pin') {
            return (
                <div className='type_block yellow'>
                    {(resource.info.length > 0) ?
                        <img width={'11px'} src='/icons/pin.svg' /> :
                        <img width={'14px'} src='/icons/text.svg' />}
                </div>
            );
        }
        else if (type === 'Asset') {
            return (
                <div className='type_block blue'>A</div>
            );
        }
    }

    function handleZoom(e) {
        if (e) e.stopPropagation();
        console.log(location)
        if (type === 'Dataset') {
            store.ui.map.state.olmap.jumpTo({ geog_4326: resource.coordinate });
        } else {
            store.ui.map.state.olmap.jumpTo({ point_3857: resource.coordinate });
        }
    }

    function DetailedView(e) {
        e.stopPropagation();
        clearMapHover();
        switch (type) {
            case 'Dataset':
                setSelectedDataset(resource);
                handleZoom();
                break;
            case 'Pin':
                store.ui.map.setState({clickedPin : resource.pin})
                if (!filter) handleZoom();
                break;
            case 'Asset':
                setSelectedAsset(resource.info);
                store.ui.map.setState({clickedPin : null}); // remember
                break;
        }
    }

    const handleMouseEnter = (id, type) => {
        store.ui.hoveredDataBlock.type = type;
        store.ui.hoveredDataBlock.id = id;
        store.ui.map.setState({dataManagerHover : id}) // Remove this line if state/props changes re-render your component correctly
    };

    const clearMapHover = () => {
        store.ui.map.setState({dataManagerHover : null})
        store.ui.hoveredDataBlock.type = null;
        store.ui.hoveredDataBlock.id = null;
    }

    var isSelected = (selectMode && dataManagerStore.selected.includes(resource.uuid));
    return (
        <div onMouseEnter={() => handleMouseEnter(resource.id, type)} onMouseLeave={() => handleMouseEnter(-1, "foo")} ref={blockRef} key={resource?.uuid} className={'block ' + viewType + (isSelected ? ' selected' : '')} onClick={(e) => !selectMode ? DetailedView(e) : handleSelectionChange(resource.uuid)}>
            <div className={'data_block_title ' + viewType} title={resource.title}>
                {resource.title}
            </div>

            <img hidden={!viewModeIsGrid} loading='lazy' className={'data_block_image pin'} alt='Thumbnail unavailable'
                src={imageUrl || 'data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='}
            />

            {/*type === 'Dataset' && resource.olLayer && <div>
                <input
                    type="range"
                    title="Opacity"
                    defaultValue={Math.round(resource.olLayer.getOpacity() * 100)}
                    onChange={e => resource.olLayer.setOpacity(e.target.value / 100)}
                    onClick={e => e.stopPropagation()}
                  />
            </div>*/}

            {!viewModeIsGrid && <div>{resource.dateReadable}</div>}

            {(!loading) && <img className={'data_block_location ' + viewType} src='/icons/address_icon.svg' onClick={(e) => handleZoom(e)} />}
            {(type == 'Pin' && imageUrl) && <img className={'data_block_fullscreen ' + viewType} src='/icons/toolbar/minimize.svg' onClick={async () => {
                window.open(imageUrl);
            }} />}

            <div className={'type_blocks ' + viewType}>
                {cubeFactory()}
            </div>

            <input type="checkbox" className={`data_block_checkbox ${viewType}`} hidden={!isSelected} checked={true} onChange={() => { }} />
        </div>
    );
}

export default DataBlock;
