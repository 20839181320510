import React, { memo, useCallback, useEffect } from 'react';
import BaseButton from 'src/Common/Buttons/BaseButton';
import './UploadButton.css';

function UploadButton({ id, onChange, buttonContent, additionalClassName, ...rest }) {
  function handleFiles(event) {
    if (!event) {
      return;
    }

    const { target } = event;

    onChange(target.files);
  }

  useEffect(() => {
    const bootInput = document.getElementById(id);
    bootInput.addEventListener('change', handleFiles, false);

    return () => {
      bootInput.removeEventListener('change', handleFiles, false);
    };
  }, []);

  const onClick = useCallback(() => {
    document.getElementById(id).click();
  }, []);

  return (
    <div>
      <BaseButton onClick={onClick} type="button" additionalClassName={additionalClassName}>
        {buttonContent}
      </BaseButton>
      <input id={id} className="upload_input" {...rest} type="file" />
    </div>
  );
}

export default memo(UploadButton);
