/* eslint-disable */
import React from 'react';
import './Policy.css';

function Policy() {
  return (
    <div className="policy__container">
      <h2>
        <a href="https://www.svarmi.com/privacy-cookie-policy" target="_blank" rel="noreferrer">
          PRIVACY POLICY
        </a>
      </h2>
      <h3>DEFINITIONS</h3>
      <p>
        Our privacy policy should be readable and understandable. To ensure this, we would first
        like to explain some of the terms used.
      </p>
      <h3>PERSONAL DATA</h3>
      <p>
        Personal Data is all information about a specific or identifiable natural person. An
        identifiable natural person is a person that can be identified, directly or indirectly, by
        reference to an identifier such as a name, an identification number, location data, an
        online identifier or one or more specific physical, physiological, genetic, mental
        characteristics, economic, cultural, or social identity of this natural person.
      </p>
      <h3>AFFECTED PERSON</h3>
      <p>
        An affected person is any identified or identifiable natural person whose Personal Data is
        processed by the controller.
      </p>
      <h3>PROCESSING</h3>
      <p>
        Processing is any process or set of operations performed on Personal Data or Personal Data,
        whether automated, recorded, organized, structured, stored, adapted, or changed, accessed,
        consulted, used, transmitted, disseminated, or otherwise made available, directed, or
        combined, restricted, deleted or destroyed.
      </p>
      <h3>PROFILING</h3>
      <p>
        Profiling is any form of automated processing of Personal Data consisting of the use of
        Personal Data to evaluate certain aspects of a natural person, to analyse, or predict
        aspects of work performance, economic situation, health, personal preferences, interests,
        the reliability, the behaviour, the location or the movements of this natural person.
      </p>
      <h3>ANONYMISATION</h3>
      <p>
        Anonymisation is the process of removing personal identifiers, both direct and indirect,
        that may lead to an individual being identified. Once data is truly anonymised and
        individuals are no longer identifiable, the data will not fall within the scope of the GDPR.
      </p>
      <h3>PSEUDONYMISATION</h3>
      <p>
        Pseudonymisation is the processing of Personal Data in such a way that the Personal Data can
        no longer be assigned to a specific person without the use of additional information,
        provided that such additional information is kept separate and subject to technical and
        organizational measures to ensure that the Personal Data is not assigned to a specific or
        identifiable natural person.
      </p>
      <h3>CONTROLLER</h3>
      <p>
        Responsible for processing is the natural or legal person, public authority, agency, or
        other body which, alone or in concert with others, determines the purposes and means of
        processing Personal Data; where the purposes and means of such processing are determined by
        the Union or Member State law, the controller or the specific criteria for their designation
        may be determined by the Union or Member State law.
      </p>
      <h3>PROCESSOR</h3>
      <p>
        The processor is a natural or legal person, public authority, government agency or other
        body that processes Personal Data on behalf of the controller.
      </p>
      <h3>RECIPIENTS</h3>
      <p>
        Recipient is a natural or legal person, agency, agency, or other entity to which the
        Personal Data is disclosed, whether or not it is a third party. However, authorities which
        may obtain Personal Data under a specific investigation under the Union or national law
        shall not be considered as beneficiaries. The processing of these data by these authorities
        will be in accordance with the applicable data protection rules for the purposes of
        processing.
      </p>
      <h3>THIRD PARTY</h3>
      <p>
        Third parties are natural or legal persons, authorities, agencies, or bodies other than the
        data subject, the controller, the processor, and persons authorized to process Personal Data
        under the direct supervision of the controller or the processor.
      </p>
      <h3>CONSENT</h3>
      <p>
        The consent of the data subject is any free, specific, informed, and unambiguous
        presentation of the data subject’s wishes, by which they declare their consent to the
        processing of the Personal Data concerning them by means of a declaration or a clear
        affirmation.
      </p>

      <h2>GENERAL INFORMATION ABOUT THE COLLECTION AND PROCESSING OF YOUR DATA</h2>
      <h3>SCOPE OF PROCESSING</h3>
      <p>
        Svarmi processes Personal Data only as far as this is necessary to provide a functioning
        website and our content and services.
      </p>
      <p>
        We gather several types of Personal Data from our users, as explained further below, and we
        use this Personal Data for various business, commercial, and research purposes, including:
      </p>
      <ul>
        <li>
          Related to our Services, including the personalization and improvement of our Services,
          allowing our users to set up their own accounts and provide them with products and
          services as requested by them, and to analyse how users use the Services.
        </li>
        <li>To comply with legal obligations under applicable laws.</li>
        <li>In collaboration with our partners; and </li>
        <li>
          In collaboration with our partners, and to process billing information in connection with
          purchases of our products and services.
        </li>
      </ul>
      <p>
        We seek to provide our clients with clear information on how we collect and interact with
        user data and what rights you have in relation to it.
      </p>
      <p>
        This privacy policy applies to all information collected through our website, web
        application and related features or products available therein (Services), and/or any
        related services, sales, marketing, or events.
      </p>
      <p>
        In certain cases, we may also share some Personal Data with third parties, as described
        below.
      </p>
      <p>
        Svarmi does not knowingly collect or process Personal Data from anyone under the age of 18.
        If you are under 18 years of age, please do not attempt to sign up for the Services or send
        any Personal Data about yourself to us. If we learn that we have collected Personal Data
        from a child under age 18, we will delete that information as quickly as possible. If you
        believe that a child under 18 may have provided us Personal Data, please contact us via
        email at <a href="mailto:privacy@svarmi.com">privacy@svarmi.com</a>.
      </p>
      <h3>WHAT INFORMATION DOES SVARMI COLLECT?</h3>
      <p>
        We collect Personal Data directly from you, and automatically from your device and browser
        when you use our Services and have collected Personal Data from these categories of sources.
      </p>
      <p>
        In connection with Svarmi Services, we obtain the following categories of Personal Data:
      </p>
      <ul>
        <li>Name, email address, phone number.</li>
        <li>Demographic information, such as your address.</li>
        <li>
          Internet or other electronic network activity information, including information about
          your IP address and browser when you access our Services.
        </li>
        <li>Geolocation data.</li>
        <li>Commercial information, such as your purchase history; and</li>
        <li>Professional related information, such as job title, education, and employer.</li>
      </ul>
      <p>
        Some information may be required to register with us or to take advantage of some of our
        features. We request and require your explicit consent before processing any Personal Data,
        where required to do so by applicable law.
      </p>
      <p>
        When you use our Services, we seek to automatically receive and record information on our
        server logs from your browser or device, which may include geolocation data, cookie
        information, device identification, IP address, the type of browser and/or device you are
        using to access our Services, and the page or feature you requested.
      </p>
      <p>
        As you use our Services, we may use automatic data collection or tracking technologies to
        collect certain information about your location data, logs, and other communication data and
        the resources that you use (or information that you retrieve) on our Services. This includes
        information about your computer or other device and internet connection, including your IP
        address, operating system, and browser type.
      </p>
      <p>
        We do not collect personal information automatically, but we may tie this information to
        personal information about you that we collect or receive from other third-party sources, or
        you provide to us. The information we collect automatically is statistical data and does not
        include personal information, but we may maintain it or associate it with personal
        information to improve our Services and to deliver a better and more personalized service to
        our users.
      </p>
      <p>
        Svarmi does not process or seek to collect special categories of Personal Data revealing
        political opinions, religious beliefs, ethnic origin, or trade union membership, and the
        processing of genetic or biometric data for the purpose of uniquely identifying an
        individual, health data or data concerning sexual orientation.
      </p>
      <h3>HOW DO WE USE YOUR PERSONAL DATA?</h3>
      <p>
        <strong>We use may use the Personal Information we obtain about you to:</strong>
      </p>
      <ul>
        <li>
          Operate, maintain, enhance, and provide features of the Svarmi Services, to provide
          services and information that you request, to respond to comments and questions and
          otherwise to provide support to users, and to process and deliver entries and rewards in
          connection with promotions that may be offered from time to time by Svarmi.
        </li>
        <li>
          Understand and analyse the usage trends and preferences of our users, to improve the
          Svarmi Services, to evaluate and improve the quality of our products and services, and to
          develop new products, services, features, and functionality (including anonymization of
          Personal Data).
        </li>
        <li>
          Contact you for administrative, informational, and marketing purposes. This may include
          providing customer service or sending communications, including updates on promotions and
          events, relating to products and services offered by us and by third parties we work with.
          Where required under applicable law, we obtain appropriate consent to send you marketing
          communications.
        </li>
        <li>Comply with applicable legal requirements and industry standards and our policies.</li>
        <li>Enforce our Terms of Service.</li>
        <li>Manage our vendor and partner relationships.</li>
        <li>
          Process your information as necessary to consider you for, and assess your suitability
          for, employment opportunities with Svarmi.
        </li>
        <li>
          In addition, we may use information we automatically collect when you use certain Svarmi
          Services to: (i) analyse your preferences in order to personalize or customize the Svarmi
          Services and provide customized advertisements, content, and information; (ii) monitor and
          analyse the effectiveness of the Svarmi Services and third-party marketing activities; and
          (iii) monitor aggregate site usage metrics such as total number of visitors and pages
          viewed.
        </li>
      </ul>
      <p>
        <strong>We may process your Personal Data for the above purposes when:</strong>
      </p>
      <ul>
        <li>
          You consented to the use of your Personal Data. For example, we may seek to obtain your
          consent for our use of cookies and similar technologies, or to send you marketing
          communications. We may use this data to introduce Content for you that we believe might be
          of interest to you, based on your usage patterns. We may also use it to improve the
          Services, where we use data on feature usage to prioritise what to develop and improve
          further.
        </li>
        <li>
          We need your Personal Data to provide you with products and services requested by you, or
          to respond to your inquiries.
        </li>
        <li>The processing is necessary for compliance with a legal obligation.</li>
        <li>
          We, or a third party, have a legitimate interest in using your Personal Data, such as to
          ensure and improve the safety, security, and performance of our products and services, to
          anonymise Personal Data and conduct data analyses.
        </li>
      </ul>
      <h3>CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
      <p>
        Most web browsers and some mobile operating systems and mobile applications include a
        Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference
        not to have data about your online browsing activities monitored and collected. No uniform
        technology standard for recognizing and implementing DNT signals has been finalized. As
        such, we do not currently respond to DNT browser signals or any other mechanism that
        automatically communicates your choice not to be tracked online. If a standard for online
        tracking is adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this Privacy Policy.
      </p>
      <h3>MINORS</h3>
      <p>
        You must be an individual of legal age to use the Services. We do not knowingly collect or
        solicit Personal Data from anyone under the age of 18. If you have not reached the age
        limit, do not use the services, and do not provide us with your personal information.
      </p>
      <p>
        If you are a parent of a child below the age limit and you learn that your child has
        provided Svarmi Personal Data, please contact us at{' '}
        <a href="mailto:privacy@svarmi.com">privacy@svarmi.com</a> and insist on exercising your
        rights of access, correction, cancellation and / or opposition.
      </p>
      <p>
        If you are resident in California and are under 18 years of age and wish to erase publicly
        available content, please contact us at{' '}
        <a href="mailto:privacy@svarmi.com">privacy@svarmi.com.</a>
      </p>
      <h3>LEGAL BASIS OF PROCESSING</h3>
      <p>The legal basis we rely on for processing Personal Data are:</p>
      <ul>
        <li>
          Your consent. As far as we obtain the consent of the data subject, consent serves as the
          legal basis. You can remove your consent at any time. You can do this by contacting{' '}
          <a href="mailto:privacy@svarmi.com">privacy@svarmi.com</a>.
        </li>
        <li>
          We have a contractual obligation. The performance of a contract with Svarmi to which the
          data subject is party or to processing operations required to conduct pre-contractual
          actions.
        </li>
        <li>For compliance with a legal obligation to which Svarmi is subject.</li>
        <li>To protect the vital interests of the data subject or of another natural person.</li>
        <li>
          Processing is necessary for the performance of a task conducted in the public interest or
          in the exercise of official authority vested in Svarmi as the controller.
        </li>
        <li>
          For the purposes of the legitimate interests pursued by Svarmi or by a third party, except
          where such interests are overruled by fundamental rights and freedoms of the data subject
          which require protection of Personal Data, particularly in cases where the data subject is
          a child.
        </li>
      </ul>
      <p>
        Svarmi may communicate with you if you have provided the means to do so and given your
        explicit consent. This includes promotional email offers on behalf of other businesses or
        email you about your use of the Services. If you do not want to receive communications from
        us, please indicate your preference by either utilizing the “Unsubscribe” functionality at
        the bottom of our emails or by sending an unsubscribe request to{' '}
        <a href="mailto:privacy@svarmi.com">privacy@svarmi.com</a>.
      </p>
      <h3>HOW WE SHARE YOUR PERSONAL DATA</h3>
      <p>
        We will not disclose your Personal Data to third parties except as described in this Policy
        or as we disclose to you at the time the information is collected.
      </p>
      <p>
        Svarmi does not rent nor sell your Personal Data to anyone. However, we may share your
        Personal Data from any of the categories we collect with third parties as described below.
        If we use third-party processors, Svarmi will seek to ensure adequate technical and
        organizational measures exist at such third-party processors to safeguard Personal Data. To
        the extent any features or linked websites you visit are not owned or operated by Svarmi, we
        are not responsible for these websites’ content, use, or privacy practices.
      </p>
      <p>
        Svarmi may anonymize your Personal Data and provide non-personally identifiable information
        to our partners. We may also provide Service usage information to our partners, who may use
        such information to understand how often and in what ways people use our Services, so that
        they, too, can provide you with an optimal online experience. However, we never disclose
        information to a partner in a manner that would identify you personally, as an individual.
      </p>
      <h3>AFFECTED BUSINESSES</h3>
      <p>
        In certain situations, businesses, or third-party websites we are affiliated with may sell
        or provide products or services to you through or in connection with the Services (either
        alone or jointly with us). You can recognize when an affiliated business is associated with
        such a transaction or service, and we will share your Personal Data with that affiliated
        business only to the extent that it is related to such transaction or service.
      </p>
      <h3>CONTRACTED REPRESENTATIVEs</h3>
      <p>
        We may hire other companies and people to perform tasks on our behalf and need to share your
        information with them. These include:
      </p>
      <ul>
        <li>
          Third-party entities which process Personal Data on our behalf for IT, system
          administration and hosting, payment processing, marketing, research, analytics, client
          support, data enhancement.
        </li>
        <li>Sponsors or event managers of events for which you register; and</li>
        <li>
          Advisors who provide consultancy, legal, insurance, financial, and accounting services.
        </li>
      </ul>
      <p>
        We may share information about how you use our Services with our partners, such as
        distribution partners who may broker our Services to you.
      </p>
      <h3>BUSINESS TRANSFERS</h3>
      <p>
        We may share or transfer your information in connection with, or during negotiations of, any
        merger, sale of company assets, financing, or acquisition of all or a portion of our
        business to another company. If you have would like to remove your data from our database in
        the event of such transfers, please do so by sending a written request to{' '}
        <a href="mailto:privacy@svarmi.com">privacy@svarmi.com</a>.
      </p>
      <h3>LEGAL OBLIGATIONS</h3>
      <p>
        Svarmi may process your information where we are legally required to do so to comply with
        applicable law, governmental requests, a judicial proceeding, court order, or legal process,
        such as in response to a court order or a subpoena (including in response to public
        authorities to meet national security or law enforcement requirements).
      </p>
      <h3>INTERNATIONAL TRANSFERS</h3>
      <p>
        Svarmi may transfer or disclose Personal Data we collect about you to recipients in
        countries other than your country. These countries may not have the same data protection
        laws as the country in which you initially provided the information. When we transfer or
        disclose your Personal Data to other countries, we will protect that information as
        described in this Privacy Policy.
      </p>
      <p>
        If you are located in the European Economic Area (“EEA”) or in the UK, we comply with
        applicable legal requirements to provide adequate safeguards for the transfer of your
        Personal Data to countries – other than the country where you are located – that are not
        considered by the European Commission and the UK Government to offer an equivalent level of
        protection of privacy to the one applicable in the EEA and the UK. We may transfer Personal
        Data to countries for which adequacy decisions have been issued, use contractual protections
        for the transfer of Personal Information, such as Standard Contractual Clauses, or collect
        your consent. You may contact us at{' '}
        <a href="mailto:privacy@svarmi.com">privacy@svarmi.com</a> to obtain a copy of the
        safeguards we use to transfer Personal Information outside of the EEA and the UK.
      </p>
      <h3>SECURITY</h3>
      <p>
        We maintain appropriate administrative, technical, and physical safeguards to protect
        Personal Data against accidental or unlawful destruction, accidental loss, unauthorized
        alteration, unauthorized disclosure or access, misuse, and any other unlawful form of
        processing of the Personal Data in our possession.
      </p>
      <p>
        We restrict access to Personal Data about you to those employees who need to know that
        information to provide products or services to you.
      </p>
      <p>
        Please be aware that no security measures are perfect or impenetrable and thus we cannot and
        do not guarantee that your information will not be accessed, viewed, disclosed, altered, or
        destroyed by breach of any of our physical, technical, or organizational safeguards, subject
        to requirements under applicable law to ensure or warrant information security.
      </p>
      <h3>DATA RETENTION</h3>
      <p>
        We retain information if it is necessary and relevant for our operations. We take measures
        to delete your Personal Data or keep it in a form that does not permit identifying you when
        this information is no longer necessary for the purposes for which we process it or when you
        request their deletion unless we are required or permitted by law to keep the information
        for a longer period. We will only retain your Personal Data for as long as necessary to
        fulfil the purposes we collected it for, including for the purposes of satisfying any legal,
        accounting, or reporting requirements.
      </p>
      <p>
        To determine the appropriate retention period for Personal Data, we consider the amount,
        nature, and sensitivity of the Personal Data, the potential risk of harm from unauthorised
        use or disclosure of your Personal Data, the purposes for which we process your Personal
        Information and whether we can achieve those purposes through other means, and the
        applicable legal requirements.
      </p>
      <p>
        We may retain Personal Data from closed accounts to comply with the law, prevent fraud,
        collect any fees owed, resolve disputes, troubleshoot problems, assist with any
        investigation, and enforce our Terms and Conditions or other agreements.
      </p>
      <p>
        When we no longer need your Personal Data, we will securely delete or destroy it. We will
        also consider if and how we can minimize over time the Personal Data that we use, and if we
        can anonymize your Personal Data so that it can no longer be associated with you or identify
        you, in which case we may use that information without further notice to you.
      </p>
      <h3>YOUR DATA PROTECTION RIGHTS</h3>
      <p>
        In the settings on your account, you may access, and, in some cases, edit or delete
        information you have provided, including your name and password and email address.
      </p>
      <p>Under data protection law, you also have rights including:</p>
      <ul>
        <li>
          Your right of access - You have the right to ask us for copies of your Personal Data.
        </li>
        <li>
          Your right to rectification - You have the right to ask us to rectify information you
          think is inaccurate. You also have the right to ask us to complete information you think
          is incomplete.
        </li>
        <li>
          Your right to erasure - You have the right to ask us to erase your personal information in
          certain circumstances.
        </li>
        <li>
          Your right to restriction of processing - You have the right to ask us to restrict the
          processing of your information in certain circumstances.
        </li>
        <li>
          Your right to object to processing - You have the right to object to the processing of
          your personal data in certain circumstances.
        </li>
        <li>
          Your right to data portability - You have the right to ask that we transfer the
          information you gave us to another organisation, or to you, in certain circumstances.
        </li>
        <li>You are not required to pay any charge for exercising your rights.</li>
      </ul>
      <p>
        If you wish to exercise your rights. Please contact us at{' '}
        <a href="mailto:privacy@svarmi.com">privacy@svarmi.com</a>
      </p>
      <p>
        Without prejudice to any other administrative or judicial remedy, you have the right to
        complain to the Data Protection Authority in the event of a dispute regarding the processing
        of your Personal Data. However, we hope that you will seek us first with privacy issues so
        we can assist you. If you choose to turn directly to privacy, you can send an email to the
        email address: <a href="mailto:postur@personuvernd.is">postur@personuvernd.is</a>.
      </p>
      <h3>DATA PROTECTION OFFICER</h3>
      <p>
        Svarmi has appointed a Data Protection Officer (DPO) to manage any data requests and oversee
        any data issues or data breaches.
      </p>
      <p>
        Should you require further information concerning this Privacy Policy or any other
        information, it should refer to the DPO by correspondence or email. The DPO will respond to
        the query as soon as possible.
      </p>
      <p>Svarmi ehf, – Data Protection Officer</p>
      <p>
        Hlíðasmári 8 Kópavogur, Iceland
        <br />
        <a href="tel:+3545551338">(+354) 555 1338</a>
        <br />
        email: <a href="mailto:privacy@svarmi.com">privacy@svarmi.com</a>
        <br />
      </p>
      <h3>CHANGES AND UPDATES TO THIS POLICY</h3>
      <p>
        We are constantly trying to improve our Services, and in that pursuit, we may have to amend
        this Privacy Policy accordingly, but we will alert you to changes by updating the Privacy
        Policy webpage, and as otherwise required by law. If you wish to continue using the Services
        after any changes to the Privacy Policy have been posted, we may request and require your
        agreement to the changes.
      </p>
      <h2>COOKIE POLICY</h2>
      <h3>WHAT ARE COOKIES?</h3>
      <h4>Web-Browser-Cookies.</h4>
      <p>
        A web browser cookie is a small text file sent from a website to your computer or mobile
        device where it is stored by your web browser. Web browser cookies may store information
        such as your IP address or other identifier, your browser type, and information about the
        content you display and interact with on the digital services. By storing such information,
        web browser cookies can store your preferences and settings for online services and analyse
        how you use online services.
      </p>
      <h4>Tracking Technologies: Web Beacons / Gifs, Pixels, Page Tags, Script.</h4>
      <p>
        Emails and mobile applications can contain small, transparent image files or lines of code
        to record how you interact with them. This information is used to help website and app
        publishers better analyse and improve their services.
      </p>
      <h3>COOKIES AND TRACKING TECHNOLOGIES USAGE</h3>
      <p>
        When you use our Services, we seek to automatically receive and record information on our
        server logs from your browser or device, which may include geolocation data, cookie
        information, device identification, IP address, the type of browser and/or device you are
        using to access our Services, and the page or feature you requested.
      </p>
      <p>
        Svarmi website works with several types of cookies. A strictly necessary cookie is a type of
        cookie that is used by the website to function properly, without which the site would not
        work. This type of cookie does not collect any personally identifiable information about you
        and does not track your browsing habits. Performance cookies are cookies used specifically
        for gathering data on how visitors use a website to measure the performance of a website.
        Targeting cookies collect user information and use it to build a profile of users' interests
        and then show personalized ads for that specific user. Functionality cookies are cookies
        that help enhance a website's performance and functionality.
      </p>
      <p>
        Essential cookies such as statistical cookies and functionality cookies enable features on
        Svarmi´s website that are a prerequisite for using the website so that they can be used as
        intended and are therefore not required to consent to their use but are based on Svarmi´s
        legitimate interests. Essential cookies are usually first-party session cookies that are
        used exclusively by Svarmi.
      </p>
      <p>
        Session cookies are deleted when a user leaves the website. Persistent cookies, however, are
        saved on a user's computer or device and remember your actions or choices on Svarmi's
        website.
      </p>
      <h4>First-party cookies</h4>
      <p>
        Are not a prerequisite for using Svarmi´s website. They nonetheless play a key role in the
        use and functionality of sites as they facilitate use, for example by prefilled forms and
        forms and remembering settings. First-party cookies only send information about you to
        Svarmi requests your consent to use it.
      </p>
      <p>
        When accessing our website, the user is informed about the use of cookies for analysis
        purposes and his consent to the processing of the personal data used in this context is
        obtained. The purpose of using technically necessary cookies is to facilitate the use of
        websites for users. Some features of our website cannot be offered without the use of
        cookies. The use of the analysis cookies is for the purpose of improving the quality of our
        website and its contents. Through the analysis cookies, we learn how the website is used and
        so we can constantly optimize our offer.
      </p>
      <h4>Third-party cookies</h4>
      <p>
        are due to services purchased by Svarmi from third parties, such as analytics and
        advertising cookies. By doing so, Svarmi can better adapt the sites to users' needs, better
        analyse the use of sites, and create marketing content and ads tailored to specific
        audiences. For example, by looking at:
      </p>
      <ul>
        <li>Number of guests, number of visits from guests, date, and time of visit</li>
        <li>Which pages within websites are viewed and how often</li>
        <li>Type of files downloaded from the websites</li>
        <li>Which device, operating system, or browser type is used for viewing</li>
        <li>Which keywords from search engines refer to the websites</li>
        <li>
          Third-party cookies send information about you to another third-party website such as
          Google, YouTube, or Facebook. These third parties can also place cookies in your web
          browser and in this way access information about your visits to Svarmi´s website and what
          content you are interested in viewing.
        </li>
      </ul>
      <p>
        We may use this data to introduce Content for you that we believe might be of interest to
        you, based on your usage patterns. We may also use it to improve the Services, where we use
        data on feature usage to prioritize what to develop and improve further.
      </p>
      <h3>DURATION OF STORAGE, OBJECTION AND DISPOSAL OPTIONS</h3>
      <p>
        Cookies are stored on the computer of the user and transmitted by this on our side.
        Therefore, as a user, you have full control over the use of cookies. By changing the
        settings in your internet browser, you can disable or restrict the transmission of cookies.
        Already saved cookies can be deleted at any time. This can also be done automatically. If
        cookies are disabled for our website, it may not be possible to use all the functions of the
        website to the full.
      </p>
      <p>
        Please note you may change the preferences on your browser or device to prevent or limit
        your device’s acceptance of cookies, but this may prevent you from taking advantage of some
        of our features. By clicking on third-party website or service, such third party may also
        transmit cookies to you.
      </p>
      <p>
        Our Privacy Policy does not cover the use of cookies on sites operated by third parties, and
        we are not responsible for their privacy policies and practices. Please be aware that
        cookies placed by third parties may continue to track your activities online even after you
        have left our Services.
      </p>
      <p>Effective Date: November 11, 2022.</p>

      <hr />
      <br />

      <h2>
        <a href="https://www.svarmi.com/terms-conditions" target="_blank" rel="noreferrer">
          SVARMI TERMS OF SERVICE
        </a>
      </h2>
      <p>
        By creating an account on any of Svarmi’s Services, you agree to operate within the
        limitations set forth in these Terms of Services.
      </p>
      <h3>YOUR ACCOUNT</h3>
      <p>
        To access Svarmi’s full suite of Services, potential clients and other users need to sign up
        for an account within the relevant Service or Platform Environment. By creating an account
        on any of Svarmi’s Services, you agree to operate within the limitations set forth in these
        Terms of Services.
      </p>
      <p>
        Access to Services is only granted to persons and entities who are allowed to receive such
        services under applicable laws, including but not limited to those of Iceland, Switzerland,
        United Kingdom, and the European Union.
      </p>
      <p>
        You agree to protect and keep your password, API keys, and other account access information
        confidential. You acknowledge and agree that you are responsible for the activity that
        occurs under your Account on any of Svarmi’s Services. Should you suspect or discover any
        password, API key, or other access point has been compromised, contact{' '}
        <a href="mailto:support@svarmi.com">support@svarmi.com</a>.
      </p>
      <p>
        When you use our Services you agree to all of these terms. Your use of our Services is also
        subject to our Privacy &amp; Cookie Policy, which covers how we collect, use, share, and
        store your personal information.
      </p>
      <h3>THE SERVICES</h3>
      <p>Svarmi’s Services include but are not limited to:</p>
      <ul>
        <li>Geospatial or Aerial Data in various formats (Data-as-a-Service);</li>
        <li>Data from ground-based sensors (Data-as-a-Service);</li>
        <li>
          Information derived from Data (Information-as-a-Service), presented in any of Svarmi’s
          Platforms, in any other system through APIs, or in documents, spreadsheets or other form
          of communication;
        </li>
        <li>Access to Svarmi’s Software Platforms (Software as a Service); </li>
        <li>Digital Content, including images, videos, articles; and</li>
        <li>Consulting Services.</li>
      </ul>
      <h3>LIMITED LICENSE</h3>
      <p>
        Access to all Svarmi Services shall be limited and controlled by Svarmi. Your access to the
        Services shall be limited by Svarmi, in its sole discretion. Your access is subject to the
        terms and conditions of this Agreement and your use is limited to solely to fulfil the
        Purpose during the defined Term. Upon successful sign up or written confirmation of any
        Services, Svarmi grants you a limited, non-transferable, non-exclusive, non-sublicensable,
        non-assignable, revocable license to access and use the Services, subject to applicable
        restrictions. These terms are not a license to use the Svarmi name or our logos, trademarks,
        or general branded material.
      </p>
      <h3>PERMISSIBLE USE</h3>
      <p>
        Data and Information Services delivered to you, whether upon purchase or without payment,
        can generally be used in the following manner:
      </p>
      <ul>
        <li>
          To monitor environmental and/or physical assets owned, operated, or otherwise related to
          you or your organization;
        </li>
        <li>
          To collaborate and communicate to sub-contractors, partners, or other confidentiality
          bound parties, provided these parties have been duly notified that they are not to use any
          Data and Information for any other purpose than the collaboration with you, Svarmi’s
          client; and
        </li>
        <li>
          To process further into other formats, generate statistics and reports, and create
          marketing material, provided that adequate mention is ensured as further described under
          Restrictions.
        </li>
      </ul>
      <h3>RESTRICTIONS</h3>
      <h4>General Restrictions</h4>
      <p>
        The use of Svarmi’s Services, including but not limited to the DATACT online platform,
        processing infrastructure, data acquisition infrastructure, and analytics pipeline and
        algorithms, shall only be used for the defined Purpose for each use case. You will not:
      </p>
      <ol>
        <li>
          Change, remove, or obscure any proprietary notices, watermarks or legends included or
          embedded in the Service;
        </li>
        <li>Change or remove copyright or other rights;</li>
        <li>Use the Services in violation of applicable laws or regulations;</li>
        <li>
          Alter, adapt, display, perform, translate, derive new products, or otherwise modify the
          Service;
        </li>
        <li>
          Sublicense, transfer, loan, rent, resell, distribute, or otherwise use the Service for any
          commercial purpose whatsoever, unless explicitly permitted to do so by written agreement
          with Svarmi;
        </li>
        <li>
          Reverse engineer, disassemble, decompile, or otherwise attempt to derive the source code
          for the platform; or
        </li>
        <li>
          Allow any third party to access or use the Service, excluding specific clearance for
          purchased Services and with a written permission thereof.
        </li>
      </ol>
      <h4>Restrictions on the use of Data &amp; Information</h4>
      <p>
        When you receive Spatial Data or Information from Svarmi, no matter the source .whether
        through Svarmi’s software platform or 3<sup>rd</sup> party distributors, as well as any
        derived Products or Services, <strong>you cannot:</strong>
      </p>
      <ol>
        <li>
          Re-sell the Data and/or Information and derived products without a written agreement
          providing specified permission thereof;
        </li>
        <li>
          Deliver Data and/or Information to external parties. Data and Information can be shared
          with employees, sub-contractors, research partners, and business partners. When
          distributing Data and/or Information to permissible parties, the Data/Information origin
          shall be followed with an explanation of the type, purpose, and limitations of
          Data/Information, in addition to a reference as to the correct source of the Data,
          example: “Data from Svarmi, 2021”; and
        </li>
        <li>
          Use the data for marketing purposes without referencing the correct data source, example
          “Data from Svarmi, 2021”, and ensuring that published material is in no violation of these
          Terms of Services and Svarmi’s Privacy &amp; Cookie Policy.
        </li>
      </ol>
      <h3>RIGHTS RESERVED</h3>
      <p>
        Excluding the limited license granted to you as a user of Svarmi’s Services, Svarmi retains
        all right, title, and interest, including all intellectual property rights, in and to its
        Services and all associated properties. All rights not expressly mentioned or granted in
        this Agreement are hereby expressly reserved by Svarmi.
      </p>
      <h3>OWNERSHIP</h3>
      <p>
        Unless specifically agreed otherwise, Svarmi retains general ownership of all its generated
        Services, including but not limited to Software, Data, and Information. Svarmi therefor has
        full authority to sell Services originating from the same dataset to multiple buyers. You as
        a Client can request Exclusive Ownership of Datasets or other Services delivered to you,
        provided the relevant Exclusivity Premium is paid in full, the amount of which will be made
        available to you upon request.
      </p>
      <h3>FEEDBACK</h3>
      <p>
        When you provide Svarmi with any feedback, comments or suggestions (collectively, Feedback)
        about the Services, this Agreement, and, in general, Svarmi, you grant to Svarmi, under any
        right, title or interest you may have in and to such Feedback, a non-exclusive,
        royalty-free, sub-licensable, worldwide, transferable, irrevocable, perpetual license, to
        use that Feedback or to incorporate it into the Services, this Agreement, or otherwise as
        Svarmi sees fit, entirely without obligation of any kind to you.
      </p>
      <h3>
        3<sup>RD</sup> PARTY CONTENT
      </h3>
      <p>
        Svarmi cannot and does not guarantee the quality and general integrity of any and all 3
        <sup>rd</sup> party content made available through Svarmi’s Services.
      </p>
      <h3>TERM &amp; TERMINATION</h3>
      <p>
        This Agreement takes effect once you have signed up to any of Svarmi’s Services and remains
        effective for as long as you have an account to our Services. You may stop using our
        Services at any given time, HOWEVER, all restrictions and guidelines as to any and all
        Services and associated data, documents, or content, shall survive the termination of this
        agreement. Upon termination of this Agreement, the limited licence(s) granted hereunder
        shall immediately terminate, and you shall immediately cease all use of the Services. If
        requested by Svarmi, you shall certify to Svarmi the complete destruction of all Service
        Deliverables, including but not limited to Data, Information, Documents, and generated
        Content.
      </p>
      <h3>CONFIDENTIALITY</h3>
      <p>
        You hereby acknowledge that by using Svarmi’s Services, you will have access to and may also
        obtain from Svarmi confidential information, including but not limited to technology,
        know-how, algorithms, procedures, structure, specifications, documentation, bugs, problem
        reports, analysis information, and other business and technical information, hereinafter
        referred to as Proprietary Information. During and after the term of this Agreement, you
        confirm that you will keep all Proprietary Information strictly confidential and not
        disclose any such information, unless such information becomes part of public domain without
        breach of this Agreement.
      </p>
      <h3>DISCLAIMER</h3>
      <p>
        THE SERVICES ARE PROVIDED AS THEY ARE WITHOUT ANY WARRANTIES OF ANY KIND. SVARMI HEREBY
        DISCLAIMS ALL WARRANTIES EXPRESS OR IMPLIED. YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT
        RELIED ON ANY REPRESENTATIONS OR WARRANTIES IN CONNECTION HEREWITH.
      </p>
      <h3>LIMITED LIABILITY</h3>
      <p>
        SVARMI SHALL NOT BE LIABLE FOR ANY SPECIAL, CONSEQUENTIAL, INDIRECT, INCIDENTAL, OR PUNITIVE
        DAMAGES, INCLUDING WITHOUT LIMITATION LOSS OF GOODWILL, BUSINESS, REVENUE, PROFITS, DATA, OR
        OTHER LOSS ARISING OUT OF OR IN RELATION TO THIS AGREEMENT OR THE SERVICES, EVEN IF SVARMI
        WAS PREVIOUSLY MADE AWARE OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH
        LIABILITY SOUNDS IN CONTRACT, NEGLIGENCE, OR OTHER THEORY OF LIABILITY. IN NO EVENT SHALL
        SVARMI’S TOTAL CUMULATIVE LIABILITY FOR ANY CLAIM UNDER THIS AGREEMENT EXCEED €1.000 EUR.
      </p>
      <h3>ADDITIONAL LICENSES &amp; ASSOCIATED TERMS</h3>
      <p>
        You shall at all times comply with all applicable laws, regulations, and rules. You shall
        ensure that the Services provided by Svarmi, or any derivative information or content are
        not used for any illegal purposes, as defined in relevant legislation of your country of
        residence and/or operation, as well as the applicable legislation of Svarmi.
      </p>
    </div>
  );
}

export default Policy;
