import React, { memo } from 'react';
import BaseButton from 'src/Common/Buttons/BaseButton';
import './CreateChannelWrapper.css';

function CreateChannelWrapper({ onClickContinue, children, onClickBack }) {
  return (
    <div className="create_channel_wrapper_form">
      <h1 className="create_channel_wrapper_title">Create New Channel</h1>
      {children}
      <div className="create_channel_wrapper_base_buttons_container">
        <BaseButton
          buttonType={'SECONDARY'}
          type="button"
          additionalClassName="create_channel_wrapper_base_button"
          onClick={onClickBack}
        >
          Cancel
        </BaseButton>
        <BaseButton
          type="button"
          additionalClassName="create_channel_wrapper_base_button"
          onClick={onClickContinue}
        >
          Create Channel
        </BaseButton>
      </div>
    </div>
  );
}

export default memo(CreateChannelWrapper);
