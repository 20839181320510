import React, { memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Input from 'src/Common/Inputs/Input';
import TextArea from 'src/Common/Inputs/TextArea';
import CreateChannelWrapper from '../CreateChannelWrapper';
import BaseModal from 'src/Common/Modal/BaseModal';
import BaseButton from 'src/Common/Buttons/BaseButton';
import './ChannelDataTabPanel.css';
import api from 'src/api';
import utils from 'src/utils';
import { useStore } from 'src/store';
import LoadingScreen from 'src/LoadingScreen';

function ChannelDataTabPanel({ onClickBack, refresh }) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const methods = useForm();
  const store = useStore();

  const onSubmit = useCallback(async data => {
    try {
      if (store.data.channels.some(c => c.name === data.channelName))
        throw 'A channel with this name already exists';

      if (utils.slugify(data.channelName) != data.channelName) {
        console.log(methods);
        methods.setValue('channelName', utils.slugify(data.channelName));
        throw 'Special characters are not supported in the channel name and have been replaced. Edit or confirm the alternative name and submit again.';
      }

      setLoading(true);
      const res = await api.call('/channel/create', {
        name: data.channelName,
        private: false,
        active: true,
        description: data.channelDescription,
      });

      const joinChan = await api.call('/user/invite', {
        invite_email: store.session.user.email,
        invite_channel_id: res.success.channel_id,
      });

      if (joinChan) await refresh();
      setLoading(false);
      onClickBack();
    } catch (e) {
      setError(e.toString());
      setLoading(false);
    }
  }, []);

  const handleClickContinue = useCallback(() => {
    methods.handleSubmit(onSubmit)();

    const {
      formState: { isValid },
    } = methods;

    if (!isValid) {
      return;
    }
  }, [methods, onSubmit]);

  return isLoading ? (
    <div className="channel_loading">
      <LoadingScreen />
    </div>
  ) : (
    <>
      {error && (
        <BaseModal title={error} hideButtons={true}>
          <BaseButton buttonType={'SECONDARY'} onClick={() => setError(null)}>
            Close
          </BaseButton>
        </BaseModal>
      )}
      <FormProvider {...methods}>
        <form>
          <CreateChannelWrapper onClickContinue={handleClickContinue} onClickBack={onClickBack}>
            <Input
              name="Channel Name*"
              registerName="channelName"
              placeholder="Enter channel name"
              inputWrapperClassName="channel_data_tab_panel_channel_input"
              registerRules={{
                required: 'Please fill out this field.',
                //validate: v => v === utils.slugify(v),
              }}
            />
            <TextArea
              registerName="channelDescription"
              name="Channel Description"
              placeholder="Enter channel description"
              className="channel_data_tab_panel_channel_textarea"
              inputWrapperClassName="channel_data_tab_panel_channel_textarea_wrapper"
            />
          </CreateChannelWrapper>
        </form>
      </FormProvider>
    </>
  );
}

export default memo(ChannelDataTabPanel);
