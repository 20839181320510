import React, { useEffect } from 'react';

import OptionBarItemContainer from 'src/Components/Common/OptionBarItemContainer';

import CreateDataForm from './CreateDataForm';

function CreateData({ onClickClose }) {
  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        onClickClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <OptionBarItemContainer
      title="Processing"
      onClickBack={onClickClose}
      isExpandButtonShowed
      icon="/icons/upload_cloud.svg"
      isIconShowed={true}
      isShowingChannel={true}
      isArrowButtonShowed={true}
    >
      <CreateDataForm />
    </OptionBarItemContainer>
  );
}

export default CreateData;
