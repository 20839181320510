import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import MenuPage from 'src/Common/MenuPage';
import NatureReportingAssets from './NatureReportingAssets';
import NatureReportingDetails from './NatureReportingDetails';
import api from 'src/api';
import LoadingScreen from 'src/LoadingScreen';
import { useStore } from 'src/store';
import Analytics from 'src/Analytics';

function NatureReporting({
  mapJumpTo,
  onClickClose,
  assets,
  teamExtraData,
  setMonAreaAOI,
  selectedAssetFromMap,
  setMonAreaAOIToNull,
  refreshAssets,
  setAssetOverlap,
  setDataframes,
  dataframes,
}) {
  const [screen, setScreen] = useState('natureReportingAssets');
  const [selectedAssetDetails, setSelectedAssetDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [kba, setKba] = useState(null);
  const [wdpa, setWdpa] = useState(null);
  const [editId, setEditId] = useState(-1);
  const [editInfo, setEditInfo] = useState(null);
  const store = useStore();
  const menuOpen = store.ui.menuOpen;

  const handleGoToNatureReportDetails = useCallback(
    async asset => {
      // fetch /getAssetDetails
      try {
        Analytics.trackClick('assets-details');
        store.ui.mapPreviewPin.style = null;
        console.log('fetching asset details');
        setIsLoading(true);
        store.ui.menu.currentTab = 'Assets';
        const res = await api.call(`/asset/details`, {
          asset_id: asset.asset_id,
        });
        console.log(res);
        setEditId(-1); // reset edit mode

        res.success = enumerateAreas(res.success);

        setSelectedAssetDetails(res.success);

        LoadOverlaps(asset.asset_id);
        mapJumpTo(
          res.success?.areas?.length > 0
            ? { geog_4326: res.success.areas[0].geog }
            : { point_4326: res.success.location }
        );

        setMonAreaAOI(res.success);
        setScreen('natureReportingDetails');
        setIsLoading(false);
      } catch (error) {
        console.info(error);
        setIsLoading(false);
      }
    },
    [setMonAreaAOI, mapJumpTo]
  );

  async function fetchEditInfo() {
    if (editId > 0) {
      console.log(editId);
      const res = await api.call(`/asset/details`, {
        asset_id: editId,
      });
      setEditInfo(res.success);
      console.log(res.success);
    }
  }

  useEffect(() => {
    fetchEditInfo();
  }, [editId]);

  function enumerateAreas(data) {
    console.log(data);
    if (data?.areas?.length <= 0) return data;
    data.aois = [];
    data.monitoring_areas = [];
    data.asset_footprints = [];
    data.areas.forEach(area => {
      switch (area.area_type) {
        case 'aoi':
          area.nameOfArea = `AOI-${data.aois.length + 1}`;
          data.aois.push(area);
          break;
        case 'monitoring_area':
          area.nameOfArea = `MA-${data.monitoring_areas.length + 1}`;
          data.monitoring_areas.push(area);
          break;
        case 'asset_footprint':
          area.nameOfArea = `AF-${data.asset_footprints.length + 1}`;
          data.asset_footprints.push(area);
          break;
      }
    });
    return data;
  }

  async function LoadOverlaps(id) {
    const fetchData = async () => {
      try {
        store.ui.assetOverlap.loading = true;

        const [kba, wdpa] = await Promise.all([
          api.call(`/overlap`, { id: id, type: 'kba' }),
          api.call(`/overlap`, { id: id, type: 'wdpa' }),
        ]);

        setKba(kba?.success?.filter(item => item.active == true));
        setWdpa(wdpa?.success);

        store.ui.assetOverlap.loading = false;
        console.log('DONE');
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }

  useEffect(() => {
    let type = store.ui.assetOverlap.currentOverlap;
    if (!store.ui.assetOverlap.currentOverlap) {
      setAssetOverlap(null);
      return;
    }
    if (type === null) {
      setAssetOverlap(null);
      return;
    } else if (type === 'kba') {
      if (kba) {
        setAssetOverlap(kba);
        return;
      }
    } else if (type === 'wdpa') {
      if (wdpa) {
        setAssetOverlap(wdpa);
        return;
      }
    }
  }, [store.ui.assetOverlap.currentOverlap]);

  const handleClickBack = () => {
    setMonAreaAOIToNull();
    setScreen('natureReportingAssets');
    store.ui.mapPreviewPin.style = null;
  };

  const setTab = index => {
    setSelectedTab(index);
  };

  useEffect(() => {
    if (!selectedAssetFromMap) return;
    setScreen('natureReportingDetails');
    handleGoToNatureReportDetails(selectedAssetFromMap);
    setSelectedAssetDetails(selectedAssetFromMap);
  }, [selectedAssetFromMap]);

  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        onClickClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    if (!menuOpen) onClickClose();
  }, [menuOpen]);

  useMemo(() => screen !== 'natureReportingAssets', [screen]);

  return (
    <MenuPage
      isArrowButtonShowed={true}
      title={editId > 0 ? 'Editing Asset' : 'Assets'}
      icon="/icons/menu/assets.svg"
      isIconShowed={true}
      onClickBack={screen === 'natureReportingAssets' ? onClickClose : handleClickBack}
      isExpandButtonShowed
      isShowingChannel
    >
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {screen === 'natureReportingAssets' ? (
            <NatureReportingAssets
              mapJumpTo={mapJumpTo}
              onClickDetails={handleGoToNatureReportDetails}
              assets={assets}
              teamExtraData={teamExtraData}
              refreshAssets={refreshAssets}
              editId={editId}
              setEditId={setEditId}
              editInfo={editInfo}
            />
          ) : (
            <NatureReportingDetails
              assets={assets}
              teamExtraData={teamExtraData}
              asset={selectedAssetDetails}
              mapJumpTo={mapJumpTo}
              onClickDetails={handleGoToNatureReportDetails}
              refreshAssets={refreshAssets}
              setTab={setTab}
              currentTab={selectedTab}
              setEditId={setEditId}
              closeWindow={() => {
                onClickClose();
              }}
              backWindow={() => {
                setMonAreaAOIToNull();
                setScreen('natureReportingAssets');
              }}
              setDataframes={setDataframes}
              dataframes={dataframes}
            />
          )}
        </>
      )}
    </MenuPage>
  );
}

export default memo(NatureReporting);
