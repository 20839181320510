import React, { memo } from 'react';
import ConnectForm from 'src/Common/Inputs/ConnectForm';
import './SimpleInput.css';

function SimpleInput({
  registerName,
  registerRules = {},
  className,
  showError = true,
  inputContainerClassName,
  setValue,
  returnValue,
  disabled,
  ...rest
}) {
  function handleChange(event) {
    const value = event.target.value;
    if (returnValue) returnValue(value);
  }

  return (
    <ConnectForm>
      {({ register, formState: { errors } }) => {
        const error = errors[registerName]?.message;

        return (
          <div
            className={`simple_input_container ${
              inputContainerClassName && inputContainerClassName
            }`}
          >
            <input
              disabled={disabled}
              defaultValue={setValue}
              {...rest}
              className={`simple_input ${className && className}`}
              {...register(registerName, registerRules)}
              onChange={handleChange}
            />
            {error && showError ? <p className="simple_input_error">{error}</p> : null}
          </div>
        );
      }}
    </ConnectForm>
  );
}

export default memo(SimpleInput);
