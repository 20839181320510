import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const tenantDomain = process.env.REACT_APP_AUTH0_TENANT_DOMAIN;
  const customDomain = process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

  const onRedirectCallback = appState => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(customDomain && tenantDomain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={customDomain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: `https://${tenantDomain}/api/v2/`,
        scope: 'openid profile email update:current_user_metadata',
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
