import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import './AreaSelection.less';

function AreaSelection({ changeArea, options, selectedArea }) {

  const [defaultValue, setDefaultValue] = useState(null);

  const handleSelectChange = selectedOption => {
    changeArea(selectedOption);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      'width': 200,
      'boxShadow': 'none',
      'borderColor': state.isFocused ? 'var(--gold)' : 'var(--black-olive)',
      '&:hover': {
        borderColor: state.isFocused ? 'var(--gold)' : 'var(--blue)',
      },
      'display': 'flex',
      'justifyContent': 'center',
      'alignItems': 'center',
      'minHeight': '38px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      justifyContent: 'center',
      padding: '0 8px',
    }),
    option: (provided, state) => ({
      ...provided,
      'backgroundColor': state.isSelected ? 'var(--gold)' : 'transparent',
      '&:hover': {
        backgroundColor: state.isFocused ? 'var(--blue)' : 'var(--gold)',
        color: 'white',
      },
    }),
    menu: provided => ({
      ...provided,
      width: 205,
      borderRadius: 0,
      marginTop: 5,
    }),
  };

  useEffect(() => {
    if (options.length > 0 && selectedArea === null) {
      const findDefaultOption = () => {
        for (let i = options.length - 1; i >= 0; i--) {
          if (options[i]?.options.length) {
            for (let j = options[i].options.length - 1; j >= 0; j--) {
              if (options[i].options[j].active) {
                return options[i].options[j];
              }
            }
          }
        }
      };

      const defaultOption = findDefaultOption();
      if (defaultOption) {
        setDefaultValue(defaultOption);
        handleSelectChange(defaultOption);
      }
    }
  }, []);

  return (
    <div className="area-selection-container">
      <Select
        id="areaSelect"
        onChange={handleSelectChange}
        options={options}
        styles={customStyles}
        placeholder="Select an area"
        value={selectedArea || defaultValue}
        formatOptionLabel={option => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{option.label}</span>
            <span
              style={{
                marginLeft: '10px',
                color: 'white',
                backgroundColor: option.active ? 'green' : 'red',
                padding: '3px 10px',
                borderRadius: '5px',
                fontWeight: 'bold',
                fontSize: '16px',
              }}
            >
              {option.active ? 'Active' : 'Inactive'}
            </span>
          </div>
        )}
      />
    </div>
  );
}

export default AreaSelection;
