import React, { memo, useCallback, useEffect, useState } from 'react';
import search from 'public/icons/search_icon.svg';
import InputWrapper from 'src/Common/Inputs/InputWrapper';
import ConnectForm from 'src/Common/Inputs/ConnectForm';
import './Search.css';

function Search({
  registerName,
  registerRules = {},
  name = '',
  onChange,
  children,
  newValue,
  onBlur,
  inputWrapperClassName,
  ...rest
}) {
  const [value, setValue] = useState('');

  const handleChange = useCallback(
    ({ target }) => {
      setValue(target.value);

      if (onChange && target.value) onChange(target.value);
    },
    [onChange]
  );

  useEffect(() => {
    if (newValue) setValue(newValue);
  }, [newValue]);

  return (
    <ConnectForm>
      {({ register }) => {
        return (
          <InputWrapper name={name} inputWrapperClassName={inputWrapperClassName}>
            <div className="search_main_container">
              <input
                {...rest}
                className="search"
                type="text"
                value={value}
                {...register(registerName, {
                  registerRules,
                  onChange: handleChange,
                  onclick: handleChange,
                  onBlur,
                })}
              />
              <img src={search} alt="search" className="search_icon" />
              {children}
            </div>
          </InputWrapper>
        );
      }}
    </ConnectForm>
  );
}

export default memo(Search);
