import React from 'react';
import { useStore } from 'src/store';
import expandIcon from 'public/icons/expand.svg';
import squeezeIcon from 'public/icons/squeeze.svg';
import arrowIcon from 'public/icons/arrow_right.svg';
import ImageButton from 'src/Common/Buttons/ImageButton';
import './MenuPage.less';

function MenuPage({
  children,
  title,
  isArrowButtonShowed,
  isExpandButtonShowed,
  onClickBack,
  isIconShowed,
  icon,
  isShowingChannel,
}) {
  const store = useStore();
  const isFullMode = store.ui.menuFull;

  /*
  useEffect(() => {
    const [form] = document.getElementsByClassName('option_bar_item_container_main_container');
    setTimeout(() => {
      form.style.width = isFullMode ? '100vw' : '725px';
    }, 0);
  }, [isFullMode]);
  */

  return (
    <div className={'option_bar_item_container_main_container' + (isFullMode ? ' deployed' : '')}>
      <div className="option_bar_item_container_top_container">
        <img
          src="/SVARMI-Logo_Datact-Horiz.svg"
          alt="logo"
          className="option_bar_item_container_logo"
        />
        {isExpandButtonShowed && (
          <ImageButton
            src={isFullMode ? squeezeIcon : expandIcon}
            alt="expand icon"
            className="option_bar_item_container_expand_icon"
            onClick={() => (store.ui.menuFull = !isFullMode)}
          />
        )}
      </div>
      <div
        className="option_bar_item_container_title_container"
        onClick={onClickBack}
        style={{ cursor: 'pointer' }}
      >
        {isArrowButtonShowed && (
          <ImageButton
            src={arrowIcon.slice(1)}
            alt="arrow icon"
            className="option_bar_item_container_arrow_icon"
          />
        )}
        {isIconShowed && <img src={icon} alt="icon" className="option_bar_item_container_icon" />}
        <h2 className="option_bar_item_container_title">{title}</h2>
        {isShowingChannel && (
          <p className="option_bar_item_container_channel">Channel: {store.session.channel.name}</p>
        )}
      </div>
      {children}
    </div>
  );
}

export default MenuPage;
