import React, { useEffect, useState } from 'react';
import starFilledIcon from 'public/icons/star_filled.svg';
import starEmptyIcon from 'public/icons/star_empty.svg';
import ImageButton from 'src/Components/Common/Buttons/ImageButton';
import './NameWithLabel.css';

function NameWithLabel({ name, onNavigateMap, onSelectDetails, asset }) {
  const [isPriority, setIsPriority] = useState(false);

  useEffect(() => {
    setIsPriority(asset.priority);
  }, []);

  /*async function handleSetIsPriority() {
    console.log(isPriority);
    const updatedIsPriority = !isPriority;
    setIsPriority(updatedIsPriority);
    const modifiedAsset = { priority: updatedIsPriority, asset_id: asset.asset_id };
    const res = await api.call('/asset/update', { asset: modifiedAsset });
  }*/

  return (
    <>
      <div className="nature_reporting_name_with_label_main_container">
        <ImageButton
          alt="star"
          data-tooltip-id="label_star_icon_tooltip"
          src={isPriority ? starFilledIcon : starEmptyIcon}
          className="nature_reporting_name_with_label_star_icon"
          /*onClick={handleSetIsPriority}*/
        />
        <span
          className="nature_reporting_name_with_label_name"
          onClick={() => {
            onNavigateMap();
            onSelectDetails();
          }}
        >
          {name}
        </span>
      </div>
    </>
  );
}

export default NameWithLabel;
