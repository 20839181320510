import React, { Fragment, memo, useState } from 'react';
import { useTable, useRowSelect } from 'react-table';
import { useStore } from 'src/store';
import MoreActions from 'src/Components/Common/MoreActions/MoreActions';
import {
  STATUS_TYPES,
  TH_EXPANDED_WIDTH_AOI_TABS_PANEL_TABLE,
  TH_SQUEEZED_WIDTH_AOI_TABS_PANEL_TABLE,
} from 'src/shared/constants/nature_reporting';
import './Table.css';
import Status from 'src/Components/NatureReporting/NatureReportingDetails/Status';
import api from 'src/api';
import utils from 'src/utils';

function Table({ data, refreshAssets, assetDetails }) {
  const store = useStore();
  const isFullMode = store.ui.menuFull;
  const [active, setActive] = useState(false);

  async function toggleActivation(id, active) {
    console.log('Toggling activation for', id);
    const res = await api.call('/area/toggle', { area_id: id, active: active });
    refreshAssets(assetDetails);
  }

  async function handleDeleteButtonClick(id) {
    console.log('handleDeleteButtonClick', id);
    await api.call('/area/delete', { area_id: id });
    refreshAssets(assetDetails);
  }

  function handleEditButtonClick(id) {
    console.log(id);
    if (id == active) {
      setActive(false);
      return;
    }
    setActive(id);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'col1',
      },
      {
        Header: 'Date Created',
        accessor: 'col2',
      },
      {
        Header: 'Area Km²',
        accessor: 'col3',
      },
      {
        Header: 'Status',
        accessor: 'col4',
        Cell: ({ value, row }) => (
          <>
            <div style={{ display: 'flex' }}>
              <Status
                status={value ? STATUS_TYPES.ACTIVE : STATUS_TYPES.INACTIVE}
                className="areas_tab_panel_table_status"
              />
              <MoreActions
                active={row.original.id === active}
                handleClick={e => {
                  handleEditButtonClick(row.original.id);
                }}
                actions={[
                  {
                    func: () => toggleActivation(row.original.id, !value),
                    color: 'var(--generic-text)',
                    iconSrc: '/icons/edit_action.svg',
                    iconAlt: value ? 'De-Activate' : 'Activate',
                    name: value ? 'De-Activate' : 'Activate',
                  },
                  {
                    func: () => handleDeleteButtonClick(row.original.id),
                    color: 'var(--pastel-red)',
                    iconSrc: '/icons/delete_action.svg',
                    iconAlt: 'Delete',
                    name: 'Delete',
                  },
                  {
                    func: () => utils.exportGeoJSON(row.original.geog, row.original.col1),
                    color: 'var(--generic-text)',
                    iconSrc: '/icons/download.svg',
                    iconAlt: 'Download',
                    name: 'Download',
                  },
                ]}
              />
            </div>
          </>
        ),
        disableGlobalFilter: true,
      },
    ],
    [active]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows } =
    useTable(
      {
        columns,
        data,
      },
      useRowSelect
    );

  return (
    <>
      <div className="monitoring_areas_tab_panel_table_container">
        <table className="aoi_tab_panel_table" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => {
              const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

              return (
                <tr key={key} {...restHeaderGroupProps}>
                  {headerGroup.headers.map(column => {
                    const { key, ...restHeaderProps } = column.getHeaderProps();

                    return (
                      <th
                        key={key}
                        {...restHeaderProps}
                        style={{
                          width: isFullMode
                            ? TH_EXPANDED_WIDTH_AOI_TABS_PANEL_TABLE[key]
                            : TH_SQUEEZED_WIDTH_AOI_TABS_PANEL_TABLE[key],
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);

              const historyRecords = row.cells[row.cells.length - 1]?.value?.historyRecords;

              const { key, ...restRowProps } = row.getRowProps();

              const isRowSelected = selectedFlatRows.includes(row);
              const isHistoryBlockShowed = isRowSelected && historyRecords.length;

              return (
                <Fragment key={key}>
                  <tr
                    onMouseOut={() => {store.ui.hoveredOverlay = null}} 
                    {...restRowProps}
                    className={isRowSelected ? 'aoi_tab_panel__row_selected' : ''}
                  >
                    {row.cells.map(cell => {
                      const { key, ...restCellProps } = cell.getCellProps();
                      return (
                        <td
                          key={key}
                          onMouseEnter={() => {
                            if (data[index]) {
                              store.ui.hoveredOverlay = data[index].id.toString();
                            }
                          }}
                          onMouseOut={() => {store.ui.hoveredOverlay = null}}
                          onClick={() => store.ui.map.state.olmap.jumpTo({geog_4326: data[index].geog})}
                          {...restCellProps}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="aoi_tab_panel_table_border" />
    </>
  );
}

export default memo(Table);
