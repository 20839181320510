import { proxy, ref } from 'valtio';
import { useProxy } from 'valtio/react/utils';
export { useStore, ref };

const useStore = () => useProxy(store);
const store = (window.store = proxy({
  ui: {
    loading: false,
    map: null,
    setMapLocation: null,
    menuOpen: false,
    menuFull: false,
    menu: {
      currentTab: null,
    },
    unsavedChanges: false,
    modalParams: {
      name: null,
      onClickRightButton: null,
    },
    sliderOpen: false,
    rangeFilters: {
      raster: {
        selected: [0, 0],
      },
    },
    aoiPreview: null,
    assetOverlap: {
      currentOverlap: null,
      loading: false,
    },
    mapPreviewPin: {
      location: null,
      style: null,
      address: null,
      moveendLocation: null,
    },
    hoveredOverlay: null,
    hoveredDataBlock: { id: null, type: null }, //this is each unit data manager block
    mapserverUrl: null,
  },

  session: {
    token: null,
    user: null,
    team: null,
    role: null,
    channel: {
      channel_id: null,
      name: 'main',
    },
    isExternalUser: null,
    assets_enabled: null,
    experimental_enabled: null,
    wms_config: [],
  },

  data: {
    teams: [],
    users: [],
    channels: [],
    datasets: [],
    pins: [],
    layers: [],
    assets: [],
    allChannels: null,
    teamMetrics: null,
  },
}));
