import React, { memo } from 'react';
import './RowCheckbox.css';

function RowCheckbox({ label, id, register, ...rest }) {
  return (
    <label htmlFor={id} className="checkbox_input_label">
      <input type="checkbox" id={id} {...register} {...rest} className="checkbox_input" />
      {label}
    </label>
  );
}

export default memo(RowCheckbox);
