import React, { memo } from 'react';
import './BaseButton.css';

const ButtonTypes = {
  STANDARD: 'base_button',
  SECONDARY: 'secondary_button',
  DELETE: 'delete_button',
  TERTIARY: 'tertiary_button',
  JUICY: 'juicy_button',
};

function BaseButton({ children, buttonType = 'STANDARD', additionalClassName = '', ...rest }) {
  const buttonClassName = `${ButtonTypes[buttonType]} ${additionalClassName}`;
  return (
    <button className={`${buttonClassName}`} {...rest}>
      {children}
    </button>
  );
}

export default memo(BaseButton);
