import React, { useEffect, useMemo, useRef, useState } from 'react';
import './DataManagerPro.less';
import OptionBarItemContainer from 'src/Components/Common/OptionBarItemContainer';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import SelectPro from 'src/Components/Common/SelectPro/SelectPro';
import DataBlock from './DataBlock/DataBlock';
import { useStore } from 'src/store';
import { transform } from 'ol/proj';
import { containsCoordinate } from 'ol/extent';
import SliderPro from 'src/Components/SliderPro';
import DetailedView from './DetailedView/DetailedView';
import { DataManagerStore } from './DataManagerStore';
import BaseModal from 'src/Components/Common/Modal/BaseModal';
import Analytics from 'src/Context/Analytics';
import api from 'src/api';
import utils from 'src/utils';


function DataManagerPro({onClickBack, setSelectedAsset, refreshAssets, refreshPins, refreshDatasets, setClickedPin, setNewViewBasedOnId}) {
    const store = useStore()
    const dataManagerStore = DataManagerStore();
    
    const All = "All"
    const DataSets = "Datasets"
    const Pins = "Pins"
    const Assets = "Assets"

    const view_only_user = store.session.user.role == "view_only"
    const searchBarRef = useRef(null)
    const [searchBar, setSearchBar] = useState('')
    
    const [batchDeleteMode, setBatchDeleteMode] = useState(false)
    
    const [viewModeIsGrid, setViewMode] = useState(true)
    const [selectedDataset, setSelectedDataset] = useState(null)
    
    const showOptions = [{value: All, label: All}, {value: DataSets, label: DataSets}, {value: Pins, label: Pins}, {value: Assets, label: Assets}]
    const [show, setShow] = useState(showOptions[0].value)

    const filterOptions = [{value: 'All', label: 'All'}, {value: 'Screen', label: 'Screen'}]
    const sortOptions = [
        {value: 'Name ↓', label: 'Name ↓'},
        {value: 'Name ↑', label: 'Name ↑'},
        {value: 'Date ↓', label: 'Date ↓'},
        {value: 'Date ↑', label: 'Date ↑'},
    ]
    const [currentFilter, setCurrentFilter] = useState(filterOptions[0].value)
    const [currentSort, setCurrentSort] = useState(sortOptions[0].value)

    const [dateFilter, setDateFilter] = useState([0, new Date()])
    var dateRange = [0, new Date()]

    const [shiftPressed, setShiftPressed] = useState(false);

    const [deleteProgress, setDeleteProgress] = useState(-1);
    const [deleteItemsCount, setDeleteItemsCount] = useState(-1);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const [selectMode, setSelectMode] = useState(false)

    const [cancelDelete, setCancelDelete] = useState(false)

    const [singleDeleteMode, setSingleDeleteMode] = useState(false) //Used in detailed view for datasets only
    const [shareModal, setShareModal] = useState(false);
    const [shareLink, setShareLink] = useState('');


    function clearAll() {
        setDateFilter([0, new Date()])
        setSearchBar('')
        dataManagerStore.selected = []
    }

    function selectAll() {
        dataManagerStore.selected = resourcesFinal.map(resource => resource.uuid);
    }

    function toggleViewModes() 
    {
        Analytics.trackClick(viewModeIsGrid ? 'data-manager-show-list-view' : 'data-manager-show-grid-view');
        setViewMode(!viewModeIsGrid);
    }

    const getCentroid = (coords) => { //I use this to find center of dataset
        let centroid = coords.reduce((accumulator, coord) => {
          return [accumulator[0] + coord[0], accumulator[1] + coord[1]];
        }, [0, 0]);
    
        centroid[0] /= coords.length;
        centroid[1] /= coords.length;
    
        return centroid;
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);
    
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    });

    const allResources = useMemo(() => {
        const pins = store.data.pins || [];
        const datasets = store.data.datasets || [];
        const assets = store.data.assets || [];
        const tempResources = [];

        datasets.forEach(element => {
            tempResources.push({
                title: element.display_name || element.name || `dataset_${element.dataset_id}`,
                type: 'Dataset',
                location: element?.optimized_rasters?.[0]?.geog?.[0],
                coordinate: element?.optimized_rasters?.[0]?.geog,
                preview: element.preview,
                info: element,
                date: new Date(element.created_at).getTime(),
                dateReadable: utils.formatDate(element.created_at),
                id: element.dataset_id,
                uuid: element.display_name + "-" + element.dataset_id,
                olLayer: store.data.layers.find(l => l.dataset.dataset_id === element.dataset_id)?.viewObject,
            });
        });

        pins.forEach(element => {
            tempResources.push({
                title: element.title || `pin_${element.pin_id}`,
                type: 'Pin',
                location: element.geog,
                coordinate: element.geog_3857,
                preview: element.preview,
                info: element.media_urls,
                date: new Date(element.origin_date).getTime(),
                dateReadable: utils.formatDate(element.origin_date),
                pin: element,
                id: element.pin_id,
                uuid: element.title + "-" + element.pin_id
            });
        });

    
        assets.forEach(element => {
            tempResources.push({
                title: element.display_name || element.name || `asset_${element.asset_id}`,
                type: 'Asset',
                location: element.location,
                coordinate: element.location_3857,
                preview: element.preview,
                info: element,
                date: new Date(element.created_at).getTime(),
                dateReadable: utils.formatDate(element.created_at),
                id: element.asset_id,
                uuid: element.display_name + "-" + element.asset_id
            });
        });

        return tempResources;
    }, [store.data, show, viewModeIsGrid]);

    const resources = allResources.filter(resource => {
        if (show === DataSets) {
            return resource.type === 'Dataset';
        } else if (show === Pins) {
            return resource.type === 'Pin';
        } else if (show === Assets) {
            return resource.type === 'Asset';
        } else {
            return true;
        }
    });

    function isLocationInView(longitude, latitude, type, mapProjection, mapExtent) {
        const transformedCoords = type !== "Pin" ? transform([longitude, latitude], 'EPSG:4326', mapProjection) : [longitude, latitude];
        return containsCoordinate(mapExtent, transformedCoords);
    }
    
    var resourcesTrimmed = [];
    
    const mapView = store.ui.map.state.olmap.getView();
    const mapProjection = mapView.getProjection();
    const mapSize = store.ui.map.state.olmap.getSize();
    const mapExtent = mapView.calculateExtent(mapSize);
    
    var minDate = 0;
    allResources.forEach(resource => {
        if(resource.date < minDate || minDate == 0) 
        {
            minDate = resource.date;
            dateRange = [minDate, new Date()];
        };
    });
    

    if (currentFilter === "All") {
        resourcesTrimmed = resources.slice(); // Use slice to create a shallow copy of the array
    } else {
        resources.forEach(resource => {
            if (resource.location) {
                let [longitude, latitude] = resource.location;
    
                /*if (resource.type === "Dataset") {
                    [longitude, latitude] = getCentroid(resource.location);
                }*/
    
                const isInView = containsCoordinate(mapExtent, transform(resource.location, 'EPSG:4326', mapProjection))
                const isSelected = dataManagerStore.selected.includes(resource.uuid);
                if (isInView || (isSelected && selectMode)) {
                    resourcesTrimmed.push(resource);
                }
            }
        });
    }


    var ticks = [];
    resourcesTrimmed.forEach(resource => {
        ticks.push(resource.date);
    });

    if      (currentSort === 'Date ↓') resourcesTrimmed.sort((a, b) => a.date - b.date);
    else if (currentSort === 'Date ↑') resourcesTrimmed.sort((a, b) => b.date - a.date);
    else if (currentSort === 'Name ↓') resourcesTrimmed.sort((a, b) => a.title.localeCompare(b.title));
    else if (currentSort === 'Name ↑') resourcesTrimmed.sort((a, b) => b.title.localeCompare(a.title));

    function handleSelectionChange (itemKey) { 
        const lastClicked = dataManagerStore.lastAdded;

        if (shiftPressed && lastClicked) {
            const startIndex = resourcesTrimmed.findIndex(r => r.uuid === lastClicked);
            const endIndex = resourcesTrimmed.findIndex(r => r.uuid === itemKey);
            const rangeStart = Math.min(startIndex, endIndex);
            const rangeEnd = Math.max(startIndex, endIndex);
            
            dataManagerStore.selected = []
            for (let i = rangeStart; i <= rangeEnd; i++) {
                const rangeItemKey = resourcesTrimmed[i].uuid;
                const index = dataManagerStore.selected.indexOf(rangeItemKey);
                if (index === -1) {
                    dataManagerStore.selected.push(rangeItemKey);
                }
            }
        } else {
            const index = dataManagerStore.selected.indexOf(itemKey);
            if (index === -1) {
                dataManagerStore.selected.push(itemKey);
            } else {
                dataManagerStore.selected.splice(index, 1);
            }
        }
        
        dataManagerStore.lastAdded = itemKey; // Update last added if needed
    }

    var resourcesFinal = resourcesTrimmed.filter(resource => 
        (resource.title.toLowerCase().includes(searchBar.toLowerCase()) &&
        resource.date >= dateFilter[0] && resource.date <= dateFilter[1]) || dataManagerStore.selected.includes(resource.uuid)) // Filter by search bar and date range

    function handleKeyDown (event) {
        if (event.shiftKey || event.metaKey) {
            setShiftPressed(true);
        }
        if ((event.ctrlKey || event.metaKey) && event.code === 'KeyA')
        {
            if (document.activeElement === searchBarRef.current) return;
            if (dataManagerStore.selected.length >= resourcesFinal.length && resourcesFinal.length > 0)
            {
                dataManagerStore.selected = [];
            }
            else {
                dataManagerStore.selected = [];
                dataManagerStore.selected = resourcesFinal.map(resource => resource.uuid);
            }
        }
    };

    const handleKeyUp = (event) => {
        if (!(event.shiftKey || event.metaKey)) {
            setShiftPressed(false);
        }
    };

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }
    
    async function deleteResource(resource) {
        console.log(resource)
        if (resource.type === 'Dataset') {
            await api.call(`/dataset/remove`, {dataset_name: resource.info.name});
        } else if (resource.type === 'Pin') {
            await api.call(`/pin/delete`, {pin_id: resource.id});
        } else if (resource.type === 'Asset') {
            await api.call(`/asset/delete`, {asset_id: resource.info.asset_id});
        }
    }

    async function deleteItems() {
        setCancelDelete(false);
        setDeleteProgress(0);
        setDeleteItemsCount(dataManagerStore.selected.length);
        var iterations = 0;

        var doRefreshAssets = false
        var doRefreshPins = false
        var doRefreshDatasets = false

        for (const element of dataManagerStore.selected) {

            if(cancelDelete) 
            {
                setDeleteProgress(-1);
                break;
            }
            setDeleteProgress(iterations);
            const resource = resourcesFinal.find(i => i.uuid === element);
            if (resource) {
                if(resource.type == "Pin") doRefreshPins = true;
                if(resource.type == "Asset") doRefreshAssets = true;
                if(resource.type == "Dataset") doRefreshDatasets = true;
                try {
                    await deleteResource(resource);
                } catch (error) {
                    console.error(`Failed to delete resource: ${resource.title}`, error);
                }
                await delay(100);
                iterations++;
            } else {
                console.log(`Resource with UUID ${element} not found.`);
            }
        }

        setCancelDelete(false);
        setIsRefreshing(true);
        if(doRefreshAssets) await refreshAssets();
        if(doRefreshPins) await refreshPins();
        if(doRefreshDatasets) await refreshDatasets();
        setShow(All);
        setIsRefreshing(false);
        setDeleteProgress(-1);
        dataManagerStore.selected = [];
        setBatchDeleteMode(false);
        setDeleteItemsCount(-1);
    }

    async function deleteSingleItem() {
        setCancelDelete(false);
        setDeleteProgress(0);
        setDeleteItemsCount(dataManagerStore.selected.length);
        var iterations = 0;

        setSingleDeleteMode(false);
        setDeleteProgress(iterations);
        if (selectedDataset) {
            try {
                await deleteResource(selectedDataset);
                setSelectedDataset(null);

            } catch (error) {
                console.error(`Failed to delete resource: ${selectedDataset.title}`, error);
            }
            iterations++;
        } else {
            console.log(`Resource with UUID ${selectedDataset} not found.`);
        }

        setCancelDelete(false);
        setIsRefreshing(true);
        await refreshDatasets();
        setShow(All);
        setIsRefreshing(false);
        setDeleteProgress(-1);
        dataManagerStore.selected = [];
        setBatchDeleteMode(false);
        setDeleteItemsCount(-1);
    }

    return (
        <div className='data_manager_container'>
            {deleteProgress >= 0 && //Deleting progress bar
                <BaseModal title={isRefreshing ? 'Refreshing Data...' : 'Deletion In Progress...'} hideButtons>
                    <div className='data_manager_delete_progress'>
                        <div className='data_manager_delete_progress_bar' style={{width: `${Math.max(1, deleteProgress * 100 / deleteItemsCount)}%`}}></div>
                        {/*<BaseButton onClick={() => {setCancelDelete(true); setDeleteProgress(0)}}>Cancel</BaseButton> */ } {/* This is causing some refresh problems, disabled for now */}
                    </div>
                </BaseModal>
            }
            {(batchDeleteMode && deleteProgress <= -1) && //Batch Delete Confirmation screen 
                <BaseModal
                title={<div style={{display:'flex', gap: '10px', justifyContent: 'center'}}>Deleting<div style={{color:'red', fontWeight:'bold'}}>{dataManagerStore.selected.length}</div>items</div>}
                rightButton='Batch delete'
                onClickRightButton={() => deleteItems()}
                onClickCancel={() => setBatchDeleteMode(false)}
                >
                <div className='data_manager_batch_delete_view'>
                {dataManagerStore.selected.map(element => {
                    const resource = allResources.find(i => i.uuid === element);
                    if (resource) {
                        return (
                            <div style={{display:'flex', gap: '10px'}} key={resource.uuid}>
                                <div className={`data_type_cube ${resource.type}`}> {resource.type[0]}</div>
                                {` ${resource.uuid.split("-")[0]}`}
                            </div>
                        );
                    }
                    return null;
                })}
                </div>
                </BaseModal>
            }
            
            {singleDeleteMode && <BaseModal title='Delete Dataset?' rightButton='Delete' onClickCancel={() => setSingleDeleteMode(false)} onClickRightButton={() => deleteSingleItem()}>{"Dataset: " + selectedDataset.title}</BaseModal>}
            {selectedDataset != null ?  
                DetailedView(setSelectedDataset, selectedDataset, store, setSingleDeleteMode, shareModal, setShareModal, shareLink, setShareLink) : //Deteailed view of selected dataset

                <OptionBarItemContainer //Main view

                    title={'Data Manager'}
                    isExpandButtonShowed
                    onClickBack={() => onClickBack()}
                    icon={"/icons/menu/data_manager.svg"}
                    isIconShowed={true}
                    isShowingChannel={true}
                    isArrowButtonShowed={true}
                >
                    <input
                        ref={searchBarRef}
                        value={searchBar}
                        onClick={e => Analytics.trackClick('data-manager-search-bar')}
                        onInput={(e) => setSearchBar(e.target.value)}
                        placeholder='Search...'
                        className='data_manager_search_bar'
                    ></input>
                    <div className='data_manager_top_bar'>

                        <div className='bar_block' style={{width: "170px"}}>
                            <div>Show</div>
                            <SelectPro
                                noOptionsMessage={() => "error..."}
                                options={showOptions}
                                onChange={e => {
                                    Analytics.trackClick('data-manager-show-type-dropdown');
                                    setShow(e.value)
                                }}
                                defaultValue={showOptions[0]}
                            ></SelectPro>
                        </div>

                        <div className='bar_block' style={{width: "170px"}}>
                            <div>Sort</div>
                            <SelectPro
                                noOptionsMessage={() => "error..."}
                                options={sortOptions}
                                onChange={e => {
                                    Analytics.trackClick('data-manager-sort-by-dropdown');
                                    setCurrentSort(e.value)
                                }}
                                defaultValue={sortOptions[0]}
                            ></SelectPro>
                        </div>

                        <div className='bar_block' style={{width: "170px"}}>
                            <div>Filter</div>
                            <SelectPro
                                noOptionsMessage={() => "error..."}
                                options={filterOptions}
                                onChange={e => {
                                    Analytics.trackClick('data-manager-filter-screen-dropdown');
                                    setCurrentFilter(e.value)
                                }}
                                defaultValue={filterOptions[0]}
                            ></SelectPro>
                        </div>


                        <div className='bar_block' style={{width: "170px"}}>
                            <div>View</div>
                            <div style={{display: 'flex', gap: '4px', justifyContent: 'center'}}>
                                <div
                                    style={{backgroundColor : viewModeIsGrid ? 'var(--gold)' : 'white', borderColor: viewModeIsGrid ? 'var(--gold)' : 'black'}}
                                    onClick={() => toggleViewModes()}
                                    className='grid_block clear_data_manager'
                                >
                                    <img
                                        style={{filter: viewModeIsGrid ? 'invert(0)' : 'invert(1)'}}
                                        className='grid_img' src='/icons/datamanager/gridView.svg'
                                    />
                                </div>
                                <div
                                    style={{backgroundColor : viewModeIsGrid ? 'white' : 'var(--gold)', borderColor: viewModeIsGrid ? 'black' : 'var(--gold)'}} 
                                    onClick={() => toggleViewModes()}
                                    className='grid_block clear_data_manager'
                                >
                                    <img
                                        style={{filter: viewModeIsGrid ? 'invert(1)' : 'invert(0)'}}
                                        className='grid_img' src='/icons/datamanager/listView.svg'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='data_manager_select_buttons'>
                            <BaseButton onClick={() => !selectMode ? setSelectMode(true) : selectAll()} buttonType={selectMode ? "SECONDARY" : "STANDARD"}>{selectMode ? "Select All" : "Select"}</BaseButton>
                            {selectMode && <BaseButton onClick={() => setSelectMode(false)} buttonType={"STANDARD"}>Cancel</BaseButton>}
                        </div>

                    </div>
                    
                    <div className={`data_manager_content ${selectMode ? 'select' : ''}`} style={{justifyContent: viewModeIsGrid ? 'flex-start' : 'center'}}>
                    {resourcesFinal.map((resource, index) => {
                        return <DataBlock key={resource.uuid}
                        handleSelectionChange={handleSelectionChange}
                        setSelectedDataset={setSelectedDataset}
                        setSelectedAsset={setSelectedAsset}
                        selectMode={selectMode}
                        type={resource.type}
                        viewModeIsGrid={viewModeIsGrid}
                        resource={resource}
                        filter={currentFilter != "All"}
                        setNewViewBasedOnId={setNewViewBasedOnId}
                    />
                    })}
                </div>

                    {(dataManagerStore.selected.length > 0 && selectMode) && <div className='data_manager_delete_section'> 
                        <div className='data_manager_selected_count'>{dataManagerStore.selected.length} selected</div>

                        <BaseButton style={{margin: "15px 15px 15px 60px"}} buttonType={"SECONDARY"} onClick={() => clearAll()}>Clear Selection</BaseButton>
                        <BaseButton style={{margin: 0}} disabled={store.session.user.role == "view_only"} title={view_only_user ? "Only Admins Can Delete" : "Delete Selected Data"} buttonType={"DELETE"} onClick={() => setBatchDeleteMode(true)}>Delete</BaseButton>
                    </div>}

                    {resources.length > 0 && <div className="timeline_data_manager" onClick={e => Analytics.trackClick('data-manager-time-slider')}>
                        <SliderPro values={dateFilter} range={dateRange} ticks={ticks} updateValue={setDateFilter}/>
                    </div>}
                </OptionBarItemContainer>}

            {/*<div className='sidetrigger'> </div>*/}
        </div>
    );
}

export default DataManagerPro;
