import React, { memo } from 'react';
import BaseModal from 'src/Common/Modal/BaseModal';
import './ArchiveModal.css';

function ArchiveModal({ onClickCancel, onClickRightButton, channelName }) {
  console.log(channelName);
  return (
    <BaseModal
      rightButton="Archive"
      onClickCancel={onClickCancel}
      onClickRightButton={onClickRightButton}
    >
      <h2 className="channels_settings_tab_panel_archive_modal_title">
        {`Are you sure you want to archive\n ${channelName}?`}
      </h2>
    </BaseModal>
  );
}

export default memo(ArchiveModal);
