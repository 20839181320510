import React, { memo, useEffect, useState } from 'react';
import { useStore } from 'src/store';

import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import { SectorOptions, SubSectorOptions } from 'src/shared/constants/sector_subsector';
import './CreateNatureReport.less';
import InputPro from 'src/Components/Common/InputPro';
import LocationSelection from 'src/Components/NatureReporting/LocationSelection/LocationSelection';
import SelectPro from 'src/Components/Common/SelectPro/SelectPro';
import api from 'src/api';
import LoadingScreen from 'src/Routes/LoadingScreen';

function CreateNatureReport({
  onClickCancel,
  assetName,
  setAssetName,
  setIndustry,
  setSector,
  industry,
  sector,
  registration,
  description,
  setCoordinates,
  setAddressExternal,
  setRegistration,
  setDescription,
  editId,
  editInfo,
  refreshAssets,
  onClickDetails,
}) {
  const store = useStore();
  const isFullMode = store.ui.menuFull;
  const [address, setAddress] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [addressLock, setAddressLock] = useState(false);

  const [loading, setLoading] = useState(false);

  const [subSectorOptions, setSubSectors] = useState([]);

  function changeIndustry(e) {
    setIndustry(e);

    let subSectorOptions = [];
    SubSectorOptions.forEach(element => {
      if (element.Parent == e.Code) {
        subSectorOptions.push(element);
      }
    });
    setSubSectors(subSectorOptions);

    setSector('');
  }

  function changeSector(e) {
    setSector(e);
  }


  useEffect(() => {
    if (editInfo) {
      console.log(editInfo);
      store.ui.map?.state.olmap?.jumpTo({ point_4326: editInfo.location });
      setAssetName(editInfo.display_name);
      setLongitude(editInfo.location[0]);
      setLatitude(editInfo.location[1]);
      if (editInfo.industry) {
        SectorOptions.forEach(element => {
          if (element.label == editInfo.industry) {
            setIndustry(element);
          }
        });
      }
      if (editInfo.sector) {
        SubSectorOptions.forEach(element => {
          if (element.label == editInfo.sector) {
            setSector(element);
          }
        });
      }
    }
  }, [editInfo]);

  const [selectedTab, setSelectedTab] = useState('Address');

  useEffect(() => {
    const { location } = store.ui.mapPreviewPin || {};

    if (location && selectedTab === 'Manual') {
      const [longitude, latitude] = location.map(parseFloat);

      setLongitude(longitude);
      setLatitude(latitude);
      setCoordinates([longitude, latitude]);
      updateAddress([longitude, latitude]);
    }
  }, [store.ui.mapPreviewPin?.location, selectedTab]);

  useEffect(() => {
    if (selectedTab === 'Manual') {
      store.ui.map?.locationOffset();
    }
  }, [selectedTab]);

  async function updateAddress(position) {
    if (store.ui.mapPreviewPin.style != 'manual') return;
    const url =
      `https://api.mapbox.com/geocoding/v5/mapbox.places/` +
      `${position[0]},${position[1]}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;
    const result = await fetch(url);
    const data = await result.json();
    if (data.features[0]) {
      store.ui.mapPreviewPin.address = data.features[0].place_name;
    }
  }

  useEffect(() => {
    store.ui.mapPreviewPin.style = 'asset';
    if (selectedTab === 'Manual') {
      store.ui.mapPreviewPin.style = 'manual';
    } else {
      store.ui.mapPreviewPin.style = 'asset';

      if (longitude && latitude) {
        store.ui.mapPreviewPin.location = [longitude, latitude];
      }

      if (selectedTab == 'Gps') {
        if (longitude != 0 && latitude != 0 && (longitude || latitude)) {
          console.log(longitude + ' ' + latitude);
          store.ui.map?.state.olmap?.jumpTo({ point_4326: [longitude, latitude] });
          setCoordinates([parseFloat(longitude), parseFloat(latitude)]);
        }
        if (longitude) setLongitude(parseFloat(longitude));
        if (latitude) setLatitude(parseFloat(latitude));
      }
    }
  }, [selectedTab, longitude, latitude]);

  useEffect(() => {
    console.log(addressLock);
    if (!addressLock) {
      setAddress(store.ui.mapPreviewPin.address);
    }
  }, [store.ui.mapPreviewPin.address]);

  async function EditAsset() {
    console.log(editInfo);
    if (editInfo) {
      setLoading(true);
      let payload = {
        asset_id: editInfo.asset_id,
        display_name: assetName,
        address: address,
        description: description,
        registration: registration,
      };

      if (longitude && latitude) {
        payload.location = [longitude, latitude];
      }
      if (sector) {
        payload.sector = sector.label;
      }
      if (industry) {
        payload.industry = industry.label;
      }

      const res = await api.call('/asset/update', { ...payload });

      await refreshAssets();
      store.ui.mapPreviewPin.style = 'AssetNamenull';
      const asset = await api.call(`/asset/details`, { asset_id: editInfo.asset_id });
      onClickDetails(asset.success);
      console.log(res);
      setLoading(false);
    }
  }

  const locationInfo = {
    isFullMode: isFullMode,
    selectedTab: selectedTab,
    setSelectedTab: setSelectedTab,
    address: address,
    setAddress: setAddress, // include the setter function here
    longitude: longitude,
    setLongitude: setLongitude,
    latitude: latitude,
    setLatitude: setLatitude,
    addressExternal: address,
    setAddressExternal: setAddressExternal,
    addressLock: addressLock,
    setAddressLock: setAddressLock,
  };

  return (
    (loading == true) ? <LoadingScreen/> : 
    <div>
      <LocationSelection locationInfo={locationInfo} />

      <InputPro
        label="Asset Name"
        fixedLabel
        placeholder="Enter your asset name"
        bindValue={setAssetName}
        defaultValue={assetName}
        className="asset_name"
      />
      <div className="sector_and_industry_container">
        <SelectPro
          name="Sector"
          placeholder="Choose Sector..."
          registerName="sector"
          options={SectorOptions}
          onChange={changeIndustry}
          value={industry}
          className={'create_asset_selector'}
        />
        <SelectPro
          name="Subsector"
          placeholder={industry == '' ? 'Choose Sector First...' : 'Choose Subsector...'}
          registerName="subsector"
          options={subSectorOptions}
          value={sector}
          onChange={changeSector}
          className={'create_asset_selector'}
        />
      </div>
      <InputPro
        bindValue={setRegistration}
        fixedLabel
        className="nature_reporting_registration"
        label="Registration"
      ></InputPro>
      <textarea
        defaultValue={editInfo?.description}
        onChange={e => setDescription(e.target.value)}
        className="nature_reporting_create_description"
        placeholder="Description"
      ></textarea>
      <div
        className="nature_report_create_nature_report_double_filed"
        style={{ gridColumn: `1/${isFullMode ? 5 : 3}` }}
      >
        <div className="buttons_container">
          <div>
            <BaseButton buttonType={'SECONDARY'} onClick={onClickCancel} type="button">
              Cancel
            </BaseButton>
          </div>
          <div>
            {editId ? (
              <BaseButton
                disabled={!(latitude && longitude && assetName)}
                onClick={() => EditAsset()}
              >
                Save Changes
              </BaseButton>
            ) : (
              <BaseButton disabled={!(latitude && longitude && assetName)}>Create Asset</BaseButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(CreateNatureReport);
