import React from 'react';

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
  position: 'absolute',
  width: '100%',
  height: 5,
  transform: 'translate(0%, -50%)',
  cursor: 'pointer',
  zIndex: 16,
  // border: "1px solid grey"
};

const railInnerStyle = {
  position: 'absolute',
  width: '100%',
  height: 2,
  transform: 'translate(0%, -50%)',
  borderRadius: 4,
  pointerEvents: 'none',
  backgroundColor: 'rgb(155,155,155)',
};

export default function SliderRail({ getRailProps }) {
  return (
    <>
      <div style={railOuterStyle} {...getRailProps()} />
      <div style={railInnerStyle} />
    </>
  );
}
