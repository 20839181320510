import React, { memo, useCallback, useEffect, useState } from 'react';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import starEmptyIcon from 'public/icons/star_empty.svg';
import starFilledIcon from 'public/icons/star_filled.svg';
import Tab from 'src/Common/Tab';
import DashboardTabPanel from './DashboardTabPanel';
import FilesTabPanel from './FilesTabPanel';
import './NatureReportingDetails.less';
import ImageButton from 'src/Common/Buttons/ImageButton';
import locationIcon from 'public/icons/address_icon.svg';
import editIcon from 'public/icons/edit.svg';
import { useStore } from 'src/store';
import AoITabPanel from './AoITabPanel';
import AreaTabPanel from './AreaTabPanel';
import DataTabPanel from './DataTabPanel/DataTabPanel';
import api from 'src/api';
import AreaSelection from './AreaSelection/AreaSelection';
import InfoHover from 'src/Common/InfoHover';
Tab.tabsRole = 'Tab';

function NatureReportingDetails({
  asset,
  assets,
  mapJumpTo,
  onClickDetails,
  refreshAssets,
  setTab,
  currentTab,
  closeWindow,
  setEditId,
  backWindow,
  setDataframes,
  dataframes,
}) {
  const [isPriority, setIsPriority] = useState(false);
  const store = useStore();
  const isFullMode = store.ui.menuFull;

  const [showGenerateAoi, setShowGenerateAoi] = useState(false);
  const [showMonitoringArea, setShowMonitoringArea] = useState(false);
  const [showAssetFootprint, setShowAssetFootprint] = useState(false);

  const [areaSelection, setAreaSelection] = useState(null);
  const [areaOptions, setAreaOptions] = useState([]);


  const [areaDetails, setAreaDetails] = useState(null);
  const [spreadSheets, setSpreadSheets] = useState([]);
  const [loadSpreadSheets, setLoadSpreadSheets] = useState(false);

  useEffect(() => {
    setIsPriority(asset.priority);
  }, []);

  const handleSetTabIndex = useCallback(() => {
    setTab(prev => prev + 1);
  }, []);

  const handleSetSelectedIndex = useCallback(index => {
    setTab(index);

    console.log(index);
  }, []);

  const [assetEditMode, setAssetEditMode] = useState(false);

  function setAssetEditModeOff() {
    setAssetEditMode(false);
  }

  function GoToLocation() {
    console.log(asset);
    mapJumpTo(
      asset.areas[0]?.geog ? { geog_4326: asset.areas[0].geog } : { point_4326: asset.location }
    );
  }

  function resetAreaTab() {
    setShowAssetFootprint(false);
    setShowMonitoringArea(false);
    setShowGenerateAoi(false);
  }



  async function loadAreas() {
    const areaTypes = {
      aoi: "AOI's",
      monitoring_area: 'Monitoring Areas',
      asset_footprint: 'Asset Footprints',
    };

    let options = Object.entries(areaTypes).map(([key, label]) => ({
      label,
      options:
        asset.areas
          .filter(area => area.area_type === key)
          .map(area => ({
            value: area.area_id,
            label: area.nameOfArea,
            identifier: area.area_id,
            areaType: area.area_type,
            active: area.active,
          })) || [],
    }));

    setAreaOptions(options);
  }


  useEffect(() => {
    fetchSpreadSheets();
    console.log(areaSelection);
  }, [areaSelection, asset]);


  async function fetchSpreadSheets() {
    setDataframes([]);
    if (!areaSelection) {
      return;
    }
    setLoadSpreadSheets(true);
    let tmpSpreadSheets = [];
    console.log(areaSelection);

    let res = await api.call('/asset/dataframe/list', { area_id: areaSelection.identifier });
    tmpSpreadSheets.push(res.success);

    let res2 = await api.call('/asset/area/details', { area_id: areaSelection.identifier });

    setAreaDetails(res2?.success);
    setSpreadSheets(tmpSpreadSheets);
    setDataframes(tmpSpreadSheets);
    setLoadSpreadSheets(false);
  }

  useEffect(() => {
    loadAreas();
  }, [asset]);

  return (
    <>
      <div className="dashboard_panel_header">
        {areaOptions.length > 0 && (
          <AreaSelection
            changeArea={setAreaSelection}
            options={areaOptions}
            selectedArea={areaSelection}
          />
        )}
        <InfoHover
          iconClass="asset_description_container"
          text={asset.description || 'No Description'}
        />
      </div>
      <div className="nature_reporting_nature_reporting_details_title_container">
        {asset && (
          <>
            <div
              alt="star"
              data-tooltip-id="star_icon_tooltip"
              style={{ backgroundImage: `url(${isPriority ? starFilledIcon : starEmptyIcon})` }}
              className="nature_reporting_nature_reporting_details_star_icon"
              title="Priority Asset"
              /*onClick={handleSetIsPriority}*/
            />
          </>
        )}
        <h3 className="nature_reporting_nature_reporting_details_title">{asset?.display_name}</h3>
        <ImageButton
          onClick={() => {setEditId(asset.asset_id), backWindow()}}
          src={editIcon}
          alt="edit icon"
          className="nature_reporting_details_dashboard_panel_edit_icon"
        />
        <ImageButton
          onClick={() => GoToLocation()}
          src={locationIcon}
          alt="location icon"
          className="nature_reporting_details_dashboard_panel_location_icon"
        />
      </div>
      <Tabs
        selectedIndex={currentTab}
        onSelect={handleSetSelectedIndex}
        className="nature_reporting_nature_reporting_details_main_container"
      >
        <TabList className="nature_reporting_nature_reporting_details_tab_list">
          <Tab tabValue="Dashboard" />
          {<Tab tabValue="Data" />}
          <Tab tabValue="Areas" />
          <Tab tabValue="Files" />
        </TabList>
        <TabPanel selectedClassName="nature_reporting_nature_reporting_details_tab_panel">
          <DashboardTabPanel
            onClickEdit={handleSetTabIndex}
            onClickDetails={onClickDetails}
            assets={assets}
            assetDetails={asset}
            areaDetails={areaDetails}
            setDataframes={setDataframes}
            dataframes={dataframes}
            fetchSpreadSheets={fetchSpreadSheets}
            spreadSheets={spreadSheets}
            loadingSpreadSheet={loadSpreadSheets}
            area={areaSelection}
          />
        </TabPanel>

        {
          <TabPanel selectedClassName="nature_reporting_nature_reporting_details_tab_panel">
            <DataTabPanel asset={asset} reload={() => onClickDetails(asset)} />
          </TabPanel>
        }
        <TabPanel selectedClassName="nature_reporting_nature_reporting_details_tab_panel">
          <AoITabPanel
            assetDetails={asset}
            showGenerateAoi={showGenerateAoi}
            setShowGenerateAoi={setShowGenerateAoi}
            refreshAssets={onClickDetails}
            resetAreaWindow={resetAreaTab}
          />
          <AreaTabPanel
            assetDetails={asset}
            area={asset.monitoring_areas}
            areaName={'Monitoring Area'}
            showArea={showMonitoringArea}
            setShowArea={setShowMonitoringArea}
            refreshAssets={onClickDetails}
            resetAreaWindow={resetAreaTab}
          />
          <AreaTabPanel
            assetDetails={asset}
            area={asset.asset_footprints}
            areaName={'Asset Footprint'}
            showArea={showAssetFootprint}
            setShowArea={setShowAssetFootprint}
            refreshAssets={onClickDetails}
            resetAreaWindow={resetAreaTab}
          />
        </TabPanel>
        <TabPanel selectedClassName="nature_reporting_nature_reporting_details_tab_panel">
          <FilesTabPanel assetDetails={asset} onClickDetails={onClickDetails} setTab={setTab} />
        </TabPanel>
      </Tabs>
    </>
  );
}

export default memo(NatureReportingDetails);
