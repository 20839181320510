import React, { useEffect, useState } from 'react';
import utils from 'src/utils';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { MentionsInput, Mention } from 'react-mentions';
import Avatar from 'src/Common/Avatar';
import emojiIcon from 'public/icons/emoji.svg';
import attachmentIcon from 'public/icons/attachment.svg';
import sendIcon from 'public/icons/send.svg';
import mentionIcon from 'public/icons/mention.svg';

export default function CommentComposer({ onSubmitComment, onChange, editing }) {
  const [commentText, setCommentText] = useState(editing || '');
  const [attachments, setAttachments] = useState(null);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

  useEffect(() => onChange?.({ commentText, attachments }), [commentText, attachments]);

  return (
    <div
      className={'comments__draft_area' + (editing !== undefined ? ' editing' : '')}
      onKeyDown={e => {
        if ((e.shiftKey || e.metaKey) && e.key === 'Enter') {
          e.preventDefault();
          document.querySelector('.send img').click();
        }
      }}
    >
      <MentionsInput
        className="react-mentions"
        singleLine={false}
        value={commentText}
        placeholder="Comment"
        onChange={e => setCommentText(e.target.value)}
        forceSuggestionsAboveCursor={true}
      >
        <Mention
          trigger="@"
          data={window.store.data.users.map(u => ({
            id: u.email,
            display_name: u.display_name.trim(),
            display: [u.display_name, utils.slugify(u.display_name), u.email].join(' '), // search text
          }))}
          renderSuggestion={({ id, display_name }) => (
            <>
              <Avatar user={id} size={40} />
              <div>
                <div>{display_name || id}</div>
                <div>{id}</div>
              </div>
            </>
          )}
          displayTransform={id => `\u00A0\u00A0@${id}\u00A0\u00A0`}
          markup={`\u00A0\u00A0@${'__id__'}\u00A0\u00A0`}
          appendSpaceOnAdd={true}
        />
      </MentionsInput>

      <div className="mention">
        <img
          title="Mention someone"
          alt="Mention someone"
          src={mentionIcon}
          onClick={() => {
            // Make sure to handle the textarea cursor position correctly
            const textarea = document.querySelector('.react-mentions textarea');
            const start = textarea.selectionStart ?? textarea.value.length;
            const end = textarea.selectionEnd ?? textarea.value.length;
            let before = textarea.value.slice(0, start);
            let after = textarea.value.slice(end);

            // Insert the @ wherever the cursor was (or append if no cursor), adding spaces around as needed
            before = before + (/^$|\s$/.test(before) ? '' : ' ');
            after = (/^\s|^$/.test(after) ? '' : ' ') + after;
            const value = before + '@' + after;

            // Move the cursor to right after the @, and trigger a MentionsInput refresh
            textarea.value = value;
            textarea.setSelectionRange(before.length + 1, before.length + 1);
            textarea.focus();
            setCommentText(value);
          }}
        ></img>
      </div>

      <div className="send">
        <img
          title="Send comment"
          alt="Send comment"
          src={sendIcon}
          onClick={() => (commentText || attachments) && onSubmitComment({ commentText, attachments })}
        ></img>
      </div>

      {
        <div className="emoji">
          {emojiPickerOpen && (
            <Picker
              data={data}
              onEmojiSelect={e => {
                console.log(e);
                setCommentText(commentText + e.native);
                setEmojiPickerOpen(false);
              }}
              onClickOutside={() => {
                setEmojiPickerOpen(false);
              }}
              autoFocus={true}
              theme="light"
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                margin: 30,
              }}
            />
          )}
          <img
            title="Insert emoji"
            alt="Insert emoji"
            src={emojiIcon}
            onClick={e => {
              if (!emojiPickerOpen) {
                setEmojiPickerOpen(true);
                e.stopPropagation(); // else Picker.onClickOutside will close it right back down
              }
            }}
          ></img>
        </div>
      }

      <label className="attachment">
        <input
          type="file"
          autoComplete="off"
          tabIndex="-1"
          multiple
          onChange={e => setAttachments([...e.target.files])}
        />

        <img title="Add file attachment" alt="Add file attachment" src={attachmentIcon}></img>

        <p>
          {attachments &&
            (attachments.length > 1
              ? `${attachments.length} file(s): ${
                  attachments
                  .map(a => a.name)
                  .join(', ')
                  .replace(/(.{50})..+/, '$1…')
                }`
              : attachments[0].name)}
        </p>
      </label>
    </div>
  );
};