import React, { memo, useEffect, useState } from 'react';
import { useTable, useGlobalFilter, useRowSelect } from 'react-table';
import { useStore } from 'src/store';
import Status from 'src/Assets/NatureReportingDetails/Status';
import './Table.css';
import api from 'src/api';
import MoreActions from 'src/Common/MoreActions/MoreActions';
import utils from 'src/utils';

const TH_SQUEEZED_WIDTH_MONITORING_AREA_TABS_PANEL_TABLE = {
  header_col1: '23%',
  header_col2: '34%',
  header_col3: '23%',
  header_col4: '20%',
};

const TH_EXPANDED_WIDTH_MONITORING_AREA_TABS_PANEL_TABLE = {
  header_col1: '20%',
  header_col2: '46%',
  header_col3: '14%',
  header_col4: '10%',
};


function Table({ data, assetDetails, globalFilterValue, refreshAssets }) {
  const store = useStore();
  const isFullMode = store.ui.menuFull;
  const [active, setActive] = useState(false);

  async function toggleActivation(id, active) {
    console.log('Toggling activation for', id);

    const res = await api.call('/area/toggle', { area_id: id, active });
    console.log(res);
    refreshAssets(assetDetails);
  }

  function handleEditButtonClick(id) {
    console.log('handleEditButtonClick', id);
    if (id == active) {
      setActive(false);
      return;
    }
    setActive(id);
  }

  async function handleDeleteButtonClick(id) {
    console.log('handleDeleteButtonClick', id);
    await api.call('/area/delete', { area_id: id });
    refreshAssets(assetDetails);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'area_id',
      },
      {
        Header: 'Date Created',
        accessor: 'created_at',
        disableGlobalFilter: true,
      },
      {
        Header: 'Area Km²',
        accessor: 'area',
      },

      {
        Header: 'Status',
        accessor: 'active',
        Cell: ({ value, row }) => (
          <>
            
            <div style={{display:'flex', justifyContent: store.ui.menuFull ? 'center' : 'flex-start'}}>
              <Status
                status={value ? "active" : "inactive"}
                className="areas_tab_panel_table_status"
              />
              <MoreActions
                active={row.original.id === active}
                handleClick={e => {
                  handleEditButtonClick(row.original.id);
                }}
                actions={[
                  {
                    func: () => toggleActivation(row.original.id, !value),
                    color: 'var(--generic-text)',
                    iconSrc: '/icons/edit_action.svg',
                    iconAlt: value ? 'De-Activate' : 'Activate',
                    name: value ? 'De-Activate' : 'Activate',
                  },
                  {
                    func: () => handleDeleteButtonClick(row.original.id),
                    color: 'var(--pastel-red)',
                    iconSrc: '/icons/delete_action.svg',
                    iconAlt: 'Delete',
                    name: 'Delete',
                  },
                  {
                    func: () => utils.exportGeoJSON(row.original.geog, row.original.area_id),
                    color: 'var(--generic-text)',
                    iconSrc: '/icons/download.svg',
                    iconAlt: 'Download',
                    name: 'Download',
                  },
                ]}
              />
            </div>
          </>
        ),
        disableGlobalFilter: true,
      },
    ],
    [active]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: {
          0: true,
        },
      },
    },
    useGlobalFilter,
    useRowSelect
  );

  useEffect(() => {
    setGlobalFilter(globalFilterValue);
  }, [globalFilterValue, setGlobalFilter]);

  return (
    <>
      <div className="areas_tab_panel_table_container">
        <table className="areas_tab_panel_table" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => {
              const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

              return (
                <tr key={key} {...restHeaderGroupProps}>
                  {headerGroup.headers.map(column => {
                    const { key, ...restHeaderProps } = column.getHeaderProps();

                    return (
                      <th
                        key={key}
                        {...restHeaderProps}
                        style={{
                          width: isFullMode
                            ? TH_EXPANDED_WIDTH_MONITORING_AREA_TABS_PANEL_TABLE[key]
                            : TH_SQUEEZED_WIDTH_MONITORING_AREA_TABS_PANEL_TABLE[key],
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);

              const { key, ...restRowProps } = row.getRowProps();

              const handleRowClick = () => {
                const { isSelected, id } = row;
                rows.forEach(item => {
                  item.toggleRowSelected(item.id !== id ? false : !isSelected);
                });
              };

              return (
                <tr
                  key={key}
                  {...restRowProps}
                  className={selectedFlatRows.includes(row) ? 'areas_tab_panel__row_selected' : ''}
                  onClick={handleRowClick}
                >
                  {row.cells.map(cell => {
                    const { key, ...restCellProps } = cell.getCellProps();
                    return (
                      <td
                        key={key}
                        title="Click to view on map"
                        onMouseEnter={() => {
                          if (data[index]) {
                            store.ui.hoveredOverlay = data[index].id.toString();
                          }
                        }}
                        onMouseLeave={() => {store.ui.hoveredOverlay = null}} {...restCellProps}
                        onClick={() => store.ui.map.state.olmap.jumpTo({geog_4326: data[index].geog})}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="areas_tab_panel_table_border" />
    </>
  );
}

export default memo(Table);
