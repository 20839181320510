import React, { useEffect, useRef } from 'react';

const MoreActions = ({ active, handleClick, actions }) => {
  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && active) {
        handleClick();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      className="more_edit_button"
      onClick={e => {
        e.stopPropagation();
        handleClick(e);
      }}
    >
      {active && (
        <div className="more_edit_popup">
          {actions.map((action, index) => (
            <div
              key={index}
              onClick={action.func}
              className="more_edit_popup_option"
              style={{ color: action.color }}
            >
              <img className="fade-in" width="32px" src={action.iconSrc} alt={action.iconAlt} />
              <div>{action.name}</div>
            </div>
          ))}
        </div>
      )}
      <div className="more_edit_dot" />
      <div className="more_edit_dot" />
      <div className="more_edit_dot" />
    </div>
  );
};

export default MoreActions;
