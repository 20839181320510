import React, { memo } from 'react';
import './TextButton.css';

function TextButton({ children, className, ...rest }) {
  return (
    <p className={`text_button ${className && className}`} {...rest}>
      {children}
    </p>
  );
}

export default memo(TextButton);
