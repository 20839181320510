import React, { useEffect } from 'react';
import './Slider.css';
import SliderItem from '../SliderItem';
import Analytics from 'src/Context/Analytics';
import { useStore } from 'src/store';

const Slider = ({
  rangeFilters,
  updateMinMax,
  updateValuefromSlider,
  pins,
  selectedPins,
  layers,
}) => {
  const store = useStore();
  const layerTypes = { raster: layers };

  // Automatically close Slider on Menu open
  useEffect(() => {
    if (store.ui.menuOpen) store.ui.sliderOpen = false;
  }, [store.ui.menuOpen]);

  if (store.ui.sliderOpen) {
    // Deployed state
    return (
      <div className="slider__container deployed">
        <div
          className="ledge"
          title="Filter layers by date"
          onClick={() => {
            store.ui.sliderOpen = false;
          }}
        >
          <div className="arrow--down" />
        </div>

        <div>
          {Object.entries(rangeFilters || {}).map((item, index) => {
            if (!item[1].status) return null;
            return (
              <SliderItem
                maxDate={rangeFilters[item[0]].max}
                minDate={rangeFilters[item[0]].min}
                type={item[0]}
                key={index}
                sliderId={index}
                status={item[1].status}
                datapoints={layerTypes[item[0]]}
                updateMinMax={updateMinMax}
                updateValuefromSlider={updateValuefromSlider}
                values={item[1].selected}
              ></SliderItem>
            );
          })}

          {pins && selectedPins.status && (
            <SliderItem
              maxDate={selectedPins.max}
              minDate={selectedPins.min}
              type={'pins'}
              key={500}
              sliderId={500}
              status={selectedPins.status}
              datapoints={pins}
              updateMinMax={updateMinMax}
              updateValuefromSlider={updateValuefromSlider}
              values={selectedPins.selected}
            ></SliderItem>
          )}
        </div>
      </div>
    );
  } else {
    // Retracted state
    return (
      <div className="slider__container">
        <div
          className="ledge"
          title="Filter layers by date"
          onClick={() => {
            store.ui.menuOpen = false;
            store.ui.sliderOpen = true;
            store.ui.menu.currentTab = null;
            Analytics.trackClick('slider');
          }}
        >
          <div className="arrow--up" />
        </div>
      </div>
    );
  }
};

export default Slider;
