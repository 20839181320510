export const EMAIL_VALIDATION_RULE = /\S+@\S+\.\S+/;

// ChannelsListTabPanel Table

export const TH_SQUEEZED_WIDTH_CHANNELS_LIST_TAB_PANEL_TABLE = {
  header_col1: '30%',
  header_col2: '33%',
  header_col3: '19%',
  header_col4: '10%',
  header_col5: '20%',
};

export const TH_EXPANDED_WIDTH_CHANNELS_LIST_TAB_PANEL_TABLE = {
  header_col1: '28%',
  header_col2: '20%',
  header_col3: '28%',
  header_col4: '10%',
  header_col5: '14',
};

export const DEFAULT_TAB_PANEL = 0;

// ChannelsSettingsTabPanel Table

export const TH_SQUEEZED_WIDTH_CHANNELS_SETTINGS_TAB_PANEL_TABLE = {
  header_col1: '45%',
  header_col2: '45%',
  header_col3: '10%',
};

export const TH_EXPANDED_WIDTH_CHANNELS_SETTINGS_TAB_PANEL_TABLE = {
  header_col1: '45%',
  header_col2: '45%',
  header_col3: '10%',
};

export const TH_EXPANDED_WIDTH_USERS_SETTINGS_TAB_PANEL_TABLE = {
  header_col1: '25%',
  header_col2: '25%',
  header_col3: '15%',
  header_col4: '7%',
};

export const CHANNEL_SETTINGS_TAB_PANEL = 4;

// ProfileTabPanel

export const MAX_AVATAR_SIZE = 2000000;

export const SWITCH_OPTIONS = {
  height: 21,
  width: 45,
  offColor: '#7c7655',
  onColor: '#7c7655',
  uncheckedIcon: false,
  checkedIcon: false,
  activeBoxShadow: 'none',
};

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export const DEFAULT_PROFILE_FORM_VALUES = {
  phone: null,
  theme: THEMES.LIGHT,
};

// TeamTabPanel

export const DEFAULT_TEAM_FORM_VALUES = {
  companyName: '',
  companyBusinessType: '',
  companyWebSite: '',
  coordinateSystem: '',
};

// UsersTabPanel

export const DEFAULT_USERS_FORM_VALUES = {
  invitationEmail: '',
};

// ChangePasswordTabPanel

export const DEFAULT_CHANGE_PASSWORD_FORM_VALUES = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const OLD_PASSWORD_ERROR = 'Incorrect old password. Please, try again.';
