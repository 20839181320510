import React, { memo, useMemo } from 'react';
import addAreaDetails from 'public/icons/cutouts/networking.png';
import TabBaseInfo from 'src/Common/TabBaseInfo';
import './SuccessfulInviteTabPanel.css';

function SuccessfulInviteTabPanel({ onClick }) {
  const imageParams = useMemo(
    () => ({
      src: addAreaDetails,
      alt: 'successful invite',
      className: 'successful_invite_tab_panel_empty_page_image',
    }),
    []
  );

  const baseButtonParams = useMemo(
    () => ({
      buttonName: 'Go to the List of Channels',
      className: 'successful_invite_tab_panel_base_button',
      onClick,
    }),
    [onClick]
  );

  return (
    <TabBaseInfo
      title={'Invites have been \n successfully sent.'}
      imageParams={imageParams}
      baseButtonParams={baseButtonParams}
    />
  );
}

export default memo(SuccessfulInviteTabPanel);
