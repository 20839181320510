import React, { useEffect, useState } from 'react';
import './Input.css';

function Input(props) {
  const { value, onChangeInput, typeInput, placeholder, returnValue } = props;

  const [showPassword, setShowPassword] = useState(props.showPassword);

  useEffect(() => {
    console.log(returnValue);
  }, []);

  const icon =
    props.typeInput === 'email'
      ? 'icons/profile.svg'
      : props.typeInput === 'password'
      ? 'icons/password.svg'
      : undefined;

  function handleOnChange(event) {
    if (returnValue) returnValue(event.target.value);
    onChangeInput(event);
  }

  return (
    <div className="Input__container">
      {icon && (
        <div
          className="Input__icon"
          style={{
            background: `transparent url(/${icon}) 0% 0% no-repeat padding-box`,
          }}
        />
      )}
      <input
        name={props.name}
        placeholder={placeholder}
        type={typeInput === 'password' && showPassword ? 'text' : typeInput}
        value={value}
        onChange={event => handleOnChange(event)}
        onFocus={event => event.target.select()}
        autoComplete={props.disableAutocomplete || 'on'}
        autoFocus={props.autoFocus || false}
      />
      {typeInput === 'password' && (
        <div
          className="Input__show__option"
          onClick={() => setShowPassword(showPassword ? false : true)}
        >
          <img src={showPassword ? 'component_164.svg' : 'component_164_2.svg'}></img>
        </div>
      )}
    </div>
  );
}

export default Input;
