import React, { memo, useCallback, useState, useEffect } from 'react';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import { useStore, ref } from 'src/store';
import Tab from 'src/Components/Common/Tab';
import { DEFAULT_TAB_PANEL, GLOBAL_MODAL_NAMES } from 'src/shared/constants';
import OptionBarItemContainer from 'src/Components/Common/OptionBarItemContainer';
import ProfileTabPanel from './ProfileTabPanel';
// eslint-disable-next-line unused-imports/no-unused-imports
import ChannelsTabPanel from './ChannelsTabPanel'; //Removing this causes arrows in assetlist to not work!?!!??!?!
import TeamTabPanel from './TeamTabPanel';
import UsersTabPanel from './UsersTabPanel';
import './Settings.css';
import WmsTabPannel from './WmsTabPanel';

Tab.tabsRole = 'Tab';

function Settings({ onClickClose, users, refreshTeam, refreshChannels }) {
  const [selectedIndex, setSelectedIndex] = useState(DEFAULT_TAB_PANEL);

  const store = useStore();
  const channels = store.data.channels;
  const menuOpen = store.ui.menuOpen;
  const isFormModified = store.ui.unsavedChanges;

  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        onClickClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const handleClickRightButton = useCallback(
    index => {
      store.ui.unsavedChanges = false;
      setSelectedIndex(index);
    },
    [setSelectedIndex]
  );

  const handleSwitchTab = useCallback(
    index => {
      if (isFormModified) {
        (store.ui.modalParams.name = GLOBAL_MODAL_NAMES.SAVE_CHANGES),
          (store.ui.modalParams.onClickRightButton = ref(() => handleClickRightButton(index)));
        return;
      }

      setSelectedIndex(index);
    },
    [handleClickRightButton]
  );

  useEffect(() => {
    store.data.channels = users.channels || channels;
  }, [users.channels, channels]);

  useEffect(() => {
    if (!menuOpen) onClickClose();
  }, [menuOpen, onClickClose]);

  useEffect(() => {
    if (!menuOpen) onClickClose();
  }, [menuOpen]);
  return (
    <OptionBarItemContainer
      title="Settings"
      icon="/icons/menu/settings.svg"
      isIconShowed={true}
      onClickBack={onClickClose}
      isExpandButtonShowed
      isArrowButtonShowed
    >
      <Tabs
        selectedIndex={selectedIndex}
        onSelect={handleSwitchTab}
        className="settings_main_container"
      >
        <TabList className="settings_tab_list">
          <Tab tabValue="Profile" />
          {store.session.user.role === 'owner' && <Tab tabValue="Team" />}
          <Tab tabValue="Users" />
          <Tab tabValue="Channels" />
          <Tab tabValue="WMS" />
        </TabList>

        <TabPanel selectedClassName="settings_tab_panel">
          <ProfileTabPanel refreshTeam={refreshTeam} />
        </TabPanel>

        {store.session.user.role === 'owner' && (
          <TabPanel selectedClassName="settings_tab_panel">
            <TeamTabPanel teamInfo={users} refreshTeam={refreshTeam} />
          </TabPanel>
        )}

        <TabPanel selectedClassName="settings_tab_panel">
          <UsersTabPanel teamInfo={users} refreshTeam={refreshTeam} />
        </TabPanel>

        <TabPanel selectedClassName="settings_tab_panel">
          <ChannelsTabPanel
            channels={users.channels}
            refreshTeam={refreshTeam}
            refreshChannels={refreshChannels}
          />
        </TabPanel>

        <TabPanel selectedClassName="settings_tab_panel">
          <WmsTabPannel />
        </TabPanel>
      </Tabs>
    </OptionBarItemContainer>
  );
}

export default memo(Settings);
