import React from 'react';
import './NotAvailablePopup.css';

function NotAvailablePopup(props) {
  return (
    <div className="container__popup">
      <h1 className="container__popup__header">{props.header}</h1>
      <p>{props.text}</p>
      <button className="close__down__box" onClick={props.onClose}>
        Close this message
      </button>
    </div>
  );
}

export default NotAvailablePopup;
