import React, { useEffect, useState } from 'react';
import InputPro from 'src/Common/InputPro';
import './AddressPro.less';

const AddressPro = ({ address }) => {
  const [searchResult, setSearchResult] = useState([]);
  const [value, setValue] = useState('');
  const [isTooltipsShown, setIsTooltipsShown] = useState(true);
  const [displayValue, setDisplayValue] = useState('');

  const performSearch = async query => {
    if (!query) {
      console.log('do nothing');
      return setSearchResult([]);
    }

    const url =
      `https://api.mapbox.com/geocoding/v5/mapbox.places/` +
      `${query}.json` +
      `?types=place,postcode,address&autocomplete=true&fuzzyMatch=true` +
      `&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;

    const result = await fetch(url);
    console.log(result);

    if (!result) {
      return setSearchResult([]);
    }

    const json = await result.json();
    console.log(json);

    if (!json.features) {
      return setSearchResult([]);
    }

    setSearchResult(json.features);
  };

  const handleClickTip = item => {
    setValue(item.place_name);
    console.log(item);
    if (address) address(item);
    setIsTooltipsShown(false);
    setValue('');
    setDisplayValue(item.place_name);
  };

  useEffect(() => {
    if (value) {
      setIsTooltipsShown(true);
      performSearch(value);
      return;
    }
    setIsTooltipsShown(false);
  }, [value]);

  return (
    <div style={{ width: '500px', margin: '0 auto' }}>
      <InputPro label="Enter Address" forceValue={displayValue} bindValue={setValue}></InputPro>
      {searchResult.length && isTooltipsShown ? (
        <div className="address_input_tooltips_container">
          {searchResult.map(item => {
            return (
              <p
                key={item.place_name}
                onClick={() => handleClickTip(item)}
                className="address_input_tooltip"
                style={{
                  backgroundColor: item.place_name === value && 'rgba(166, 158, 114, 0.3)',
                }}
              >
                {item.place_name}
              </p>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default AddressPro;
