import React, { useState } from 'react';
import ProgressBar from 'src/Components/Common/ProgressBar';
import './Progress.css';
import 'src/Components/Common/MoreActions/MoreActions.less';
import MoreActions from 'src/Components/Common/MoreActions/MoreActions';

function Progress({ progress, asset, editAsset, deleteAsset }) {
  const [id, setId] = useState();
  function handleEditButtonClick(e) {
    e.stopPropagation();
    if (id == asset.asset_id) setId(-1)
    else setId(asset.asset_id)
  }

  function handleEditAsset() {
    editAsset(asset);
  }

  function handleDelete() {
    deleteAsset(asset);
  }

  const actions = [
    {
      func: handleEditAsset,
      color: 'var(--generic-text)',
      iconSrc: '/icons/edit_action.svg',
      iconAlt: 'Edit',
      name: 'Edit',
    },
    {
      func: handleDelete,
      color: 'var(--pastel-red)',
      iconSrc: '/icons/delete_action.svg',
      iconAlt: 'Delete',
      name: 'Delete',
    },
  ];

  return (
    <div className="nature_reporting_progress_main_container">
      <div className="nature_reporting_progress_bar_main_container">
        <ProgressBar progress={progress} width="100%" fillColor={'#7C7655'} />
        <span className="nature_reporting_progress_bar_progress">{progress?.toFixed?.(1)}%</span>
        <MoreActions
          active={id == asset.asset_id}
          handleClick={handleEditButtonClick}
          actions={actions}
        />
      </div>
    </div>
  );
}

export default Progress;
