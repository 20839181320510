import React, { memo } from 'react';
import { components } from 'react-select';
import RowCheckbox from 'src/Common/Inputs/RowCheckbox';

const CheckboxOption = props => {
  const handleClick = e => {
    e.preventDefault();
  };

  return (
    <div onClick={handleClick}>
      <components.Option {...props}>
        <RowCheckbox id={props.label} label={props.label} checked={props.isSelected} readOnly />
      </components.Option>
    </div>
  );
};

export default memo(CheckboxOption);
