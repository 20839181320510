import React, { memo } from 'react';
import './Link.css';

function Link({ linkText, ...rest }) {
  return (
    <a className="link_text" {...rest}>
      {linkText}
    </a>
  );
}

export default memo(Link);
