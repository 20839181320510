import React, { memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CreateChannelWrapper from '../CreateChannelWrapper';
import Select from 'src/Components/Common/Inputs/Select';
import CheckboxOption from 'src/Components/Common/Inputs/Select/CheckboxOption';
import './AddPeopleTabPanel.css';

// TODO: remove mock data

const options = [
  { value: 'All team members', label: 'All team members' },
  { value: 'Wind/Solar ', label: 'Wind/Solar' },
  { value: 'Wind/Solar PV/Geotherma', label: 'Wind/Solar PV/Geotherma' },
  { value: 'Wind/Solar PV/Geotherm', label: 'Wind/Solar PV/Geotherm' },
  { value: 'Wind/Solar PV/Geother', label: 'Wind/Solar PV/Geother' },
];

function AddPeopleTabPanel({ onClickContinue, onClickBack }) {
  const methods = useForm();

  const onSubmit = useCallback(data => {
    // TODO: remove console.log
    console.log(data);
  }, []);

  const handleClickContinue = useCallback(() => {
    methods.handleSubmit(onSubmit)();
    onClickContinue();
  }, [methods, onClickContinue, onSubmit]);

  const handleChange = useCallback(selectedOption => {
    const [selectAllValue] = options;

    if (selectedOption.includes(selectAllValue)) {
      return options.slice(1);
    }

    return selectedOption;
  }, []);

  return (
    <FormProvider {...methods}>
      <form>
        <CreateChannelWrapper onClickContinue={handleClickContinue} onClickBack={onClickBack}>
          <Select
            name="Add People"
            placeholder="Add the people you want to invite to the channel..."
            registerName="addPeople"
            inputWrapperClassName="add_people_tab_panel_select_input_wrapper_class_name"
            options={options}
            isMulti
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option: CheckboxOption }}
            onChange={handleChange}
          />
        </CreateChannelWrapper>
      </form>
    </FormProvider>
  );
}

export default memo(AddPeopleTabPanel);
