import React, { memo } from 'react';
import DashboardPanel from './DashboardPanel';
import EmptyDashboardPanel from './EmptyDashboardPanel';

function DashboardTabPanel({
  onClickEdit,
  onClickDetails,
  assetDetails,
  areaDetails,
  assets,
  setDataframes,
  dataframes,
  fetchSpreadSheets,
  spreadSheets,
  area,
  loadingSpreadSheet,
}) {
  return assetDetails ? (
    <DashboardPanel
      onClickEdit={onClickEdit}
      onClickDetails={onClickDetails}
      assets={assets}
      assetDetails={assetDetails}
      areaDetails={areaDetails}
      setDataframes={setDataframes}
      dataframes={dataframes}
      spreadSheets={spreadSheets}
      fetchSpreadSheets={fetchSpreadSheets}
      loadingSpreadSheet={loadingSpreadSheet}
      area={area}
    />
  ) : (
    <EmptyDashboardPanel onClick={onClickEdit} />
  );
}

export default memo(DashboardTabPanel);
