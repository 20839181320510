import React, { memo } from 'react';
import faq from 'public/icons/cutouts/faq.png';
import TabBaseInfo from 'src/Common/TabBaseInfo';
import Analytics from 'src/Analytics';

function FAQTab() {
  return (
    <div>
      <TabBaseInfo
        imageParams={{
          src: faq,
          alt: 'faq icon',
        }}
        baseButtonParams={{
          buttonName: 'Go to the FAQ',
          onClick: () => {
            Analytics.trackClick('help-go-to-faq');
            window.open('https://docs.svarmi.com/en/latest/', '_blank');
          },
        }}
        title={'Everything you need to know about the product.\n Click button to find answers.'}
      />
    </div>
  );
}

export default memo(FAQTab);
