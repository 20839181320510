import React, { useState } from 'react';
import './PinColorPicker.less';
import Analytics from 'src/Analytics';

function PinColorPicker({ disabled = false, currentStatus, updateStatus }) {
  const [pickerOpen, setPickerOpen] = useState(false);

  const statusMap = {
    purple: '#A567BB',
    cyan: '#8FC4E1',
    green: '#426E47',
    yellow: '#F5AF47',
  };

  return (
    <div
      className={'pin-details-color-picker' + (disabled ? ' disabled' : '')}
      onBlur={e => setPickerOpen(false)}
      tabIndex="-1"
    >
      <div
        className="pin-details-picker-button circle"
        style={{ background: statusMap[currentStatus] }}
        onClick={() => {
          Analytics.trackClick('pin-details-color-picker');
          setPickerOpen(!pickerOpen);
        }}
      >
        <img src="/icons/color-picker.svg" />
      </div>
      {pickerOpen && (
        <div className="pin-details-picker-dropdown">
          {Object.entries(statusMap).map(([status, color]) => (
            <div
              className="circle"
              style={{ background: color }}
              key={status}
              onClick={() => {
                setPickerOpen(false);
                updateStatus(status);
              }}
            >
              {status === currentStatus && <img src="/icons/round_checkmark.svg" />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PinColorPicker;
