import React, { useState, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import Input from 'src/Common/Inputs/Input';
import BaseButton from 'src/Common/Buttons/BaseButton';
import FileInput from 'src/Common/Inputs/FileInput';
import UploadProgressBar from 'src/Common/UploadProgressBar';
import Analytics from 'src/Analytics';
import UploadPreview from 'src/ProcessingManager/UploadPreview';
import MenuPage from 'src/Common/MenuPage';
import { useStore } from 'src/store';
import api from 'src/api';
import utils from 'src/utils';

import './UploadFlow.less';
import exifr from 'exifr';
import slugify from 'slugify';

function UploadFlow() {
  const { handleSubmit, control, setValue, watch, setError, ...methods } = useForm({
    shouldUnregister: false,
  });

  const store = useStore();
  const map = store.ui.map;

  const [showPreview, setShowPreview] = useState(false);

  const images = watch('images');

  const [progressbarDone, setProgressBarDone] = useState();
  const [progressbarStarted, setProgressBarStarted] = useState();
  const [progressbarTotal, setProgressBarTotal] = useState();

  const extraFormats = [
    '.csv',
    '.txt',
    '.nav',
    '.obs',
    '.bin',
    '.mrk',
    '.ubx',
    '.dat',
    '.\\d\\d[opn]',
    '.t\\d\\d',
  ];
  const extraFormatsRegexp = new RegExp(`(\\${extraFormats.join('|\\')})$`, 'i');
  const photoFormats = ['.jpg', '.jpeg'];
  const photoFormatsRegexp = new RegExp(`(\\${photoFormats.join('|\\')})$`, 'i');

  const extraSizeLimit = 1 * Math.pow(2, 30); // 1GB
  const photoSizeLimit = 5 * Math.pow(2, 30); // 5GB
  const totalSizeLimit = 1 * Math.pow(2, 40); // 1TB

  const [uploading, setUploading] = useState(false);

  const [done, setDone] = useState(false);
  const onSubmit = async data => {
    try {
      const { images, extras, datasetName } = data;

      // validate extra files
      const errorMessage = await validateExtraFiles(extras);

      if (errorMessage) {
        setError('extras', errorMessage);
        return;
      }

      // Limit total size
      const totalSize = [...images, ...extras].reduce((t, p) => t + p.size, 0);
      if (totalSize > totalSizeLimit) {
        setError('images', {
          type: 'custom',
          message: `Upload (${utils.formatBytes(
            totalSize
          )}) exceeds our size limit (${utils.formatBytes(totalSizeLimit)})`,
        });
      }

      // upload all of the files and launch the cloud processing
      // AWS is a piece of crap and will fail silently with stuff like
      // "InvalidSignatureException" if there are spaces or other weird
      // characters in the filename ; even if URLEncoded according to spec!
      const datasetNameSlug = `${utils.slugify(datasetName)}_${crypto.randomUUID().substring(0, 4)}`;
      const path = `${utils.slugify(store.session.team.name)}/${utils.slugify(datasetNameSlug)}`;

      await uploadAllPhotos(path, extras, images);
      await launchCloudProcessing(datasetName, datasetNameSlug);

      setDone(true);
    } catch (error) {
      setError('images', { type: 'custom', message: error });
    } finally {
      setShowPreview(false);
      setUploading(false);
    }
  };

  const fileCountLimit = ({'enterprise': 10000, 'advanced': 3000, 'baseline': 400})[store.session.team.plan] || 400;

  const validateExtraFiles = async files => {
    const extras = files;

    for (let extra of extras) {
      // Limit individual size
      if (extra.size > extraSizeLimit)
        return `"${extra.name}" (${utils.formatBytes(
          extra.size
        )}) exceeds our file size limit (${utils.formatBytes(extraSizeLimit)})`;

      // Check format
      if (!extraFormatsRegexp.test(extra.name))
        return `"${extra.name}" is not in one of the accepted formats: ${photoFormats.join(', ')}`;
    }
  };

  console.log(fileCountLimit);
  const validateImages = async photos => {
    // check at least one image selected
    if (!photos || photos.length < 3) {
      return 'Please select a minimum of 3 photos';
    }

    // Limit file count
    if (photos.length > fileCountLimit)
      return `Please upload ${fileCountLimit} photos or less (${photos.length} selected)`;

    for (let photo of photos) {
      // Limit individual size
      if (photo.size > photoSizeLimit)
        return `"${photo.name}" (${utils.formatBytes(
          photo.size
        )}) exceeds our file size limit (${utils.formatBytes(photoSizeLimit)})`;

      // Check .jpg
      if (!photoFormatsRegexp.test(photo.name)) return `"${photo.name}" is not jpg or jpeg format`;

      // Check EXIF geotag
      if (!(await exifr.gps(photo))) return `"${photo.name}"" is missing an EXIF geotag.`;
    }
  };

  const handleErrorOnPreview = error => {
    setError('images', { type: 'custom', message: error });
  };

  async function uploadAllPhotos(path, extras, photos) {
    // Set up a task list with parallel uploads and retries,
    // instead of launching everything at once or only doing them one by one
    const maxParallelUploads = 10;
    const maxRetries = 3;

    const tasks = [].concat(extras, photos).map(file => ({
      file,
      status: 'remaining', // remaining -> running -> success / failed
      errors: [],
    }));

    let remaining, running, success;
    function updateStatus() {
      remaining = tasks.filter(u => u.status === 'remaining');
      running = tasks.filter(u => u.status === 'running');
      success = tasks.filter(u => u.status === 'success');
      setProgressBarStarted(success.length + running.length);
      setProgressBarDone(success.length);

      // Shove failed tasks back at the end of the queue to be retried later
      remaining = remaining.sort((a, b) => a.errors.length - b.errors.length);
    }

    // Main loop
    setProgressBarTotal(tasks.length);
    updateStatus();
    while (remaining.length || running.length) {
      // Launch the next request if able
      while (remaining.length && running.length <= maxParallelUploads) {
        const task = remaining[0];
        task.status = 'running';
        uploadSinglePhoto(task.file, path)
          .then(() => {
            task.status = 'success';
            updateStatus();
          })
          .catch(e => {
            // Give up after maxRetries
            task.status = task.errors.push(e) < maxRetries ? 'remaining' : 'failed';
            updateStatus();
          });
        updateStatus();
      }

      // Wait a bit before checking again
      await new Promise(r => setTimeout(r, 1000));
      updateStatus();
    }

    // Wait for all requests to complete then mark a one second pause
    // to let the user actually see the completed upload

    await new Promise(r => setTimeout(r, 1000));

    // Flow back any transient errors tha may have happened, even if everything eventually succeeded
    const warnings = tasks.filter(t => t.errors.length);
    if (warnings.length)
      Analytics.reportClientError(
        warnings.map(t => ({
          ...t,
          name: t.file.name,
          messages: t.errors.map(e => e.message),
        }))
      );

    // If some files are missing entirely, we cannot go on as normal and have to involve support
    const failures = warnings.filter(t => t.status === 'failed');
    if (failures.length)
      throw new Error(
        `All files have been successfully uploaded except for the following. Please contact support or try the upload again: \n${failures
          .map(e => e.file.name)
          .join('\n')}`
      );
  }

  async function uploadSinglePhoto(photo, path) {
    // First get a presigned bundle for it
    const response = await api.call(`/getPresignedUpload/${path}/${
      // Custom slugify because we want to keep the .JPG at the end (instead of turning into _jpg)
      slugify(photo.name, { remove: /[^A-Za-z0-9.]/g, replacement: '_', lower: false })
    }`);
    const signedRequestBundle = response.success;

    if (!signedRequestBundle) {
      Analytics.reportClientError(response);
      throw new Error(`Unable to obtain an upload URL: ${response.error || response}`);
    }

    // Then build a request from the signed bundle
    const formFields = new FormData();
    Object.entries(signedRequestBundle.fields).forEach(_ => formFields.append(..._));
    formFields.append('file', photo);

    // And send it
    const result = await fetch(signedRequestBundle.url, {
      method: 'POST',
      body: formFields,
    });
    return result;
  }

  async function launchCloudProcessing(display_name, dataset_slug) {
    // Trigger cloud processing of the uploaded images
    const complete = await api.call(`/uploadCompleted`, {
      display_name,
      dataset_slug,
      coordinate_system: store.data.teamInfo.coordinate_system,
      user: store.session.user.display_name,
    });

    if (!complete?.info?.['$metadata']?.requestId) {
      Analytics.reportClientError(complete);
      throw new Error(
        `All files have successfully been uploaded, but a problem occurred during processing. Retrying the upload is not needed. Please contact support: \n${JSON.stringify(
          complete
        )}`
      );
    }
  }

  if (uploading) {
    return (
      <UploadProgressBar
        progressbarDone={progressbarDone}
        progressbarTotal={progressbarTotal}
        progressbarStarted={progressbarStarted}
      />
    );
  }

  if (done) {
    return (
      <div className="create_data_done_container">
        <div className="create_data_done_icon"></div>
        <p>
          All images have been successfully uploaded!<br/>
          Processing has started,<br />
          and you will be notified by email once complete.
        </p>
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        className="create_data_form_main_container"
        onSubmit={handleSubmit(e => {
          // Prevent multiple submits in a row
          if (uploading) return;
          setUploading(true);
          onSubmit(e);
        })}
      >
        {showPreview && map?.state?.olmap && images && (
          <UploadPreview
            map={map.state.olmap}
            photos={images}
            error={handleErrorOnPreview}
            cancel={() => setShowPreview(false)}
          />
        )}
        <div className="create_data_form_info_container">
          <p>
            Here you can create your own 2D and 3D data from your own images. To create 2D and 3D
            maps the images need to follow some basic rules such as overlap etc. For further
            information click{' '}
            <a href="https://docs.svarmi.com" target="_blank" rel="noreferrer">
              here
            </a>
            .
          </p>
          <div className="create_data_form_input_container">
            <Input
              name="Name of Dataset"
              placeholder="Enter a name of dataset(e.g. reykjavik_123)"
              registerName="datasetName"
              registerRules={{ required: 'Add a name for your dataset' }}
            />
            <FileInput
              name="images"
              registerRules={{
                required: { message: 'Please upload a minimum of 3 images', value: true },
                validate: validateImages,
              }}
              control={control}
              setValue={setValue}
            />
          </div>
          <p>
            Please note that this service is only available to certain subscriptions. To upgrade or
            to see other plans available click{' '}
            <a
              href="https://www.svarmi.com/products/datact#Pricing"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
          <p>
            Powered by{' '}
            <a href="https://www.agisoft.com/" target="_blank" rel="noreferrer">
              Agisoft Metashape
            </a>{' '}
          </p>
          <div className="create_data_form_continue_button_container">
            <BaseButton
              type="button"
              disabled={!(images?.length >= 3) || showPreview}
              onClick={e => {
                Analytics.trackClick('processing-show-preview');
                e.target.disabled = true;
                setShowPreview(true);
              }}
            >
              Preview
            </BaseButton>
            <BaseButton
              type="submit"
              disabled={!(images?.length >= 3) || uploading}
              onClick={e => {
                Analytics.trackClick('context-menu-upload');
                e.target.style.pointerEvents = 'none';
              }}
            >
              Upload
            </BaseButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}


export { UploadFlow as UploadFlow };
export function UploadFlowWrapper({ onClickClose }) {
  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        onClickClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <MenuPage
      title="Processing"
      onClickBack={onClickClose}
      isExpandButtonShowed
      icon="/icons/upload_cloud.svg"
      isIconShowed={true}
      isShowingChannel={true}
      isArrowButtonShowed={true}
    >
      <UploadFlow />
    </MenuPage>
  );
};