import React from 'react';
import './AssetInfo.less';
import utils from 'src/utils';

const AssetInfo = asset => {
  asset = asset.asset;
  return (
    <div className="asset_info_container">
      <div className="asset_info_column">
        <div className="asset_info_label">
          Sector: <div className="asset_info_value"> {asset.industry || 'No Sector'} </div>
        </div>
        <div className="asset_info_label">
          Subsector: <div className="asset_info_value">{asset.sector || 'No Sub Sector'}</div>
        </div>
      </div>

      <div className="asset_info_column">
        <div className="asset_info_label">
          Address: <div className="asset_info_value"> {asset.address || 'No Address'} </div>
        </div>
        <div className="asset_info_label">
          Coordinates:
          <div style={{ textWrap: 'nowrap' }} className="asset_info_value">
            {utils.parseLocation(asset.location)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetInfo;
