import React, { memo } from 'react';
import { useStore } from 'src/store';

function Widget({
  amount,
  description,
  amountClassName,
  descriptionClassName,
  className,
  overlapKey,
}) {
  const formattedAmount = amount; //typeof amount === 'number' ? formatArea(amount) : amount || 0;
  const store = useStore();

  const clickFunc = () => {
    if (!overlapKey || store.ui.assetOverlap.loading || amount === '0.0 %' || amount === "N/A") return; //amount should be done better but it works for now

    if (overlapKey === store.ui.assetOverlap.currentOverlap) {
      store.ui.assetOverlap.currentOverlap = null;
    } else {
      store.ui.assetOverlap.currentOverlap = overlapKey;
    }
  };

  const isActive = () => {
    return overlapKey === store.ui.assetOverlap.currentOverlap;
  };

  return (
    <div
      className={`dashboard_panel_widget_main_container ${className && className}`}
      style={{
        cursor: overlapKey ? 'pointer' : 'default',
        pointerEvents: overlapKey ? 'auto' : 'none',
        border: isActive() && overlapKey ? '1px groove var(--gold)' : 'none',
      }}
      onClick={() => clickFunc()}
    >
      <p className={`dashboard_panel_widget_amount ${amountClassName && amountClassName}`}>
        {overlapKey != null
          ? store.ui.assetOverlap.loading
            ? ''
            : formattedAmount
          : formattedAmount}
      </p>
      <p
        className={`dashboard_panel_widget_description ${
          descriptionClassName && descriptionClassName
        }`}
      >
        {overlapKey != null ? (store.ui.assetOverlap.loading ? '' : description) : description}
      </p>
      {
        overlapKey != null && store.ui.assetOverlap.loading && (
          <div className="widget-loading-spinner-container">
            <div className="widget-loading-spinner"></div>
          </div>
        ) //Loading Spinner
      }
    </div>
  );
}

function formatArea(area) {
  if (area <= 0) {
    return '0';
  }

  return area < 1e5
    ? `${area.toLocaleString('en-US', {
        maximumFractionDigits: 0,
      })} m²`
    : `${(area / 1e6).toLocaleString('en-US', {
        maximumFractionDigits: 2,
      })} km²`;
}

export default memo(Widget);
