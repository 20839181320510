import React from 'react';
import { useStore } from 'src/store';
import utils from 'src/utils';
import './LayerDetails.less';

function LayerDetails({ layer }) {
  const store = useStore();
  const accuracy = layer?.dataset?.accuracy;
  const resolution = layer?.dataset?.ground_resolution;

  if (store.ui.menuOpen && store.ui.menuFull && store.ui.menu.currentTab) return <></>;
  if (!layer) return <></>;

  return (
    <div className="layer-details" style={{ bottom: store.ui.sliderOpen && 185 }}>
      <dl>
        <dt>Name</dt>
        <dd style={{ maxWidth: 280 }} title={layer?.dataset?.display_name}>
          {layer?.dataset?.display_name}
        </dd>
      </dl>

      <dl>
        <dt>Capture date</dt>
        <dd>{utils.formatDate(layer?.raster?.origin_date)} UTC</dd>
      </dl>

      <dl>
        <dt>Accuracy</dt>
        {
          accuracy === 'superb' ? <dd className="quality-tag best">Best</dd> :
          accuracy === 'high'   ? <dd className="quality-tag high">High</dd> :
          accuracy === 'medium' ? <dd className="quality-tag medium">Medium</dd> :
          accuracy === 'low'    ? <dd className="quality-tag low">Low</dd> :
          <dd className="quality-tag">N/A</dd> 
        }
      </dl>

      <dl>
        <dt>Resolution</dt>
        {
          resolution ? (
            resolution <= 0.04 ? <dd className="quality-tag best">{resolution} m/px</dd> :
            resolution <= 0.12 ? <dd className="quality-tag high">{resolution} m/px</dd> :
            resolution <= 0.25 ? <dd className="quality-tag medium">{resolution} m/px</dd> :
            <dd className="quality-tag low">{resolution} m/px</dd>
          ) : <dd className="quality-tag">N/A</dd>
        }
        </dl>
      </div>
  );
}

export default LayerDetails;
