import React, { memo, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Input from 'src/Components/Common/Inputs/Input';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import api from 'src/api';
import utils from 'src/utils';

import RadioButton from 'src/Components/Common/Inputs/RadioButton';
import Analytics from 'src/Context/Analytics';

import { getCenter } from 'ol/extent';

function SatelliteImageryForm({ shape, onComplete }) {
  const methods = useForm({ shouldUnregister: false });
  const { getValues, setValue, watch } = methods;

  const [error, setError] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [availablePasses, setAvailablePasses] = useState(null);
  const selectedPass = watch('selectedPass');

  useEffect(() => {
    const fetchAddress = async () => {
      if (!shape) return;
      const place = await utils.getGeocodedPlacename(...getCenter(shape.getExtent()));
      if (!getValues('requestName')) setValue('requestName', place);
      refreshAvailablePasses();
    };

    fetchAddress();
  }, [shape, setValue]);

  async function refreshAvailablePasses() {
    try {
      setAvailablePasses('loading');
      const result = await api.call('/satellite/list', { area: shape.getCoordinates() });
      console.log(result);
      if (!result?.passes) throw 'Error while fetching satellite passes';
      setAvailablePasses(result.passes);
    } catch (error) {
      setError(error);
      Analytics.reportClientError(error);
    }
  }

  const onSubmit = async data => {
    try {
      const { requestName, /*dataType,*/ selectedPass } = data;

      // Trigger satellite API
      setRefreshing(true);
      const result = await api.call('/satellite/fetch', {
        area: shape.getCoordinates(),
        //type: dataType,
        pass: selectedPass,
        name: requestName,
      });
      if (result?.info?.msg !== 'Succesfully saved satellite data')
        throw 'Failed to launch satellite data processing';

      // Loading bar illusion + page refresh at the end
      const delay = 10;
      setTimeout(() => onComplete(shape), (delay + 2) * 1000);
      requestAnimationFrame(() =>
        Array.from(document.querySelectorAll('.satellite-imagery .progressbar-fill')).forEach(e => {
          e.style.transitionDuration = delay + 's';
          e.style.transitionTimingFunction = 'linear';
          e.style.width = '100%';
        })
      );
    } catch (error) {
      setError(error);
      Analytics.reportClientError(error);
    }
  };

  if (error) {
    return <div style={{ color: 'var(--pastel-red)' }}>{error.toString()}</div>;
  }

  if (refreshing) {
    return (
      <div className="satellite-imagery">
        <p>Satellite data is being fetched...</p>
        <div className="progressbar" style={{ marginBottom: 0 }}>
          <div className="progressbar-fill" />
          <div className="progressbar-fill" />
        </div>
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form className="satellite-imagery" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="">
          <div className="">
            <Input
              name="Layer name"
              placeholder="Enter a name for this satellite layer"
              registerName="requestName"
              registerRules={{ required: 'Enter a name for this layer' }}
            />
            {/*
            <div className="">
              <h2>Type</h2>
              <div className="request_data_form_radio_button_input_container">
                <RadioButton
                  label="RGB"
                  registerName="dataType"
                  className="request_data_form_radio_button"
                  value="rgb"
                  defaultChecked={true}
                />
                <RadioButton
                  label="NDVI"
                  registerName="dataType"
                  className="request_data_form_radio_button"
                  value="ndvi"
                />
                <RadioButton
                  label="Green Index"
                  registerName="dataType"
                  className="request_data_form_radio_button"
                  value="green_index"
                />
                <RadioButton
                  label="World Cover"
                  registerName="dataType"
                  className="request_data_form_radio_button"
                  value="world_cover"
                />
              </div>
            </div>
            */}
            <div className="">
              <h2>Satellite passes</h2>
              {availablePasses === null && 'No area selected'}
              {availablePasses === 'loading' && (
                <div>
                  <span className="spinner" />
                  Loading...
                </div>
              )}
              {Array.isArray(availablePasses) &&
                (!availablePasses.length
                  ? 'No passes available for this area in the last 15 days'
                  : availablePasses.map((p, i) => (
                      <RadioButton
                        registerName="selectedPass"
                        value={p.datetime}
                        key={i}
                        label={`${p.datetime}: ${p.cloud_coverage}% cloudy`}
                      />
                    )))}
            </div>
            <div className="request_data_form_continue_button_container">
              <BaseButton
                disabled={!selectedPass}
                type="submit"
                onClick={e => Analytics.trackClick('satellite-imagery-confirm')}
              >
                Confirm
              </BaseButton>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default memo(SatelliteImageryForm);
