import React, { useEffect, useState } from 'react';
import './InputPro.less';

const InputPro = ({
  defaultValue,
  bindValue,
  label,
  placeholder,
  type = 'text',
  inactiveLabel,
  className = '',
  fixedLabel,
  forceValue,
  subFix,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (defaultValue && !inputValue) {
      setInputValue(defaultValue);
      bindValue(defaultValue);
      console.log(defaultValue);
    }
  }, []);

  useEffect(() => {
    if (forceValue) {
      setInputValue(forceValue);
    }
  }, [forceValue]);

  const handleInputChange = e => {
    const value = e.target.value;

    setInputValue(value);
    bindValue(value);
    console.log(e.target.value);
    if (onChange) onChange(e);
  };

  return (
    <div className={className}>
      <div className={`input-container ${inputValue || fixedLabel ? 'active' : ''}`}>
        <label className="label">
          {inputValue ? label : inactiveLabel ? inactiveLabel : label}
        </label>
        <input
          type={type}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
        />
        {subFix && <span className="input_subfix">{subFix}</span>}
      </div>
    </div>
  );
};

export default InputPro;
