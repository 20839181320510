import React, { useState } from 'react';
import TextToolTip from 'src/Components/Common/TextToolTip/TextToolTip';
import './InfoHover.less';

function InfoHover({ href, text, iconClass }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div className="Info__Hover" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <img
          className={iconClass ? iconClass : 'upperIcon'}
          src="icons/tooltip.svg"
          alt="Tooltip Icon"
        />
        {isHovered && <TextToolTip tooltipContent={text} />}
      </div>
    </a>
  );
}

export default InfoHover;
