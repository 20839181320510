import React, { memo } from 'react';
import Tooltip from 'src/Common/Tooltip';
import './InputWrapper.less';
import InfoHover from 'src/Common/InfoHover';

function InputWrapper({
  children,
  name,
  error = '',
  tooltipId,
  tooltipContent,
  inputWrapperClassName = '',
  isDisabled = false,
}) {
  return (
    <div
      className={`input_wrapper_main_container ${inputWrapperClassName && inputWrapperClassName} ${
        isDisabled && 'input_wrapper_disabled'
      }`}
    >
      <div className={`input_wrapper_container ${error && 'input_wrapper_container_error'}`}>
        {children}
        <div className={`input_wrapper_name ${error && 'input_wrapper_name_error'}`}>
          {name}
          {tooltipContent && <InfoHover text={tooltipContent}></InfoHover>}
          <Tooltip tooltipId={tooltipId} tooltipContent={tooltipContent} isError={!!error} />
        </div>
      </div>
      {error?.message ? <span className="input_wrapper_error">{error.message}</span> : null}
    </div>
  );
}

export default memo(InputWrapper);
