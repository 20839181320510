import React, { memo } from 'react';
import ConnectForm from 'src/Common/Inputs/ConnectForm';
import './RadioButton.css';

function RadioButton({
  label,
  registerName,
  registerRules = {},
  className = '',
  isDisabled = false,
  ...rest
}) {
  return (
    <ConnectForm>
      {({ register }) => {
        return (
          <label
            className={`radio_button_label_main_container ${className && className} ${
              isDisabled && 'radio_button_label_disabled'
            }`}
            htmlFor={label}
          >
            <div className="radio_button_main_container">
              <input
                {...rest}
                className="radio_button"
                type="radio"
                id={label}
                disabled={isDisabled}
                {...register(registerName, registerRules)}
              />
            </div>
            {label}
          </label>
        );
      }}
    </ConnectForm>
  );
}

export default memo(RadioButton);
