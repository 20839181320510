import React from 'react';
import Inspect from 'src/Components/Inspect';
import './ColorBar.less';

function ColorBar({ map, layer }) {
  const range = (min, max, n) => [...new Array(n + 1)].map((x, i) => min + (i / n) * (max - min));

  if (['thermal', 'diff_thermal'].includes(layer.raster.raster_type)) {
    let min = window.thermalMin === undefined ? -10 : window.thermalMin;
    let max = window.thermalMax === undefined ? 50 : window.thermalMax;
    return (
      <>
        <Inspect layer={layer} map={map} type="thermal" key="thermal" />
        <div
          id="colorbar"
          style={{
            backgroundImage: `linear-gradient(to top, ${range(min, max, 100)
              .map((s, i) => {
                const { r, g, b, a } = ColorBar.toColor(s, 'thermal');
                return `rgba(${r}, ${g}, ${b}, ${a / 255}) ${i}%`;
              })
              .join(', ')})`,
          }}
        >
          {range(min, max, 8).map((s, i) => (
            <div key={i}>
              {i === 8 && '≥'}
              {i === 0 && '≤'}
              {s.toFixed(2)}°C
            </div>
          ))}
        </div>
      </>
    );
  }

  if (['dem', 'diff_dem', 'dsm', 'diff_dsm'].includes(layer.raster.raster_type)) {
    let min = window.elevationMin === undefined ? -10 : window.elevationMin;
    let max = window.elevationMax === undefined ? 10 : window.elevationMax;
    return (
      <>
        <Inspect layer={layer} map={map} type="elevation" key="elevation" />
        <div
          id="colorbar"
          style={{
            backgroundImage: `linear-gradient(to top, ${range(min, max, 100)
              .map((s, i) => {
                const { r, g, b, a } = ColorBar.toColor(s, 'elevation');
                return `rgba(${r}, ${g}, ${b}, ${a / 255}) ${i}%`;
              })
              .join(', ')})`,
          }}
        >
          {range(min, max, 8).map((s, i) => (
            <div key={i}>
              {i === 8 && '≥'}
              {i === 0 && '≤'}
              {(s > 0 ? '+' : '') + s.toFixed(2)}m
            </div>
          ))}
        </div>
      </>
    );
  }
}

ColorBar.interpolate = function (nodes, t_) {
  const t = Math.min(Math.max(t_, 0), 1);
  const i = nodes.findIndex(n => t < n.t);
  const prev = nodes[i] ?? nodes[nodes.length - 1];
  const next = nodes[i - 1] ?? nodes[0];

  const f = (t - prev.t) / (next.t - prev.t);
  return {
    t: f,
    r: f * (next.r - prev.r) + prev.r,
    g: f * (next.g - prev.g) + prev.g,
    b: f * (next.b - prev.b) + prev.b,
    a: f * (next.a - prev.a) + prev.a,
  };
};

ColorBar.toColor = function (value, type) {
  let min = -1,
    max = 1,
    scale = ColorBar.COLOR_SCALES.mordor;

  if (type === 'thermal') {
    min = window.thermalMin === undefined ? -10 : window.thermalMin;
    max = window.thermalMax === undefined ? 50 : window.thermalMax;
    scale = ColorBar.COLOR_SCALES.magma;
  }

  if (type === 'elevation') {
    min = window.elevationMin === undefined ? -10 : window.elevationMin;
    max = window.elevationMax === undefined ? 10 : window.elevationMax;

    // For DEM, there are three scenarios:
    // Either both min and max are above zero, in which case the scale goes from green to dark green
    if (min > 0 && max > 0) {
      scale = ColorBar.COLOR_SCALES.Gg;
    }

    // Or both min and max are below zero, in which case scale goes from red to dark red
    if (min < 0 && max < 0) {
      scale = ColorBar.COLOR_SCALES.Rr;
    }

    // Or zero is within the scale, in which case we make it symmetrical and zero as transparent
    if (min <= 0 && max >= 0) {
      max = Math.max(max, -min);
      min = Math.min(-max, min);
      scale = ColorBar.COLOR_SCALES.RtG;
    }
  }

  let v = Math.min(Math.max((value - min) / (max - min), 0), 1);
  const { r, g, b, a } = ColorBar.interpolate(scale, v);
  if (type === 'thermal' && (value === -10000 || value === 0)) return { r: 0, g: 0, b: 0, a: 0 };
  return { r, g, b, a };
};

ColorBar.COLOR_SCALES = {
  magma: [
    { t: 0.0, r: 0, g: 0, b: 0, a: 255 },
    { t: 0.25, r: 87, g: 20, b: 126, a: 255 },
    { t: 0.5, r: 196, g: 59, b: 117, a: 255 },
    { t: 0.75, r: 252, g: 143, b: 100, a: 255 },
    { t: 1.0, r: 252, g: 253, b: 191, a: 255 },
  ],
  mordor: [
    { t: 0.0, r: 0, g: 0, b: 0, a: 255 },
    { t: 0.25, r: 65, g: 65, b: 65, a: 255 },
    { t: 0.5, r: 203, g: 61, b: 45, a: 255 },
    { t: 0.75, r: 243, g: 219, b: 119, a: 255 },
    { t: 1.0, r: 255, g: 255, b: 255, a: 255 },
  ],
  viridis: [
    { t: 0.0, r: 68, g: 1, b: 84, a: 255 },
    { t: 0.25, r: 59, g: 82, b: 139, a: 255 },
    { t: 0.5, r: 33, g: 145, b: 140, a: 255 },
    { t: 0.75, r: 94, g: 201, b: 98, a: 255 },
    { t: 1.0, r: 253, g: 231, b: 37, a: 255 },
  ],
  RtG: [
    { t: 0.0, r: 75, g: 0, b: 0, a: 255 },
    { t: 0.4, r: 255, g: 0, b: 0, a: 255 },
    { t: 0.5, r: 255, g: 255, b: 255, a: 0 },
    { t: 0.6, r: 0, g: 255, b: 0, a: 255 },
    { t: 1.0, r: 0, g: 75, b: 0, a: 255 },
  ],
  Gg: [
    { t: 0.0, r: 0, g: 0, b: 0, a: 0 },
    { t: 0.01, r: 0, g: 255, b: 0, a: 255 },
    { t: 1.0, r: 0, g: 75, b: 0, a: 255 },
  ],
  Rr: [
    { t: 0.0, r: 75, g: 0, b: 0, a: 255 },
    { t: 0.99, r: 255, g: 0, b: 0, a: 255 },
    { t: 1.0, r: 255, g: 255, b: 255, a: 0 },
  ],
};

export default ColorBar;
