import React from 'react';
import InputPro from 'src/Components/Common/InputPro';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import Tab from 'src/Components/Common/Tab';
import { useStore } from 'src/store';
import AddressPro from 'src/Components/Common/AddressPro/AddressPro';
import './LocationSelection.less';

const LocationSelection = ({ locationInfo }) => {
  const store = useStore();

  const gpsMessage = 'Please enter exact GPS Coordinates of the new resource.';
  const addressMessage = 'Please enter the exact address of the new resource.';
  const manualMessage = 'Please drag the map to the location of the new resource.';


  const message = locationInfo.selectedTab == 'Gps' ? 
    gpsMessage : (locationInfo.selectedTab == 'Address' ? addressMessage : manualMessage);

  const icon = locationInfo.selectedTab == 'Gps' ? '/icons/address_icon.svg' : (locationInfo.selectedTab == 'Address' ? '/icons/gps.svg' : '/icons/manual_drag.svg');

  console.log('LocationSelection', locationInfo.selectedTab, message);
  return (
    <>
    <div className='location_info_container'>
        <div className='location_info_message'>{message}<img src={icon}></img></div>
    </div>
    <Tabs className="nature_reporting_create_asset_tab" defaultIndex={locationInfo.defaultIndex ? locationInfo.defaultIndex : 0}>
      <TabList className="select_asset_creation_type">
        <Tab
          className="long"
          icon="/icons/gps.svg"
          tabValue="Location Address"
          onClick={() => locationInfo.setSelectedTab('Address')}
        />
        <Tab
          className="long"
          icon="/icons/address_icon.svg"
          tabValue="GPS Coordinates"
          onClick={() => locationInfo.setSelectedTab('Gps')}
        />
        <Tab
          className="long"
          icon="/icons/manual_drag.svg"
          tabValue="Manual Selection"
          onClick={() => {
            locationInfo.setSelectedTab('Manual');
            store.ui.mapPreviewPin.style = 'manual';
          }}
        />
      </TabList>

      <div className="nature_reporting_create_nature_report_main_container">
        <TabPanel>
          <div className="address_top_gap" />
          <AddressPro
            address={newAddress => {
              locationInfo.setLongitude(parseFloat(newAddress.center[0]));
              locationInfo.setLatitude(parseFloat(newAddress.center[1]));
              if (locationInfo.setAddressExternal) locationInfo.setAddressExternal(newAddress);
              locationInfo.setAddressLock(true);
              locationInfo.setAddress(newAddress.place_name);
              store.ui.mapPreviewPin.location = [newAddress.center[0], newAddress.center[1]];
              store.ui.mapPreviewPin.style = 'address';
            }}
          />
          {/*<AddressInput
                name="Company Address"
                placeholder="Enter your company address"
                registerName="addressSearch"
                address={newAddress => {
                  locationInfo.setLongitude(parseFloat(newAddress.center[0]));
                  locationInfo.setLatitude(parseFloat(newAddress.center[1]));
                  locationInfo.setAddressExternal(newAddress);
                  locationInfo.setAddressLock(true);
                  locationInfo.setAddress(newAddress.place_name);
                  store.ui.mapPreviewPin.location = [newAddress.center[0], newAddress.center[1]];
                  store.ui.mapPreviewPin.style = 'address';
                }}
              />*/}
        </TabPanel>

        <TabPanel>
          <div className="nature_reporting_create_nature_report_gps_container">
            <InputPro
              defaultValue={locationInfo.latitude}
              bindValue={locationInfo.setLatitude}
              inactiveLabel={'Enter Latitude Coordinates'}
              label={'Latitude'}
              className="latitude"
              type="number"
            />
            <InputPro
              defaultValue={locationInfo.longitude}
              bindValue={locationInfo.setLongitude}
              inactiveLabel={'Enter Longitude Coordinates'}
              label={'Longitude'}
              className="longitude"
              type="number"
            />
          </div>
        </TabPanel>

        <TabPanel>
          <div
            className={
              locationInfo.centerStyle ? 'nature_reporting_create_nature_gps_container' : null
            }
          >
            <div className="manual_header"> Location Details </div>
            <div className="nature_reporting_create_nature_report_manual_container">
              <div>Address: {locationInfo.address ? locationInfo.address : 'None'}</div>
              {store.ui.mapPreviewPin?.location && (
                <div style={{ display: 'flex', gap: '15px' }}>
                  <div>
                    Latitude:{' '}
                    {typeof store.ui.mapPreviewPin.location[0] === 'number'
                      ? store.ui.mapPreviewPin.location[0].toFixed(5)
                      : store.ui.mapPreviewPin.location[0]}
                  </div>
                  <div>
                    Longitude:{' '}
                    {typeof store.ui.mapPreviewPin.location[1] === 'number'
                      ? store.ui.mapPreviewPin.location[1].toFixed(5)
                      : store.ui.mapPreviewPin.location[1]}
                  </div>
                </div>
              )}
            </div>
          </div>
        </TabPanel>
      </div>
    </Tabs>
    </>
  );
};

export default LocationSelection;
