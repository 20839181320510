import React, { useState } from 'react';
import './DataList.less';
import { useStore } from 'src/store';
import api from 'src/api';
import BaseModal from 'src/Components/Common/Modal/BaseModal';
import utils from 'src/utils';
import InfoHover from 'src/Components/InfoHover/InfoHover';

const DataList = ({ asset, reload, setEditId, setEditType, spreadSheets, loadSpreadSheets }) => {
  console.log(asset);

  const store = useStore();

  const [modalOpen, setModalOpen] = useState(null);
  const [modalFunction, setModalFunction] = useState(null);

  const [foldWater, setFoldWater] = useState(false);
  const [foldEnergy, setFoldEnergy] = useState(false);
  const [foldSpreadSheets, setFoldSpreadsheets] = useState(false);

  const [deleteInfo, setDeleteInfo] = useState(null);

  function capitalize(string) {
    if (typeof string !== 'string') return string;
    if (string.length === 0) return string;
    string = string.replace(/_/g, ' ');
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async function deleteData(type, id) {
    switch (type) {
      case 'water':
        await api.call('/asset/water_usage/delete', { water_usage_id: id });
        reload();
        setModalOpen(false);
        break;
      case 'electricity':
        await api.call('/asset/energy_usage/delete', { energy_usage_id: id });
        reload();
        setModalOpen(false);
        break;
      case 'spreadsheet':
        await api.call('/asset/data_frame/delete', { dataframe_id: id });
        reload();
        setModalOpen(false);
        break;
      default:
        break;
    }
  }

  function SubFixBody(location, id, deleteType, name) {
    return (
      <>
        {/*<img className='edit' onClick={() => {setEditId(element.water_usage_id), setEditType("water")}} src='/icons/edit.svg' alt='trash' />*/}
        {location && (
          <img
            className="location"
            onClick={() => store.ui.map.state.olmap.jumpTo({ point_4326: location })}
            src="/icons/address_icon.svg"
            alt="trash"
          />
        )}
        <img
          className="trash"
          onClick={() => {
            setModalFunction(() => () => deleteData(deleteType, id)), setModalOpen(true);
            setDeleteInfo(name || deleteType);
          }}
          src="/icons/delete_action.svg"
          alt="trash"
        />
      </>
    );
  }

  let foldWaterClass = 'dropDown ' + (!foldWater ? 'up' : 'down');
  let foldEnergyClass = 'dropDown ' + (!foldEnergy ? 'up' : 'down');
  let foldSpreadSheetsClass = 'dropDown ' + (!foldSpreadSheets ? 'up' : 'down');

  console.log(spreadSheets);
  return (
    <div>
      {modalOpen && (
        <BaseModal
          rightButton="Delete"
          onClickRightButton={() => modalFunction()}
          onClickCancel={() => setModalOpen(false)}
        >
          <div className="data_modal">Delete {deleteInfo}?</div>
        </BaseModal>
      )}

      {/* WATER USAGE */}
      <div
        className={`data_catagory_header ${!foldWater ? 'active' : ''}`}
        onClick={() => setFoldWater(!foldWater)}
      >
        <img src="/icons/water.svg" alt="water icon" /> Water Usage
        <img className={foldWaterClass} src="/icons/arrow.svg" />
      </div>
      <div className={`data_collection ${foldWater ? 'fold' : ''}`}>
        {asset?.water_usage?.map((element, index) => (
          <div className="sub_asset_element" key={index}>
            <div className="data_list_column">
              <div className="data_item">
                <span className="data_label">Type:</span>
                <span className="data_value">{capitalize(element.action)}</span>
              </div>

              <div className="data_item">
                <span className="data_label">Water Source:</span>
                <span className="data_value">
                  {`${capitalize(element.source)} (${element.amount?.toLocaleString('en-US', { maximumFractionDigits: 1 })}hL)`}
                </span>
              </div>
            </div>

            <div className="data_list_column">
              <div className="data_item">
                <span className="data_label">Location:</span>
                <span className="data_value">{utils.parseLocation(element.location)}</span>
              </div>
              <div className="data_item">
                <span className={`data_back_plate${element.confirmed ? ' confirmed' : ''}`}>
                  {element.confirmed ? 'CONFIRMED' : 'ESTIMATED'}
                </span>
              </div>
            </div>

            {SubFixBody(element.location, element.water_usage_id, 'water')}
          </div>
        ))}
        {asset?.water_usage?.length === 0 && (
          <div className="sub_asset_element">No Water Usage Data</div>
        )}
      </div>

      {/* ENERGY USAGE */}
      <div
        className={`data_catagory_header ${!foldEnergy ? 'active' : ''}`}
        onClick={() => setFoldEnergy(!foldEnergy)}
      >
        <img src="/icons/energy.svg" alt="energy icon" /> Energy Usage
        <img
          className={foldEnergyClass}
          onClick={() => setFoldEnergy(!foldEnergy)}
          src="/icons/arrow.svg"
        />
      </div>
      <div className={`data_collection ${foldEnergy ? 'fold' : ''}`}>
        {asset?.energy_usage?.map((element, index) => (
          <div className="sub_asset_element" key={index}>
            <div className="data_item energy">
              <span className="data_label">Energy Source:</span>
              <span className="data_value">
                {`${capitalize(element.source)} (${element.amount?.toLocaleString('en-US', { maximumFractionDigits: 1 })}kWh)`}
              </span>
            </div>

            <div className="data_item energy">
              <span className="data_label">Location:</span>
              <span className="data_value">{utils.parseLocation(element.location)}</span>
            </div>

            {SubFixBody(element.location, element.energy_usage_id, 'electricity')}
          </div>
        ))}
        {asset?.energy_usage?.length === 0 && (
          <div className="sub_asset_element">No Energy Usage Data</div>
        )}
      </div>

      {/* SPREADSHEETS */}
      <div
        className={`data_catagory_header ${!foldSpreadSheets ? 'active' : ''}`}
        onClick={() => setFoldSpreadsheets(!foldSpreadSheets)}
      >
        <img src="/icons/copy.svg" alt="copy icon" /> Spreadsheets
        <img
          className={foldSpreadSheetsClass}
          onClick={() => setFoldEnergy(!foldEnergy)}
          src="/icons/arrow.svg"
        />
      </div>
      <div className={`data_collection ${foldSpreadSheets ? 'fold' : ''}`}>
        {spreadSheets?.map((area, areaIndex) =>
          area?.map((element, elementIndex) => {
            console.log(element);
            // Since tableRows are now the first element of values, adjust accordingly
            const values = element.data[0]?.values || [];
            const tableRowsExist = values.length > 0;

            return (
              tableRowsExist && (
                <div className="sub_asset_element" key={`${areaIndex}-${elementIndex}`}>
                  {element.name !== undefined && (
                    <div className="data_list_column">
                      <div className="data_item">
                        <span className="data_label">Name:</span>
                        <span className="data_value">{element.name}</span>
                      </div>
                    </div>
                  )}

                  {/* Now accessing the first row for headers directly from values */}
                  {values[0] !== undefined && (
                    <div className="data_list_column">
                      <div className="data_item">
                        {/* Displaying the first header as an example, similar to the original Table Row 1 */}
                        <span className="data_label">Area:</span>
                        <span className="data_value">{asset.areas?.find(area => element.area_id == area.area_id)?.nameOfArea || "N/A"}</span>
                      </div>
                    </div>
                  )}
                  
                  {element.description && <InfoHover text={element.description} iconClass={"spreadsheet_info_icon"}/>}
                  {SubFixBody(null, element.dataframe_id, 'spreadsheet', element.name)}
                </div>
              )
            );
          })
        )}
        {spreadSheets.length < 1 && (
          <div className="sub_asset_element" key="no-spread-sheets">
            {loadSpreadSheets ? '...Loading Spreadsheets' : 'No Spreadsheets'}
          </div>
        )}
      </div>
    </div>
  );
};

export default DataList;
