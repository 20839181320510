import React from 'react';
import './Avatar.less';

export default function Avatar(props) {
  const style = props.size ? { width: props.size, height: props.size } : {};

  if (props.user) {
    return (
      <img
        className="avatar"
        src={`//datact-avatars.s3.eu-west-1.amazonaws.com/users/${encodeURIComponent(props.user)}`}
        onError={e => {
          if (!e.target.src?.endsWith('/user_placeholder.png'))
            e.target.src = '/user_placeholder.png';
        }}
        title={props.user}
        alt={props.user}
        style={style}
      />
    );
  }

  if (props.team) {
    return (
      <img
        className="avatar"
        src={`//datact-avatars.s3.eu-west-1.amazonaws.com/teams/${props.team}`}
        onError={e => {
          if (!e.target.src?.endsWith('/team_placeholder.png'))
            e.target.src = '/team_placeholder.png';
        }}
        title={props.team}
        alt={props.team}
        style={style}
      />
    );
  }

  return <img className="avatar" src="/user_placeholder.png" style={style} />;
}
