import React, { useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import './ChartsPro.less';

const ChartsPro = ({
  chartType,
  spreadSheet,
  disabledColumns,
  ChartData,
  selectedColumn,
  vertical,
  stacked,
}) => {
  const chartRef = useRef(null);
  const [chartOption, setChartOption] = useState({});

  const updateChart = () => {
    let labels = [];
    let yAxis = [];
    let xAxis = [];
    let seriesData = [];
    let seriesNames = ['Default Series'];
    let yAxisName = 'Default Y-Axis Name';

    if (spreadSheet?.data) {
      const { values } = spreadSheet.data[0];
      yAxisName = values[0][0];
      yAxis = values.map(value => value[0]);
      labels = values.slice(1).map(value => value[0]);
      seriesData = values[0]
        .slice(1)
        .map((_, colIndex) =>
          values.slice(1).map(row => ({ value: parseFloat(row[colIndex + 1]), name: row[0] }))
        );

      seriesNames = values[0].slice(1).map(header => header.toString());
      xAxis = values.map(row => row.slice(1));
    }

    let series = [];

    if (chartType === 'pie') {
      console.log(seriesData)
      series = [{
        name: seriesNames[selectedColumn - 1],
        type: chartType,
        data: seriesData[selectedColumn - 1],        
      }];
    } else {
      series = seriesData
        .map((data, index) => ({
          name: seriesNames[index],
          type: chartType,
          data: data,
        }))
        .filter((_, index) => !disabledColumns.includes(index + 1));

      xAxis = xAxis.map(row => row.filter((_, index) => !disabledColumns.includes(index + 1)));
    }

    // Adjust xAxis and yAxis for pie chart
    if (chartType === 'pie') {
      yAxis = seriesData[selectedColumn - 1];
      xAxis = [seriesNames[selectedColumn - 1]];
    }
    ChartData(yAxis, xAxis);

    const updatedOptions = {
      tooltip: chartType != 'pie' ? { trigger: 'axis' } : { trigger: 'item' },
      legend: { data: seriesNames, selectedMode: false },
      xAxis: chartType !== 'pie' ? {
        type: 'category',
        boundaryGap: chartType !== 'line',
        data: labels,
        axisLabel: {
          interval: 0,
          rotate: vertical ? 0 : 30,
        },
      } : null,
      yAxis: chartType !== 'pie' ? {
        type: 'value',
        name: yAxisName,
        
      } : null,
      series: series,
    };

    if (stacked) {
      updatedOptions.series.forEach(serie => {
        serie.stack = stacked;
      });
    }

    if (vertical) {
      console.log(updatedOptions);
      var temp = updatedOptions.xAxis;
      updatedOptions.xAxis = updatedOptions.yAxis;
      updatedOptions.yAxis = temp;
    }
    const chartInstance = chartRef.current.getEchartsInstance();
    chartInstance.setOption(updatedOptions, true);
    setChartOption(updatedOptions);
  };


  useEffect(() => {
    updateChart();
  }, [spreadSheet, disabledColumns, vertical, stacked, selectedColumn]);

  useEffect(() => {
    updateChart();
  }, [chartType]);

  return (
    <div className="chart_creation_preview">
      <ReactECharts ref={chartRef} option={chartOption} />
    </div>
  );
};

export default ChartsPro;
