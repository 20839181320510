import {
  Circle as CircleStyle,
  RegularShape as RegularShapeStyle,
  Fill,
  Stroke,
  Text,
  Style,
  Icon,
} from 'ol/style';

import pinShapeSrc from './icons/pin.svg';
import pinTextIconSrc from './icons/text.svg';
import pinFileIconSrc from './icons/file.svg';
import pinImageIconSrc from './icons/image.svg';
import pinPanoramaIconSrc from './icons/panorama.svg';
import pinCameraTrapIconSrc from './icons/camera_trap.svg';
import pinLiveCameraIconSrc from './icons/live_camera.svg';
import pinPreviewIconSrc from './icons/location_pin.svg';
import assetIconSrc from './icons/asset.svg';
import energyIconSrc from './icons/energy.svg';
import waterIconSrc from './icons/water.svg';
import dataframeIconSrc from './icons/copy.svg';
import layerIconSrc from './icons/layer.svg';

const colors = {
  pink: '#FB6262',
  green: '#426E47',
  gold: '#7C7655',
  black: '#3A3A3A',
  purple: '#A567BB',
  blue: '#2668A7',
  blueLight: '#4A90E2',
  cyan: '#8FC4E1',
  gray: '#AAAAAA',
  white: '#FFFFFF',
  yellow: '#F5AF47',
  yellowLight: '#F5D547',
};

const hexShape = (color, radius, border) =>
  new RegularShapeStyle({
    points: 6,
    radius: radius || 20,
    fill: new Fill({ color }),
    stroke: new Stroke({
      color: border ? 'white' : 'transparent',
      width: border ? 3 : 0,
    }),
  });

const pinShape = (color, scale = 1) =>
  new Icon({
    anchor: [0.5, 1],
    src: pinShapeSrc,
    color,
    scale,
  });

const layerShape = (color, hovered) =>
  new CircleStyle({
    radius: hovered ? 33 : 22,
    fill: new Fill({ color }),
    stroke: new Stroke({
      color: hovered ? 'white' : 'transparent',
      width: hovered ? 3 : 0,
    }),
  });

const font = 'bold 20px Open sans';
const fill = new Fill({ color: colors.white });

const blueHex = new Style({ image: hexShape(colors.blue) });
const nullHex = new Style();
const grayHex = new Style({ image: hexShape(colors.gray) });
const hoverHex = new Style({ image: hexShape(colors.blue, 35, true) });
const assetIcon = new Style({ image: new Icon({ src: assetIconSrc }) });
const energyIcon = new Style({ image: new Icon({ src: energyIconSrc }) });
const waterIcon = new Style({ image: new Icon({ src: waterIconSrc }) });
const dataframeIcon = new Style({ image: new Icon({ src: dataframeIconSrc }) });
const previewIcon = new Style({
  image: new Icon({
    src: pinPreviewIconSrc,
    displacement: [0, 15],
  }),
});

const IconStyles = {
  assetLocationMuted: [grayHex, assetIcon],
  assetLocationHovered: [hoverHex, assetIcon],
  assetLocation: [blueHex, assetIcon],
  energyLocation: [blueHex, energyIcon],
  waterLocation: [blueHex, waterIcon],
  previewLocation: [nullHex, previewIcon],
  dataframeLocation: [grayHex, dataframeIcon],

  pin: (type, status, displacement, selected) => [
    new Style({ image: pinShape(colors[status] || colors.yellow, selected ? 2 : 1) }),
    new Style({
      image: new Icon({
        displacement: selected ? [0, 50] : displacement ? displacement : [0, 25],
        src: {
          image: pinImageIconSrc,
          panorama: pinPanoramaIconSrc,
          live_camera: pinLiveCameraIconSrc,
          camera_trap: pinCameraTrapIconSrc,
          vegagerdin: pinLiveCameraIconSrc,
          file: pinFileIconSrc,
        }[type] || pinTextIconSrc,
      }),
    }),
  ],

  pinCluster: (n, maxStatus, hovered) =>
    new Style({
      image: pinShape(colors[maxStatus] || colors.yellow, hovered ? 2 : 1),
      text: new Text({
        text: n.toString(),
        offsetY: hovered ? -45 : -23,
        font,
        fill,
        scale: hovered ? 2 : 1,
      }),
    }),

  layer: hovered => [
    new Style({ image: layerShape(colors.gold, hovered) }),
    new Style({ image: new Icon({ src: layerIconSrc }) }),
  ],

  layerCluster: (n, hovered) =>
    new Style({
      image: layerShape(colors.gold, hovered),
      text: new Text({
        text: n.toString(),
        font,
        fill,
      }),
    }),
};

export default IconStyles;
