import waterIcon from 'public/icons/water.svg';
import energyIcon from 'public/icons/energy.svg';

// NatureReporting

export const SCREENS = {
  NATURE_REPORTING_ASSETS: 'natureReportingAssets',
  NATURE_REPORTING_DETAILS: 'natureReportingDetails',
};

//NatureReportingAssets

export const NATURE_REPORTING_ASSETS_DEFAULT_TAB_INDEX = 0;

// TooltipsWidget

export const RED_LIST_SPECIES_DESCRIPTION = 'Red List Species';

export const RED_LIST_SPECIES = {
  CRITICALLY_ENDANGERED: 'Critically Endangered',
  ENDANGERED: 'Endangered',
  VULNERABLE: 'Vulnerable',
  NEAR_THREATENED: 'Near Threatened',
};

export const RED_LIST_LABEL = {
  [RED_LIST_SPECIES.CRITICALLY_ENDANGERED]: 'ce',
  [RED_LIST_SPECIES.ENDANGERED]: 'en',
  [RED_LIST_SPECIES.VULNERABLE]: 'vu',
  [RED_LIST_SPECIES.NEAR_THREATENED]: 'nt',
};

export const RED_LIST_NAMES = {
  cr: 'Critically Endangered',
  en: 'Endangered',
  vu: 'Vulnerable',
  nt: 'Near Threatened',
};

//TeamDashboardTabPanel

export const DEPENDENCIES_DATA = [
  'Name 1',
  'Name 2',
  'Name 3',
  'Name 4',
  'Name 5',
  'Name 6',
  'Name 7',
  'Name 8',
  'Name 9',
  'Name 10',
];

// ResourceLabel

export const RESOURCE_TYPES = {
  WATER: 'water',
  ENERGY: 'energy',
};

export const RESOURCE_TITLES = {
  [RESOURCE_TYPES.WATER]: 'Water Usage',
  [RESOURCE_TYPES.ENERGY]: 'Energy Usage',
};

export const RESOURCE_IMAGES = {
  [RESOURCE_TYPES.WATER]: waterIcon,
  [RESOURCE_TYPES.ENERGY]: energyIcon,
};

export const RESOURCE_TOOLTIP_CONTENT = {
  [RESOURCE_TYPES.WATER]:
    'Here you need to put in the exact information on the water usage this asset uses. You need to know the volume as well as the source the Abstraction and Discharge take place. The exact location is not needed and can be automatically estimated, although exact location is recommended for the most accurate results',
  [RESOURCE_TYPES.ENERGY]:
    'Here you need to put in the exact information on the energy being used in this asset only. You need to know the amount as well as the primary source.',
};

export const RESOURCE_TOOLTIP_ID = {
  [RESOURCE_TYPES.WATER]: 'waterResourceLabelTooltip',
  [RESOURCE_TYPES.ENERGY]: 'energyResourceLabelTooltip',
};

// WaterUsageForm

export const RADIO_BUTTON_VALUES = {
  ABSTRACTION: 'abstraction',
  DISCHARGE: 'discharge',
};

//Status

export const STATUS_TYPES = {
  CONFIRMED: 'Confirmed',
  ESTIMATED: 'Estimated',
  ACTIVE: 'Active',
  ARCHIVE: 'Archive',
  INACTIVE: 'Inactive',
};

// BarChart

const AXIS_LABEL = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 15,
  fontFamily: 'Open Sans',
};

const AXIS_LINE = {
  show: true,
  lineStyle: {
    color: '#000',
  },
};

export const OPTION = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    textStyle: { ...AXIS_LABEL, color: '#747474' },
    formatter: 'Assets: {c0}<br /> Score: {b0}',
  },
  color: '#7c7655',
  grid: {
    containLabel: true,
    left: '3%',
    right: '0%',
    bottom: '0%',
    top: '20%',
  },
  xAxis: [
    {
      type: 'category',
      data: [],
      axisTick: {
        alignWithLabel: true,
      },
      axisLine: AXIS_LINE,
      axisLabel: AXIS_LABEL,
    },
  ],
  yAxis: [
    {
      type: 'value',
      axisLine: AXIS_LINE,
      axisTick: {
        show: true,
        length: 10,
      },
      axisLabel: { ...AXIS_LABEL, margin: 15 },
      name: 'Score',
      nameTextStyle: { ...AXIS_LABEL, color: '#8E8E8E', fontSize: 10 },
    },
  ],
  series: [
    {
      name: 'Number of assets',
      type: 'bar',
      barWidth: '60%',
    },
  ],
};

export const BAR_CHART_SIZES = {
  height: 150,
  width: 300,
};

// NatureReportingAssetsList Table

export const TH_SQUEEZED_WIDTH_NATURE_REPORTING_ASSETS_LIST_TABLE = {
  header_display_name: '50%',
  //header_Location: '10%',
  header_opportunity_score: '10%',
  header_Progress: '40%',
};

export const TH_EXPANDED_WIDTH_NATURE_REPORTING_ASSETS_LIST_TABLE = {
  header_display_name: '40%',
  //header_Location: '7%',
  header_opportunity_score: '10%',
  header_priorityScore: '10%',
  header_Progress: '15%',
};

export const PRIORITY_RANK_SCORE_ID = 'priorityScore';

export const DEFAULT_DELETE_ASSET_MODAL_OPTIONS = {
  isShowed: false,
  assetName: '',
  assetId: null,
};

// NatureReportingDetails FilesTabPanel Table

export const TH_SQUEEZED_WIDTH_NATURE_REPORTING_DETAILS_FILES_TAB_PANEL_TABLE = {
  header_file_location: '30%',
  header_file_size: '16%',
  header_created_at: 'auto',
};

export const TH_EXPANDED_WIDTH_NATURE_REPORTING_DETAILS_FILES_TAB_PANEL_TABLE = {
  header_file_location: '30%',
  header_file_size: '10%',
  header_created_at: 'auto',
};

// MonitoringAreasTabPanel Table

export const TH_SQUEEZED_WIDTH_MONITORING_AREA_TABS_PANEL_TABLE = {
  header_col1: '23%',
  header_col2: '34%',
  header_col3: '23%',
  header_col4: '20%',
};

export const TH_EXPANDED_WIDTH_MONITORING_AREA_TABS_PANEL_TABLE = {
  header_col1: '20%',
  header_col2: '46%',
  header_col3: '14%',
  header_col4: '10%',
};

// AoITabPanel Table

export const TH_SQUEEZED_WIDTH_AOI_TABS_PANEL_TABLE = {
  header_col1: '25%',
  header_col2: '25%',
  header_col3: '25%',
  header_col4: '25%',
};

export const TH_EXPANDED_WIDTH_AOI_TABS_PANEL_TABLE = {
  header_col1: '25%',
  header_col2: '25%',
  header_col3: '25%',
  header_col4: '25%',
};

// SankeyDiagram

export const CHART_FONT = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 15,
  fontFamily: 'Open Sans',
  color: '#747474',
};

export const SANKEY_DIAGRAM_SIZE = { width: 630, height: 315 };
export const SANKEY_DIAGRAM_SIZE_FULL_MODE = { width: 850, height: 425 };

// NatureTrendsChart

export const NATURE_TRENDS_CHART_OPTION = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    textStyle: { ...AXIS_LABEL, color: '#747474' },
    formatter: p => `${p[0].value.toFixed(3)}<br /> Year:${p[0].axisValue}`, // '{c0.toFixed(3)}<br /> Year: {b0}',
  },
  color: '#006400',
  grid: {
    containLabel: true,
    left: '10px',
    right: '10px',
    bottom: '10px',
    top: '10px',
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLabel: AXIS_LABEL,
    axisLine: AXIS_LINE,
  },
  yAxis: {
    name: '%',
    type: 'value',
    min: 'dataMin',
    max: 'dataMax',
    axisLine: AXIS_LINE,
    axisTick: {
      show: true,
      length: 10,
    },
    axisLabel: { ...AXIS_LABEL, margin: 15, formatter: v => `${v.toFixed(3)}` },
    nameTextStyle: { ...AXIS_LABEL, color: '#8E8E8E', fontSize: 10 },
  },
  series: {
    type: 'scatter',
    symbolSize: 5,
    data: [],
    z: 155,
    symbol: 'circle',
    symbolRotate: 45,
    symbolOffset: [0, 0],
    itemStyle: {
      borderColor: '#006400',
      borderWidth: 2,
      color: '#fff',
    },
  },
};

export const NATURE_TRENDS_CHART_SIZE = { width: 300, height: 150 };

// WaterUsageTrendChart

export const WATER_USAGE_TREND_CHART_OPTION = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    textStyle: { ...AXIS_LABEL, color: '#747474' },
    formatter: '{c0} hL<br /> Year: {b0}',
  },
  color: '#0064C8',
  grid: {
    containLabel: true,
    left: '10px',
    right: '10px',
    bottom: '10px',
    top: '10px',
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLabel: AXIS_LABEL,
    axisLine: AXIS_LINE,
  },
  yAxis: {
    name: 'hL',
    type: 'value',
    min: 'dataMin',
    max: 'dataMax',
    axisLine: AXIS_LINE,
    axisTick: {
      show: true,
      length: 10,
    },
    axisLabel: { ...AXIS_LABEL, margin: 15 },
    nameTextStyle: { ...AXIS_LABEL, color: '#8E8E8E', fontSize: 10 },
  },
  series: { type: 'line', smooth: true, data: [] },
};

// EnergyUsageTrendChart

export const ENERGY_USAGE_TREND_CHART_OPTION = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    textStyle: { ...AXIS_LABEL, color: '#747474' },
    formatter: '{c0} kWh<br /> Year: {b0}',
  },
  color: '#F15A29',
  grid: {
    containLabel: true,
    left: '10px',
    right: '10px',
    bottom: '10px',
    top: '10px',
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLabel: AXIS_LABEL,
    axisLine: AXIS_LINE,
  },
  yAxis: {
    name: 'kWh',
    type: 'value',
    min: 'dataMin',
    max: 'dataMax',
    axisLine: AXIS_LINE,
    axisTick: {
      show: true,
      length: 10,
    },
    axisLabel: { ...AXIS_LABEL, margin: 15 },
    nameTextStyle: { ...AXIS_LABEL, color: '#8E8E8E', fontSize: 10 },
  },
  series: { type: 'line', smooth: true, data: [] },
};

// Forest trend chart

export const FOREST_TREND_CHART_OPTION = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    textStyle: { ...AXIS_LABEL, color: '#747474' },
    formatter: '{c0}ha<br /> Year: {b0}',
  },
  color: '#006400',
  grid: {
    containLabel: true,
    left: '10px',
    right: '10px',
    bottom: '10px',
    top: '10px',
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLabel: AXIS_LABEL,
    axisLine: AXIS_LINE,
  },
  yAxis: {
    name: 'ha',
    type: 'value',
    min: 'dataMin',
    max: 'dataMax',
    axisLine: AXIS_LINE,
    axisTick: {
      show: true,
      length: 10,
    },
    axisLabel: { ...AXIS_LABEL, margin: 15, formatter: v => `${v.toFixed(2)}ha` },
    nameTextStyle: { ...AXIS_LABEL, color: '#8E8E8E', fontSize: 10 },
  },
  series: { type: 'line', smooth: true, data: [] },
};

// TooltipsWidget Tooltip

export const TOOLTIP_STATUSES = {
  INCREASING: 'increasing',
  DECREASING: 'decreasing',
};
