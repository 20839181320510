import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { Fill, Stroke, Style } from 'ol/style';
import { MapboxVectorLayer } from 'ol-mapbox-style';
import { Tile as TileLayer, WebGLTile as WebGLTileLayer, Image as ImageLayer } from 'ol/layer';
import { XYZ, TileJSON } from 'ol/source';
import Analytics from 'src/Context/Analytics';
import api from 'src/api';
import ColorBar from 'src/Components/ColorBar';
import GML from 'ol/format/GML';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';
import UTIF from 'utif';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
// import ExampleWMSConfig from './ExampleWMSConfig';

// The default RGB layer, using XYZ requests to Mapserver
function getRGBLayer(item) {
  return new TileLayer({
    properties: { modelObject: item },
    title: item.name,
    type: 'tiled',
    minZoom: 9,
    extent: item.extent_3857,
    source: new XYZ({
      url:
        `${window.store.ui.mapserverUrl}&LAYERS=${item.path}` +
        `&SERVICE=TMS&MODE=TILE` +
        `&TILE={x}+{y}+{z}`,
      tileLoadFunction: customLoader(),
    }),
  });
}

// UNUSED
// The legacy default RGB layer (tiled, but using BBOX requests to Mapserver under the hood)
function getRGBLayerBBOX(item) {
  return new TileLayer({
    properties: { modelObject: item },
    title: item.name,
    type: 'tiled',
    minZoom: 9,
    extent: item.extent_3857,
    source: new TileWMS({
      url: window.store.ui.mapserverUrl,
      params: {
        LAYERS: [item.path],
        min: 0,
        max: 30,
        TILED: true,
      },
      serverType: 'mapserver',
      transparent: 'true',
      isBaseLayer: false,
      tileLoadFunction: customLoader(),
    }),
  });
}

// Floating point tiffs layers for things like thermal and DSM (as opposed to pixel color values)
function getFloatLayer(item) {
  const type = item.raster.raster_type.includes('thermal') ? 'thermal' : 'elevation';
  return new TileLayer({
    properties: { modelObject: item },
    title: item.name,
    type: type,
    minZoom: 9,
    extent: item.extent_3857,
    source: new TileWMS({
      url: window.store.ui.mapserverUrl,
      params: {
        LAYERS: [item.path],
        FORMAT: 'image/tiff',
      },
      serverType: 'mapserver',
      transparent: 'true',
      isBaseLayer: false,
      tileLoadFunction: customLoader(
        async res => await urlFromBuffer(colormap(await bufferFromTIFF(res), type))
      ),
    }),
  });
}

// All the basemap layers underneath
// We have to pass each one as an array to allow for the possibility of vector street names + raster imagery
function getBasemaps() {
  return {
    light: [
      new /*WebGL*/TileLayer({ // note: enabling webgl breaks curtain
        visible: true,
        preload: 2,
        dontDelete: true,
        source: new XYZ({
          attributions: 'Maptiler Outdoor',
          url:
            'https://api.maptiler.com/maps/' +
            'outdoor/' +
            '{z}/{x}/{y}.png' +
            '?key=' +
            process.env.REACT_APP_MAPTILER_TOKEN,
          tileSize: 512,
          maxZoom: 22,
          crossOrigin: 'anonymous',
        }),
        style: {
          variables: { saturation: 0 },
          saturation: ['var', 'saturation'],
        },
      }),
    ],

    satellite: [
      new TileLayer({
        visible: false,
        preload: 2,
        dontDelete: true,
        source: new XYZ({
          attributions: 'Maptiler Satellite',
          url:
            'https://api.maptiler.com/tiles/' +
            'satellite-v2/' +
            '{z}/{x}/{y}.jpg' +
            '?key=' +
            process.env.REACT_APP_MAPTILER_TOKEN,
          tileSize: 512,
          maxZoom: 22,
          crossOrigin: 'anonymous',
        }),
      }),
    ],

    hybrid: [
      new TileLayer({
        visible: false,
        preload: 2,
        dontDelete: true,
        source: new XYZ({
          attributions: 'Maptiler Satellite',
          url:
            'https://api.maptiler.com/tiles/' +
            'satellite-v2/' +
            '{z}/{x}/{y}.jpg' +
            '?key=' +
            process.env.REACT_APP_MAPTILER_TOKEN,
          tileSize: 512,
          maxZoom: 22,
          crossOrigin: 'anonymous',
        }),
      }),
      new MapboxVectorLayer({
        // This is a *Mapbox binary format* being served by *maptiler.com*
        visible: false,
        preload: 2,
        dontDelete: true,
        maxZoom: 22,
        styleUrl:
          'https://api.maptiler.com/maps/hybrid/style.json' +
          '?key=' +
          process.env.REACT_APP_MAPTILER_TOKEN,
      }),
    ],
  };
}

function getWMSLayers() {
  let config = []; // ExampleWMSConfig;
  try{ config = JSON.parse(window?.store?.data?.teamInfo?.wms_config) || [] } catch(e) { console.error(e)}
  console.log({ wms_config: config })
  return config.map(layer_config => {
    if(layer_config.TileLayer && layer_config.TileLayer.source.TileWMS) {
      
      layer_config.TileLayer.source = new TileWMS(layer_config.TileLayer.source.TileWMS);
      
      //if(layer_config.properties.legend === "auto")
      //  layer_config.properties.legend = layer_config.TileLayer.source.getLegendUrl()
      
      layer_config = new TileLayer(layer_config.TileLayer);
      
      return layer_config;
    }

    if(layer_config.TileLayer && layer_config.TileLayer.source.XYZ) {
      
      layer_config.TileLayer.source = new XYZ(layer_config.TileLayer.source.XYZ);      
      layer_config = new TileLayer(layer_config.TileLayer);
      
      return layer_config;
    }
  })
}

// UNUSED AND DEPRECATED
// Shapefile layers will use Mapserver to stream out of a shapefile only the tiny fraction of the vector features that are actually visible
// Whereas a straight up VectorLayer would just already contains all of its geometry at initialization
function getShapefileLayer(item) {
  const color = crypto.randomUUID().slice(-6); // Random color to differentiate betwen multiple vector layers
  return new VectorLayer({
    title: item.name,
    type: 'shapefile',
    source: new VectorSource({
      format: new GML(),
      url: `${window.store.ui.mapserverUrl}/&LAYERS=${item.name}&VERSION=1.1.0&SERVICE=WFS&REQUEST=GetFeature&TYPENAME=${item.name}`,
    }),
    style: new Style({
      stroke: new Stroke({ color, width: 3 }),
      fill: new Fill({ color }),
    }),
  });
}

// UNUSED AND DEPRECATED
// So-called "fullscreen" layers are queried as a single tile spanning the viewport, and requeried again every time the viewport changes
function getFullscreenLayer(item) {
  return new ImageLayer({
    properties: { modelObject: item },
    title: item.name,
    type: 'fullscreen',
    minZoom: 9,
    extent: item.extent_3857,
    source: new ImageWMS({
      url: window.store.ui.mapserverUrl,
      params: {
        LAYERS: [item.path],
      },
      serverType: 'mapserver',
      transparent: 'true',
      isBaseLayer: false,
      strategy: bboxStrategy,
      imageLoadFunction: customLoader(),
    }),
  });
}

// Loading helper functions to fetch and reinterpret the data from those layers:
function customLoader(loader) {
  return async (tile, src) => {
    let res;
    try {
      res = await fetch(src, {
        method: 'GET',
        mode: 'cors',
        headers: {
          authorization: `Bearer ${await api.token()}`,
        },
      });
      if (!res.ok) throw new Error('Tile request has error response');
      // res.headers.get("content-type") === "image/tiff"
      tile.getImage().src = loader ? await loader(res) : URL.createObjectURL(await res.blob());
    } catch (error) {
      error.src = src;
      error.res = res;
      error.headers = res?.headers?.entries?.();
      Analytics.reportClientError(error);
      return error;
    }
  };
}

async function bufferFromTIFF(res) {
  const tiff_bytes = await res.arrayBuffer();
  const subfiles = UTIF.decode(tiff_bytes);
  UTIF.decodeImage(tiff_bytes, subfiles[0]); // only bother decoding the first tiff subfile
  return {
    buffer: subfiles[0].data.buffer,
    width: subfiles[0].width,
    height: subfiles[0].height,
  };
}

// UNUSED
async function bufferFromPNG(res) {
  // Note, this could also be used to pass down float32 values encoded as 4 bytes over rgba
  const blob = await res.blob();
  const bitmap = await createImageBitmap(blob);
  const canvas = document.createElement('canvas');
  canvas.width = bitmap.width;
  canvas.height = bitmap.height;
  const context = canvas.getContext('2d');
  context.drawImage(bitmap, 0, 0);
  return context.getImageData(0, 0, bitmap.width, bitmap.height).data.buffer;
}

/*
// UNUSED
// Function to request a value at a particular pixel by calling mapserver
olmap.on('singleclick', async evt => {
    try {
    const { olmap, layers } = this.state;

    const clickedWMSLayers = [];

    olmap.forEachLayerAtPixel(evt.pixel, layer => {
        if (layers.includes(layer)) {
        clickedWMSLayers.push(layer.getSource());
        }
    });

    const view = olmap.getView();
    const viewResolution = view.getResolution();

    const wmsSource = clickedWMSLayers[0];

    olmap.getTargetElement().style.cursor = 'wait';

    const url = wmsSource.getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {
        INFO_FORMAT: 'text/html',
    });

    if (url) {
        const myHeaders = new Headers();

        const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
        myHeaders.append('authorization', token);

        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
        mode: 'cors',
        };

        const req = await fetch(url, requestOptions);

        const res = await req.text();

        olmap.getTargetElement().style.cursor = '';

        this.setState({ pixelValue: res });
    }
    } catch (error) {
    this.state.olmap.getTargetElement().style.cursor = '';
    }
});
*/

async function urlFromBuffer({ buffer, width, height }) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d');
  const array = new Uint8ClampedArray(buffer);
  context.putImageData(new ImageData(array, width, height), 0, 0);

  return URL.createObjectURL(await new Promise(r => canvas.toBlob(r)));
}

function colormap({ buffer, width, height }, type) {
  const f32vals = new Float32Array(buffer);
  const u8vals = new Uint8ClampedArray(buffer);

  // Float32 temperature to Uint8 RGBA
  for (let i = 0; i < f32vals.length; i++) {
    const rgba = ColorBar.toColor(f32vals[i], type);
    u8vals[i * 4 + 0] = rgba.r;
    u8vals[i * 4 + 1] = rgba.g;
    u8vals[i * 4 + 2] = rgba.b;
    u8vals[i * 4 + 3] = rgba.a;
  }

  return { buffer, width, height };
}


// Curtain function that will run for each layer on each rendering to clip either half
// See https://openlayers.org/en/latest/examples/layer-swipe.html
// And https://openlayers.org/en/latest/examples/webgl-layer-swipe.html for reference

function curtainPreRender(event) {
  if (!window.store.ui.map.props.curtain) return;

  const { width, height } = event.context.canvas;
  const fraction = window.store.ui.map.state.curtainSliderValue / 100;
  const side = event.target.get('curtainSide');

  const renderRect = // [x, y, width, height]
    side === 'left' ? [0, 0, fraction*width, height] :
    side === 'right' ? [fraction*width, 0, (1-fraction)*width, height] :
    [0, 0, width, height]


  if (event.context instanceof CanvasRenderingContext2D) {
    const canvas = event.context;
    canvas.save();
    canvas.beginPath();
    canvas.rect(...renderRect);
    canvas.clip();
  } else /* WebGL */ {
    const gl = event.context;
    gl.enable(gl.SCISSOR_TEST);
    gl.scissor(...renderRect);
  }
}

function curtainPostRender(event) {
  if (!window.store.ui.map.props.curtain) return;

  if (event.context instanceof CanvasRenderingContext2D) {
    const canvas = event.context;
    canvas.restore();
  } else /* WebGL */ {
    const gl = event.context;
    gl.disable(gl.SCISSOR_TEST);
  }
}


export {
  getBasemaps,
  getWMSLayers,
  getRGBLayer,
  getFloatLayer,
  getFullscreenLayer,
  getShapefileLayer,
  customLoader,
  bufferFromPNG,
  bufferFromTIFF,
  urlFromBuffer,
  colormap,
  curtainPreRender,
  curtainPostRender,
};
