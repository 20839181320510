import React, { memo } from 'react';
import './TextField.css';

function TextField({ textFieldKey, textFieldValue, isWrap = false, className }) {
  return (
    <div
      className={`area_details_tab_panel_text_field_main_container ${
        !isWrap && 'area_details_tab_panel_text_field_hidden_overflow'
      } ${className && className}`}
    >
      <span className="area_details_tab_panel_text_field_text_field_key">{textFieldKey}:</span>
      &nbsp;
      <span
        className={`area_details_tab_panel_text_field_value ${
          !isWrap && 'area_details_tab_panel_text_field'
        }`}
      >
        {textFieldValue}
      </span>
    </div>
  );
}

export default memo(TextField);
