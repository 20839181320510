import React, { useCallback, useMemo, useState } from 'react';
import api from 'src/api';
import UploadButton from 'src/Common/Buttons/UploadButton';
import './UploadAvatar.less';
import uploadIcon from 'public/icons/upload_cloud_dark.svg';

export default function UploadAvatar({ avatar, isTeam }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleChange = useCallback(
    async files => {
      const [file] = files;
      try {
        setError(null);
        setLoading(true);
        if (file && file.size > 2e6)
          throw 'Your image size is bigger than 2MB';

        await uploadPicture(file);
        document.querySelector('.settings_upload_avatar_image').src = avatar; // refetch image

        setLoading(false);
      } catch (e) {
        console.error(e);
        setError(e.toString());
        setLoading(false);
      }
    },
    [avatar]
  );

  const uploadPicture = async picture => {
    let response = null;
    if (!isTeam) {
      response = await api.call('/uploadUserAvatar');
    } else {
      response = await api.call('/uploadTeamAvatar');
    }
    const signedRequestBundle = response.success;

    if (!signedRequestBundle)
      throw new Error(`Unable to obtain an upload URL: ${response.error || response}`);

    const formFields = new FormData();
    Object.entries(signedRequestBundle.fields).forEach(_ => formFields.append(..._));
    formFields.append('file', picture);

    const result = await fetch(signedRequestBundle.url, {
      method: 'POST',
      body: formFields,
    });

    return result;
  };

  const uploadButtonContent = useMemo(() =>
    <img src={uploadIcon} alt="upload icon" className="settings_upload_avatar_edit_button" />,
  []);

  return (
    <div className="settings_upload_avatar_main_container">
      <div className="settings_upload_avatar_image_container" style={{ position: 'relative' }}>
        <img
          className="settings_upload_avatar_image"
          src={avatar}
          alt="avatar"
          onError={e => {
            if (!e.target.src?.endsWith('/user_placeholder.png'))
              e.target.src = '/user_placeholder.png';
          }}
        />
        <UploadButton
          id="download_profile_avatar_button"
          accept=".jpg,.png"
          buttonContent={uploadButtonContent}
          onChange={handleChange}
          additionalClassName="settings_upload_avatar_buttons_tooltip"
          style={{
            position: 'absolute',
            top: '50px',
            right: '10px',
          }}
        />
      </div>
      {error && <p className="settings_upload_avatar_error">{error}</p>}
      {loading && <span className="spinner"></span>}
    </div>
  );
};