import React from 'react';

export default function Tick({ tick, scale = 7 }) {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          marginTop: 15.5,
          width: scale,
          height: scale,
          top: -19,
          background: `var(--backgroundColor) 0% 0% no-repeat padding-box`,
          border: '2px solid #6AAFE1',
          transform: 'matrix(0.71, -0.71, 0.71, 0.71, 0, 0)',
          left: `${tick.percent}%`,
          zIndex: 15,
        }}
      />
    </div>
  );
}
